import React, { Component } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  message,
  Pagination,
  Select,
  Spin,
  Table,
  Tag,
  Tooltip,
  TreeSelect,
} from "antd";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  FlagFilled,
} from "@ant-design/icons";
import { connect } from "react-redux";
import IntlMessages from "../../util/IntlMessages";
import CustomScrollbars from "../../util/CustomScrollbars";
import AppModuleHeader from "../../components/AppModuleHeader";
import AddTodo from "../../modals/Todo/addTodo";
import AddPeopleTags from "../../modals/Todo/addPeopleTags";
import axiosJSON from "../../util/SetHeaderAPI";
import moment from "moment";
import "nprogress/nprogress.css";
import nprogress from "nprogress";
import { CustomModal as Modal } from "../../util/CustomModal";
import { debounce } from "lodash";

const sortData = [
  {
    title: "By Title",
    value: "title",
    children: [
      {
        title: (
          <span>
            Title <ArrowUpOutlined />
          </span>
        ),
        value: "title_asc",
      },
      {
        title: (
          <span>
            Title <ArrowDownOutlined />
          </span>
        ),
        value: "title_desc",
      },
    ],
  },
  {
    title: "By Priority",
    value: "priority",
    children: [
      {
        title: (
          <span>
            Priority <ArrowUpOutlined />
          </span>
        ),
        value: "priority_asc",
      },
      {
        title: (
          <span>
            Priority <ArrowDownOutlined />
          </span>
        ),
        value: "priority_desc",
      },
    ],
  },
];

class Todo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      user: {
        name: this.props.auth.name,
      },
      todoList: [],
      filter: {},
      drawerState: false,
      addTask: false,
      candidateList: [],
      userList: [],
      labelList: [],
      priorityList: [],
      statusList: [],
      statusFlag: [false],
      priorityFlag: [false],
      peopleTags: [],
      tagsFlag: false,
      currentTodo: null,
      filterList: [
        {
          name: "Status",
          value: "status",
        },
        {
          name: "Labels",
          value: "labels",
        },
        {
          name: "Priority",
          value: "priority",
        },
      ],
      openFilterOption: [false],
      filters: [],
      filterObj: {},
      page: 1,
      sortObj: "",
      total: 0,
      filter_for: "all",
      loading: false,
    };
  }

  componentDidMount() {
    nprogress.start();
    this.getFilterData();
    this.getTodoList(1);
    this.getPriorityList();
    this.getLabelList();
    this.getStatus();
    this.getUserList("");
    this.getCandidateList("");
    nprogress.done();
  }

  getFilterData = () => {
    axiosJSON
      .get("/api/todo/filter_data/")
      .then(({ data }) => {
        this.setState({
          filters: data.data,
        });
      })
      .catch((err) => message.error(err.message));
  };

  onToggleDrawer = () => {
    this.setState({
      drawerState: !this.state.drawerState,
    });
  };
  addTask = (currentTodo) => {
    this.setState({
      addTask: true,
      currentTodo: currentTodo,
    });
  };
  handleRequestClose = () => {
    this.setState(
      {
        addTask: false,
        tagsFlag: false,
      },
      () => this.getTodoList(this.state.page)
    );
  };

  toggleFilterOption = (i) => {
    let openFilterOption = this.state.openFilterOption;
    openFilterOption[i] = !this.state.openFilterOption[i];
    this.setState({
      openFilterOption,
    });
  };
  filterMenu = (filter, k) => {
    var filterList = Object.keys(this.state.filters);
    var options;
    filterList.map((ele, i) => {
      if (ele === filter) {
        options = this.state.filters[ele].map((filterObj, j) => (
          <div key={j}>
            <Checkbox
              checked={
                ele !== "labels"
                  ? this.state.filterObj.hasOwnProperty(filter) &&
                    this.state.filterObj[filter].includes(filterObj.id)
                  : this.state.filterObj.hasOwnProperty(filter) &&
                    this.state.filterObj[filter].includes(filterObj.name)
              }
              onChange={(e) =>
                ele !== "labels"
                  ? this.selectFilter(e.target.checked, filterObj.id, filter, k)
                  : this.selectFilter(
                      e.target.checked,
                      filterObj.name,
                      filter,
                      k
                    )
              }
            />
            {filterObj.display_name}
          </div>
        ));
      }
      return null;
    });
    return options;
  };

  selectFilter = (checked, obj, type, i) => {
    let filterObj = this.state.filterObj;
    let o = this.state.filterObj;
    if (checked) {
      if (o.hasOwnProperty(type)) {
        if (type === "custom" || type === "important") {
          o[type] = obj;
        } else if (type === "due_by") {
          o[type] = obj;
        } else {
          o[type].push(obj);
        }
      } else if (type === "custom" || type === "important") {
        o[type] = obj;
        filterObj = o;
      } else if (type === "due_by") {
        o[type] = obj;
      } else {
        o[type] = [obj];
        filterObj = o;
      }
    } else {
      if (o.hasOwnProperty(type)) {
        if (type === "custom" || type === "important") {
          o[type] = checked;
        } else {
          var index = o[type].indexOf(obj);
          if (index !== -1) o[type].splice(index, 1);
          if (o[type].length === 0) delete o[type];
        }
      }
    }
    let openFilterOption = this.state.openFilterOption;
    openFilterOption[i] = true;

    this.setState(
      {
        filterObj: filterObj,
        openFilterOption,
      },
      () => this.getTodoList(1)
    );
  };

  ToDoSideBar = () => {
    return (
      <div className="gx-module-side">
        <div className="gx-module-side-header">
          <div className="gx-module-logo">
            <i className="icon icon-select gx-mr-4" />
            <IntlMessages id="sidebar.todo" />
          </div>
        </div>
        <div className="gx-module-side-content">
          <CustomScrollbars>
            <div className="gx-module-add-task">
              <Button type="primary" onClick={() => this.addTask(null)}>
                Add Task
              </Button>
              <ul className="gx-module-nav new-side-nav">
                <li className="gx-module-nav-label">
                  <IntlMessages id="candidate.filters" />
                  {Object.keys(this.state.filterObj).length !== 0 && (
                    <span
                      className="gx-pointer gx-text-blue"
                      onClick={() =>
                        this.setState({ filterObj: {} }, () =>
                          this.getTodoList(1)
                        )
                      }
                    >
                      Clear all filters
                    </span>
                  )}

                  {this.state.filterList.map((filter, i) => (
                    <div
                      className="sidebar_new gx-pointer"
                      key={i}
                      onClick={() => this.toggleFilterOption(i)}
                    >
                      {filter.name}
                      {this.state.openFilterOption[i] ? (
                        <i className="icon icon-menu-up gx-pointer" />
                      ) : (
                        <i className="icon icon-menu-down gx-pointer" />
                      )}
                      {this.state.openFilterOption[i] && (
                        <div>{this.filterMenu(filter.value, i)}</div>
                      )}
                    </div>
                  ))}

                  <div className="sidebar_new">
                    <Checkbox
                      checked={
                        this.state.filterObj.hasOwnProperty("custom") &&
                        this.state.filterObj["custom"]
                      }
                      onChange={(e) =>
                        this.selectFilter(
                          e.target.checked,
                          e.target.checked,
                          "custom",
                          6
                        )
                      }
                    />
                    Custom
                  </div>

                  <div className="sidebar_new">
                    <Checkbox
                      checked={
                        this.state.filterObj.hasOwnProperty("important") &&
                        this.state.filterObj["important"]
                      }
                      onChange={(e) =>
                        this.selectFilter(
                          e.target.checked,
                          e.target.checked,
                          "important",
                          6
                        )
                      }
                    />
                    Important
                  </div>

                  <div className="sidebar_new">
                    <Checkbox
                      checked={this.state.filter_for === "my"}
                      onChange={(e) => this.onChangeFilterFor(e.target.checked)}
                    />
                    My
                  </div>

                  <div className="sidebar_new">
                    Due Date
                    <div>
                      <DatePicker
                        className="sidebar-datepicker"
                        onChange={(date, dateString) =>
                          this.selectFilter(true, dateString, "due_by", 6)
                        }
                        picker="date"
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </CustomScrollbars>
        </div>
      </div>
    );
  };
  getTodoList = (page) => {
    this.setState({ loading: true });
    axiosJSON
      .get(
        "/api/todo/?page=" +
          page +
          "&filter_json=" +
          JSON.stringify(this.state.filterObj) +
          "&sort_by=" +
          this.state.sortObj +
          "&query=" +
          this.state.query +
          "&filter_for=" +
          this.state.filter_for
      )
      .then(({ data }) => {
        let obj = {};
        let todoList = [];
        data.data.map((ele, index) => {
          var startOfToday = moment().startOf("day");
          var startOfDate = moment(ele.due_by).startOf("day");
          var daysDiff = startOfDate.diff(startOfToday, "days");
          if (ele.status.name === "complete") {
            obj = { ...ele, key: ele.id, strike: true, overdue: false };
          } else if (daysDiff === -1) {
            obj = { ...ele, key: ele.id, strike: false, overdue: true };
          } else {
            obj = { ...ele, key: ele.id, strike: false, overdue: false };
          }
          todoList.push(obj);
          return todoList;
        });
        this.setState({
          todoList: todoList,
          total: data.total,
          page: page,
          loading: false,
        });
      })
      .catch((err) => {
        message.error(err.message);
        this.setState({ loading: false });
      });
  };

  getPriorityList = () => {
    axiosJSON
      .get("/api/choice/?type=priority")
      .then(({ data }) => {
        this.setState({
          priorityList: data.data,
        });
      })
      .catch((err) => message.error(err.message));
  };
  getLabelList = () => {
    axiosJSON
      .get("/api/todo/labels/")
      .then(({ data }) => {
        this.setState({
          labelList: data.data,
        });
      })
      .catch((err) => message.error(err.message));
  };
  getStatus = () => {
    axiosJSON
      .get("/api/choice/?type=todo_status")
      .then(({ data }) => {
        this.setState({
          statusList: data.data,
        });
      })
      .catch((err) => message.error(err.message));
  };

  getUserList = (query) => {
    axiosJSON
      .get("/api/user/?query=" + query)
      .then(({ data }) => {
        this.setState({
          userList: data.data,
        });
      })
      .catch((err) => message.error(err.message));
  };

  getCandidateList = (query) => {
    axiosJSON
      .get("/api/candidate/dropdown/?query=" + query)
      .then(({ data }) => {
        this.setState({
          candidateList: data.data,
        });
      })
      .catch((err) => message.error(err.message));
  };
  onSelectChange = (id) => {
    axiosJSON
      .put("/api/todo/" + id + "/complete/")
      .then(({ data }) => {
        message.success(data.message);
        let todoList = this.state.todoList;
        todoList.map((todo) => {
          if (todo.id === id) {
            todo.strike = true;
          }

          return todoList;
        });
        setTimeout(() => {
          this.setState({
            todoList: todoList,
          });
        }, 500);
      })
      .catch((err) => message.error(err.message));
  };

  updateTodo = (todo, state, value) => {
    const body = {
      [state]: value,
    };
    axiosJSON
      .put("/api/todo/" + todo.id + "/", body)
      .then(({ data }) => {
        message.success(data.message);
        this.setState(
          {
            statusFlag: [false],
            priorityFlag: [false],
            tagsFlag: false,
            currentTodo: null,
          },
          () => this.getTodoList(1)
        );
      })
      .catch((err) => message.error(err.message));
  };

  onChange = (state, value, todo) => {
    this.updateTodo(todo, [state], value);
  };
  setChange = (state, value, i, flagArr) => {
    let arr = this.state[flagArr];
    arr[i] = true;
    this.setState({
      [state]: value.display_name,
      [flagArr]: arr,
    });
  };
  getDataFormat = (d) => {
    var dateText = moment(d);
    var startOfToday = moment().startOf("day");
    var startOfDate = moment(d).startOf("day");
    var daysDiff = startOfDate.diff(startOfToday, "days");
    var days = {
      0: "Today",
      "-1": "Yesterday",
      1: "Tomorrow",
    };
    if (Math.abs(daysDiff) <= 1) {
      return days[daysDiff];
    } else {
      return dateText.format("LLLL");
    }
  };
  setPeopleTags = (tags, todo) => {
    let people_tags = [];
    tags.map((p) => people_tags.push(p.id));

    this.setState({
      currentTodo: todo,
      peopleTags: people_tags,
      tagsFlag: true,
    });
  };
  onSelectPeopleTags = (value) => {
    this.setState({
      peopleTags: value,
    });
  };
  selectSortBy = (value) => {
    this.setState(
      {
        sortObj: value,
        page: 1,
      },
      () => this.getTodoList(this.state.page)
    );
  };

  serarchListAPI = debounce(() => {
    this.getTodoList(this.state.page);
  }, 500);

  updateSearch(evt) {
    evt.preventDefault();
    this.setState(
      {
        query: evt.target.value,
        page: 1,
      },
      () => this.serarchListAPI()
    );
  }

  onChangeFilterFor = (checked) => {
    if (checked) {
      this.setState(
        {
          filter_for: "my",
        },
        () => this.getTodoList(this.state.page)
      );
    } else {
      this.setState(
        {
          filter_for: "all",
        },
        () => this.getTodoList(this.state.page)
      );
    }
  };

  render() {
    const { drawerState, addTask, tagsFlag } = this.state;
    const columns = [
      {
        title: null,
        dataIndex: "strike",
        width: "3%",
        render: (text, record) =>
          text ? (
            <CheckCircleFilled style={{ color: "#02b60c", fontSize: 18 }} />
          ) : (
            <CheckCircleOutlined
              style={{ fontSize: 18 }}
              onClick={() => this.onSelectChange(record.id)}
            />
          ),
      },
      {
        title: null,
        dataIndex: "important",
        width: "3%",
        render: (text, record) =>
          text ? (
            <ExclamationCircleFilled
              style={{ color: "#fcb502", fontSize: 18 }}
              onClick={() =>
                !record.strike
                  ? this.updateTodo(record, "important", false)
                  : null
              }
            />
          ) : (
            <ExclamationCircleOutlined
              style={{ fontSize: 18 }}
              onClick={() =>
                !record.strike
                  ? this.updateTodo(record, "important", true)
                  : null
              }
            />
          ),
      },
      {
        title: "Title",
        dataIndex: "title",
        width: "20%",
        ellipsis: true,
      },
      {
        title: "Status",
        dataIndex: "status",
        width: "10%",
        render: (status, record, i) =>
          !this.state.statusFlag[i] ? (
            <Tag
              onClick={() =>
                !record.strike &&
                this.setChange("currentStatus", status, i, "statusFlag")
              }
              color={status.description}
            >
              {status.display_name}
            </Tag>
          ) : (
            !record.strike && (
              <Select
                onBlur={() => {
                  let arr = this.state.statusFlag;
                  arr[i] = false;
                  this.setState({
                    statusFlag: arr,
                  });
                }}
                style={{ width: "110px" }}
                value={this.state.currentStatus}
                onChange={(v) => this.updateTodo(record, "status", v)}
              >
                {this.state.statusList.map((s, index) => (
                  <Select.Option value={s.id}>{s.display_name}</Select.Option>
                ))}
              </Select>
            )
          ),
      },
      {
        title: "Priority",
        dataIndex: "priority",
        width: "10%",
        // sorter: (a, b) => parseInt(b.priority.name) - parseInt(a.priority.name),
        // sortDirections: ['ascend', 'descend', false],
        render: (priority, record, i) =>
          priority !== null ? (
            !this.state.priorityFlag[i] ? (
              <Tooltip title={priority.display_name}>
                <FlagFilled
                  onClick={() =>
                    this.setChange(
                      "currentPriority",
                      priority,
                      i,
                      "priorityFlag"
                    )
                  }
                  style={{ color: priority.description, fontSize: 18 }}
                />
              </Tooltip>
            ) : (
              !record.strike && (
                <Select
                  onBlur={() => {
                    let priorityFlag = this.state.priorityFlag;
                    priorityFlag[i] = false;
                    this.setState({
                      priorityFlag: priorityFlag,
                    });
                  }}
                  style={{ width: "110px" }}
                  value={this.state.currentPriority}
                  onChange={(v) => this.updateTodo(record, "priority", v)}
                >
                  {this.state.priorityList.map((p, index) => (
                    <Select.Option value={p.id}>
                      <FlagFilled
                        style={{ color: p.description, fontSize: 18 }}
                      />{" "}
                      {p.display_name}
                    </Select.Option>
                  ))}
                </Select>
              )
            )
          ) : null,
      },
      {
        title: "People Tags",
        dataIndex: "people_tags",
        width: "10%",
        render: (people_tags, record, i) => (
          <div key={i}>
            {people_tags
              ? people_tags.map((p, index) =>
                  p.name.split(" ").length >= 2
                    ? index < 3 && (
                        <Avatar className="gx-size-20 gx-pointer gx-mr-2 avatar-tags">
                          <Tooltip title={p.name}>
                            {p.name.split(" ")[0].charAt(0).toUpperCase() +
                              p.name.split(" ")[1].charAt(0).toUpperCase()}
                          </Tooltip>
                        </Avatar>
                      )
                    : index < 3 && (
                        <Avatar className="gx-size-20 gx-pointer gx-mr-2 avatar-tags">
                          <Tooltip title={p.name}>
                            {p.name.split(" ")[0].charAt(0).toUpperCase()}
                          </Tooltip>
                        </Avatar>
                      )
                )
              : null}
            {people_tags.length >= 3 && !record.strike ? (
              <Avatar
                className="gx-size-20 gx-pointer gx-mr-2 avatar-tags"
                onClick={() => this.setPeopleTags(people_tags, record)}
              >
                <Tooltip title="Show more">..</Tooltip>
              </Avatar>
            ) : (
              people_tags.length >= 3 && (
                <Avatar className="gx-size-20 gx-pointer gx-mr-2 avatar-tags">
                  <Tooltip
                    title={people_tags.map((tag, index) => {
                      if (index > 2) return tag.name;
                      return null;
                    })}
                  >
                    ..
                  </Tooltip>
                </Avatar>
              )
            )}
            {!record.strike && (
              <Avatar
                className="gx-size-20 gx-fs-md gx-pointer avatar-tags"
                onClick={() => this.setPeopleTags(people_tags, record)}
              >
                +
              </Avatar>
            )}
          </div>
        ),
      },
      {
        title: "Due Date",
        dataIndex: "due_by",
        width: "22%",
        render: (text, record) => (
          <div>
            {text !== null ? (
              <span>{this.getDataFormat(text)}</span>
            ) : (
              "No due date"
            )}
            {record.overdue && (
              <Tag
                className="gx-fs-xs"
                color="red"
                style={{ position: "absolute", bottom: 10, right: 10 }}
              >
                Overdue
              </Tag>
            )}
          </div>
        ),
      },
      {
        title: "",
        width: "7%",
        dataIndex: "id",
        render: (text, record) =>
          record.strike ? null : (
            <EditOutlined onClick={() => this.addTask(record)} />
          ),
      },
    ];
    return (
      <div className="gx-main-content">
        <div className="gx-app-module">
          <div className="gx-d-block gx-d-lg-none">
            <Drawer
              placement="left"
              closable={false}
              open={drawerState}
              onClose={this.onToggleDrawer.bind(this)}
            >
              {this.ToDoSideBar()}
            </Drawer>
          </div>
          <div className="gx-module-sidenav gx-d-none gx-d-lg-flex">
            {this.ToDoSideBar()}
          </div>
          <div className="gx-module-box">
            <div className="gx-module-box-header">
              <span className="gx-drawer-btn gx-d-flex gx-d-lg-none">
                <i
                  className="icon icon-menu gx-icon-btn"
                  aria-label="Menu"
                  onClick={this.onToggleDrawer.bind(this)}
                />
              </span>
              <AppModuleHeader
                placeholder="Search Task"
                user={this.state.user}
                onChange={this.updateSearch.bind(this)}
                value={this.state.query}
              />
            </div>
            <div className="gx-module-box-content">
              <div className="gx-module-box-topbar gx-module-box-topbar-todo">
                <TreeSelect
                  style={{ width: "15%" }}
                  className="right-button"
                  value={this.state.value}
                  dropdownStyle={{ maxHeight: 200, overflow: "auto" }}
                  treeData={sortData}
                  placeholder="Sort by"
                  treeDefaultExpandAll={false}
                  onChange={this.selectSortBy}
                />
              </div>
              <Spin spinning={this.state.loading}>
                <Table
                  rowClassName={(record) =>
                    record.strike ? "table-strike-through" : null
                  }
                  columns={columns}
                  dataSource={this.state.todoList}
                  pagination={false}
                />
                <div className="Pagination-section">
                  <Pagination
                    simple
                    total={this.state.total}
                    current={this.state.page}
                    onChange={(page) =>
                      this.setState({ page: page }, () =>
                        this.getTodoList(this.state.page)
                      )
                    }
                  />
                </div>
              </Spin>
            </div>
          </div>
        </div>
        {addTask && (
          <Modal
            title={this.state.currentTodo === null ? "New Task" : "Edit Task"}
            className="schedule-form-popup"
            visible={addTask}
            footer={null}
            onCancel={this.handleRequestClose.bind(this)}
          >
            <AddTodo
              currentTodo={this.state.currentTodo}
              userList={this.state.userList}
              labelList={this.state.labelList}
              candidateList={this.state.candidateList}
              priorityList={this.state.priorityList}
              statusList={this.state.statusList}
              getCandidateList={this.getCandidateList}
              getUserList={this.getUserList}
              closeModal={this.handleRequestClose}
            />
          </Modal>
        )}
        {tagsFlag && (
          <Modal
            title="Add People"
            visible={tagsFlag}
            footer={null}
            onCancel={this.handleRequestClose.bind(this)}
          >
            <AddPeopleTags
              getUserList={this.getUserList}
              onSelectPeopleTags={this.onSelectPeopleTags}
              updateTodo={this.updateTodo}
              peopleTags={this.state.peopleTags}
              currentTodo={this.state.currentTodo}
              userList={this.state.userList}
              closeModal={this.handleRequestClose}
            />
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.authUser,
});

export default connect(mapStateToProps)(Todo);
