import React from "react";
import axiosJSON from "../../util/SetHeaderAPI";
import { Select, Button, Popconfirm, message } from "antd";

const { Option } = Select;
class PositionStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            status: this.props.data.status,
            text: "Do you want to keep status same?"
        }
    }

    handleChange = (value) => {
        let text = "Are you sure you want to change the status from " + this.state.data.status + " to " + value + " ?"
        this.setState({
            status: value,
            text: text
        })
    }

    closeModal = () => {
        this.setState({
            status: this.state.data.status,
            text: "Do you want to keep status same?"
        })
    }

    changeStatus = (id) => {
        let body = {
            "status": this.state.status
        }
        axiosJSON.put('/api/position/' + id + '/status/', body)
            .then(({ data, status }) => {
                message.success(data.message)
                this.props.closeModal()
            }).catch(function (error) {
                message.error(error.message)
            })
    }

    render() {
        const { data, status, text } = this.state;
        return (<div style={{ paddingBottom:'0' }} className="gx-login-container">
            <div className="current_status_popup">
                
                <ul>
                   <li> <h4>Current Status:</h4>  <span> {data.status}</span> </li>
                   
                    <li> <h4>Select Status:</h4>
                      <span> <Select style={{ width:'110px' }} placeholder="Status..." value={status} onChange={this.handleChange}>
                            <Option value="published">Published</Option>
                            {/* <Option disabled value="draft">Draft</Option> */}
                            <Option value="closed">Closed</Option>
                        </Select></span> 
                    </li>

                </ul>

                <span>
                    <Popconfirm
                        title={text}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => this.changeStatus(data.id)}
                        onCancel={() => this.closeModal()}
                    >
                        <Button type="primary">Submit</Button>
                    </Popconfirm></span>
            </div>
        </div>
        );
    }
}

export default PositionStatus;