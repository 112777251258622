export default [
  {
    'id': 0,
    'handle': 'inbox',
    'title': 'Inbox',
    'icon': 'inbox'
  },
  {
    'id': 1,
    'handle': 'sent',
    'title': 'Sent',
    'icon': 'sent'
  },
  {
    'id': 2,
    'handle': 'draft',
    'title': 'Drafts',
    'icon': 'draft'
  },
  {
    'id': 3,
    'handle': 'failed',
    'title': 'Failed',
    'icon': 'error'
  },
  {
    'id': 4,
    'handle': 'archived',
    'title': 'Archived',
    'icon': 'mail-open'
  },
];
