import React, { Component } from "react";
import Widget from "../../../../components/Widget";
import axiosJSON from "../../../../util/SetHeaderAPI";
import { InfoCircleTwoTone, EditOutlined } from '@ant-design/icons';
import { Collapse, Col, Checkbox, message, Row, Tooltip, Button } from "antd";
import "nprogress/nprogress.css";
import nprogress from "nprogress";

const { Panel } = Collapse;
class NotificationSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      events_list: [],
      email_checked_events: [],
      edit: false,
      email_checked_id: ""
    }
  }
  componentDidMount() {
    nprogress.start()
    this.getInitialData()
    nprogress.done()
  }

  getInitialData = () => {
    axiosJSON.get('api/notification_events/')
      .then(({ data, status }) => {
        if (status === 200) {
          this.setState({
            events_list: data.data
          }, () => this.getCheckedData())
        }
      }).catch(function (error) {
        message.error(error.message)
      })
  }

  getCheckedData = () => {
    axiosJSON.get('api/user_notification/')
      .then(({ data, status }) => {
        if (status === 200) {
          data.data.map((type) => {
            if (type.type === "email") {
              this.setState({
                email_checked_events: type.events,
                email_checked_id: type.id
              }, () => this.setChecked())
            }
            return null
          })
        }
      }).catch(function (error) {
        message.error(error.message)
      })
  }

  setChecked = () => {
    let eventObj = {};
    let eventList = [];
    this.state.events_list.map((event, index) => {
      if (this.state.email_checked_events.includes(event.id)) {
        eventObj = event;
        eventObj["checked"] = true
      }
      else {
        eventObj = event;
        eventObj["checked"] = false
      }
      eventList.push(eventObj)
      return eventList
    })
    this.setState({
      events_list: eventList
    })
  }

  onChange = (event) => {
    if (this.state.edit) {
      let eventObj = event;
      let eventList = this.state.events_list
      let checked_list = this.state.email_checked_events
      eventList.filter((events) => {
        if (events === eventObj) {
          events["checked"] = !events["checked"]
          if (events["checked"]) {
            checked_list.push(event.id)
          }
          else {
            if (checked_list.includes(event.id)) { checked_list.pop(event.ids) }
          }
        }
        return null
      })
      this.setState({
        events_list: eventList,
        email_checked_events: checked_list
      })
    }
    else {
      return null
    }
  }

  editEmailCheckBoxes = () => {
    this.setState({
      edit: true
    })
  }

  discard = () => {
    this.setState({
      edit: false
    }, () => this.getInitialData())
  }

  submit = () => {
    let body = {
      "events": this.state.email_checked_events
    }
    axiosJSON.post('api/user_notification/', body)
      .then(({ data, status }) => {
        if (status === 202) {
          message.success(data.message)
          this.setState({
            edit: false,
          }, () => this.getInitialData())
        }
      }).catch(function (error) {
        message.error(error.message)
      })
  }

  render() {
    const { edit, events_list } = this.state;
    return (
      <div style={{ padding: '20px' }} className="gx-profile-content-right">
        <Row>
          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <Widget className="notification_setting" title="Notification Settings" styleName="gx-card-profile">

             <div className="notification_setting_section">
              <Collapse bordered={false}>
                <Panel header="Email Notification" key="1">
                  <div className="edit-icon">{!edit && <EditOutlined onClick={() => this.editEmailCheckBoxes()} />}</div>

                  <div className="Notification-list">{events_list.map((event, index) => (<span>
                    <Checkbox key={index} checked={event.checked} onChange={() => this.onChange(event)} >{event.display_name}</Checkbox> {event.description && <Tooltip title={event.description}><InfoCircleTwoTone /></Tooltip>} <br />
                  </span>
                  ))}</div>

                  <div>{edit && <Button type="primary" onClick={this.discard}>Discard</Button>} {edit && <Button type="primary" onClick={this.submit}>Submit</Button>}</div>
                </Panel>
              </Collapse>
              </div>

            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

export default NotificationSettings;

// // (
  //  <span>  <Checkbox key={index} checked={}>{event.display_name}</Checkbox> {event.description && <Tooltip title={event.description}><InfoCircleTwoTone /></Tooltip>} <br/>   </span>
// // )
