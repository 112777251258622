import React, { Component } from "react";

import { Pie, PieChart, ResponsiveContainer, Sector, Cell } from "recharts";
import { DatePicker, Button, message, Spin } from "antd";
import axiosJSON from "../../../../../util/Api";
import moment from "moment-timezone";
import { RxDotFilled } from "react-icons/rx";

const { RangePicker } = DatePicker;

const COLORST = [

  "#F48A4F",
  "#2e9cc7",
  "#8C68CD",
  "#D7A614",
  "#555F8B",
  "#28A745",
  "#1890ff",
];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {/* {payload.sum} - {payload.name} - {payload.value} */}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{` ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

class TeamAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: "",
      end: "",
      data: null,
      consultadd: null,
      consultaddTotal: null,
      flash: null,
      xPlus: null,
      garuda: null,
      flashTotal: null,
      garudaTotal: null,
      xPlusTotal: null,
      activeIndex: null,
      loading: false,
    };
  }

  onRangeChange = (value) => {
    if (value === null) {
      this.setState({
        start: "",
        end: "",
      });
    } else {
      let start = value[0],
        end = value[1];

      this.setState({
        start: start,
        end: end,
      });
    }
  };

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  getReportsData = (start, end) => {
    this.setState({
      loading: true,
    });
    if (start && end) {
      start = moment(this.state.start).format("YYYY-MM-DD");
      end = moment(this.state.end).format("YYYY-MM-DD");
    }
    axiosJSON
      .get(`api/recruitment_report/team/?start=${start}&end=${end}`)
      .then((res) => {
        let team = {};
        let teamList = [];
        res.data.data.map((item, i) => {
          team = {
            name: item.name,
            id: item.id,
            totalCount: item.total_count,
            pie: [
              item.bench,
              item.candidate_added,
              item.disqualified,
              item.qualified,
              item.screening,
              item.training,
            ],
          };
          return teamList.push(team);
        });
        this.setState({
          data: teamList,
          loading: false,
          consultadd: this.filterArray(teamList, "Consultadd")
            ? this.filterArray(teamList, "Consultadd").pie
            : null,
          consultaddTotal: this.filterArray(teamList, "Consultadd")
            ? this.filterArray(teamList, "Consultadd").totalCount
            : null,
          garuda: this.filterArray(teamList, "Garuda")
            ? this.filterArray(teamList, "Garuda").pie
            : null,
          garudaTotal: this.filterArray(teamList, "Garuda")
            ? this.filterArray(teamList, "Garuda").totalCount
            : null,
          flash: this.filterArray(teamList, "Flash")
            ? this.filterArray(teamList, "Flash").pie
            : null,
          flashTotal: this.filterArray(teamList, "Flash")
            ? this.filterArray(teamList, "Flash").totalCount
            : null,
          xPlus: this.filterArray(teamList, "X Plus")
            ? this.filterArray(teamList, "X Plus").pie
            : null,
          xPlusTotal: this.filterArray(teamList, "X Plus")
            ? this.filterArray(teamList, "X Plus").totalCount

            : null,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        message.error(error);
      });
  };

  filterArray = (list, filterString) =>
    list.filter((item) => item.name === filterString)[0];

  componentDidMount = () => {
    this.getReportsData(this.state.start, this.state.end);
  };

  render() {
    const {
      consultadd,
      consultaddTotal,
      flash,
      xPlus,
      garuda,
      flashTotal,
      garudaTotal,
      xPlusTotal,
      loading,
    } = this.state;
    return (
      <div className="row">
        <div className="col-6 p-0 mb-3">
          <RangePicker
            className="rangePickerStyle"
            onChange={(value) => this.onRangeChange(value)}
            style={{ marginLeft: "0px" }}
            value={[this.state.start, this.state.end]}
            format="MM-DD-YYYY"
          />
          <Button
            type="primary applyButton m-0"
            onClick={this.componentDidMount}
          >
            Apply
          </Button>
        </div>
        <div className="col-12 p-0">
          <Spin spinning={loading}>
            <div className="pieChart row">

              {consultaddTotal > 0 && (
                <div className="col-6 mb-4">

                  <div className="card">
                    <div className="card-header">Team - Consultadd</div>
                    <div className="card-body">
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-6">
                            <ResponsiveContainer width="100%" height={230}>
                              <PieChart>
                                <text
                                  x="50%"
                                  className="h2"
                                  y="50%"
                                  textAnchor="middle"
                                  dominantBaseline="middle"
                                >
                                  Total -{" "}
                                  {consultaddTotal ? consultaddTotal : "NA"}
                                </text>

                                <Pie
                                  //activeIndex={this.state.activeIndexT}
                                  activeShape={renderActiveShape}
                                  data={consultadd}
                                  cx="50%"
                                  cy="50%"
                                  innerRadius={80}
                                  outerRadius={100}
                                  fill="#8884d8"
                                  dataKey="count"
                                  // onMouseEnter={this.onPieEnterT}
                                >
                                  {consultadd &&
                                    consultadd.map((entry, index) => (
                                      <Cell
                                        key={`cell-${index}`}
                                        fill={COLORST[index % COLORST.length]}
                                      />
                                    ))}
                                </Pie>
                              </PieChart>
                            </ResponsiveContainer>
                          </div>
                          <div className="col-6">
                            {consultadd &&
                              consultadd.map((item, i) => (
                                <div className="col-12" key={i}>
                                  {item.count > 0 ? (
                                    <div className="white p-3 font-16 top_report">
                                      <div className="mb-2">
                                        <span className="pr-2">
                                          <RxDotFilled
                                            color={COLORST[i % COLORST.length]}
                                          />
                                        </span>
                                        <span className="ico_heading">
                                          {item.name}
                                        </span>
                                      </div>
                                      <div className="text_pie">
                                        <span className="pr-3 font_custom">
                                          {item.count}
                                        </span>
                                        <span className="tag_blue">
                                          {consultaddTotal > 0
                                            ? (
                                                (item.count / consultaddTotal) *
                                                100
                                              ).toFixed(2) + "%"
                                            : "NA"}
                                        </span>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              )}


              {garudaTotal > 0 && (
                <div className="col-6 mb-4">
                  <div className="card">
                    <div className="card-header">Team - Garuda</div>
                    <div className="card-body">
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-6">
                            <ResponsiveContainer width="100%" height={230}>
                              <PieChart>
                                <text
                                  x="50%"
                                  className="h2"

                                  y="50%"
                                  textAnchor="middle"
                                  dominantBaseline="middle"
                                >
                                  Total - {garudaTotal ? garudaTotal : "NA"}
                                </text>

                                <Pie
                                  // activeIndex={this.state.activeIndexT}
                                  activeShape={renderActiveShape}
                                  data={garuda}
                                  cx="50%"
                                  cy="50%"
                                  innerRadius={80}
                                  outerRadius={100}
                                  fill="#8884d8"
                                  dataKey="count"
                                  // onMouseEnter={this.onPieEnterT}
                                >
                                  {garuda &&
                                    garuda.map((entry, index) => (
                                      <Cell
                                        key={`cell-${index}`}
                                        fill={COLORST[index % COLORST.length]}
                                      />
                                    ))}
                                </Pie>
                              </PieChart>
                            </ResponsiveContainer>
                          </div>
                          <div className="col-6">
                            {garuda &&
                              garuda.map((item, i) => (
                                <div className="col-12" key={i}>
                                  {item.count > 0 ? (
                                    <div className="white p-3 font-16 top_report">
                                      <div className="mb-2">
                                        <span className="pr-2">
                                          <RxDotFilled
                                            color={COLORST[i % COLORST.length]}
                                          />
                                        </span>
                                        <span className="ico_heading">
                                          {item.name}
                                        </span>
                                      </div>
                                      <div className="text_pie">
                                        <span className="pr-3 font_custom">
                                          {item.count}
                                        </span>
                                        <span className="tag_blue">
                                          {garudaTotal > 0
                                            ? (
                                                (item.count / garudaTotal) *
                                                100
                                              ).toFixed(2) + "%"
                                            : "NA"}
                                        </span>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {xPlusTotal > 0 && (
                <div className="col-6 mb-4">
                  <div className="card">
                    <div className="card-header">Team X-Plus</div>
                    <div className="card-body">
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-6">
                            <ResponsiveContainer width="100%" height={230}>
                              <PieChart>
                                <text
                                  x="50%"
                                  className="h2"
                                  y="50%"
                                  textAnchor="middle"
                                  dominantBaseline="middle"
                                >
                                  Total - {xPlusTotal ? xPlusTotal : "NA"}
                                </text>

                                <Pie
                                  // activeIndex={this.state.activeIndexT}
                                  activeShape={renderActiveShape}
                                  data={xPlus}
                                  cx="50%"
                                  cy="50%"
                                  innerRadius={80}
                                  outerRadius={100}
                                  fill="#8884d8"
                                  dataKey="count"
                                  // onMouseEnter={this.onPieEnterT}
                                >
                                  {xPlus &&
                                    xPlus.map((entry, index) => (
                                      <Cell
                                        key={`cell-${index}`}
                                        fill={COLORST[index % COLORST.length]}
                                      />
                                    ))}
                                </Pie>
                              </PieChart>
                            </ResponsiveContainer>
                          </div>
                          <div className="col-6">
                            {xPlus &&
                              xPlus.map((item, i) => (
                                <div className="col-12" key={i}>
                                  {item.count > 0 ? (
                                    <div className="white p-3 font-16 top_report">
                                      <div className="mb-2">
                                        <span className="pr-2">
                                          <RxDotFilled
                                            color={COLORST[i % COLORST.length]}
                                          />
                                        </span>
                                        <span className="ico_heading">
                                          {item.name}
                                        </span>
                                      </div>
                                      <div className="text_pie">
                                        <span className="pr-3 font_custom">
                                          {item.count}
                                        </span>
                                        <span className="tag_blue">
                                          {xPlusTotal > 0
                                            ? (
                                                (item.count / xPlusTotal) *
                                                100
                                              ).toFixed(2) + "%"
                                            : "NA"}
                                        </span>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {flashTotal > 0 && (
                <div className="col-6 mb-4">
                  <div className="card">
                    <div className="card-header">Team - Flash</div>
                    <div className="card-body">
                      <div className="col-12 p-0">
                        <div className="row">
                          <div className="col-6">
                            <ResponsiveContainer width="100%" height={230}>
                              <PieChart>
                                {flashTotal > 0 && (
                                  <text
                                    x="50%"
                                    className="h2"
                                    y="50%"
                                    textAnchor="middle"
                                    dominantBaseline="middle"
                                  >
                                    Total - {flashTotal ? flashTotal : "NA"}
                                  </text>
                                )}
                                <Pie
                                  // activeIndex={this.state.activeIndexT}
                                  activeShape={renderActiveShape}
                                  data={flash}
                                  cx="50%"
                                  cy="50%"
                                  innerRadius={80}
                                  outerRadius={100}
                                  fill="#8884d8"
                                  dataKey="count"
                                  // onMouseEnter={this.onPieEnterT}
                                >
                                  {flash &&
                                    flash.map((entry, index) => (
                                      <Cell
                                        key={`cell-${index}`}
                                        fill={COLORST[index % COLORST.length]}
                                      />
                                    ))}
                                </Pie>
                              </PieChart>
                            </ResponsiveContainer>
                          </div>
                          {flashTotal > 0 && (
                            <div className="col-6">
                              {flash &&
                                flash.map((item, i) => (
                                  <div className="col-12" key={i}>
                                    {item.count > 0 ? (
                                      <div className="white p-3 font-16 top_report">
                                        <div className="mb-2">
                                          <span className="pr-2">
                                            <RxDotFilled
                                              color={
                                                COLORST[i % COLORST.length]
                                              }
                                            />
                                          </span>
                                          <span className="ico_heading">
                                            {item.name}
                                          </span>
                                        </div>
                                        <div className="text_pie">
                                          <span className="pr-3 font_custom">
                                            {item.count}
                                          </span>
                                          <span className="tag_blue">
                                            {flashTotal > 0
                                              ? (
                                                  (item.count / flashTotal) *
                                                  100
                                                ).toFixed(2) + "%"
                                              : "NA"}
                                          </span>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {consultaddTotal === 0 &&
                garudaTotal === 0 &&
                flashTotal === 0 &&
                xPlusTotal === 0 && (
                  <div className="noData">
                    <label>No Data Availaible</label>
                  </div>
                )}
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}

export default TeamAnalytics;
