import React from 'react';
import {Button, Input, Form, DatePicker, message, Select, Row, Col,TimePicker} from 'antd';
import axiosJSON from "../../util/SetHeaderAPI";
import TextArea from 'antd/lib/input/TextArea';
import SlateEditor from "../../components/CommentTagEditor";
import {deserialize, serialize} from "../../components/Editor/parseJson";

const moment = require('moment-timezone');
const FormItem = Form.Item;

const beforeMatch = /^@(\w+)$/
const beforeStr = '@';
const afterStr = '';


const {Option} = Select;

const Editor = ({ onChange, value, onSelectUser, user_list }) => (
  <div>
    <SlateEditor
      beforeMatch={beforeMatch}
      afterStr={afterStr}
      beforeStr={beforeStr}
      onSelectUser={onSelectUser}
      setData={onChange}
      user_list={user_list}
      value={value} />
  </div>
)

export default class MeetingForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: [
        {
          name: 'candidate',
          value: ''
        },
        {
          name: 'type',
          value: ''
        },
        {
          name: 'start',
          value: ''
        },
        // {
        //   name: 'end',
        //   value: ''
        // },
        {
          name: 'start_time',
          value: ''
        }, {
          name: 'end_time',
          value: ''
        }, {
          name: 'interviewers',
          value: []
        },
        {
          name: 'guest',
          value: []
        },
        // {
        //   name: 'description',
        //   value: ''
        // },
        {
          name: 'job_position',
          value: this.props.position ? this.props.position.id : ''
        }, {
          name: 'subject',
          value: "Your interview is scheduled!"
        }
      ],
      minuteStep:15,
      current_date: '',
      guest_list: [],
      positions: [],
      interview_type: [{
        name: 'CFR',
        value: 'cfr'
      },
        {
          name: 'Coding',
          value: 'coding'
        },
        {
          name: 'Initial Screening',
          value: 'initial_screening'
        },
        {
          name: 'Post Training Screening',
          value: 'post_training'
        }],
      tzMessage: "",
      loading: false,
      description: [
        {type: 'paragraph', children: [{ text: '' }]},
      ],
      showEditor: false
    }
    this.Mounted = false
  }

  onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  updateProps = () => {
    var temp_guest_list = []
    var temp_interviewer_list = []
    this.props.data.guest.map((guest) => {
      temp_guest_list.push(guest.id);
      return null
    })
    this.props.data.interviewers.map((interviewer) => {
      temp_interviewer_list.push(interviewer.id);
      return null
    })
    let text;
    if(this.props.data.description === "<p></p>"){
      text=[
        {
          type: 'paragraph',
          children: [
            { text: '' },
          ],
        },
      ]
    }
    else{
      text = deserialize(new DOMParser().parseFromString(this.props.data.description, 'text/html').body)
    }
    this.setState({
      fields: [{
        name: 'candidate',
        value: this.props.data.candidate.name
      }, {
        name: 'type',
        value: this.props.data.type
      }, {
        name: 'interviewers',
        value: temp_interviewer_list
      }, {
        name: 'start',
        value: moment(this.props.data.start)
      }, {
        name: 'end',
        value: moment(this.props.data.start)
      }, {
        name: 'guest',
        value: temp_guest_list
      },
        {
          name: 'description',
          value: this.props.data.description
        }, {
          name: 'job_position',
          value: this.props.data.position ? this.props.data.position.id : ''
        }, {
          name: 'subject',
          value: this.props.data.subject
        },
        {
          name: 'start_time',
          value: moment(this.props.data.start)
        }, {
          name: 'end_time',
          value: moment(this.props.data.end)
        },
      ],
      current_date: moment(this.props.data.start),
      screening_id: parseInt(this.props.data.id),
      description: text,
      showEditor: true
    })
  }

  componentWillUnmount() {
    this.Mounted = false
  }

  componentDidMount() {
    let timezone=this.props.timezone
    this.Mounted = true
    this.props.tzStatus && timezone === "EST" ? message.warning("Please change your system timezone to "+timezone+"!") :
      message.success("TimeZone is " +timezone+"!")
    axiosJSON.get('api/user/')
      .then(({data, status}) => {
        if (status === 200) {
          this.setState({guest_list: data.data})
        }
      }).catch(function (error) {
      message.error(error.message)
    })
    axiosJSON.get('api/position/dropdown ')
      .then(({data, status}) => {
        if (status === 200) {
          this.setState({positions: data.data})
        }
      }).catch(function (error) {
      message.error(error.message)
    })
    if (this.props.edit) {
      this.updateProps()
    }
    this.setState({
      showEditor: true
    })
  }

  onFinish = (values) => {
    this.setState({
      loading: true
    })
    const body = values;
    let text = serialize(this.state.description)

    body['start'] = moment(values.start).format("YYYY-MM-DD") + moment(values.start_time).format("THH:mmZ")
    body['end'] = moment(values.start).format("YYYY-MM-DD") + moment(values.end_time).format("THH:mmZ")
    body['description'] = text
    if (this.props.edit) {
      body['candidate'] = parseInt(this.props.data.candidate.id);
    } else {
      body['candidate'] = parseInt(this.props.candidate);
    }
    delete body['start_time'];
    delete body['end_time'];
    if (this.props.edit) {
      axiosJSON.put('api/screening/' + this.state.screening_id + '/', body)
        .then(({data, status}) => {
          if (status === 202) {
            this.setState({loading: false})
            message.success("Meeting edited!")
            this.props.closeModal()
          }
        }).catch(function (error) {
        message.error(error.message)
      })
    } else {
      axiosJSON.post('api/screening/', body)
        .then(({data, status}) => {
          if (status === 201) {
            message.success("Candidate Meeting Scheduled!")
            this.props.closeModal()
          }
        }).catch((error) => {
        message.error(error.message)
        this.setState({loading : false})
      })
    }
  }

  setStart = (e) => {
    this.setState({tempStartDate: e})
  }

  handleDescriptionChange = (e) => {
    this.setState({
        description: e,
      });

  }

  render() {
    console.log('Start end date-------', this.props.data.end, this.props.data.start)
    console.log('Fields-----', this.state.fields)
    return (
      <div className="gx-login-container">
        <div className="main-experience-form">
          <div className="gx-login-content experience-form expCorrection">
            <Form
              name="basic"
              fields={this.state.fields}
              onValuesChange={(changedValues, allValues) => {
                let minuteStep = this.state.minuteStep;
                if (changedValues.hasOwnProperty("type")) {
                  if (changedValues.type === 'cfr')
                    minuteStep = 30
                  else if (changedValues.type === 'initial_screening')
                    minuteStep = 15
                  this.setState({
                    minuteStep
                  })
                }

              }}
              onFieldsChange={(newFields) => this.setState({fields: newFields})}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              className="gx-signin-form gx-form-row0">

              {this.props.edit ? <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{marginBottom: '20px'}}><FormItem name="candidate" label="Candidate">
                <Input className='gx-input-lineheight' type="text" readOnly/>
              </FormItem></Col> : null}

              <Row >
                <Col xl={12} lg={6} md={6} sm={12} xs={12}>
                  <FormItem name="start" label="Date"
                            rules={[{required: true, message: 'Please input Start Date!'}]}>
                    <DatePicker
                      dropdownClassName={"date-picker-start"}
                      onChange={(date) => this.setState({current_date: date})}
                      disabledDate={(date) => date && date.valueOf() <= moment().startOf('day')}
                      format={"MM-DD-YYYY"}
                      disabled={this.props.tzStatus}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row style={{marginTop: '20px'}}>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>

                  <FormItem name="start_time" label="Start Time"
                            rules={[{required: true, message: 'Please input Start Time!'}]}>
                    <TimePicker showNow={false} format={"HH:mm A"} minuteStep={this.state.minuteStep}/>
                  </FormItem>

                  <FormItem name="type" label="Type" rules={[{required: true, message: 'Please input Type!'}]}>
                    <Select optionFilterProp="children"
                    >
                      {this.state.interview_type.map((type, index) => (
                        <Option key={index} value={type.value}>{type.name}</Option>
                      ))}
                    </Select>
                  </FormItem>


                  <FormItem name="job_position" label="Position" rules={[{required: true, message: 'Please input Position!'}]}>
                    <Select optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                    >
                      {this.state.positions.map((position, index) => (
                        <Option key={index} value={position.id}>{position.title + " - " + position.location}</Option>
                      ))}
                    </Select>
                  </FormItem>

                </Col>


                <Col xl={12} lg={6} md={6} sm={12} xs={12}>

                  <FormItem name="end_time" label="End Time"
                            rules={[{required: true, message: 'Please input End Time!'}]}>
                    <TimePicker showNow={false} format={"HH:mm A"} minuteStep={this.state.minuteStep}/>
                  </FormItem>

                  <FormItem name="interviewers" label="Interviewers"
                            rules={[{required: true, message: 'Please select Interviewers!'}]}>
                    <Select
                      mode="multiple"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.guest_list.map((guest, index) => (
                        <Option key={index} value={guest.id}>{guest.name}</Option>
                      ))}
                    </Select>
                  </FormItem>

                  <FormItem name="guest" label="Guests">
                    <Select optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            } mode="multiple">
                      {this.state.guest_list.map((guest, index) => (
                        <Option key={index} value={guest.id}>{guest.name}</Option>
                      ))}
                    </Select>
                  </FormItem>


                </Col>

              </Row>


              <div className="description-sec">
                <FormItem name="subject" label="Title">
                  <TextArea className='gx-input-lineheight' type="text"/>
                </FormItem>

                {/*<FormItem className="description-text" name="description" label="Description">*/}
                {/*  <TextArea className='gx-input-lineheight'/>*/}
                {/*</FormItem>*/}
             
                <label>Description</label>
                <div className="text-editor">
                  <div style={{ padding:'20px' }}>
                    {this.state.showEditor &&
                    <Editor
                      onChange={this.handleDescriptionChange}
                      value={this.state.description}
                      user_list={[]}
                      onSelectUser={()=>{}}
                    />}
                  </div>
                </div>
              </div>


              <FormItem>
                <Button loading={this.state.loading} type="primary" htmlType="submit">
                  Submit
                </Button>
              </FormItem>

            </Form>

          </div>
        </div>
      </div>
    );
  }

}
