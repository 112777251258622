import React, { Component } from "react";
import { Button, Col, Row, Input, message, Card, Checkbox } from "antd";
import Widget from "../../../../components/Widget";
import CustomScrollbars from "../../../../util/CustomScrollbars";
import CustomSidebar from "../CustomSidebar";
import { AiOutlineEye } from "react-icons/ai";
import PreviewForm from "./previewForm";
import { arrayMove, SortableContainer } from "react-sortable-hoc";
import { withRouter } from "react-router";
import FieldCell from "./fieldCell";
import axiosJSON from "../../../../util/SetHeaderAPI";
import "nprogress/nprogress.css";
import nprogress from "nprogress";
import { CustomModal as Modal } from "../../../../util/CustomModal";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiArrowLeft } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";
import AddFields from "./addFields";
import { connect } from "react-redux";


const FormFields = SortableContainer(
  ({ fields, onRequiredChange, onDescriptionChange, changeFields,verificationStatus }) => {
    return (
      <Row
        className="box boxmain height_auto"
        gutter={8}
        style={{ overflow: "hidden" }}
      >
        <Col span={24}>
          <Card bordered={true}>
            {fields.map((field, index) => (
              <FieldCell
                key={index}
                index={index}
                field={field}
                verificationStatus={verificationStatus}
                onDescriptionChange={onDescriptionChange}
                changeFields={changeFields}
                onRequiredChange={onRequiredChange}
              />
            ))}
          </Card>
        </Col>
      </Row>
    );
  }
);

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      inputFields: [],
      showFields: [],
      formName: "",
      form_type: "",
      btnString: "Create Form",
      formId: "",
      showAddFields: false,
      validationRequired: false,
    };
    this.Mounted = false;
  }

  componentWillUnmount() {
    this.Mounted = false;
  }

  componentDidMount() {
    nprogress.start();
    this.Mounted = true;
    var flag = this.props.history.location.search.split("=");
    if (flag.length > 2) {
      this.getCustomFields(true);
      const template_id = flag[2];
      axiosJSON
        .get("/api/form_template/" + template_id)
        .then(({ data, status }) => {
          this.setState({
            form_type: data.data.type,
            formName: data.data.name,
            inputFields: data.data.form_attributes,
            btnString: "Save",
            formId: template_id,
            validationRequired: data.data.validation_required
          });
        })
        .catch((err) => {
          message.error(err.message);
        });
    } else {
      this.getCustomFields(false);
      this.setState({
        form_type: flag[1],
      });
    }
    nprogress.done();
  }

  getCustomFields = (edit) => {
    let obj = [];
    axiosJSON
      .get("/api/field/")
      .then(({ data, status }) => {
        if (!edit) {
          data.data.forEach((field) => {
            if (field.name === "email" || field.name === "phone_numbers") {
              field["required"] = true;
              field["descriptipon"] = null;
              obj.push(field);
            }
            if(this.props.history.location.search.split('=')[1] === 'internal' && this.state.validationRequired && field.name === "employee_id"){
              field["required"] = true;
              field["descriptipon"] = null;
              obj.push(field);
            }
          });
        }
        this.setState({
          showFields: data.data,
          inputFields: obj.length > 0 ? obj : this.state.inputFields,
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  onRequiredChange = (id, value) => {
    let inputFields = [...this.state.inputFields];
    inputFields.map((ele, i) => {
      if (ele.name === id) {
        ele.required = value;
      }
      return null;
    });

    this.setState({
      inputFields: inputFields,
    });
  };

  addFields = () => {
    this.setState({
      showAddFields: true,
    });
  };
  onDescriptionChange = (id, value) => {
    let inputFields = [...this.state.inputFields];
    inputFields.map((ele, i) => {
      if (ele.name === id) {
        ele.description = value;
      }
      return null;
    });

    this.setState({
      inputFields: inputFields,
    });
  };

  // onAddField = (field) => {
  //   this.setState({
  //     addCustomField: true,
  //     selectedField: field ? field : null,
  //   });
  // };

  ToDoSideBar = () => {
    return (
      <Widget
        title="Custom Fields"
        styleName="gx-card-profile-sm custom-widget-1"
        extra={
          ((this.props.currentAuth ? this.props.currentAuth.role : this.props.auth.role ) === "superadmin") ?
            (
              <Button onClick={() => this.addFields()}>
                <AiOutlinePlus /> Add Custom Fields
              </Button>
            ) : null
        }
      >
        <div className="gx-module-side-content">
          <CustomScrollbars className="gx-module-side-scroll">
            <div className="gx-tab-list">
              <CustomSidebar
                changeFields={this.changeFields}
                inputFields={this.state.showFields.filter(
                  (field) =>
                    !this.state.inputFields.find(
                      (item) => item.name === field.name
                    )
                )}
                // onEditField={this.onAddField}
                // onDeleteField={this.onDeleteField}
              />
            </div>
          </CustomScrollbars>
        </div>
      </Widget>
    );
  };

  // onDeleteField = (id) => {
  //   axiosJSON
  //     .delete("api/field/" + id + "/")
  //     .then((res) => {
  //       message.success("Field Deleted");
  //       this.getCustomFields(true);
  //     })
  //     .catch(function (error) {
  //       message.error(error?.message);
  //     });
  // };

  changeFields = (action, name) => {
    let showFields = [...this.state.showFields];
    let inputFields = [...this.state.inputFields];
    let obj = {};
    if (action === "delete") {
      this.setState({
        inputFields: this.state.inputFields.filter((k) => k.name !== name),
      });
    } else {
      obj = showFields.find((item) => item.name === name);
      if (name === "email" || name === "phone_numbers") obj["required"] = true;
      else obj["required"] = false;
      inputFields.push(obj);
      this.setState({
        inputFields: inputFields,
      });
    }
  };

  onVerifyChange = (event) => {
    this.setState({
      validationRequired: event.target.checked ? true : false,
    },()=>{
      this.getCustomFields();
    });
    
  };

  onSubmit = () => {
    if (this.state.formName) {
      let fields = [],
        obj = {},
        api_call;
      this.state.inputFields.forEach((ele, index) => {
        obj = {
          attribute_id: ele.id,
          required: ele.required,
          description: ele.description ? ele.description : null,
          position: index,
        };
        fields.push(obj);
        return null;
      });
      const body = {
        name: this.state.formName,
        type: this.state.form_type,
        //fields: JSON.stringify(fields),
        validation_required: this.state.validationRequired,
        fields: fields,
      };
      let flag = this.props.history.location.search.split("=");

      api_call =
        flag.length > 2
          ? axiosJSON.put("/api/form_template/" + flag[2] + "/", body)
          : axiosJSON.post("/api/form_template/", body);

      api_call
        .then(({ data, status }) => {
          message.success(data.message);
          this.props.history.goBack();
        })
        .catch((err) => {
          message.error(err.message);
        });
    } else {
      message.error("Form Name cannot be empty.");
    }
  };

  previewForm = () => {
    this.setState({
      previewFlag: true,
    });
  };

  closeModal = () => {
    this.setState({
      previewFlag: false,
      addCustomField: false,
      showAddFields: false,
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      inputFields: arrayMove(this.state.inputFields, oldIndex, newIndex),
    });
  };

  delete = () => {
    axiosJSON
      .delete("/api/form_template/" + this.state.formId)
      .then(({ data, status }) => {
        if (status === 204) {
          message.success("Form removed successfully!");
          this.props.history.goBack();
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  onInputChange = (e, field) => {
    if (field === "email") {
      this.setState({ otpDialog: false });
    }
    this.setState({
      [field]: e.target.value,
    });
  };

  render() {
    const { previewFlag, inputFields, formName, showAddFields } = this.state;

    return (
      <div className="gx-main-content">
        <Row>
          <Col className="left-section height-vh col-8 px-4 pt-2">
            <div className="col-12 row px-0 m-0 py-3">
              <div className="col-11 p-0 flex-center">
                <div className="mr-3">
                  <Button
                    icon={<FiArrowLeft />}
                    onClick={() => this.props.history.goBack()}
                  />
                </div>
                <div className="grow-flex">
                  <span className="gx-fs-lg pb-2">Form Name</span>
                  <span className="gx-text-red gx-fs-sm">*</span>{" "}
                  <div className="pt-2">
                    <Input
                      className="gx-fs-md"
                      value={formName}
                      onChange={(e) => this.onInputChange(e, "formName")}
                      name="formName"
                      style={{ width: "50%" }}
                      placeholder="Enter Form Name"
                    />
                  </div>
                </div>
                <Checkbox
                  style={{ marginRight: "10px" }}
                  checked={this.state.validationRequired}
                  onChange={this.onVerifyChange}
                />
                <span
                  className="gx-fs-lg pb-2"
                  style={{ marginRight: "8px", marginBottom: "-8px" }}
                >
                  Verification Required{" "}
                </span>
                <Button type="primary" onClick={this.onSubmit} className="m-0">
                  {this.state.btnString}
                </Button>
                {this.state.formId !== "" && (
                  <span>
                    <Button
                      type="danger"
                      style={{
                        cursor: "pointer",
                        margin: "10px",
                      }}
                      onClick={this.delete}
                    >
                      <RiDeleteBinLine
                        className="ico-bin"
                        style={{
                          marginRight: "10px",
                        }}
                      />
                      Delete Form
                    </Button>
                  </span>
                )}
              </div>
              <div className="col-1 p-0 delete_form">
                <AiOutlineEye
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "25px",
                    marginRight: "40px",
                  }}
                  onClick={this.previewForm}
                >
                  Preview
                </AiOutlineEye>
              </div>
            </div>
            <CustomScrollbars>
              <FormFields
                onDescriptionChange={this.onDescriptionChange}
                onRequiredChange={this.onRequiredChange}
                fields={inputFields}
                onSortEnd={this.onSortEnd}
                changeFields={this.changeFields}
                useDragHandle={true}
                verificationStatus={this.state.validationRequired}
              />
            </CustomScrollbars>
          </Col>
          <Col className="col-4">{this.ToDoSideBar()}</Col>
        </Row>

        {previewFlag && (
          <Modal
            width="80%"
            title="Preview External Form"
            visible={previewFlag}
            footer={null}
            onCancel={this.closeModal}
          >
            <PreviewForm
              formName={formName}
              disabled={true}
              inputFields={inputFields}
            />
          </Modal>
        )}
        {showAddFields && (
          <Modal
            // width="80%"
            title="Add Custom Fields"
            visible={showAddFields}
            footer={null}
            onCancel={this.closeModal}
          >
            <AddFields
              formName={formName}
              disabled={true}
              inputFields={inputFields}
              handleClose={this.closeModal}
            />
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentAuth: state.auth.newUser,
  auth: state.auth.authUser
});


export default connect(mapStateToProps)(withRouter(Form));;
