import React, { useState } from "react";
import { Dropdown, Menu, message, Tooltip } from "antd";
import EnrollCandidate from "../../../../modals/Training/enrollCandidates";
import AddTraining from "../../../../modals/Training/addTraining";
import PlanTraining from "../../../../modals/Training/planTraining";
import moment from "moment";
import { BsArrowRight } from "react-icons/bs";
import { MdOutlinePeopleAlt } from "react-icons/md"
import { BsCheckAll, BsArchive, BsFillExclamationCircleFill } from "react-icons/bs";
import { GrInProgress } from "react-icons/gr";
import axiosJSON from "../../../../util/SetHeaderAPI";
import {CustomModal as Modal, confirm} from "../../../../util/CustomModal"

const TrainingItem = ({
  training,
  selectTraining,
  getTrainingData,
  accessData,
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showEnrolCandidate, setShowEnrolCandidate] = useState(false);
  const [planTraining, setPlanTraining] = useState(false);
  const statusMapping = [
    {
      key: "New",
      value: "New",
      icon: <BsFillExclamationCircleFill />,
      classValue: "alert alert-primary compltd",
    },
    {
      key: "On Going",
      value: "On Going",
      icon: <GrInProgress />,
      classValue: "alert alert-info compltd",
    },
    {
      key: "Complete",
      value: "Complete",
      icon: <BsCheckAll />,
      classValue: "alert alert-success compltd",
    },
    {
      key: "Archived",
      value: "Archived",
      icon: <BsArchive />,
      classValue: "alert alert-secondary compltd",
    },
  ];

  const onOptionMenuItemSelect = (e) => {
    switch (e.key) {
      case "edit":
        setShowEdit(true);
        break;
      case "enroll_candidate":
        setShowEnrolCandidate(true);
        break;
      case "planTraining":
        setPlanTraining(true);
        break;
      case "archive_training":
        onArchiveTrainingClick();
        break;
      default:
        break;
    }
  };

  const menu = () => (
    <Menu onClick={(e) => onOptionMenuItemSelect(e)}>

      <Menu.Item key="edit">
        <span>Edit Training</span>
      </Menu.Item>
      <Menu.Item key="planTraining">
        <span>Plan Training</span>
      </Menu.Item>
      <Menu.Item key="enroll_candidate">
        <span>Enroll Candidate</span>
      </Menu.Item>
      {training.status !== "Archived" && (
        <Menu.Item key="archive_training">
          <span>Archive Training</span>
        </Menu.Item>
      )}
    </Menu>
  );

  const handleClose = () => {
    setShowEdit(false);
    setShowEnrolCandidate(false);
    setPlanTraining(false);
    getTrainingData(1, "");
    //this.props.getCall(1)
  };
  const handleCancel = () => {
    setShowEdit(false);
    setShowEnrolCandidate(false);
    setPlanTraining(false);
  };

  const onArchiveTrainingClick = () => {
    confirm({
      title: `Do you want to Archive '${training.title}' ?`,
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => {
        axiosJSON
          .put("api/training/" + training.id + "/archive/")
          .then(({ data, status }) => {
            if (status === 202) {
              message.success(data.message);
              getTrainingData(1, "");
            }
          })
          .catch(function (error) {
            message.error(error.message);
          });
      },
    });
  };

  return (
    <div className="card_container col-3 pr-0">
      <div className="card">
        <div className="card-body card_cust">
          <div className="col-12 row m-0 p-0">
            <div
              className="col-2 pl-0"
              onClick={() => selectTraining(training.id)}
            >
              <h3 className="card-title m-0"> {training.id} </h3>
            </div>
            <div
              className="col-8 pr-0 pl-0"
              onClick={() => selectTraining(training.id)}
            >

              {/*<div className="">*/}
              {/*  <span role="alert" className="alert alert-primary ml-1 compltd">*/}
              {/*    <Tooltip title={"Total Candidates in training"}><MdOutlinePeopleAlt /> {training.count} </Tooltip>*/}
              {/*  </span>*/}
              {/*</div>*/}

              {training.status &&
                statusMapping.map((status, i) => {
                  if (status.key === training.status) {
                    return (
                      <div key={i} className={status.classValue} role="alert">
                        {status.icon} {status.value}
                      </div>
                    );
                  }
                  return null;
                })}
              <div className="">
                <span role="alert" className="alert alert-primary mr-1 compltd">
                  <Tooltip title={"Total Candidates in training"}><MdOutlinePeopleAlt /> {training.count} </Tooltip>
                </span></div>

            </div>
            <div className="col-2 pr-0" style={{right:"2.5rem"}}>
              <Dropdown.Button overlay={menu()} trigger={["click"]} />
            </div>
          </div>

          <h4
            className="card-text pb-2"
            onClick={() => selectTraining(training.id)}
          >
            {" "}
            {training.title}{" "}
          </h4>
        </div>
        <div
          className="card-footer"
          onClick={() => selectTraining(training.id)}
        >
          <div className="footer_contain">
            <div className="s_date">
              Start Date
              <div className="start_d">
                {training.start && training.start !== ""
                  ? moment(training.start).format("MM-DD-YYYY")
                  : "NA"}
              </div>
            </div>
            <div className="arrow">
              {" "}
              <BsArrowRight />{" "}
            </div>
            <div className="e_date">
              End Date
              <div className="end_d">
                {training.end && training.end !== ""
                  ? moment(training.end).format("MM-DD-YYYY")
                  : "NA"}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showEdit && (
        <Modal
          title="Edit Training"
          visible={showEdit}
          footer={null}
          onCancel={handleCancel}
        >
          <AddTraining
            data={{
              title: training.title,
              start_date: moment(training.start),
              end_date: training.end ? moment(training.end) : null,
            }}
            edit={true}
            training_id={training.id}
            closeModal={handleClose}
          />
        </Modal>
      )}
      {setShowEnrolCandidate && (
        <Modal
          title="Enroll Candidate"
          width="592px"
          visible={showEnrolCandidate}
          footer={null}
          onCancel={handleCancel}
        >
          <EnrollCandidate
            training={training}
            training_id={training.id}
            closeModal={handleClose}
          />
        </Modal>
      )}

      {planTraining && (
        <Modal
          title="Plan Training"
          visible={planTraining}
          footer={null}
          onCancel={handleCancel}
          className="traning_mdl"
        >
          <PlanTraining
            training={training}
            closeModal={handleClose}
            training_id={training.id}
          />
        </Modal>
      )}
    </div>
  );
};

export default TrainingItem;
