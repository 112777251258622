import React, {Component} from "react";
import {message, Table, Tag} from "antd";
import Widget from "../../../components/Widget";
import axiosJSON from "../../../util/SetHeaderAPI";
import "nprogress/nprogress.css";
import nprogress from "nprogress";

class FormSubmission extends Component {
  constructor(props) {
    super(props);
    const splitArray = window.location.href.split("/")
    this.id = splitArray[splitArray.length-2];
    this.state = {
      formValues: [],
      formName: null
    }
  }

  componentDidMount() {
    nprogress.start()
    this.getFormValues()
    nprogress.done()
  }

  getFormValues = () => {
    // const id = window.location.href.split("/")[2];
    axiosJSON.get('/api/candidate/'+this.id+'/form_submitted/')
      .then(res => {
       this.setState({formValues: res.data.data.values, formName: res.data.data.form_name})
      })
      .catch(err => message.error(err.message))
  }

  render(){
    const {formValues, formName} = this.state
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '30%',
        render: (text, record) => (<span>{text ? text : "-"}</span>)
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '40%',
        render: (text, record) => (
          <span>{text ? text : "-"}</span>)
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        width: '30%',
        render: (text, record) => (<span>{text ? text : "-"}</span>)
      },

    ]
    return(
      <div className="gx-profile-content">
        <Widget title="Form Submission" styleName="gx-card-profile">
          {formName && <Tag color={"green"}>Form Name : {formName} </Tag>}
          <div className="form-submission-table">
            <Table
              dataSource={formValues}
              columns={columns}
              pagination={true}
            />
          </div>
        </Widget>
      </div>

    )
  }
}

export default FormSubmission;
