import React from "react";
import {Button, DatePicker, message, Select} from "antd";
import axiosJSON from "../../util/SetHeaderAPI";
import moment from "moment";

const {RangePicker} = DatePicker;

class EditVisa extends React.Component {
  state = {
    visa_types: [],
    visa_start: null,
    visa_end: null,
    visa_type: '',
    checkFlag: {}
  };
  Mounted = false

  componentWillUnmount() {
    this.Mounted = false
  }

  componentDidMount() {
    this.Mounted = true
    if (this.props.visa_obj) {
      this.setState({
        visa_start: moment(this.props.visa_obj.start),
        visa_end: moment(this.props.visa_obj.end),
        visa_type: this.props.visa_obj.type ? this.props.visa_obj.type.id :""
      })
    }
    this.getVisaType(this.props.id)
  }

  getVisaType = () => {
    axiosJSON.get('api/choice/?type=visa')
      .then(({data, status}) => {
        if (status === 200) {
          this.setState({
            visa_types: data.data
          })
        }
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  onSubmit = () => {
    let checkFlag = {'visa_start':false,'visa_end':false,'visa_type':false};
    if(this.state.visa_start === null){
      checkFlag["visa_start"]=true
    }else if(this.state.visa_end === null){
      checkFlag["visa_end"]=true
    }else if(this.state.visa_type === ""){
      checkFlag["visa_type"]=true
    }

    var flag = Object.values(checkFlag).every(k => k === false)
    if(flag){
      if (this.props.visa_obj) {
        const body = {
          "work_auth_id": this.props.visa_obj.id,
          "start": moment(this.state.visa_start).format("YYYY-MM-DD"),
          "end": moment(this.state.visa_end).format("YYYY-MM-DD"),
          "type": this.state.visa_type
        }
        axiosJSON.put('api/candidate/' + this.props.id + '/work_auth/', body)
          .then(({data, status}) => {
            message.success(data.message)
            this.props.closeModal()
          }).catch(function (error) {
          message.error(error.message)
        })
      } else {
        const body = {
          "candidate": this.props.id,
          "start": moment(this.state.visa_start).format("YYYY-MM-DD"),
          "end": moment(this.state.visa_end).format("YYYY-MM-DD"),
          "type": this.state.visa_type
        }
        axiosJSON.post('api/candidate/' + this.props.id + '/work_auth/', body)
          .then(({data, status}) => {
            message.success(data.message)
            this.props.closeModal()
          }).catch(function (error) {
          message.error(error.message)
        })
      }
    }
    else{
      message.error("Please fill all the details")
    }
    this.setState({
      checkFlag:checkFlag
    })
  }

  onRangeChange = ([start, end]) => {
    this.setState({
      checkFlag:{},
      visa_start: start,
      visa_end: end
    })
  }
  onVisaTypeChange = (value) => {
    this.setState({
      checkFlag:{},
      visa_type: value
    })
  }

  render() {
    const {visa_start, visa_end, visa_type, visa_types,checkFlag} = this.state;
    return (
      <div>

        <div>
          Visa Range:<span className="gx-text-grey gx-fs-md right-required">(Required)</span>
          <RangePicker allowClear={false} style={{marginLeft: '10px'}} value={[visa_start, visa_end]} format="MM-DD-YYYY"
                                  onChange={this.onRangeChange}/>
          {checkFlag["visa_start"] &&   <span
            className="gx-text-red gx-fs-md">Start date cannot be empty</span> }
          {checkFlag["visa_end"] &&   <span
            className="gx-text-red gx-fs-md">End date cannot be empty</span> }
        </div>

        <div>
          Visa Type:<span className="gx-text-grey gx-fs-md right-required">(Required)</span>
          <Select
          style={{width: '150px', marginTop: '20px', marginLeft: '10px'}}
          showSearch
          value={visa_type}
          onChange={this.onVisaTypeChange}
        >
          {visa_types.map((visa, i) =>
            <Select.Option key={i} value={visa.id}>{visa.display_name}</Select.Option>)}
        </Select>
          {checkFlag["visa_type"] &&   <span
            className="gx-text-red gx-fs-md">Select a visa type</span> }
        </div>

        <Button type="primary" htmlType="submit" onClick={() => this.onSubmit()}>
          Submit
        </Button>
        <br clear="all"/>

      </div>

    );
  }
}

export default EditVisa;
