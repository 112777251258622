import React from "react";
import { Switch, Input, Button, Tag, Popover } from "antd";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { CustomModal as Modal, confirm } from "../../../../util/CustomModal";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import { MdDragIndicator } from "react-icons/md";
// const {confirm} = Modal;

const DragHandle = SortableHandle(() => (
  <span className="gx-draggable-icon gx-pt-2">
    <MdDragIndicator />
  </span>
));

class FieldCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputEle: [],
      inputDesc: false,
      desc: null,
    };
    this.Mounted = false;
  }

  componentWillUnmount() {
    this.Mounted = false;
  }

  // componentDidMount() {
  //   this.Mounted = true;
  //   let inputEle = this.state.inputEle;
  //   let obj = {};
  //   obj = {
  //     [this.props.desc.key]: false,
  //   };
  //   inputEle.push(obj);
  //   this.setState({
  //     inputEle: inputEle,
  //   });
  // }

  openInput = (key) => {
    let inputEle = { ...this.state.inputEle };
    inputEle[key] = true;
    this.setState({
      inputEle: inputEle,
      desc: this.props.field.description,
    });
  };

  closeInput = (key) => {
    let inputEle = { ...this.state.inputEle };
    inputEle[key] = false;
    this.setState(
      {
        inputEle: inputEle,
      },
      () => this.props.onDescriptionChange(key, this.state.desc)
    );
  };

  showDeleteConfirm = (key) => {
    confirm({
      title: "Are you sure you want to delete this field?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => this.props.changeFields("delete", key),
      onCancel() {},
    });
  };

  changeDisplayFieldType = (field_type) => {
    switch (field_type) {
      case "text":
        return "Short Answer";
      case "longtext":
        return "Long Answer";
      case "select":
        return "Single Choice Answer";
      case "multiselect":
        return "Multiple Choice Answer";
      case "file":
        return "File Upload";
      case "number":
        return "Only Numeric value";
      case "datepicker":
        return "Date";
      default:
        break;
    }
  };

  render() {
    const { inputEle } = this.state;
    const { field, key,verificationStatus } = this.props;
    const { name, display_name, field_type, required, description } = field;
    return (
      <div className="gx-contact-item gx-dragndrop-item custom-drag" key={key}>
        <div className="obs-center">
          <DragHandle />
        </div>
        <div className="col-12 row p-0 m-0 flex-center">
          <div className="gx-col  gx-dragndrop-name gx-text-truncate gx-px-2">
            <span style={{ fontWeight: 500 }}>{display_name}</span>
          </div>

          <div className="gx-col gx-job-title gx-d-none gx-d-sm-flex gx-px-2 flex-right flex-end">
            <Popover content="Field Type">
              <Tag
                className="mr-3 mb-0 custom-tag-pur"
                style={{ width: "fit-content" }}
              >
                {this.changeDisplayFieldType(field_type)}
              </Tag>
            </Popover>
            <Popover content="Required?">
              <Switch
                className="mr-2"
                disabled={name === "email" || name === "phone_numbers"}
                onChange={(value) => this.props.onRequiredChange(name, value)}
                size="small"
                checked={required}
              />
            </Popover>
            <Popover content="Remove Field Type">
              {!(name === "phone_numbers" || (name === "employee_id" && verificationStatus)) && (
                <FiTrash2
                  className="mr-2"
                  style={{
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => this.showDeleteConfirm(name)}
                />
              )}
            </Popover>
            <Popover content="Edit Description">
              <FiEdit3
                className="mr-2"
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() => this.openInput(name)}
              />
            </Popover>
          </div>
        </div>
        <div className="col-12 p-0 my-2 ml-2">
          <span>
            {description ? (
              <span>
                {/* <Input
                  value={description}
                  // onChange={(e) =>
                  //   this.props.onDescriptionChange(name, e.target.value)
                  // }
                  readOnly={true}
                  bordered={false}
                /> */}
                {description}
              </span>
            ) : (
              "No Description"
            )}
          </span>
        </div>
        {inputEle[name] && (
          <Modal
            visible={inputEle[name]}
            title="Add Description"
            footer={null}
            onCancel={() => this.closeInput(name)}
          >
            <div className="gx-col gx-dragndrop-phone gx-d-none gx-d-md-flex gx-px-2">
              <div className="add_description_popup">
                <ul>
                  <li> Description: </li>
                  <li>
                    <Input
                      value={this.state.desc}
                      onChange={(e) =>
                        this.setState({
                          desc: e.target.value,
                        })
                      }
                    />
                  </li>
                  <li>
                    <Button onClick={() => this.closeInput(name)}>Add</Button>
                  </li>
                </ul>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default SortableElement(FieldCell);
