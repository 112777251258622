import React, { useState, useEffect } from "react";
import { Button, Card, Popconfirm, message } from 'antd';
import AddExperience from '../../../../../modals/Candidate/addExperience';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"
import axiosJSON from "../../../../../util/SetHeaderAPI";
import {CustomModal as Modal} from "../../../../../util/CustomModal"

const Experience = ({ id }) => {

  const [showMore, setShow] = useState(false);
  const [showEdit, setEdited] = useState(false);
  const [expList, setExpList] = useState([]);
  const [experience, setExperience] = useState({});

  const handleShow = (flag) => { setShow(!flag); }

  const handleEditShow = (flag, listitem) => {
    setEdited(!flag)
    setExperience(listitem)
  }

  const handleclose = () => {
    setShow(false);
    setExperience({})
    setEdited(false);
    fetchData();
  }

  const deleteConfirm=(exp_id)=>{
    axiosJSON.delete('api/candidate/'+id+'/experience/'+exp_id+'/')
      .then(res => {
        message.success('Experience Deleted!');
        fetchData();
      }).catch(function (error) {
        message.error(error.message)
      })
  }

  const fetchData = () => {
    axiosJSON.get('api/candidate/' + id + '/exp_edu/')
      .then(res => {
        const exp_list = res.data.data.experience;
        setExpList(exp_list)
      }).catch(function (error) {
        message.error(error.message)
      })
  }
  useEffect(() => {
    let mounted = true
    if(mounted){
      fetchData();
    }
    return function cleanup() {
      mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div>
        {expList.map((listitem, index) => (
          <Card key={index}>

            <div className="experience-form-section">
              <ul>
                <li> <i className="icon icon-company" /> </li>

                <li>
                  <span className="top-headline">{listitem.title}</span><br></br>
                  <span>{listitem.company}</span><br></br>
                  <span>{listitem.exp_type}</span><br></br>
                  <span>{listitem.start_date}</span><br></br>
                  <span>{listitem.end_date}</span><br></br>
                  <span>{listitem.city}</span><br></br>
                  <span>{listitem.remark}</span><br></br>
                </li>

                <li>
                  <div className="icons">
                    <EditOutlined onClick={() => handleEditShow(showEdit, listitem)} />
                    <Popconfirm
                      title="Are you sure delete this experience?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={()=>deleteConfirm(listitem.id)}
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </div>
                </li>

              </ul>


            </div>



          </Card>
        ))}
        <Modal
          title="Edit Experience"
          visible={showEdit}
          footer={null}
          onCancel={() => setEdited(false)}>
          <AddExperience id={id} closeModal={handleclose} edit={true} data={experience} />
        </Modal>
        <Button onClick={() => handleShow(showMore)}>Add Experience</Button>
        <Modal
          title="Add Experience"
          visible={showMore}
          footer={null}
          onCancel={() => setShow(false)}>
          <AddExperience id={id} closeModal={handleclose} edit={false} data={{}} />
        </Modal>
      </div>
    </div>
  )
}


export default Experience;
