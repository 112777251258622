import React from "react";
import { Route, Switch,} from "react-router-dom";
import CustomSettings from "./CustomSettings"
import "nprogress/nprogress.css";
import nprogress from "nprogress";
// import asyncComponent from "../../../util/asyncComponent";

const CustomFields =(props)=>{
    nprogress.start()
    nprogress.done()
    return (
        <Switch>
          <Route exact path={`${props.path}`}>
            <CustomSettings />
          </Route>

          {/* <Route exact path={`${props.path}`} component={asyncComponent(() => import('./CustomSettings'))}/> */}
        </Switch>
    );

};

export default CustomFields;
