import React from "react";
import {
  Button,
  Input,
  Radio,
  Checkbox,
  message,
  Select,
  Spin,
  Alert,
} from "antd";
import axiosJSON from "../../../util/SetHeaderAPI";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { confirm } from "../../../util/CustomModal";

const { Option } = Select;

export default class SingleUserCreation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      internValue: false,
      isIntern: null,
      employeeId: null,
      log1: false,
      beatsUS: false,
      beatsIndia: false,
      firstName: null,
      lastName: null,
      roleList: [],
      log1RoleList: [],
      teamList: [],
      email: null,
      emailExist: false,
      gender: null,
      teamName: null,
      phone: null,
      log1Role: [],
      beatsIndiaRole: null,
      beatsUsRole: null,
      submitActive: false,
      log1Changed: false,
      emailCheck: false,
      edit: false,
      user: [],
      role: false,
      changeAccess: false,
    };
  }

  componentDidMount() {
    this.getRoleList();
    this.getLog1RoleList();
    this.getTeamList();
    if (this.props.edit) {
      const fullName = this.props.user.name;
      const first = fullName.substring(0, fullName.lastIndexOf(" "));
      const last = fullName.substring(
        fullName.lastIndexOf(" ") + 1,
        fullName.length
      );
      this.setState({
        edit: this.props.edit,
        user: this.props.user,
        submitActive: true,
        employeeId: this.props.user?.employee_id,
        staticEmployeeId: this.props.user?.employee_id,
        teamName: this.props.user.team?.name,
        email: this.props.user?.email,
        firstName: first,
        lastName: last,
        phone: this.props.user?.phone,
        gender: this.props.user?.gender,
      });
    }
    if (this.props.role || this.props.changeAccess) {
      this.setState({
        role: this.props.role,
        changeAccess: this.props.changeAccess,
        submitActive: true,
        user: this.props.user,
        employeeId: this.props.user?.employee_id,
        teamName: this.props.user.team?.name,
        beatsUS: this.props.user?.us_is_active,
        beatsIndia: this.props.user?.is_active,
        log1: this.props.user?.log1_is_active,
        log1Role: this.props.user?.log1_role
          ? this.props.user?.log1_role
              .split(",")
              .map((element) => element.trim())
          : [],
        beatsIndiaRole: this.props.user?.role?.name,
        beatsUsRole: this.props.user?.us_role,
      });
    }
  }

  getRoleList = () => {
    axiosJSON
      .get("/api/util/roles/")
      .then((data) => {
        this.setState({
          roleList: data.data.data,
        });
      })
      .catch(function (error) {
        message.error(error?.message);
      });
  };

  getLog1RoleList = () => {
    axiosJSON
      .get("/api/user/log1_role/")
      .then((data) => {
        this.setState({
          log1RoleList: data.data.data,
        });
      })
      .catch(function (error) {
        message.error(error?.message);
      });
  };

  getTeamList = () => {
    axiosJSON
      .get("/api/util/teams/")
      .then((data) => {
        this.setState({
          teamList: data.data.data,
        });
      })
      .catch(function (error) {
        message.error(error?.message);
      });
  };

  handleChange = (value, type) => {
    if (value && value.length > 0) {
      this.updateState(value, type);
    } else {
      this.updateState(value, type);
      message.error("Please Enter all mandatory fields");
    }
  };

  updateState = (value, type) => {
    this.setState({
      employeeId: type === "id" ? value : this.state.employeeId,
      firstName: type === "firstName" ? value : this.state.firstName,
      lastName: type === "lastName" ? value : this.state.lastName,
      email: type === "email" ? value : this.state.email,
      phone: type === "phone" ? value : this.state.phone,
      password: type === "password" ? value : this.state.password,
      internValue: type === "intern" ? value : this.state.internValue,
      beatsUsRole: type === "beatsus" ? value : this.state.beatsUsRole,
      teamName: type === "team" ? value : this.state.teamName,
      gender: type === "gender" ? value : this.state.gender,
      log1Role: type === "log1" ? value : this.state.log1Role,
      beatsIndiaRole: type === "beatsindia" ? value : this.state.beatsIndiaRole,
    });
  };

  checkMail = () => {
    const emailTotal = this.state.email;
    let checked = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
      emailTotal
    );
    let body = {
      email: emailTotal,
    };
    if (checked) {
      this.setState({
        loader: true,
      });
      axiosJSON
        .post("/api/user/check_email/", body)
        .then(({ data }) => {
          message.success(data.message);
          this.setState({
            submitActive: true,
            emailCheck: true,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            emailExist: true,
            loader: false,
          });
        });
    } else {
      message.error("Email Format Error");
    }
    this.setState({
      loader: false,
    });
  };

  singleSubmit = () => {
    let self = this;
    this.setState({
      loader: true,
    });
    if (
      (!this.state.internValue && !this.state.employeeId) ||
      (this.state.log1 &&
        !(this.state.log1Role && this.state.log1Role.length > 0)) ||
      (this.state.beatsIndia && !this.state.beatsIndiaRole) ||
      (this.state.beatsUS && !this.state.beatsUsRole) ||
      !this.state.firstName ||
      !this.state.lastName ||
      !this.state.gender ||
      !this.state.email ||
      !this.state.password ||
      !this.state.phone ||
      !this.state.teamName
    ) {
      this.setState({ loader: false });
      message.error("Please Enter all the Mandatory details");
    } else {
      const body = {
        employee_id: this.state.employeeId,
        name: this.state.firstName + ` ` + this.state.lastName,
        email: this.state.email,
        password: this.state.password,
        phone: this.state.phone,
        team_name: this.state.teamName,
        intern: this.state.internValue,
        gender: this.state.gender,
        log1: this.state.log1,
        beats_us: this.state.beatsUS,
        beats_ind: this.state.beatsIndia,
        log1_role: this.state.log1Role,
        beats_us_role: this.state.beatsUsRole,
        beats_role: this.state.beatsIndiaRole,
      };
      axiosJSON
        .post(`/api/user/create_new/`, body)
        .then(({ data, status }) => {
          if (status === 202 || status === 200 || status === 201) {
            message.success(data.message);
            this.setState({
              loader: false,
            });
            this.props.closeModal();
          }
        })
        .catch(function (error) {
          message.error(error?.message);
          self.setState({
            loader: false,
          });
        });
    }
  };

  onSubmit = () => {
    if (this.state.edit) {
      this.editUser();
    } else if (this.state.changeAccess) {
      this.updateApps();
    } else if (this.state.role) {
      this.updateRoles();
    } else {
      this.singleSubmit();
    }
  };

  editUser = () => {
    let self = this;
    if (
      !this.state.firstName ||
      !this.state.lastName ||
      !this.state.gender ||
      !this.state.email ||
      !this.state.phone ||
      !this.state.teamName ||
      (this.state.employeeId !== this.state.staticEmployeeId &&
        !this.state.employeeId)
    ) {
      message.error("Please Enter all the Mandatory details");
    } else {
      self.setState({
        loader: true,
      });
      let eid = null;
      const body = {
        name: this.state.firstName + ` ` + this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        team_name: this.state.teamName,
        gender: this.state.gender,
      };
      if (this.state.employeeId !== this.state.staticEmployeeId) {
        eid = this.state.staticEmployeeId;
        body.employee_id = this.state.employeeId;
      } else {
        eid = this.state.employeeId;
      }
      axiosJSON
        .put(`/api/user/${eid}/update_user/`, body)
        .then(({ data, status }) => {
          message.success(data.message);
          self.setState({
            loader: false,
          });
          self.props.closeModal();
        })
        .catch(function (error) {
          message.error(error?.message);
          self.setState({
            loader: false,
          });
        });
    }
  };

  updateApps = () => {
    let self = this;
    if (
      (this.state.log1 &&
        !(this.state.log1Role && this.state.log1Role.length > 0)) ||
      (this.state.beatsIndia && !this.state.beatsIndiaRole) ||
      (this.state.beatsUS && !this.state.beatsUsRole)
    ) {
      self.setState({
        loader: false,
      });
      message.error(" Please Enter all mandatory fields");
    } else {
      confirm({
        title: "Are you sure?",
        icon: <ExclamationCircleOutlined />,
        content:
          "This action will activate this user in the selected apps and Deactivate this user in non selected apps.",
        onOk: () => {
          self.setState({
            loader: true,
          });
          const body = {
            log1: this.state.log1,
            beats_us: this.state.beatsUS,
            beats_ind: this.state.beatsIndia,
            team_name: this.state.teamName,
          };
          axiosJSON
            .put(`api/user/${this.state.employeeId}/change_access/`, body)
            .then(({ data, status }) => {
              if (status === 202) {
                message.success(data.message);
                if (
                  this.state.log1 ||
                  this.state.beatsUS ||
                  this.state.beatsIndia
                ) {
                  this.updateRoles();
                } else {
                  self.props.closeModal();
                }
                self.setState({
                  loader: false,
                });
              }
            })
            .catch(function (error) {
              message.error(error?.message);
              self.setState({
                loader: false,
              });
            });
        },
        onCancel: () => {},
      });
    }
    self.setState({
      loader: false,
    });
  };

  updateRoles = () => {
    let self = this;
    if (
      (!this.state.log1 && !this.state.beatsIndia && !this.state.beatsUS) ||
      (this.state.log1 &&
        !(this.state.log1Role && this.state.log1Role.length > 0)) ||
      (this.state.beatsIndia && !this.state.beatsIndiaRole) ||
      (this.state.beatsUS && !this.state.beatsUsRole)
    ) {
      message.error("Please Select Apps and Roles");
    } else {
      self.setState({
        loader: true,
      });
      const body = {
        ...(this.state.log1 && {
          log1_role:
            this.state.log1Role?.length > 0 ? this.state.log1Role : null,
        }),
        ...(this.state.beatsUS && { beats_us_role: this.state.beatsUsRole }),
        ...(this.state.beatsIndia && { beats_role: this.state.beatsIndiaRole }),
        team_name: this.state.teamName,
      };
      axiosJSON
        .put(`/api/user/${this.state.employeeId}/change_role/`, body)
        .then(({ data }) => {
          message.success(data.message);
          self.setState({
            loader: false,
          });
          self.props.closeModal();
        })
        .catch(function (error) {
          message.error(error?.message);
          self.setState({
            loader: false,
          });
        });
    }
  };

  render() {
    const { loader, role, edit, changeAccess } = this.state;
    return (
      <Spin spinning={loader}>
        <div className="col-12">
          <div
            className="row"
            layout="inline"
            id="single-user-form"
            name="basic"
          >
            {" "}
            {!role && !changeAccess && (
              <div className="col-12 p-0 mb-3">
                <div className="row m-0">
                  {/* intern */}
                  {!this.state.edit && (
                    <div className="col-6 mb-2">
                      <div className="mb-2">
                        Is Intern? {<span className="redimpot">*</span>}
                      </div>
                      <Radio.Group
                        className="gx-radio-group-link gx-radio-group-link-news intern_radio"
                        defaultValue={false}
                        onChange={(event) =>
                          this.setState({
                            internValue: event.target.value,
                            isIntern: "check",
                            employeeId: null,
                          })
                        }
                      >
                        <Radio value={true} className="gx-mb-1">
                          Yes
                        </Radio>
                        <Radio value={false} className="gx-mb-1">
                          No
                        </Radio>
                      </Radio.Group>
                    </div>
                  )}
                  {!this.state.internValue ? (
                    <div className="col-6">
                      <div className="mb-2">
                        Employee ID {<span className="redimpot">*</span>}
                      </div>
                      <Input
                        className="input_style"
                        type="number"
                        placeholder="Employee Id"
                        disabled={edit && this.state.staticEmployeeId <= 9999}
                        value={this.state.employeeId}
                        onChange={(event) =>
                          this.handleChange(event.target.value, "id")
                        }
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            )}
            {!this.state.role && !changeAccess && (
              <div className="col-6 mb-3">
                <div className="mb-2">
                  First Name {<span className="redimpot">*</span>}
                </div>
                <Input
                  className="input_style"
                  type="text"
                  placeholder="First Name"
                  value={this.state.firstName}
                  onChange={(event) =>
                    this.handleChange(event.target.value, "firstName")
                  }
                />
              </div>
            )}
            {!role && !changeAccess && (
              <div className="col-6 mb-3">
                <div className="mb-2">
                  Last Name {<span className="redimpot">*</span>}
                </div>
                <Input
                  className="input_style"
                  type="text"
                  value={this.state.lastName}
                  placeholder="Last Name"
                  onChange={(event) =>
                    this.handleChange(event.target.value, "lastName")
                  }
                />
              </div>
            )}
            {!role && !changeAccess && (
              <div className="col-6 mb-3">
                <div className="mb-2">
                  Email {<span className="redimpot">*</span>}
                </div>
                <Input.Group compact>
                  <Input
                    className="input_style inputgroup1"
                    type="text"
                    placeholder="Employee Email"
                    value={this.state.email}
                    onChange={(event) =>
                      this.handleChange(event.target.value, "email")
                    }
                  />
                  <Button
                    disabled={!this.state.email}
                    type="primary mb-0 inputgroup2"
                    onClick={() => {
                      this.checkMail();
                    }}
                  >
                    Verify
                  </Button>
                </Input.Group>
                {this.state.emailCheck ? (
                  <span style={{ color: "green" }}>Email Available</span>
                ) : this.state.emailExist ? (
                  <span style={{ color: "red" }}>
                    Email Already Exist ! Try Another Email
                  </span>
                ) : null}
              </div>
            )}
            {!edit && !role && !changeAccess && (
              <div className="col-6 mb-3">
                <div className="mb-2">
                  Create Password <span className="redimpot">*</span>
                </div>
                <Input.Password
                  className="input_style"
                  type="text"
                  placeholder="Password"
                  autoComplete="new-password"
                  onChange={(event) =>
                    this.handleChange(event.target.value, "password")
                  }
                />
              </div>
            )}
            {!role && !changeAccess && (
              <div className="col-6 mb-3">
                <div className="mb-2">
                  Phone Number {<span className="redimpot">*</span>}
                </div>
                <Input
                  className="input_style"
                  type="text"
                  value={this.state.phone}
                  maxLength={15}
                  placeholder="Employee Phone Number"
                  onChange={(event) =>
                    this.handleChange(event.target.value, "phone")
                  }
                />
              </div>
            )}
            {!role && !changeAccess && (
              <div className="col-6 mb-3">
                <div className="mb-2">
                  Gender {<span className="redimpot">*</span>}
                </div>
                <Select
                  className="select_inputStyle"
                  placeholder="Select your gender"
                  value={this.state.gender}
                  onChange={(e) => {
                    this.handleChange(e, "gender");
                  }}
                >
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                  <Option value="other">Other</Option>
                </Select>
              </div>
            )}
            {!role && !changeAccess && (
              <div className="col-6 mb-3">
                <div className="mb-2">
                  Team Name {<span className="redimpot">*</span>}
                </div>
                <Select
                  className="select_inputStyle"
                  value={this.state.teamName}
                  placeholder="Select your team"
                  onChange={(e) => {
                    this.handleChange(e, "team");
                  }}
                >
                  {this.state.teamList.map((item, i) => (
                    <Select.Option value={item.name} key={i}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
            {!edit && (
              <div
                className={
                  role
                    ? "col-12"
                    : changeAccess
                    ? "col-12"
                    : "col-12 seperator_border"
                }
              >
                <div className="row m-0">
                  {/* Checkbox comes here  */}
                  <div className="col-6 p-0">
                    <div className="col-12 p-0 mb-3">
                      <div className="mb-2">
                        {changeAccess ? (
                          <span>
                            <Alert
                              message="Mark the apps to activate access."
                              type="warning"
                              showIcon
                              className="warning-feedback"
                            />
                            Select Apps.
                          </span>
                        ) : (
                          <span>Select Apps.</span>
                        )}
                      </div>
                      <div name="log1" valuepropname="checked">
                        <Checkbox
                          value={true}
                          checked={this.state.log1}
                          onChange={(event) =>
                            this.setState({
                              log1: event.target.checked,
                            })
                          }
                        >
                          {" "}
                          Log1{" "}
                        </Checkbox>
                      </div>
                    </div>
                    <div className="col-12 p-0 mb-3">
                      <div name="beats_us" valuepropname="checked">
                        <Checkbox
                          value={true}
                          checked={this.state.beatsUS}
                          onChange={(event) =>
                            this.setState({ beatsUS: event.target.checked })
                          }
                        >
                          {" "}
                          Beats US{" "}
                        </Checkbox>
                      </div>
                    </div>
                    <div className="col-12 p-0 mb-3">
                      <div name="beats_india" valuepropname="checked">
                        <Checkbox
                          value={true}
                          checked={this.state.beatsIndia}
                          onChange={(event) =>
                            this.setState({
                              beatsIndia: event.target.checked,
                            })
                          }
                        >
                          {" "}
                          Beats India{" "}
                        </Checkbox>
                      </div>
                    </div>
                  </div>

                  {/* Select comes here  */}
                  <div className="col-6 p-0">
                    {this.state.log1 ? (
                      <div className="col-12 mb-2">
                        <div className="mb-2" name="log1_role">
                          Log1 Roles
                          <span className="redimpot">*</span>
                        </div>
                        <Select
                          className="select_inputStyle"
                          mode="multiple"
                          allowClear={false}
                          placeholder="Select log1 Roles"
                          onChange={(e) => {
                            this.handleChange(e, "log1");
                          }}
                          value={
                            this.state.log1Role !== null
                              ? this.state.log1Role
                              : []
                          }
                        >
                          {this.state.log1RoleList.map((item, i) => (
                            <Select.Option value={item.name} key={i}>
                              {item.display_name}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    ) : null}
                    {this.state.beatsUS ? (
                      <div className="col-12 mb-2">
                        <div className="mb-2" name="beats_us_role">
                          Beats-US Role
                          <span className="redimpot">*</span>
                        </div>
                        <Select
                          className="select_inputStyle"
                          placeholder="Select Beats US Roles"
                          onChange={(e) => {
                            this.handleChange(e, "beatsus");
                          }}
                          value={
                            this.state.beatsUsRole !== null
                              ? this.state.beatsUsRole
                              : null
                          }
                        >
                          {this.state.roleList.map((item, i) => (
                            <Select.Option value={item.name} key={i}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    ) : null}
                    {this.state.beatsIndia ? (
                      <div className="col-12 mb-2">
                        <div className="mb-2" name="beats_india_role">
                          Beats-India Role
                          <span className="redimpot">*</span>
                        </div>
                        <Select
                          className="select_inputStyle"
                          placeholder="Select Beats India Roles"
                          onChange={(e) => {
                            this.handleChange(e, "beatsindia");
                          }}
                          value={
                            this.state.beatsIndiaRole !== null
                              ? this.state.beatsIndiaRole
                              : null
                          }
                        >
                          {this.state.roleList.map((item, i) => (
                            <Select.Option value={item.name} key={i}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-12 p-0 row mx-0 mt-4 right_butn">
            <Button
              type="btn-outline-secondary"
              className="cancel_btn mb-0"
              onClick={this.props.closeModal}
            >
              Cancel
            </Button>
            <Button
              disabled={!this.state.submitActive}
              type="primary"
              className="submit_btn mr-2 mb-0"
              htmlType="submit"
              onClick={this.onSubmit}
            >
              {" "}
              {role ? "Change" : "Submit"}{" "}
            </Button>
          </div>
        </div>
      </Spin>
    );
  }
}
