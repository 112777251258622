import React, {Component} from "react";
import axiosJSON from "../../util/SetHeaderAPI";
import {Card, message} from "antd";
import ActivityItem from "./activityItem";
import CustomScrollbars from "util/CustomScrollbars";
import Auxiliary from "util/Auxiliary";
import "nprogress/nprogress.css";
import nprogress from "nprogress";

class UserActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityList: [],
      page: 1,
      prevY: 0,
      loading:true
    }
    this.Mounted=false
  }

  componentWillUnmount() {
    this.Mounted = false
  }

  componentDidMount() {
    nprogress.start()
    this.Mounted = true
    this.getActivity(this.state.page);
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };
    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    this.observer.observe(this.loadingRef);
    nprogress.done()
  }


  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;

    if (this.state.prevY > y) {
      const curPage = this.state.page+1;
      this.getActivity(curPage);
      this.setState({page: curPage});
    }
    this.setState({prevY: y});
  }

  getActivity = (p) => {

    axiosJSON.get('api/dashboard/activity/?page=' + p)
      .then(res => {
        let arr=this.state.activityList
        res.data.data.map((ele,i)=>{
          arr.push(ele);
          return arr
        })
        this.setState({

          activityList: arr,
          loading:false
        })
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  render() {
    const {activityList} = this.state;
    const loadingCSS = {
    };
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    return (
      <Auxiliary>
        <div className="gx-popover-header">
          <h3 className="gx-mb-0">Your Activity</h3>
        </div>
      <CustomScrollbars style={{ width: "100%", height: "90%" }}>
      <div style={{backgroundColor:'white', padding: "10px", margin: "10px"}} >
        {/*<div className="gx-popover-header">*/}
        {/*<h2 className="title gx-mb-4">Your Activity</h2>*/}
        {/*</div>*/}
        {activityList.map((ele, index) => {
          return <Card key={index} >
            <ActivityItem key={index} id={ele.id}
                          created={ele.created}
                          desc={ele.desc}
                          activity_type={ele.activity_type}
            />
          </Card>
        })
        }

        <div ref={loadingRef => (this.loadingRef = loadingRef)} style={loadingCSS}/>
        <span style={loadingTextCSS}>Loading...</span>

      </div>
      </CustomScrollbars>
      </Auxiliary>
    );
  }
}

export default UserActivity;
