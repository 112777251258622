import React, {useEffect} from "react";
import {Button, Form, Input, message} from "antd";
import IntlMessages from "util/IntlMessages";
import axiosJSON from "../util/Api";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";


const FormItem = Form.Item;


const ResetPassword = (props) => {

  const history = useHistory();
  const token = useSelector(({auth}) => auth.token);
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  useEffect(() => {
    if (token !== null) {
      props.history.push('/dashboard');
    }
  }, [token, props.history]);

  const onFinish = values => {
    axiosJSON.post('api/password/confirm_password/', values)
      .then(({data, status}) => {
        message.success(data.message)
        history.push('/signin')

      }).catch(function (error) {
      if(error.status === 400){
        message.error(error.data.email.toString())
      }
      else{
        message.error(error.data.email.toString())
      }
    })
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">

        <div className="gx-login-header">
          <img src={require("assets/images/logo.png")} alt="beats" title="beats"/>
        </div>
        <div className="gx-mb-4">
          <h2>Reset Password</h2>
          <p><IntlMessages id="appModule.enterResetToken"/></p>
        </div>

        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0">

          <FormItem rules={ [{required: true, message: 'Please enter correct token!'}]} name="token">
            <Input className='gx-input-lineheight' type="text" placeholder="Enter Token from email"/>
          </FormItem>

          <FormItem rules={ [{required: true, message: 'Please enter your password!'}]} name="password">
            <Input className='gx-input-lineheight' placeholder="Enter New Password" type="password"/>
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType="submit">
              <IntlMessages id="app.userAuth.reset"/>
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
