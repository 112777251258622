import React, { useEffect, useState } from "react";
import { Button, Form, message, Select, Input, Spin } from "antd";
import axiosJSON from "../../util/SetHeaderAPI";

const FormItem = Form.Item;

const EditModule = (props) => {
  const [moduleList, setModuleList] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log("--->>",props)
  console.log(props);
  const [fields] = useState([
    {
      name: "candidateName",
      value: props.candidate.name,
    },
    {
      name: "title",
      value:
        props.candidate.module.id !== "-" ? props.candidate.module.id : null,
    },
    {
      name: "technology",
      value:
        props.candidate.technology !== "-" ? props.candidate.technology : null,
    },
  ]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = () => {
    setLoading(true);
    axiosJSON
      .get("api/module/")
      .then((res) => {
        setModuleList(res.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        message.error(error.message);
        setLoading(false);
      });
  };

  const onFinish = (value) => {
    setLoading(true);
    const data = {
      module: value.title,
      technology: value.technology,
    };
    axiosJSON
      .put(
        "api/training_candidate/" + props.candidate.training_candidate_id + "/",
        data
      )
      .then(({ data, status }) => {
        if (status === 202) {
          message.success(data.message);
          setLoading(false);
          props.closeModal();
        }
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error.message);
      });
  };

  return (
    <div className="gx-login-container">
      <Spin spinning={loading}>
        <div className="gx-login-content education-form">
          <Form
            name="basic"
            onFinish={onFinish}
            fields={fields}
            className="gx-signin-form gx-form-row0"
          >
            <FormItem
              rules={[{ required: false, message: "Please select Candidate" }]}
              name="candidateName"
              label="Candidate Name"
            >
              <Input readOnly={true} disabled={true} />
            </FormItem>

            <FormItem
              rules={[{ required: true, message: "Please select Module" }]}
              name="title"
              label="Module"
            >
              <Select placeholder="Select Module" allowClear>
                {moduleList.map((item, i) => (
                  <Select.Option value={item.id} key={i}>
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>

            <FormItem
              rules={[{ required: true, message: "Please select language" }]}
              name="technology"
              label="Technology"
            >
              <Select placeholder="Select language" allowClear>
                <Select.Option value={"Java"} key={"java"}>
                  Java
                </Select.Option>
                <Select.Option value={"Python"} key={"python"}>
                  Python
                </Select.Option>
              </Select>
            </FormItem>

            <FormItem>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </FormItem>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default EditModule;
