import React from "react";
import {Button, Form, Input, message} from "antd";
import IntlMessages from "util/IntlMessages";
import axiosJSON from "../util/SetHeaderAPI";

const FormItem = Form.Item;


const ResetPassword = (props) => {

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    axiosJSON.post('api/user/change_password/', values)
      .then(({data, status}) => {
          message.success("Changed password successfully!")
          props.closeModal()
      }).catch(function (error) {
      if(error.status === 400){
        message.error(error.message)
      }
      else{
        message.error(error.message)
      }
    })
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">

        <div className="gx-login-header">
          <img style={{width:'30%',height:'30%'}} src={require("assets/images/logo.png")} alt="beats" title="beats"/>
        </div>
        <div className="gx-mb-4">
          <h2>Reset Password</h2>
          <p><IntlMessages id="appModule.enterPasswordReset"/></p>
        </div>

        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0">

          <FormItem rules={ [{required: true, message: 'Please input your old Password!'}]} name="cur_password">
            <Input className='gx-input-lineheight' type="password" placeholder="Old Password"/>
          </FormItem>

          <FormItem rules={ [{required: true, message: 'Please input your new password!'}]} name="new_password">
            <Input className='gx-input-lineheight' placeholder="New Password" type="password"/>
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType="submit">
              <IntlMessages id="app.userAuth.reset"/>
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
