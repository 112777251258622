import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import {useHistory} from "react-router-dom";

const UserProfile = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(({auth}) => auth.authUser);
  const newUser = useSelector(({auth}) => auth.newUser);
  const [visible, setVisible] = useState(false)
  const history = useHistory();

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => {
        setVisible(false)
        history.push('/myprofile')
      }}>My Account
      </li>
      <li onClick={(e) => {
        e.preventDefault()
        dispatch(userSignOut(history))
      }}>Logout
      </li>
    </ul>
  );
  return (

    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      {
        !newUser ?
            authUser && <Popover placement="bottomRight" content={userMenuOptions} trigger="click" visible={visible}
                   onVisibleChange={(visible) => setVisible(visible)}>
            {authUser.avatar === null && authUser.name.split(" ").length !== 0 ?
              authUser.name.split(" ").length >= 2?
                <Avatar className="gx-size-30 gx-pointer gx-mr-2">{authUser.name.split(" ")[0].charAt(0).toUpperCase() + authUser.name.split(" ")[1].charAt(0).toUpperCase()}</Avatar> :
                <Avatar className="gx-size-30 gx-pointer gx-mr-2">{authUser.name.split(" ")[0].charAt(0).toUpperCase()}</Avatar> :

              <Avatar src={authUser.avatar} className="gx-size-30 gx-pointer gx-mr-2" alt=""/>
            }
            <span className="gx-avatar-name">{authUser.name}
              <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/> </span>
          </Popover>
          :
          <Popover placement="bottomRight" content={userMenuOptions} trigger="click" visible={visible}
                   onVisibleChange={(visible) => setVisible(visible)}>
            {newUser.avatar === null && newUser.name.split(" ").length !== 0 ?
              newUser.name.split(" ").length >= 2?
                <Avatar className="gx-size-30 gx-pointer gx-mr-2">{newUser.name.split(" ")[0].charAt(0).toUpperCase() + newUser.name.split(" ")[1].charAt(0).toUpperCase()}</Avatar> :
                <Avatar className="gx-size-30 gx-pointer gx-mr-2">{newUser.name.split(" ")[0].charAt(0).toUpperCase()}</Avatar> :

              <Avatar src={newUser.avatar} className="gx-size-30 gx-pointer gx-mr-2" alt=""/>
            }
            <span className="gx-avatar-name">{newUser.name}
              <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/> </span>
          </Popover>
      }
    </div>



  )
};

export default UserProfile;
