import moment from "moment";
import React from "react";
import { BiHistory } from "react-icons/bi";
import { style } from "./activityStyle";
import { styleSheet } from "./assetStyle";

const Activity = ({ activity }) => {
  return (
    <div
      style={{ ...styleSheet.boxStyle, border: "none", paddingLeft: "20px" }}
    >
      <div style={{ ...styleSheet.assetsDetailsText, fontSize: "16px" }}>
        Activity
      </div>
      <div style={style.paragraphStyle}>
        The history of activities performed on the device.
      </div>
      {activity && activity.map((act, index) => {
        return (
          <div style={style.activityWrapper} key={index}>
            <div style={style.iconStyle}>
              <BiHistory />
            </div>
            <div style={style.activityTextStyle}>
              {act.desc + " on "}
              <b>{moment(act.created).format("llll")}</b>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Activity;
