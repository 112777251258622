import React from 'react';
import {Button, Input, Form, message, Select} from 'antd';
import axiosJSON from "../../util/SetHeaderAPI";

const FormItem = Form.Item;
const {TextArea} = Input;
class AddEditPositions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      edit:this.props.edit,
      location: '',
      fields: [
        {
          name: 'title',
          value: ''
        },
        {
          name: 'description',
          value: ''
        },
        {
          name: 'location',
          value: ''
        }
      ],
      cityArr: [],
    }
    this.Mounted = false
  }

  componentWillUnmount() {
    this.Mounted = false
  }

  componentDidMount() {
    this.Mounted = true
    this.getCities("")
    if(this.props.data)
    {
      this.setState({
        fields: [
          {
            name: 'title',
            value: this.props.data.title
          },
          {
            name: 'description',
            value: this.props.data.description
          },
          {
            name: 'location',
            value: this.props.data.location
          }
        ]
      })
    }
  }

  getCities = (query) => {
    axiosJSON.get('/api/city/?query='+query)
      .then(({data, status}) => {
        this.setState({
          cityArr: data.data
        })
      }).catch((err) => {
      message.error(err.message)
    });
  }

  onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  onFinish = (values) => {
    const body = values;
    if(this.props.edit){
    axiosJSON.put('api/position/'+this.props.data.id+'/', body)
      .then(({data, status}) => {
        if (status === 202) {
          message.success(data.message)
          this.props.closeModal()
        }
      }).catch(function (error) {
      message.error(error.message)
    })
    }
    else
    {
      axiosJSON.post('api/position/', body)
      .then(({data, status}) => {
        if (status === 201) {
          message.success(data.message)
          this.props.closeModal()
        }
      }).catch(function (error) {
      message.error(error.message)
    })}

  }

  render() {
    return (
      <div className="gx-login-container">
        <div className="gx-login-content education-form">

          <Form
            name="basic"
            fields={this.state.fields}
            onFieldsChange={(newFields) => this.setState({fields: newFields})}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            className="gx-signin-form gx-form-row0">

            <FormItem rules={[{required: true, message: 'Please enter Position title'}]} name="title" label="Position Title">
                <Input className='gx-input-lineheight' type="text" />
            </FormItem>

            <FormItem rules={[{required: true, message: 'Please select a location'}]} name="location" label="Location">

            <Select
                showSearch
                style={{width:"100%"}}
                onSearch={this.getCities}>
                {this.state.cityArr.map((ele, i) =>
                  <Select.Option key={i} value={ele.name+", "+ele.state}>
                    {ele.name+", "+ele.state}
                  </Select.Option>
                )}
              </Select>
            </FormItem>

            <FormItem name="description" label="Position Description">
              <TextArea className='gx-input-lineheight' type="textarea"/>
            </FormItem>

             <FormItem>
              <Button type="primary" htmlType="submit">
                Add Position
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

export  default  AddEditPositions;
