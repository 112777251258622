import React, { useEffect, useState } from "react";
import { styles } from "./style";
import { Rate, Spin, message } from "antd";
import axiosJSON from "../../../util/Api";
import moment from "moment";

const CandidateFeedback = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const apiCallFunction = async () => {
      setLoading(true);
      const sliceArray = window.location.href.split("/");
      const candidateID = sliceArray[sliceArray.length - 2];
      try {
        const resp = await axiosJSON.get(`/api/candidate/${candidateID}`);
        if (resp.status === 200) {
          const data = await axiosJSON.get(
            `api/feedback/?id=${candidateID}`
          );
          if (data.status === 200) {
            if(data.data.data.length > 0){
              let key='created';
              data.data.data.sort((a,b) => (b[key] > a[key]) ? 1 : ((a[key] > b[key]) ? -1 : 0))
            }
            setFeedbacks(data.data.data);
            setLoading(false);
          } else {
            setLoading(false);
            message.error("something went wrong");
          }
        } else {
          setLoading(false);
          message.error("something went wrong");
        }
      } catch (error) {
        message.error("something went wrong, Please connect support");
        setLoading(false);
      }
    };
    apiCallFunction();
    // eslint-disable-next-line
  }, []);

  const getConsultant = (name) => {
    if (name && name.length > 0) {
      return (
        name.split(" ")[0]?.[0]?.toUpperCase() +
        name.split(" ")[1]?.[0]?.toUpperCase()
      );
    }
    return "NA";
  };

  return (
    <div style={styles.outerWrapperStyle}>
      <Spin spinning={loading}>
        <div style={styles.innerWrapper}>
          {feedbacks.length > 0 ? (
            feedbacks.map((ele, index) => {
              return (
                <div
                  style={{
                    ...styles.feedbackOuterWrapper,
                    borderBottom:
                      index === feedbacks.length - 1
                        ? null
                        : "1px solid #EAEDF2",
                  }}
                  key={index}
                >
                  <div style={styles.logoWrapper}>
                    {getConsultant(ele.created_by)}
                  </div>
                  <div style={{...styles.rightOuterWrapper,width:"100%"}}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={styles.userStyle}>{ele.created_by}</div>
                      <div style={{ color: "#9AA1B0", fontSize: "15px" }}>
                        {moment(ele.created).format("llll")}
                      </div>
                    </div>
                    <div style={styles.rightWrapper}>
                      <div>
                        <div
                          style={{ alignSelf: "center", textAlign: "center" }}
                        >
                          <span>
                            <Rate
                              style={{ fontSize: "14px" }}
                              disabled={true}
                              allowHalf={false}
                              value={ele.rating}
                            />
                          </span>
                        </div>
                      </div>
                      <div style={{ color: "#717885", fontSize: "13px" }}>
                        {ele.rating < 2
                          ? "Poor"
                          : ele.rating < 3 && ele.rating >= 2
                          ? "Below Average"
                          : ele.rating < 4 && ele.rating >= 3
                          ? "Average"
                          : "Good"}
                      </div>
                      <div style={styles.midDivStyle}>{ele.feedback_type}</div>
                    </div>
                    <div style={styles.descriptionStyle}>
                      <p
                        style={styles.descriptionStyle}
                        dangerouslySetInnerHTML={{ __html: ele.description }}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                width: "100%",
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              No Data Available
            </div>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default CandidateFeedback;
