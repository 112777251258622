import React, { Component } from "react";

import { Button, message, Select, DatePicker, Pagination } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AppModuleHeader from "../../components/AppModuleHeader";
import AddTraining from "../../modals/Training/addTraining";
import TrainingList from "./TrainingList/index";
import axiosJSON from "../../util/SetHeaderAPI";
import CircularProgress from "../../components/CircularProgress";
import {
  onSubQuerySearch,
  setFilterObj,
  setSubFilterObj,
  setTrainingObj,
  onSearch,
} from "../../appRedux/actions";
import moment from "moment";
import { CustomModal as Modal } from "../../util/CustomModal";
import { debounce } from "lodash";

class Training extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trainingList: [],
      showAddTraining: false,
      loader: false,
      query: "",
      prevY: 0,
      page: 1,
      total: 0,
      size: 12,
      category: "all",
      user: {
        name: props.auth.name,
        email: props.auth.email,
      },
      filterList: [
        {
          name: "Status",
          value: "status",
        },
        {
          name: "Language",
          value: "language",
        },
      ],
      filterObj: {},
      statusMapping: [
        { name: "new", display_name: "New" },
        { name: "on_going", display_name: "On Going" },
        { name: "complete", display_name: "Completed" },
        { name: "archived", display_name: "Archived" },
      ],
    };
    this.Mounted = false;
  }

  componentDidMount() {
    this.Mounted = true;
    this.setState({
      filterObj: this.props.filterObj,
      query: this.props.query,
    });
    this.getTrainingData(
      this.state.page,
      this.props.query,
      this.state.category
    );
    // let options = {
    //   root: null,
    //   rootMargin: "0px",
    //   threshold: 1.0,
    // };
    // this.observer = new IntersectionObserver(
    //   this.handleObserver.bind(this),
    //   options
    // );
    // this.observer.observe(this.loadingRef);
  }

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    let t = parseInt(this.state.total) / 10;
    if (Math.ceil(t) >= this.state.page && this.state.prevY > y) {
      const curPage = this.state.page + 1;
      this.getTrainingData(
        this.state.page,
        this.state.query,
        this.state.category
      );
      this.setState({ page: curPage });
    }
    this.setState({ prevY: y });
  }

  componentWillUnmount() {
    this.Mounted = false;
  }

  getTrainingData = (page, query, category) => {
    this.setState({ loader: true });
    let status = this.props.filterObj.hasOwnProperty("status")
      ? this.props.filterObj.status
      : "";
    let start_date = this.props.filterObj.hasOwnProperty("start_date")
      ? moment(this.props.filterObj.start_date).format("YYYY-MM-DD")
      : "";
    axiosJSON
      .get(
        "/api/training/?page=" +
          page +
          "&page_size=" +
          this.state.size +
          "&query=" +
          query +
          "&start_date=" +
          start_date +
          "&category=" +
          category +
          "&status=" +
          status
      )
      .then(({ data, status }) => {
        this.setState({
          trainingList: data.data,
          total: data.total,
          loader: false,
          page: page,
        });
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  selectTraining = (id) => {
    let trainingObj = this.state.trainingList.find(
      (training) => training.id === id
    );
    if (trainingObj !== -1) {
      this.props.setTrainingObj(trainingObj);
      localStorage.setItem("trainingObj", JSON.stringify(trainingObj));
    }
    this.props.onSubQuerySearch("");
    this.props.setSubFilterObj({ status: "Confirmed" });
    this.props.history.push({
      pathname: `/training/${id}`,
      params: { training_id: id },
    });
  };

  handleClose = () => {
    this.setState(
      {
        showAddTraining: false,
      },
      () =>
        this.getTrainingData(
          this.state.page,
          this.state.query,
          this.state.category
        )
    );
  };

  onCategoryChange = (value) => {
    if (!value || value === undefined) {
      value = "all";
    }
    if (this.state.query) {
      this.setState({ category: value }, () =>
        this.getTrainingData(this.state.page, this.state.query, value)
      );
    } else {
      this.setState({ category: value });
    }
  };

  handleCancel = () => {
    this.setState({
      showAddTraining: false,
    });
  };

  getListAPI = debounce(() => {
    this.updateCallback();
  }, 500);

  updateSearch(e) {
    e.preventDefault();
    this.props.onSearch(e.target.value);
    this.setState(
      {
        query: e.target.value,
        page: 1,
      },
      () => this.getListAPI()
    );
  }

  updateCallback = () => {
    this.getTrainingData(
      this.state.page,
      this.state.query,
      this.state.category
    );
  };

  selectFilter = (checked, obj, type) => {
    let filterObj = this.state.filterObj;
    let o = this.state.filterObj;
    if (checked) {
      if (o.hasOwnProperty(type)) {
        o[type] = obj;
        filterObj = o;
      } else if (type === "status") {
        o[type] = "";
        o[type] = obj;
      } else if (type === "start_date") {
        o[type] = obj;
      }
    } else {
      if (o.hasOwnProperty(type)) {
        delete o[type];
      }
    }

    this.props.setFilterObj(filterObj);
    this.setState(
      {
        filterObj: filterObj,
        trainingList: [],
        page: 1,
        total: 0,
      },
      () => this.updateCallback()
    );
  };

  selectStatus = (value, filterName) => {
    if (value) {
      this.selectFilter(true, value, filterName);
    } else {
      this.selectFilter(false, value, filterName);
    }
  };

  render() {
    const { showAddTraining } = this.state;
    const loadingCSS = {
      // margin: "30px",
    };
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    return (
      <div className="gx-main-content">
        <div className="gx-app-module">
          <div className="gx-module-box width-100">
            <div className="gx-module-box-header">
              <span className="gx-drawer-btn gx-d-flex gx-d-lg-none">
                {/*<i*/}
                {/*  className="icon icon-menu gx-icon-btn"*/}
                {/*  aria-label="Menu"*/}
                {/*  onClick={this.onToggleDrawer.bind(this)}*/}
                {/*/>*/}
              </span>
              <div className="mar_min">
                <div className="col-12 vertical_center">
                  <div className="row top_rev">
                    <div className="search col-2 pr-0">
                      <AppModuleHeader
                        placeholder="Search Training"
                        user={this.state.user}
                        onChange={this.updateSearch.bind(this)}
                        value={this.state.query}
                      />
                    </div>
                    <div className="status">
                      <Select
                        style={{ width: "9.5rem", marginRight: "15px" }}
                        allowClear
                        className="category-filter"
                        placeholder="Category Filter"
                        onChange={this.onCategoryChange}
                        value={this.state.category}
                      >
                        <Select.Option value="all">All</Select.Option>
                        <Select.Option value="training_name">
                          Training Name
                        </Select.Option>
                        <Select.Option value="candidate_name">
                          Candidate Name
                        </Select.Option>
                      </Select>
                    </div>
                    <div className="start_picker">
                      <Button
                        style={{ width: "9.5rem" }}
                        variant="raised"
                        type="primary"
                        onClick={() => this.setState({ showAddTraining: true })}
                        className="gx-btn-block m-0"
                      >
                        {" "}
                        Add Training
                      </Button>
                      {/* Start Date */}
                      <DatePicker
                        style={{ width: "9.5rem", marginRight: "15px" }}
                        format={"MM/DD/YYYY"}
                        className="sidebar-datepicker"
                        value={
                          this.state.filterObj.hasOwnProperty("start_date")
                            ? this.state.filterObj["start_date"] === "" ||
                              this.state.filterObj["start_date"] === null
                              ? null
                              : moment(this.state.filterObj["start_date"])
                            : null
                        }
                        placeholder={"Select Start Date"}
                        onChange={(date, dateString) => {
                          if (
                            date === null &&
                            this.state.filterObj.hasOwnProperty("start_date")
                          ) {
                            this.selectFilter(false, "", "start_date");
                          } else {
                            this.selectFilter(true, dateString, "start_date");
                          }
                        }}
                      />
                    </div>
                    <div className="status">
                      {/* Status */}
                      <Select
                        showSearch
                        allowClear={true}
                        value={
                          this.state.filterObj.hasOwnProperty("status")
                            ? this.state.filterObj["status"]
                            : null
                        }
                        style={{ width: "9.5rem" }}
                        placeholder="Select Status"
                        onChange={(value) => this.selectStatus(value, "status")}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.statusMapping.map((status, i) => (
                          <Select.Option key={status.name} value={status.name}>
                            {status.display_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <div className="gx-module-logo grw">
                      <i className="icon icon-graduation gx-mr-4"></i>Training
                    </div>
                  </div>
                </div>
              </div>
              <div className="gx-module-box-content">
                {this.state.loader ? (
                  <CircularProgress />
                ) : (
                  <TrainingList
                    selectTraining={this.selectTraining}
                    trainingList={this.state.trainingList}
                    useDragHandle={true}
                    getCall={this.updateCallback}
                    getTrainingData={this.getTrainingData}
                    accessData={this.props.accessData}
                    loadingTextCSS={loadingTextCSS}
                  />
                )}
                {this.state.trainingList.length > 0 && (
                  <div className="Pagination-section pagination_cust">
                    <Pagination
                      current={this.state.page}
                      total={this.state.total}
                      pageSize={this.state.size}
                      onChange={(page) =>
                        this.setState({ page: page }, () =>
                          this.getTrainingData(
                            page,
                            this.state.query,
                            this.state.category
                          )
                        )
                      }
                    />
                  </div>
                )}
                <div
                  ref={(loadingRef) => (this.loadingRef = loadingRef)}
                  style={loadingCSS}
                />
              </div>
            </div>
          </div>
        </div>
        {showAddTraining && (
          <Modal
            title="Add Training"
            visible={showAddTraining}
            footer={null}
            onCancel={() => this.handleCancel()}
          >
            <AddTraining edit={false} closeModal={() => this.handleClose()} />
          </Modal>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setFilterObj: (data) => dispatch(setFilterObj(data)),
  setTrainingObj: (data) => dispatch(setTrainingObj(data)),
  setSubFilterObj: (data) => dispatch(setSubFilterObj(data)),
  onSubQuerySearch: (query) => dispatch(onSubQuerySearch(query)),
  onSearch: (query) => dispatch(onSearch(query)),
});

const mapStateToProps = (state) => ({
  accessData: state.auth.accessData,
  auth: state.auth.authUser,
  filterObj: state.common.filterObj,
  query: state.common.query,
});

const routeTraining = withRouter(Training);

export default connect(mapStateToProps, mapDispatchToProps)(routeTraining);
