import React, { useEffect, useState } from "react";
import { Layout, message, notification } from "antd";

import Sidebar from "../Sidebar/index";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";
import { withRouter } from "react-router-dom";
import Topbar from "../Topbar/index";

import App from "../../routes/index";
import { useDispatch, useSelector, connect } from "react-redux";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import { useRouteMatch } from "react-router-dom";
import {
  getTimeZone,
  getUserAccess,
  impersonatedMeDetails,
  myDetails,
  unreadNotify,
} from "../../appRedux/actions";
import firebase from "firebase";
import axiosJSON from "../../util/Api";
import { NotificationTwoTone } from "@ant-design/icons";

const { Content } = Layout;

const MainApp = (props) => {
  const { width, navStyle } = useSelector(({ settings }) => settings);
  const [app, setApp] = useState(null);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const messaging = firebase.messaging();

  messaging.onMessage(function (payload) {
    dispatch(unreadNotify(payload.data.count));
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: payload.notification.icon,
    };
    notification.open({
      style: { cursor: "pointer" },
      key: payload.data.target_id,
      message: notificationTitle,
      description: notificationOptions.body,
      icon: <NotificationTwoTone />,
      onClick: () => {},
      // onClick: ()=>{
      //   notification.close(payload.data.target_id)
      //   if(payload.data.target_type === "comment"){
      //     props.history.push("/candidate/"+payload.data.target_id+"/"+payload.data.target_type)
      //   }
      //   else if(payload.data.target_type === "candidate"){
      //     props.history.push("/"+payload.data.target_type+"/"+payload.data.target_id)
      //   }
      //   else if( payload.data.target_type ==="screening"){
      //     props.history.push("/schedule")
      //   } else if( payload.data.target_type ==="job"){
      //     props.history.push("/jobs")
      //   }
      //   else{
      //     props.history.push("/"+payload.data.target_type+"/"+payload.data.target_id)
      //   }
      // }
    });
    if (!("Notification" in window)) {
      console.log("This browser does not support system notifications.");
    } else if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      var n = new Notification(notificationTitle, notificationOptions);
      n.onclick = function (event) {
        event.preventDefault();
        window.open(payload.n.click_action, "_blank");
        n.close();
      };
    }
  });

  useEffect(() => {
    if (Notification.permission === "granted") {
      messaging.getToken().then((t) => {
        updateFCMToken(t);
      });
    } else if (Notification.permission === "default") {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            return messaging.getToken();
          } else return null;
        })
        .then((token) => {
          updateFCMToken(token);
        })
        .catch((error) => {
          if (error.code === "messaging/permission-blocked") {
            message.warning(
              "You have blocked the permission for push notifications"
            );
          }
        });
    } else {
      message.warning("You have blocked the permission for push notifications");
    }
  }, [messaging]);

  const updateFCMToken = (token) => {
    let currentToken = localStorage.getItem("current_token");
    if (currentToken) {
      axiosJSON.defaults.headers["authorization"] = "Token " + currentToken;
    } else {
      axiosJSON.defaults.headers["authorization"] =
        "Token " + localStorage.getItem("token");
    }
    const body = {
      fcm_token: token,
    };
    axiosJSON
      .post("/api/fcm/", body)
      .then(({ data, status }) => {
        if (status === 201) {
          message.success("Your system can receive notifications now!");
        } else {
          message.warning("Something wrong with notifications");
        }
      })
      .catch(function (error) {
        message.error("Something wrong with notifications");
      });
  };

  const getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return "";
    }
  };
  const getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />;
      case NAV_STYLE_DARK_HORIZONTAL:
        return <HorizontalDark />;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />;
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />;
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />;
      case NAV_STYLE_FIXED:
        return (
          <Topbar
            authInfo={props.currentAuth ? props.currentAuth : props.auth}
          />
        );
      case NAV_STYLE_DRAWER:
        return (
          <Topbar
            authInfo={props.currentAuth ? props.currentAuth : props.auth}
          />
        );
      case NAV_STYLE_MINI_SIDEBAR:
        return (
          <Topbar
            authInfo={props.currentAuth ? props.currentAuth : props.auth}
          />
        );
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <NoHeaderNotification />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />;
      default:
        return null;
    }
  };

  const getSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <Sidebar />;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED:
        return <Sidebar />;
      case NAV_STYLE_DRAWER:
        return <Sidebar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar />;
      default:
        return null;
    }
  };

  const getUnreadCount = (token) => {
    axiosJSON.defaults.headers["authorization"] = "Token " + token;
    axiosJSON
      .get("/api/notification/unread_count/")
      .then(({ data, status }) => {
        dispatch(unreadNotify(data.count));
      })
      .catch(function (error) {
        message.error(error);
      });
  };
  const getAppName = () => {
    axiosJSON
      .get("/api/util/app/")
      .then(({ data, status }) => {
        setApp(data.data);
      })
      .catch((error) => message.error(error));
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    let currentToken = localStorage.getItem("current_token");
    getAppName();
    if (currentToken) {
      dispatch(impersonatedMeDetails(currentToken));
      dispatch(getUserAccess(currentToken));
      getUnreadCount(currentToken);
      dispatch(getTimeZone(currentToken));
    } else {
      dispatch(getUserAccess(token));
      getUnreadCount(token);
      dispatch(getTimeZone(token));
    }
    dispatch(myDetails(token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, app]);
  return (
    <Layout className="gx-app-layout">
      {getSidebar(navStyle, width)}
      <Layout>
        {getNavStyles(navStyle)}
        <Content
          className={`gx-layout-content ${getContainerClass(navStyle)} `}
        >
          {app ? <App match={match} app={app} /> : null}
          {/*<Footer>*/}
          {/*  <div className="gx-layout-footer-content">*/}
          {/*    {footerText}*/}
          {/*  </div>*/}
          {/*</Footer>*/}
        </Content>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.authUser,
  currentAuth: state.auth.newUser,
});

const routeMainApp = withRouter(MainApp);

export default connect(mapStateToProps)(routeMainApp);
