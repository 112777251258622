import React from "react";

import UserItem from "./UserItem/index";

const UserList = ({ userList, authUser, selectOptions, log1RoleList }) => {
  return (
    <div className="positions_table pos_user_table">
      <div
        className="gx-module-list-item"
        style={{ backgroundColor: "#A8D0E6" }}
      >
        <div className="gx-media-body gx-task-item-content">
          <div className="gx-task-item-content-left">
            <div className={`gx-fs-md gx-text-black gx-order-sm-4`}>Name</div>
          </div>
          <div className="gx-task-item-content-left">
            <div className={`gx-fs-md gx-text-black gx-order-sm-4`}>Email</div>
          </div>
          <div className="gx-task-item-content-left">
            <div className={`gx-fs-md gx-text-black gx-order-sm-4`}>
              App Access
            </div>
          </div>
          <div className="gx-task-item-content-left">
            <div className={`gx-fs-md gx-text-black gx-order-sm-4`}>Team</div>
          </div>
          <div className="gx-task-item-content-right width50">
            <span className="gx-fs-md gx-text-black gx-order-sm-4">Role</span>
          </div>
          <div className="gx-task-item-content-right">
            <span className="gx-fs-md gx-text-black gx-order-sm-4">
              Actions
            </span>
          </div>
        </div>
      </div>
      {userList.length ? (
        userList.map((user, index) => (
          <UserItem
            authUser={authUser}
            key={index}
            index={index}
            user={user}
            selectOptions={selectOptions}
            log1RoleList={log1RoleList}
          />
        ))
      ) : (
        <div style={{ textAlign: "center" }}>No matching user found.</div>
      )}
    </div>
  );
};

export default UserList;
