import React from "react";
import { Badge,message,Avatar } from 'antd';
import axiosJSON from "../../util/SetHeaderAPI";
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {unreadNotify} from "../../appRedux/actions";
import {useDispatch} from "react-redux";

const NotificationAvatar=(props)=>{
  return   <Avatar className="gx-size-40 gx-mr-3">{props.notification.sender.name.split(" ").length >1?
    props.notification.sender.name.split(" ")[0].charAt(0).toUpperCase() +props.notification.sender.name.split(" ")[1].charAt(0).toUpperCase()
    :props.notification.sender.name.split(" ")[0].charAt(0).toUpperCase()}</Avatar>
}


const dateFormat = 'DD-MMM-YYYY, hh:mm A'
const NotificationItem = (props) => {

  const notf = props.notification;
  const dispatch = useDispatch();
  const {description, title, timestamp, object_id,target} = notf;
  const markAsRead=()=>
  {
    if(props.is_read === false)
    {
      console.log('markAsRead executed')
      axiosJSON.put('api/notification/'+props.id+'/mark_as_read/')
        .then(res => {
          console.log('New Count-----', res.data)
          dispatch(unreadNotify(res.data.count))
          // props.get()
        }).catch(function (error) {
        message.error(error.message)

      })
    }
    if(target === "comment"){
      props.history.push("/candidate/"+object_id+"/"+target)
    }
    else if(target === "candidate"){
      props.history.push("/"+target+"/"+object_id)
    }
    else if( target ==="screening"){
      props.history.push("/schedule")
    } else if( target ==="job"){
      props.history.push("/jobs")
    }else if( target ==="feedback"){
      props.history.push("/candidate/"+object_id+"/"+target)
    }else if( target ==="evaluation"){
      props.history.push("/candidate/"+object_id+"/"+target)
    }else if( target ==="message"){
      props.history.push("/candidate/"+object_id)
    }
    props.hide()
  }

  return (
    <li className="gx-media" onClick={markAsRead}>
      {props.is_read ? <NotificationAvatar notification={props.notification} />
        :<Badge dot>
          <NotificationAvatar notification={props.notification} />
        </Badge>}
      <div className="gx-media-body gx-align-self-center">
        <p className="gx-fs-sm gx-mb-0">{title}</p>
        <p className="gx-fs-sm gx-mb-0">{description}</p>
        <span className="gx-meta-date"><small>{moment(timestamp).format(dateFormat)}</small></span>
      </div>
    </li>
  );
};

export default withRouter(NotificationItem);
