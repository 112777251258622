import React from "react";
import { Tag} from "antd";


const JobItems = (({job,selectJob}) => {
  return (

    <div className="main-jobrow" onClick={()=>selectJob(job.id)}>
    <div className="gx-module-list-item jobrow">
      <div className="gx-media-body gx-task-item-content">

        <div className="gx-task-item-content-left">
          <p
            className={`gx-text-truncate gx-mb-0 gx-text-hover`}>{job.title}</p>
        </div>
        <div className="gx-task-item-content-left">
          <p
            className={`gx-text-truncate gx-mb-0 gx-text-hover`}>{job.portal}</p>
        </div>
        <div className="gx-task-item-content-left">
          <p
            className={`gx-text-truncate gx-mb-0 gx-text-hover`}>{job.position__display_name}</p>
        </div>
        <div className="gx-task-item-content-left">
          <p
            className={`gx-text-truncate gx-mb-0 gx-text-hover`}>{job.location}</p>
        </div>
        <div className="gx-task-item-content-left">
          <p
            className={`gx-text-truncate gx-mb-0 gx-text-hover`}>{job.created_by}</p>
        </div>

        <div className="gx-task-item-content-right">
          {/*<Avatar className="gx-ml-sm-3 gx-size-30 gx-order-sm-3" src={user.avatar}/>*/}
          <Tag className="btn-color-left gx-text-white gx-ml-3 gx-mr-0 gx-mb-0 gx-rounded-xxl gx-order-sm-2">{job.status ? job.status : null}</Tag>

          <Tag className="btn-color-left gx-text-white gx-ml-3 gx-mr-0 gx-mb-0 gx-rounded-xxl gx-order-sm-2">{job.visibility? job.visibility : null}</Tag>
        </div>


      </div>
    </div>
    </div>
  )
});

export default JobItems;
