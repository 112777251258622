import React from "react";
import {message, Tag} from "antd";

import IntlMessages from "util/IntlMessages";
import {deserialize, serialize} from "../../Editor/parseJson";
import axiosJSON from "../../../util/SetHeaderAPI";
import DisplayMail from "./displayMail";
import {CustomModal as Modal, confirm} from "../../../util/CustomModal"

// const {confirm} = Modal;


class ComposeMail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      to: [],
      cc: [],
      bcc: [],
      showCc: false,
      showBcc: false,
      subject: '',
      message: [
        {
          type: 'paragraph',
          children: [
            {text: ''},
          ],
        },
      ],
      emailList: [],
      fileList: [],
      prgObj: null,
    }
  }

  getEmailList = (query) => {
    this.setState({
      emailList: []
    })
    axiosJSON.get('/api/email_message/suggestions/?query=' + query)
      .then(({data, status}) => {
        this.setState({
          emailList: data.data
        })
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  componentDidMount() {
    if (this.props.id !== '') {
      this.setState({id: this.props.id})
    }
    if (this.props.mails) {
      const mail = this.props.mails;
      let m = [
        {
          type: 'paragraph',
          children: [
            {text: ''},
          ],
        },
      ]
      if (mail.body !== "<p></p>") {
        m = deserialize(new DOMParser().parseFromString(mail.body, 'text/html').body)
      }
      this.setState({
        id: mail.id,
        to: mail._to,
        cc: mail.cc || [],
        bcc: mail.bcc || [],
        showCc: mail.cc && mail.cc.length > 0,
        showBcc: mail.bcc && mail.bcc.length > 0,
        subject: mail.subject,
        message: m,
        fileList: mail.attachments,
      })
    }
    this.getEmailList('')
  }

  setEmails = (type, value) => {
    this.setState({
      [type]: value
    })
  }
  setMailBody = (value) => {
    this.setState({
      message: value
    })
  }
  handleChange = (e) => {
    const file = e.target.files[0]
    let fileList = this.state.fileList;
    let fileObj = {
      'file_name': e.target.files[0].name
    }
    fileList.push(fileObj)
    this.setState({
      fileList: fileList
    }, () => this.onFileUpload(file, this.state.id))
  }
  onFileUpload = (file, id) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('message', id);
    axiosJSON.post('/api/email_doc/', formData,
      {
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null) {
            let prgObj = {
              'file_name': file.name,
              'prg': Math.round((progressEvent.loaded * 100) / totalLength)
            }
            this.setState({
              prgObj: prgObj
            })
            if (Math.round((progressEvent.loaded * 100) / totalLength)=== 100) {
              this.setState({
                prgObj: null
              })
            }
          }
        }
      }).then(({data, status}) => {
      this.setState({
        fileList: data.data
      })
      message.success(data.message)
    }).catch(function (error) {
      message.error(error.message)
    })
  }
  onDownloadFile = (file_id, message_id) => {
    axiosJSON.get('/api/email_doc/' + file_id + '/')
      .then(({data, status}) => {
        window.open(data.data.url)
      }).catch(function (error) {
      message.error(error.message)
    })
  }
  onDeleteFile = (file_id, message_id) => {
    axiosJSON.delete('/api/email_doc/' + file_id + '/')
      .then(({data, status}) => {
        let fileList = this.state.fileList.filter(val => val.id !== file_id);
        this.setState({
          fileList: fileList
        })

      }).catch(function (error) {
      message.error(error.message)
    })
  }
  onSubChange = (e) => {
    this.setState({subject: e.target.value})
  }

  setFlag = (type) => {
    this.setState({
      [type]: true
    })
  }
  tagRender(props) {
    const { label, value, closable, onClose } = props;

    var re = /\S+@\S+\.\S+/;
    return (
      <Tag color={re.test(String(value).toLowerCase()) ? 'gray':'darkred'} closable={closable} onClose={onClose}>
        {label}
      </Tag>
    );
  }

  render() {
    const {onMailSend, onClose,} = this.props;
    const {id, to, subject, message, cc, bcc, showCc, showBcc, emailList,fileList,prgObj} = this.state;
    return (
      <Modal maskClosable={false}
             onCancel={() => {
               let m = serialize(message)
               if (to && to.length === 0 && subject === '' && m === "<p></p>") {
                 onClose(null)
               } else {
                 let data = {
                   'to': to,
                   'cc': cc,
                   'bcc': bcc,
                   'subject': subject,
                   'body': m,
                 }
                 if (id === null) {
                   onClose(data, null)
                 } else {
                   onClose(data, id)
                 }
               }

             }}
             visible={this.props.open}
             title={<IntlMessages id="mail.title"/>}
             closable={false}
             okText="Send"
             cancelText="Discard"
             onOk={() => {
               let data = {
                 'to': to,
                 'cc': cc,
                 'bcc': bcc,
                 'subject': subject,
                 'body': serialize(message),
               }
               if (to && to.length === 0)
                 return;
               if (subject.trim() !== "") {
                 if (id === null) {
                   onMailSend(data, null)
                 } else {
                   onMailSend(data, id)
                 }
               } else {
                 confirm({
                   title: 'Missing Subject',
                   content: 'Do you Want to send mail without subject?',
                   okText: 'Send',
                   cancelText: "Don't Send",
                   onOk() {
                     if(prgObj === null){
                       onMailSend({
                         'to': to,
                         'cc': cc,
                         'bcc': bcc,
                         'subject': subject,
                         'body': serialize(message),
                       })
                       onClose(null);
                       this.setState({
                         to: [],
                         cc: [],
                         bcc: [],
                         showCc: false,
                         showBcc: false,
                         subject: '',
                         message: [
                           {
                             type: 'paragraph',
                             children: [
                               {text: ''},
                             ],
                           },
                         ],
                         emailList: []
                       })
                     }
                     else{
                       message.warning("Please wait.. File uploading..")
                     }
                   },
                   onCancel() {
                     let m = serialize(message)
                     if(prgObj === null){
                       if (to.length === 0 && subject === '' && m === "<p></p>") {
                         onClose(null)
                       } else {
                         onClose({
                           'to': to,
                           'cc': cc,
                           'bcc': bcc,
                           'subject': subject,
                           'body': m,
                         })
                       }
                     }
                     else{
                       message.warning("Please wait.. File uploading..")
                     }

                   },
                 });

               }
             }}
             style={{zIndex: 2600, width: '600px'}} className="mail_popup">
        <DisplayMail
          to={to} cc={cc} bcc={bcc} showCc={showCc} showBcc={showBcc}
          emailList={emailList} fileList={fileList} prgObj={prgObj}
          id={id} subject={subject} message={message}
          setEmails={this.setEmails} setFlag={this.setFlag} tagRender={this.tagRender}
          getEmailList={this.getEmailList} setMailBody={this.setMailBody} onSubChange={this.onSubChange}
          handleChange={this.handleChange} onDownloadFile={this.onDownloadFile} onDeleteFile={this.onDeleteFile}
        />
      </Modal>
    );
  }
}

export default ComposeMail;
