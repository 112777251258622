import React, { useState } from "react";
import { Button, Form, message, Radio, Input } from "antd";
import axiosJSON from "../../util/SetHeaderAPI";

const { TextArea } = Input;

const FormItem = Form.Item;

const EditSessionType = (props) => {
  const [sessionType, setSessionType] = useState(props.candidate.session_type);
  const [fields] = useState([
    {
      name: "title",
      value:
        props.candidate.session_type !== ""
          ? props.candidate.session_type
          : null,
    },
    {
      name: "reason",
      value: props.candidate.reason,
    },
  ]);

  const onFinish = (value) => {
    const data = {
      session_type: value.title.toLowerCase(),
      reason: value.reason,
    };
    axiosJSON
      .put(
        "api/training_candidate/" + props.candidate.training_candidate_id + "/",
        data
      )
      .then(({ data, status }) => {
        if (status === 202) {
          message.success(data.message);
          props.closeModal();
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content education-form">
        <Form
          name="basic"
          onFinish={onFinish}
          fields={fields}
          className="gx-signin-form gx-form-row0"
          onFieldsChange={(newField) => {
            if (newField.length > 0 && newField[0].name[0] === "title") {
              setSessionType(newField[0].value);
            }
          }}
        >
          {/* <FormItem
            rules={[{ required: false, message: "Please select Candidate" }]}
            name="candidateName"
            label="Candidate Name"
          >
            <Input readOnly={true} />
          </FormItem> */}
          <FormItem name="title" label="Session Type">
            <Radio.Group>
              <Radio style={{ width: "80px" }} value={"Live"}>
                Live
              </Radio>
              <Radio value={"Recorded"}>Recorded</Radio>
            </Radio.Group>
          </FormItem>
          {sessionType === "Recorded" && (
            <FormItem
              name="reason"
              label="Reason"
              rules={[
                {
                  required: true,
                  message: "Please mention the reason",
                },
              ]}
            >
              <TextArea />
            </FormItem>
          )}

          <FormItem>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default EditSessionType;
