import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axiosJSON from "../../../util/SetHeaderAPI";
import Widget from "../../../components/Widget";
import { Col, Row, message, Card, Dropdown, Button, Menu, Tag, Tooltip } from "antd";
import { withRouter } from 'react-router-dom';

import "nprogress/nprogress.css";
import nprogress from "nprogress";

const moment = require('moment-timezone');
class Evaluation extends React.Component {
  constructor(props) {
    const splitArray = window.location.href.split("/")
    super(props);
    this.state = {
      type:'',
      editShow: false,
      add_dropdown:false,
      feedback_type: [],
      evaluation_list: [],
      candidate_id: splitArray[splitArray.length-2],
      user: {
        id: props.auth.id,
        name: props.auth.name
      },
    }
  }
  deleteConfirm = (id) => {
    axiosJSON.delete('api/screening/' + id + '/')
      .then(res => {
        this.getScreening()
        message.success('Meeting Deleted!');
      }).catch(function (error) {
        message.error(error.message)
      })
  }

  getFeedbackType = () => {
    axiosJSON.get('/api/eval_template/')
      .then(res => {
        this.setState({feedback_type: res.data.data})
      }).catch(function (error) {
        message.error(error.message)
      })
  }

  getEvaluation = () => {
    axiosJSON.get('/api/candidate/'+this.state.candidate_id+'/evaluation/')
      .then(res => {
        this.setState({evaluation_list:res.data.data})
      }).catch(function (error) {
        message.error(error.message)
      })
  }

  handleclose = () => {
    this.setState({
      editShow: false
    })
    this.getScreening()
  }

  handleEditShow = (data) => {
    this.setState({
      editShow: true,
      screening_obj: data
    })
    this.Mounted = false
  }

  selectType=(value)=>{
    this.setState({
      type:value.key
    },() => {
      this.props.history.push(`/feedback/${this.state.candidate_id}/${this.state.type}/${false}/0`)
    })

  }

  componentWillUnmount() {
    this.Mounted = false
  }

  componentDidMount() {
    nprogress.start()
    this.Mounted = true
    this.getEvaluation();
    this.getFeedbackType();
    nprogress.done()
  }

  typeMenu = () => {
    return (
      <Menu id="long-menu" onClick={this.selectType}>
        {this.state.feedback_type.map((type)=>(
          <Menu.Item key={type.id}>{type.display_name}</Menu.Item>
        ))}
      </Menu>)
  };

  render() {
    const { evaluation_list,feedback_type } = this.state;
    return (
      <div className="gx-profile-content">
        <Widget title="Evalution" styleName="gx-card-profile">
          <Row>
            <Col xl={16} lg={14} md={14} sm={24} xs={24}>
              <div>
                <Dropdown overlay={this.typeMenu} trigger={['click']}>
                  <Button type="primary">Add Feedback</Button>
                </Dropdown>
              </div>
            </Col>
            <Col xl={16} lg={14} md={14} sm={24} xs={24}>

              {evaluation_list.map((evaluation_obj, index) => (
                <div className="feedback-section-new">
                <Card key={index}>
                  {
                    evaluation_obj.type && (
                      <Link to={{ pathname: `/feedback/${this.state.candidate_id}/${evaluation_obj.type.id}/${true}/${evaluation_obj.id}` }}>View Feedback!</Link>

                    )
                  }

                  <div className="">
                    <ul className="feedbacknew">
                      <li className="first-icon"> <i className="icon icon-feedback" /></li>

                      <li>
                          <span className="rate-middle-sec" style={{marginTop: "44px"}}>
                           {/*{*/}
                           {/*  evaluation_obj.rating && evaluation_obj.rating !== 0 &&*/}
                           {/*  <span className="rate-middle-sec">*/}
                           {/*   <Rate disabled defaultValue={evaluation_obj.rating}/>*/}
                           {/*  </span>*/}
                           {/*}*/}
                            <span>{evaluation_obj.type?.name}</span>
                           <Tag className="btn-color-right gx-text-white gx-mr-0 gx-rounded-xxl gx-order-sm-2">{evaluation_obj.verdict}</Tag>
                        <p><span className="gx-fs-xs">{evaluation_obj.modified ? moment(evaluation_obj.modified).format(" MMMM Do YYYY , hh:mm A"):moment(evaluation_obj.created).format(" MMMM Do YYYY , hh:mm A")}</span></p>

                         </span>
                      <br/>
                        <p><Tooltip title="Created by"><i className="icon icon-user-o"/></Tooltip> : <span>{evaluation_obj.created_by.name}</span> </p>
                        <p><Tooltip title="Interview type"><i className="icon icon-schedule"/></Tooltip> : <span>{feedback_type.map((type)=>(type.name === evaluation_obj.type ? <span> {type.display_name} </span> : null
                         ))}</span></p>
                       <p dangerouslySetInnerHTML={{__html: evaluation_obj.description}} />
                      </li>

                    </ul>
                  </div>
                </Card>
                </div>
              ))}
            </Col>

          </Row>
        </Widget>
      </div>

    );
  }
};
const mapStateToProps = state => ({
  auth: state.auth.authUser
});

const routeEvalution = withRouter(Evaluation)

export default connect(
  mapStateToProps,
)(routeEvalution);
