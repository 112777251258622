import React from "react";
import { Upload, Button } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { FiUploadCloud } from "react-icons/fi";

const { Dragger } = Upload;

const CustomUpload = ({
  fileList,
  disabled,
  onRemove,
  accept,
  type,
  dragger,
  onChange,
}) => {
  const props = {
    disabled: disabled,
    accept: accept,
    onChange: onChange,
    showUploadList: false,
    customRequest: () => {},
  };

  return (
    <div>
      {dragger ? (
        <Dragger {...props}>
          <div className="">
            <div className="">
              <FiUploadCloud
                style={{
                  color: "#F86011",
                  height: "20px",
                }}
              />
            </div>
            <div className="" style={{ color: "#F86011" }}>
              Click or drag file to this area to upload
            </div>
          </div>
        </Dragger>
      ) : (
        <Upload {...props}>
          <Button>
            <UploadOutlined /> Select File
          </Button>
        </Upload>
      )}
      {fileList.length > 0 &&
        fileList.map((file, i) => (
          <span className="d-flex" key={i}>
            <span>{file.name}</span>
            <DeleteOutlined
              style={{ color: "red", marginLeft: "400px" }}
              onClick={() => onRemove(file)}
            />
          </span>
        ))}
    </div>
  );
};
export default CustomUpload;
