import React, { useEffect } from "react";
import { Button, Form, Input, message } from "antd";
import IntlMessages from "util/IntlMessages";
import axiosJSON from "../util/SetHeaderAPI";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaArrowAltCircleLeft } from "react-icons/fa/index";

const FormItem = Form.Item;

const ForgotPassword = (props) => {
  const history = useHistory();
  const token = useSelector(({ auth }) => auth.token);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (token !== null) {
      props.history.push("/dashboard");
    }
  }, [token, props.history]);

  const goBack = () => {
    window.location = "/#/signin/";
  };

  const onFinish = (values) => {
    axiosJSON
      .post("api/password/token_request/", values)
      .then(({ data, status }) => {
        message.success(data.message);
        history.push("/reset-password");
      })
      .catch(function (error) {
        message.error(error.data.message);
      });
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-login-header">
          <img
            src={require("assets/images/logo.png")}
            alt="beats"
            title="beats"
          />
        </div>
        <div className="gx-mb-4 gx-mt-4">
          <h2>
            <FaArrowAltCircleLeft
              onClick={() => goBack()}
              className="gx-mb-0"
              style={{ cursor: "pointer", color: "23315E" }}
            />{" "}
            Recovery Message
          </h2>
        </div>
        <div className="gx-mb-4">
          <h2>Forgot Your Password ?</h2>
          <p>
            <IntlMessages id="app.userAuth.forgot" />
          </p>
        </div>

        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          <FormItem
            name="email"
            rules={[
              { required: true, message: "Please input your E-mailBox!" },
            ]}
          >
            <Input
              className="gx-input-lineheight"
              type="email"
              placeholder="E-Mail Address"
            />
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType="submit">
              <IntlMessages id="app.userAuth.send" />
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
