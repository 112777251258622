import React, {useEffect, useState} from "react";
import {Cell, Pie, PieChart, ResponsiveContainer} from "recharts";
import {message, Tooltip} from "antd";
import axiosJSON from "../../../util/SetHeaderAPI";

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#F80952'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index, value, name}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.25;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <Tooltip title={name}>
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {value}
      </text>
    </Tooltip>

  );
};

const CandidatePipelinePie = (props) => {

  const [data, setData] = useState([
    {name: 'New', value: 120},
    {name: 'Qualified', value: 20},
    {name: 'Training', value: 40},
    {name: 'Evaluation', value: 50},
    {name: 'Hired', value: 60}
  ])
  const [total, setTotal] = useState(0)

  const getDashboardData = () => {
    axiosJSON.get('api/dashboard/')
      .then(({data, status}) => {
        let arr = [];
        let obj = {}
        data.data.pie_chart.map(d => {
          obj = {name: d.display_name, value: d.count}
          arr.push(obj)
          return arr
        })
        setData([...arr])
        let total = 0;
        data.data.pie_chart.map(d => total += d.count)
        setTotal(total)
      })
      .catch(error => {
        message.error(error.message)
      })
  }

  useEffect(() => {
    getDashboardData()

  }, [])
  return (
    <div className="candidate-chart" style={{backgroundColor: 'azure', marginTop: '30px'}}>
      <h2 className="h4 gx-text-capitalize gx-mb-0" style={{textAlign: 'center'}}>
        CANDIDATE PIPELINE</h2>
      <div className="gx-py-3">
        {total > 0 ?
          <ResponsiveContainer width="100%" height={250}>
            <PieChart>
              <Tooltip/>
              <Pie
                data={data} dataKey="value"
                labelLine={false}
                paddingAngle={2}
                label={renderCustomizedLabel}
                innerRadius={47}
                outerRadius={117}
                fill="#8884d8"
              >
                {
                  data.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
                }
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          :
          <h5 style={{textAlign: 'center'}}>
            No data
          </h5>
        }
      </div>

    </div>
  );

}

export default CandidatePipelinePie
