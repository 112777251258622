import { message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import axiosJSON from "../../../../../util/Api";
import Activity from "./Activity";
import Asset from "./Asset";

const AssetsDetails = ({ detailId }) => {
  const [loading, setLoading] = useState(true);
  const [activity, setActivity] = useState([]);

  const getActivity = (id) => {
    setLoading(true);
    axiosJSON
      .get("api/user_assets/" + id + "/activity/")
      .then(({ data }) => {
        setActivity(data.data);
        setLoading(false);
      })
      .catch((error) => {
        message.error(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getActivity(detailId);
    // eslint-disable-next-line
  }, []);

  return (
    <Spin spinning={loading}>
      <div style={{ display: "flex", width: "100%", paddingTop: "10px" }}>
        <div style={{ width: "53%" }}>
          <Asset detailId={detailId} getActivity={getActivity} />
        </div>
        <div style={{ width: "47%" }}>
          <Activity activity={activity} />
        </div>
      </div>
    </Spin>
  );
};

export default AssetsDetails;
