import React, {useEffect, useState} from "react";
import {Button, message,} from "antd";
import axiosJSON from "../../util/SetHeaderAPI";
import {useSelector} from "react-redux";


const ViewFollower = (props) => {
  const user = useSelector(({auth}) => auth);
  const [followers, setFollowers] = useState([])

  useEffect(() => {
    setFollowers(props.followers)
  }, [props]);

  const unfollowCandidate=()=>{
    const body = {
      'candidates': [props.id]
    }
    axiosJSON.put('api/candidate/unfollow/', body)
      .then(({data, status}) => {
        if (status === 202) {
          message.success(data.message)
          props.closeModal()
        }
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  return (
    <div className="gx-login-list">
      {
        followers.length !== 0 &&
        followers.map((follower, index) =>
          <div key={index} className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
            <div className="gx-mr-3">
              <i className={`icon icon-user gx-fs-xxl gx-text-grey`}/>
            </div>
            <div className="gx-media-body">
              <p className="gx-mb-0"><span className="gx-text-black gx-fs-l gx-mb-0" key={index}>{follower.name}</span>
              </p>
              <p className="gx-mb-0"><span className="gx-text-blue gx-fs-sm gx-mb-0" key={index}>{follower.email}</span>
              </p>
            </div>
            {
              user.authUser.employee_id === follower.employee_id ?
                <Button onClick={unfollowCandidate}>Unfollow</Button>:
                null
            }
          </div>
        )
      }

    </div>
  );
};

export default ViewFollower;
