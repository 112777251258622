import React, { Component } from "react";
import axiosJSON from "../../util/SetHeaderAPI";
import FileViewer from "react-file-viewer";
import { connect } from "react-redux";
import { serialize, deserialize } from "../../components/Editor/parseJson";
import CustomTextArea from "../../components/CustomFields/CustomTextArea";
import { message, Spin, Col, Row, Rate, Button, Select } from "antd";
import SlateEditor from "../../components/CommentTagEditor";
import { withRouter } from "react-router";
import "nprogress/nprogress.css";
import nprogress from "nprogress";

const desc = ["terrible", "bad", "normal", "good", "wonderful"];
const beforeMatch = /^@(\w+)$/;
const beforeStr = "@";
const afterStr = "";
const Editor = ({ onChange, value, onSelectUser, user_list }) => (
  <div>
    <SlateEditor
      beforeMatch={beforeMatch}
      afterStr={afterStr}
      beforeStr={beforeStr}
      onSelectUser={onSelectUser}
      setData={onChange}
      user_list={user_list}
      value={value}
    />
  </div>
);

const star = (
  <span style={{ color: "#CC332D", fontSize: "15px", paddingRight: "3px" }}>
    *
  </span>
);

class Feedback extends Component {
  constructor(props) {
    super(props);
    const {candidate_id,type,edit,eval_id} = props.match.params;
    this.state = {
      type,
      edit,
      eval_id,
      candidate_id,
      rating: 0,
      value: "",
      verdict: "",
      filePath: "",
      fileType: "",
      description: "",
      technology: "",

      eval_data: {},
      user_list: [],
      inputFields: [],
      field_values: [],
      selected_user: [],
      verdict_options: [],
      technologies: [],

      loading: false,
      put_call: false,
      showEditor: false,
      no_resume: false,
      rating_remark: true,

      details: [{ type: "paragraph", children: [{ text: "" }] }],
      appName: "us",
      currentUserId:this.props.currentAuth ? this.props.currentAuth.employee_id : this.props.auth.employee_id,
    };
    this.Mounted = false;
  }

  componentWillUnmount() {
    this.Mounted = false;
  }

  componentDidMount() {
    nprogress.start();
    this.getAppName();
    this.fetchResume();
    this.getUserList();
    this.getFormFields();
    if (this.state.edit === "true") {
      this.setState(
        {
          edit: true,
          showEditor: false,
        },
        () => this.getEvalData()
      );
    } else if (this.state.edit === "false") {
      this.setState({
        edit: false,
        showEditor: true,
        details: [{ type: "paragraph", children: [{ text: "" }] }],
      });
    }
    nprogress.done();
  }

  onSelectUser = (value) => {
    let user = this.state.selected_user;
    user.push(value);
    this.setState({
      selected_user: user,
    });
  };

  getAppName = () => {
    axiosJSON
      .get("/api/util/app/")
      .then(({ data, status }) => {
        this.setState({ appName: data.data });
      })
      .catch((error) => message.error(error));
  };

  // getVerdict = () => {
  //   axiosJSON.get('api/eval_template/feedback_fields/')
  //     .then(({ data, status }) => {
  //       if (status === 200) {
  //         this.setState({
  //           verdict_options: data.data.verdict,
  //           rating_remark: data.data.rating
  //         })
  //       }
  //     }).catch(function (error) {
  //       message.error(error.message)
  //     })
  // }

  getUserList = () => {
    axiosJSON
      .get("api/user/tags/")
      .then(({ data, status }) => {
        if (status === 200) {
          this.setState({
            user_list: data.data,
          });
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  getEvalData = () => {
    axiosJSON
      .get(
        "api/candidate/" +
          this.state.candidate_id +
          "/evaluation/" +
          this.state.eval_id +
          "/"
      )
      .then((res) => {
        this.setState({ eval_data: res.data.data }, () => {
          this.setEvalData();
        });
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  setEvalData = () => {
    let text;
    if (this.state.eval_data.description === "<p></p>") {
      text = [
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ];
    } else {
      text = deserialize(
        new DOMParser().parseFromString(
          this.state.eval_data.description,
          "text/html"
        ).body
      );
    }
    this.setState({
      details: text,
      selected_user:
        this.state.eval_data.tagged_users === null
          ? []
          : this.state.eval_data.tagged_users,
      rating: this.state.eval_data.rating,
      verdict: this.state.eval_data.verdict,
      technology: this.state.eval_data.language,
      showEditor: true,
    });
    this.state.eval_data.field_value.map((field) => {
      this.setState({ [field.field.name]: field.value });
      return null;
    });
    this.onEdit();
  };

  onError = (e) => {
    console.log(e);
  };

  fetchResume = () => {
    axiosJSON
      .get("api/candidate/" + this.state.candidate_id + "/resume/")
      .then((res) => {
        const body = res.data.data[0];
        if (body) {
          this.setState({
            filePath: body.file,
            fileType: body.file_ext,
          });
        } else {
          this.setState({ no_resume: true });
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  getFormFields = () => {
    this.setState({
      loading: true,
    });
    axiosJSON
      .get("api/eval_template/" + this.state.type + "/")
      .then(({ data, status }) => {
        this.setState({
          loading: false,
          inputFields: data.data.fields,
          verdict_options: data.data.verdict,
          rating_remark: data.data.rating,
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  onChange = (name, event, id) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  onDescriptionChange = (event) => {
    this.setState({
      description: event.target.value,
    });
  };

  onEdit = () => {
    if (
      this.state.eval_data.created_by.employee_id ===
      this.state.currentUserId
    ) {
      this.setState({ edit: false, put_call: true, showEditor: true });
    }
  };

  onSubmit = () => {
    let obj = {};
    let n = "";
    let temp_field_values = [];
    if (this.state.inputFields.length !== 0) {
      this.state.inputFields.map((field) => {
        n = field.name;
        obj[n] = false;
        if (this.state[n]) {
          obj[n] = true;
        }
        return obj;
      });
    }

    var flag = Object.values(obj).every((k) => k === false);

    if (!flag || Object.keys(obj).length === 0) {
      if (this.state.inputFields.length !== 0) {
        this.state.inputFields.map((field) => {
          n = field.name;
          if (this.state[n]) {
            temp_field_values.push({
              field_id: field.id,
              value: this.state[n],
            });
          }
          return null;
        });
      }
      let text = serialize(this.state.details);
      if (!this.state.details) {
        return;
      }
      let eid = [];
      this.state.selected_user.map((user) => {
        let i = this.state.user_list.find((u) => u.username === user.username);
        eid.push(i.id);
        return null;
      });
      let body = {
        tagged_user: eid,
        description: text,
        rating: this.state.rating,
        verdict: this.state.verdict,
        field_values: temp_field_values,
        type: this.state.type,
        language: this.state.technology,
      };
      if (this.state.verdict !== "") {
        if (this.state.put_call) {
          axiosJSON
            .put(
              "api/candidate/" +
                this.state.candidate_id +
                "/evaluation/" +
                this.state.eval_id +
                "/",
              body
            )
            .then(({ data, status }) => {
              if (status === 202) {
                message.success("Evaluation Feedback Updated!");
                this.setState(
                  {
                    details: [
                      {
                        type: "paragraph",
                        children: [{ text: "" }],
                      },
                    ],
                    showEditor: false,
                    rating: 0,
                    selected_user: [],
                  },
                  // () => this.getEvalData()
                  this.props.history.goBack()
                );
              }
            })
            .catch((err) => {
              message.error(err.message);
            });
        } else {
          axiosJSON
            .post(
              "api/candidate/" + this.state.candidate_id + "/evaluation/",
              body
            )
            .then(({ data, status }) => {
              if (status === 201) {
                message.success("Evaluation Feedback Added!");
                this.setState({
                  details: [
                    {
                      type: "paragraph",
                      children: [{ text: "" }],
                    },
                  ],
                  showEditor: false,
                  rating: 0,
                  selected_user: [],
                });
                this.props.history.goBack();
              }
            })
            .catch((err) => {
              message.error(err.message);
            });
        }
      } else {
        message.error("Select final verdict");
      }
    } else {
      message.error("Please fill the fields");
    }
  };

  handleTextChange = (e) => {
    this.setState({
      details: e,
    });
  };

  changeRating = (value) => {
    this.setState({
      rating: value,
    });
  };
  closeTab = () => {
    this.props.history.goBack();
  };
  onChangeVerdict = (val) => {
    this.setState({
      verdict: val,
    });
  };
  onChangeTechnology = (val) => {
    this.setState({
      technology: val,
    });
  };

  render() {
    return (
      <div>
        <div className="main-form">
          <Row>
            <i className="icon icon-arrow-left" onClick={this.closeTab} />
          </Row>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {this.state.loading ? (
                <Spin tip="Please wait..." />
              ) : (
                <div className="main">
                  <div className="main-app-form">
                    <h1> Add your Feedback here </h1>
                  </div>
                  <div className="evaluation-form">
                    <Row>
                      <ul>
                        <div
                          className="Verdict-label"
                          style={{ display: "flex" }}
                        >
                          <p>{star}</p>
                          <h4>Verdict</h4>
                        </div>
                        <Select
                          style={{ width: "275px" }}
                          value={this.state.verdict}
                          onChange={this.onChangeVerdict}
                        >
                          {this.state.verdict_options.map((option, i) => (
                            <Select.Option value={option.name} key={i}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </ul>

                      {this.state.appName === "us" &&
                      this.state.type === "1" ? (
                        <ul>
                          <div
                            className="Verdict-label"
                            style={{ display: "flex" }}
                          >
                            <p>{star}</p>
                            <h4>Technology</h4>
                          </div>
                          <Select
                            style={{ width: "275px" }}
                            value={this.state.technology}
                            onChange={this.onChangeTechnology}
                          >
                            <Select.Option value={"Java"} key={"java"}>
                              Java
                            </Select.Option>
                            <Select.Option value={"Python"} key={"python"}>
                              Python
                            </Select.Option>
                          </Select>
                        </ul>
                      ) : null}
                    </Row>
                    {this.state.inputFields.length !== 0 &&
                      this.state.inputFields.map((field, i) => (
                        <Row key={i}>
                          <div
                            style={{ display: "inline-flex", width: "90%" }}
                            key={i}
                          >
                            <div className="application-form">
                              <ul>
                                <li>
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      <h4>{field.display_name}</h4>
                                    </div>
                                    <span>{field.description}</span>
                                    <CustomTextArea
                                      onChange={(e) =>
                                        this.onChange(field.name, e, field.id)
                                      }
                                      value={this.state[field.name]}
                                      key={field.name}
                                      name={field.name}
                                      readOnly={this.state.edit}
                                      placeholder={field.display_name}
                                    />
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Row>
                      ))}
                    {this.state.rating_remark ? (
                      <div className="raring-section">
                        <div>
                          <h4>Rating</h4>
                          <Rate
                            tooltips={desc}
                            disabled={this.state.edit}
                            value={this.state.rating}
                            onChange={this.changeRating}
                          />
                        </div>

                        <div className="main-text-editor">
                          <h4>Explain Your Rating</h4>
                          <div className="text-editor">
                            <div style={{ padding: "20px" }}>
                              {this.state.showEditor && (
                                <Editor
                                  onChange={this.handleTextChange}
                                  value={this.state.details}
                                  user_list={this.state.user_list}
                                  onSelectUser={this.onSelectUser}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="raring-section">
                        <div className="main-text-editor">
                          <h4>Remark</h4>
                          <div className="text-editor">
                            <div style={{ padding: "20px" }}>
                              {this.state.showEditor && (
                                <Editor
                                  onChange={this.handleTextChange}
                                  value={this.state.details}
                                  user_list={this.state.user_list}
                                  onSelectUser={this.onSelectUser}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="button-submit">
                      {!this.state.edit && (
                        <Button
                          className="sub-button"
                          type="primary"
                          onClick={this.onSubmit}
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                    <br clear="all" />
                  </div>
                </div>
              )}
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="headline">
                <h1>Resume</h1>
              </div>
              <div style={{ height: "760px" }}>
                {!this.state.no_resume ? (
                  <FileViewer
                    fileType={this.state.fileType}
                    filePath={this.state.filePath}
                    errorComponent={<div>Error</div>}
                    onError={this.onError}
                  />
                ) : (
                  <div>
                    <p> Resume Not Present</p>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth.authUser,
  currentAuth: state.auth.newUser,
});

const routeFeedback = withRouter(Feedback);

export default connect(mapStateToProps)(routeFeedback);
