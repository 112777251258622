import React from "react";
import axiosJSON from "../../util/SetHeaderAPI";
import { Drawer, message, DatePicker, Spin, Pagination, Menu, Tag } from "antd";
import AppModuleHeader from "../../components/AppModuleHeader";
import CustomScrollbars from "util/CustomScrollbars";
import IntlMessages from "util/IntlMessages";
import MeetingsList from "./list";
import MeetingDetails from "../../modals/Schedule/meetingDetails";
import moment from "moment";
import CircularProgress from "../../components/CircularProgress";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import MeetingForm from "../../modals/Candidate/scheduleMeeting";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "nprogress/nprogress.css";
import nprogress from "nprogress";
import { CustomModal as Modal, confirm } from "../../util/CustomModal";
import { debounce } from "lodash";
// const { confirm } = Modal;

class Schedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerState: false,
      query: "",
      loader: false,
      meetingsList: [],
      total_pages: 0,
      currentMeetingList: null,
      showMeetingDetail: false,
      meetingDetail: {},
      allSchedules: [],
      created_filter: "",
      start_filter: "",
      prevY: 0,
      page: 1,
      tzDate: new Date(),
      editShow: false,
      data: null,
    };
    this.Mounted = false;
  }

  onToggleDrawer() {
    this.setState({
      drawerState: !this.state.drawerState,
    });
  }

  componentWillUnmount() {
    this.Mounted = false;
  }

  callListAPI = debounce(() => {
    this.updateCallback(this.state.page, []);
  }, 500);

  updateSearch(evt) {
    evt.preventDefault();
    this.setState(
      {
        query: evt.target.value,
        meetingList: [],
        page: 1,
        total_pages: 0,
      },
      () => this.callListAPI()
    );
  }

  componentDidMount() {
    nprogress.start();
    this.Mounted = true;
    this.getMeetingList(this.state.page);
    nprogress.done();
  }

  getMeetingList = (page) => {
    this.updateCallback(page, this.state.meetingsList);
  };

  updateCallback = (page, arr) => {
    this.setState({ loader: true });
    axiosJSON
      .get(
        "/api/screening/?page=" +
          page +
          "&query=" +
          this.state.query +
          "&start=" +
          this.state.start_filter +
          "&created=" +
          this.state.created_filter
      )
      .then(({ data, status }) => {
        if (status === 200) {
          let meetingList = [];
          let meetingObj = {};
          let candidateObj = {};
          data.data.map((ele, i) => {
            candidateObj = {
              name: ele.candidate.name,
              id: ele.candidate.id,
            };
            meetingObj = {
              id: ele.id,
              candidate: candidateObj,
              start: moment(ele.start).format("hh:mm A YYYY-MM-DD"),
              end: moment(ele.end).format("hh:mm A YYYY-MM-DD"),
              created_at: ele.modified
                ? moment(ele.modified).format("hh:mm A YYYY-MM-DD")
                : moment(ele.created).format("hh:mm A YYYY-MM-DD"),
              created_by: ele.created_by,
              position: ele.position,
              guest: ele.guest,
              interviewers: ele.interviewers,
              subject: ele.subject,
              type: ele.type,
              description: ele.description,
              status: ele.status ? ele.status.display_name : null,
            };
            meetingList.push(meetingObj);
            return meetingList;
          });
          this.setState({
            allSchedules: this.state.allSchedules.concat(data.data),
          });
          this.setState({
            total_pages: data.total,
            meetingsList: meetingList,
            loader: false,
          });
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  selectMeeting = (id) => {
    this.state.allSchedules.filter((schedule, index) => {
      if (schedule.id === id) {
        this.setState({
          meetingDetail: schedule,
          showMeetingDetail: true,
          meetingList: [],
          page: 1,
        });
      }
      return null;
    });
  };

  handleclose = () => {
    this.setState(
      {
        showMeetingDetail: false,
        editShow: false,
      },
      () => this.updateCallback(1, [])
    );
  };

  selectFilter = (type, object) => {
    if (type === "created") {
      this.setState(
        {
          created_filter: object,
          meetingList: [],
          page: 1,
          total_pages: 0,
        },
        () => this.updateCallback(this.state.page, [])
      );
    } else {
      this.setState(
        {
          start_filter: object,
          meetingList: [],
          page: 1,
          total_pages: 0,
        },
        () => this.updateCallback(this.state.page, [])
      );
    }
  };

  ToDoSideBar = () => {
    return (
      <div className="gx-module-side">
        <div className="gx-module-side-header">
          <div className="gx-modul-logo">
            <i className="icon icon-calendar gx-mr-4" />
            <IntlMessages id="sidebar.schedule" />
          </div>
        </div>
        <div className="gx-module-side-content">
          <CustomScrollbars className="gx-module-side-scroll">
            <ul className="gx-module-nav new-side-nav">
              <li className="gx-module-nav-label">
                <IntlMessages id="candidate.filters" />
                <div className="sidebar_new">
                  Start Date
                  <div>
                    <DatePicker
                      className="sidebar-datepicker"
                      onChange={(date, dateString) =>
                        this.selectFilter("start", dateString)
                      }
                    />
                  </div>
                </div>

                <div className="sidebar_new">
                  Created At
                  <div>
                    <DatePicker
                      className="sidebar-datepicker"
                      onChange={(date, dateString) =>
                        this.selectFilter("created", dateString)
                      }
                    />
                  </div>
                </div>
              </li>
            </ul>
          </CustomScrollbars>
        </div>
      </div>
    );
  };
  onOptionMenuItemSelect = (e, data) => {
    switch (e.key) {
      case "Edit":
        this.timeZoneCheck(data);
        break;
      case "Cancel":
        this.showDeleteConfirm(data);
        break;
      case "Profile":
        this.props.history.push("candidate/" + data.candidate.id);
        break;
      default:
        break;
    }
  };

  menu = (data) => (
    <Menu onClick={(e) => this.onOptionMenuItemSelect(e, data)}>
      {
        <Menu.Item key="Edit">
          <span>Edit</span>
        </Menu.Item>
      }
      {
        <Menu.Item key="Cancel">
          <span>Cancel</span>
        </Menu.Item>
      }
      <Menu.Item key="Profile">
        <span>View Profile</span>
      </Menu.Item>
    </Menu>
  );
  findFirstSundayNov = (d) => {
    var date = new Date(d.getFullYear(), 10, 1);
    var novDate = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
    if (date.getDay() === 0) {
      novDate.setDate(date.getDate());
    } else {
      novDate.setDate(date.getDate() + 7 - date.getDay());
    }
    return novDate;
  };
  findSecondSundayMar = (d) => {
    var date = new Date(d.getFullYear(), 2, 1);
    var marDate = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
    if (date.getDay() === 0) {
      marDate.setDate(date.getDate() + 7);
    } else {
      marDate.setDate(date.getDate() + 14 - date.getDay());
    }
    return marDate;
  };

  timeZoneCheck = (data) => {
    let startOfEST;
    let endOfEST;
    let timezone = this.props.timezone;
    var tz = this.state.tzDate.getTimezoneOffset();
    endOfEST = this.findFirstSundayNov(new Date());
    startOfEST = this.findSecondSundayMar(new Date());
    if (startOfEST < new Date() && new Date() < endOfEST) {
      if (timezone === "IST") {
        this.setState({ tzstatus: false });
      } else {
        if (tz === 240) {
          this.setState({ tzstatus: false });
        } else if (tz === 300) {
          this.setState({ tzstatus: false });
        } else {
          this.setState({ tzstatus: true });
        }
      }
    }
    this.showEditModal(data);
  };

  showDeleteConfirm = (data) => {
    let self = this;
    confirm({
      title: "Are you sure you want to cancel this meeting ?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axiosJSON
          .delete("api/screening/" + data.id + "/")
          .then((res) => {
            self.handleclose();
            message.success("Meeting Cancelled!");
          })
          .catch(function (error) {
            message.error(error.message);
          });
      },
    });
  };

  showEditModal = (data) => {
    this.setState({
      editShow: true,
      data: data,
    });
  };

  render() {
    const {
      drawerState,
      loader,
      meetingsList,
      showMeetingDetail,
      meetingDetail,
      editShow,
      data,
    } = this.state;
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    return (
      <div className="gx-main-content">
        <div className="gx-app-module">
          <div className="gx-d-block gx-d-lg-none">
            <Drawer
              placement="left"
              closable={false}
              open={drawerState}
              onClose={this.onToggleDrawer.bind(this)}
            >
              {this.ToDoSideBar()}
            </Drawer>
          </div>
          <div className="gx-module-sidenav gx-d-none gx-d-lg-flex">
            {this.ToDoSideBar()}
          </div>
          <div className="gx-module-box">
            <div className="gx-module-box-header">
              <span className="gx-drawer-btn gx-d-flex gx-d-lg-none">
                <i
                  className="icon icon-menu gx-icon-btn"
                  aria-label="Menu"
                  onClick={this.onToggleDrawer.bind(this)}
                />
              </span>
              <AppModuleHeader
                placeholder="Search Meetings"
                onChange={this.updateSearch.bind(this)}
                value={this.state.query}
              />
            </div>

            <div className="gx-module-box-content">
              <div style={{ height: "10px" }}></div>

              {loader ? (
                <CircularProgress />
              ) : (
                <MeetingsList
                  menu={this.menu}
                  meetingsList={meetingsList}
                  selectMeeting={this.selectMeeting}
                />
              )}

              {meetingsList.length > 0 ? (
                <span style={loadingTextCSS}>
                  <Spin />
                  Loading...
                </span>
              ) : (
                <span style={{ textAlign: "center" }}>No records found.</span>
              )}

              <div className="Pagination-section">
                <Pagination
                  total={this.state.total_pages}
                  current={this.state.page}
                  onChange={(page) =>
                    this.setState({ page: page }, () =>
                      this.getMeetingList(this.state.page)
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {showMeetingDetail && (
          <Modal
            title="Meeting Details"
            className="meeting-details-popup"
            visible={showMeetingDetail}
            footer={null}
            onCancel={() => this.handleclose()}
          >
            <MeetingDetails
              data={meetingDetail}
              closeModal={() => this.handleclose()}
            />
          </Modal>
        )}
        {editShow && (
          <Modal
            title={
              <div>
                <span>Edit Meeting</span>
                <Tag className="tagBlue" color="blue">
                  {data.status}
                </Tag>
              </div>
            }
            className="editmeeting"
            visible={editShow}
            footer={null}
            onCancel={() => this.setState({ editShow: false })}
          >
            <MeetingForm
              timezone={this.props.timezone}
              position={""}
              tzstatus={this.tzstatus}
              edit={true}
              data={data}
              closeModal={this.handleclose}
            />
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.auth.accessData,
  timezone: state.common.timezone,
});

const routeSchedule = withRouter(Schedule);

export default connect(mapStateToProps)(routeSchedule);
