import React from "react";
import { Button } from "antd";

const confirmDownload = (props) => {

  const handleDownload = ()=>{
    const href = props.downloadLink;
    const a = Object.assign(document.createElement('a'), {href: href,style: 'display:none'});
    a.click();
    props.setDownloadLink("");
    props.closeModal();
  }
  
  return (
        <div className="bulk-container">
          <div className="bulk-form col-12 py-3 mb-4 border_model">
            <div className="row m-0">
              <div className="bulkDescription col-12 p-0 mb-3">
                Some Candidates whom you selected have missing details.
                Those whose details are complete are moved to hired stage
                <br />
                Do you want to Download CSV for missing candidate details?
                
              </div>
            </div>
          </div>
          <div className="col-12 p-0">
            <div className="row">
              <div className="col-12 mt-4">
                <Button
                  type="primary"
                  className="submit_btn mb-0"
                  htmlType="submit"
                  onClick={handleDownload}
                >
                  Download
                </Button>
                <Button
                  type="btn-outline-secondary"
                  className="cancel_btn mr-2 mb-0"
                  onClick={props.closeModal}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
  );
};

export default confirmDownload;