export const calcHours = (startDate, endDate) => {
  let s = startDate != null ? new Date(startDate) : new Date();
  let e = endDate !== null ? new Date(endDate) : new Date();
  if (e < s) {
    return 0;
  } else {
    var diffDays = Math.floor((e - s) / 86400000);
    let weeksBetween = Math.floor(diffDays / 7);
    let adjust;
    if (s.getDay() === e.getDay()) {
      adjust = 0;
    } else if (s.getDay() === 0 && e.getDay() === 6) {
      adjust = 5;
    } else if (s.getDay() === 6 && e.getDay() === 0) {
      adjust = 0;
    } else if (e.getDay() === 6 || e.getDay() === 0) {
      adjust = 5 - s.getDay();
    } else if (s.getDay() === 0 || s.getDay() === 6) {
      adjust = e.getDay();
    } else if (e.getDay() > s.getDay()) {
      adjust = e.getDay() - s.getDay();
    } else {
      adjust = 5 + e.getDay() - s.getDay();
    }
    let ans = weeksBetween * 5 + adjust;
    return (ans + 1) * 8;
  }
};
