import React, { useState, useEffect } from 'react';
import axiosJSON from '../../../util/SetHeaderAPI';
import { Form, message, Select, Button} from 'antd';

const FormItem = Form.Item;

const Handover = ( props ) => {

    const [ fields, setFields ] = useState( [
        {
            name: [ 'candidate' ],
            value:  []
        },
        {
            name: [ 'recruiter' ],
            value: ''
        }
    ] );

    const [ candidateList, setCandidate ] = useState( [] );
    const [ recruiterList, setRecruiterList ] = useState( [] );
    //const [recruiter, setRecruiter] = useState("");

    useEffect( () => {
        getRecruiterList("");
        getCandidate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] ); 

    const getCandidate = () => {
        console.log(props.user.id)
        axiosJSON.get( '/api/user/recruits/?recruiter=' + props.user.id )
            .then( ( { data, status } ) => {
                
                if ( status === 200 ) {
                    setCandidate( [ ...data.data ] );
                }    
            } ).catch( function ( error ) {
                message.error( error.message );
            } );
    };

    const getRecruiterList = ( query ) => {
        axiosJSON.get( 'api/user/?role=recruiter&query=' + query )
            .then( ( { data, status } ) => {
                
                if ( status === 200 ) { 
                    setRecruiterList( [...data.data] );
                }
            } ).catch( function ( error ) {
                message.error( error.message );
            });
    }; 

    const onFinish = values => {
        console.log( values );
        const body = {
            'handover': values.recruiter, 
            'candidate': values.candidate,
            //'recruiter': recruiter
        }
        axiosJSON.put('api/user/handover/', body)
            .then( ( { data, status } ) => {
                if ( status === 201 ) {
                    message.success(data.message);
                    props.closeModal()
                }
              }).catch(function (error) {
              message.error(error.message)
        }) 
    }

    return (
        <div className="gx-login-content">
            <Form
                name="basic"
                onFinish={ onFinish }
                onFieldsChange={ ( newFields ) => setFields( newFields ) }
                fields={ fields }
                className="gx-signin-form gx-form-row0">
                
                <FormItem name="candidate" label="Candidate">
                    <Select
                        mode="multiple"
                        allowClear
                        style={ { width: 200 } }
                        placeholder="Select Candidates"
                    >
                        {candidateList.map((user, index) => (
                        <Select.Option key={index} value={user.id}>{user.name}</Select.Option>
                        ))}   
                    </Select>
                </FormItem>
                
                <FormItem name="recruiter" label="Recruiter">
                    <Select
                        showSearch
                        placeholder="Select a recruiter"
                        onSearch={ getRecruiterList }
                        //onChange={(val) => setRecruiter(val)}
                        filterOption={ ( input, option ) =>
                            option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                        }
                    >
                        { recruiterList.length > 0 && recruiterList.map( ( recruiter, i ) =>
                                <Select.Option key={ recruiter.id }
                                    value={ recruiter.id }>{ recruiter.name}</Select.Option>
                            ) }
                    </Select>    
                </FormItem>

                <FormItem style={ { marginBottom: '0' } }>
                    <Button
                        style={ { marginBottom: '0' } } type="primary" htmlType="submit">
                        Submit
                    </Button>
                </FormItem>
            </Form>
        </div>
    );
};

export default Handover;