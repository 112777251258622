import React, { Component } from "react";
import { message, Spin, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import axiosJSON from "../../util/SetHeaderAPI";

class ResumeUploadDragger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      candidate: {},
      resumeViewer: true,
      resume: {},
      file: "",
    };
  }

  handleChange = async (info) => {
    this.props.changeResumeLoader(true);
    const formData = new FormData();
    // this.setState({ file: info.file });
    formData.append("file", info.file);
    await axiosJSON
      .post("api/candidate/resume_parse/", formData)
      .then(({ data, status }) => {
        if (status === 200) {
          let candidate = {
            name: data.data.name,
            email: data.data.email,
            phone: data.data.mobile_number,
            file: info.file,
          };
          let d = {
            candidate: candidate,
            resume: data.resume,
          };
          //  let res = [d,d]
          this.props.changeResumeLoader(false);
          this.props.setResumeData(d);
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  handleCloseResumeViewer = () => {
    this.setState({
      resumeViewer: false,
    });
  };

  render() {
    return (
      <div>
        <Spin
          tip="Please wait...Getting information from resume.."
          spinning={this.props.resumeLoader}
        >
          <Upload.Dragger
            className="new_popop"
            customRequest={this.handleChange}
            multiple={true}
            maxCount={5}
            name="file"
            showUploadList={false}
            accept=".docx,.pdf"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              <b>
                Support for PDF or DOCX (Please prefer pdf over docx for more
                accurate results)
              </b>
            </p>
          </Upload.Dragger>
        </Spin>
      </div>
    );
  }
}

export default ResumeUploadDragger;

