import React from "react";
import { Avatar, Menu } from "antd";
import CustomScrollbars from 'util/CustomScrollbars'



const options = [
  'Reply',
  'Forward',
  'Print',
];

class MailDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDetail: false
    };
  }

  optionMenu = () => {
    return (
      <Menu id="long-menu">
        {options.map(option =>
          <Menu.Item key={option}>
            {option}
          </Menu.Item>,
        )}
      </Menu>)
  };

  render() {
    const { mail } = this.props;

    return (
      <div className="gx-module-detail gx-mail-detail">
        <CustomScrollbars className="gx-module-content-scroll">
          <div className="gx-mail-detail-inner">
            <div className="gx-mail-header">

              <div className="gx-mail-header-content gx-col gx-pl-0">
                <div className="gx-subject">
                  {mail.subject}
                </div>

              </div>

            </div>
            <hr />

            <div className="gx-mail-user-info gx-ml-0 gx-mb-3">

            {mail.from_name !== '' ?
            <Avatar className="gx-avatar gx-bg-blue gx-size-40"> {mail.from_name.charAt(0).toUpperCase()}</Avatar> :
            <Avatar className="gx-avatar gx-bg-blue gx-size-40"> {mail._from.charAt(0).toUpperCase()}</Avatar>
          }

              <div className="gx-sender-name">{mail.from_name}
              <div className="gx-send-to gx-text-grey">to me</div>
              </div>

              {/* <Dropdown trigger={['click']} overlay={this.optionMenu()}>
                <span className="gx-ml-auto"><i className="icon icon-ellipse-v gx-icon-btn" /></span>
              </Dropdown> */}

            </div>

            <div className="gx-show-link" onClick={() => {
              this.setState({ showDetail: !this.state.showDetail });
            }}>{this.state.showDetail ? 'Hide Detail' : 'Show Detail'}</div>
            {this.state.showDetail && (<div className="gx-show-detail">
              <div>
                <strong>From: </strong>{mail._from}
              </div>
              <div>
                <strong> To: </strong>
                {
                  mail._to.map((to, index) => <span>{index > 0 && ', '} {to}</span>)
                }
              </div>
              <div>
                <strong> CC: </strong>
                {
                  mail.cc.map((cc, index) => <span>{index > 0 && ', '} {cc}</span>)
                }
              </div>
              {/* <div><strong>Date: </strong>{mailBox.time} </div> */}
            </div>)}


            <p dangerouslySetInnerHTML={{__html:mail.body}}/>

            {/* {mailBox.hasAttachments &&
              <div className="gx-attachment-block">
                <h3>Attachments ({mailBox.attachments.length})</h3>
                <div className="gx-attachment-row">
                  {mailBox.attachments.map((attachment, index) =>
                    <div className="gx-attachment-col" key={index}>
                      <img src={attachment.preview} alt={attachment.fileName} />
                      <div className="size">{attachment.size}</div>
                    </div>
                  )}
                </div>
              </div>
            } */}
          </div>
        </CustomScrollbars>
      </div>
    );
  }
}

export default MailDetail;
