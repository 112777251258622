import React from 'react';
import {Button, Form, Select, message} from 'antd';
import axiosJSON from "../../util/SetHeaderAPI";

const FormItem = Form.Item;
const {Option} = Select;
export default class EditSkill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      skills_list: [],
      skills: []
    }
    this.Mounted = false
  }

  componentWillUnmount() {
    this.Mounted = false
  }

  componentDidMount() {
    this.Mounted = true
    axiosJSON.get('api/choice/?type=skills')
      .then(({data, status}) => {
        if (status === 200) {
          this.setState({skills_list: data.data})
        }
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  onFinish = (value) => {
    this.setState({skills: value.skills})
    const body = {
      'skills': this.state.skills
    }
    axiosJSON.put('api/candidate/' + this.props.id + '/', body)
      .then(({data, status}) => {
        if (status === 202) {
          message.success("Candidate Skills Added!")
          this.props.closeModal()
        }
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  render() {
    let skills;
    if(this.props.data === null){
      skills=[]
    }
    else {
      skills=this.props.data
    }
    return (
      <div className="gx-login-container">
        <div className="gx-login-content experience-form">
          <Form
            name="basic"
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            className="gx-signin-form gx-form-row0">
            <FormItem name="skills" label="Skills">
              <Select mode="multiple" defaultValue={skills}>
                {this.state.skills_list.map((skill_type, index) => (
                  <Option key={index} value={skill_type.display_name}>{skill_type.display_name}</Option>
                ))}
              </Select>
            </FormItem>
            <FormItem>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>);
  }
}
