import React, { useState } from "react";
import { Button, Select, message, Form, Spin, Input } from "antd";
import axiosJSON from "../../../util/Api";
const FormItem = Form.Item;

const ActionModal = ({ selectedItems, userList, onComplete }) => {
  const [option, setOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(null);

  const handleActionSubmit = (values) => {
    setLoading(true);
    const body = {
      emp_id: parseInt(option),
      asset_ids: selectedItems,
      description: description,
    };
    axiosJSON
      .put("/api/user_assets/move/", body)
      .then((res) => {
        message.success("Assets data updated successfully");
        setLoading(false);
        onComplete();
      })
      .catch((error) => {
        message.error("something went wrong, please reach to support");
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <div className="gx-login-container">
        <div className="gx-login-content education-form">
          <Form className="gx-signin-form gx-form-row0">
            <p>Select Employee</p>
            <FormItem>
              <Select
                showSearch
                allowClear={true}
                notFoundContent={null}
                value={option}
                onChange={(value) => {
                  setOption(value);
                }}
                placeholder="Select Employee Name"
                optionFilterProp="children"
              >
                {
                  <>
                    {userList.map((user) => (
                      <Select.Option key={user.id} value={user.employee_id}>
                        {user.name}
                      </Select.Option>
                    ))}
                  </>
                }
              </Select>
            </FormItem>
            <p>Employee ID</p>
            <FormItem>
              <Input placeholder="Employee Id" value={option} disabled={true} />
            </FormItem>
            <p>Description</p>
            <FormItem>
              <Input
                placeholder="Please provide description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleActionSubmit}
              >
                Submit
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default ActionModal;
