import React, { Component } from "react";
import { Button, message, Spin, Tag, Popover } from "antd";
import CustomSelect from "../../../../../components/CustomFields/CustomSelect";
import CustomDatePicker from "../../../../../components/CustomFields/CustomDatePicker";
import CustomTextArea from "../../../../../components/CustomFields/CustomTextArea";
import CustomUpload from "../../../../../components/CustomFields/CustomUpload";
import CustomInput from "../../../../../components/CustomFields/CustomInput";
import CustomInputNumber from "../../../../../components/CustomFields/CustomInputNumber";
import moment from "moment";
import axiosJSON from "../../../../../util/SetHeaderAPI";
import axios from "axios";
import ReactCodeInput from "react-code-input";
import { BASE_URL } from "../../../../../util/Api";
import { MdOpenInNew } from "react-icons/md";
import { FiHeadphones } from "react-icons/fi";
import { GrDocumentText } from "react-icons/gr";
import { BsArrowRepeat, BsInfoCircle } from "react-icons/bs";

class ExternalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      resume: "",
      fileList: {},
      inputFields: [],
      formName: "",
      loading: false,
      disabled: true,
      otpDialog: false,
      otpValue: "",
      checkFlag: {},
      checkFileFlag: {},
      formSuccess: false,
      link: "",
      fields: {},
      showResume: false,
      showDataFields: false,
      showOtp: false,
      removeVerify: true,
      disableEmail: false,
      fileName: "",
      validationRequired: true,
    };
    this.Mounted = false;
  }

  componentWillUnmount() {
    this.Mounted = false;
  }

  componentDidMount() {
    this.Mounted = true;
    this.getFormData();
  }

  getFormData = () => {
    let link = "";
    this.setState({
      loading: true,
    });
    if (!this.props.history.location.search.includes("&")) {
      link = this.props.history.location.search.split("=")[1];
    } else {
      link = this.props.history.location.search.split("&")[0].split("=")[1];
      this.props.history.location.search.split("&") &&
        this.props.history.location.search.split("&").map((key, index) => {
          let queryParams = key.split("=");
          this.setState({
            [queryParams[0]]: queryParams[1],
          });
          return null;
        });
    }
    axiosJSON
      .get("/api/form/?link=" + link)
      .then(({ data, status }) => {
        this.setState({
          link: data.data.link,
          loading: false,
          inputFields: data.data.form_attributes,
          formName: data.data.name,
          spinning: false,
          validationRequired:
            data.data.validation_required !== null
              ? data.data.validation_required
              : true,
        });
        if (data.data.validation_required === false) {
          this.setState({
            otpDialog: false,
            validationRequired: false,
            disableEmail: false,
            showDataFields: true,
            removeVerify: false,
            loading: false,
            disabled: false,
          });
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  onChange = (type, name, event) => {
    this.setState({
      checkFlag: {},
      checkFileFlag: {},
    });
    switch (type) {
      case "text":
        this.onInputChange(event.target.value, name);
        break;
      case "longtext":
        this.onInputChange(event.target.value, name);
        break;
      case "number":
        this.onInputChange(event, name);
        break;
      case "select":
        this.onInputChange(event, name);
        break;
      case "multiselect":
        this.onInputChange(event, name);
        break;

      case "datepicker":
        this.onDatePickerChange(name, event);
        break;
      case "file":
        this.onUploadChange(name, event);
        break;
      default:
        break;
    }
  };

  onUploadChange = (name, e) => {
    let fileList = { ...this.state.fileList };
    fileList[name] = [e.file.originFileObj];
    this.setState({
      fileList: fileList,
    });
    if (name === "resume") this.handleChange(e);
  };
  onUploadRemove = (name) => {
    let fileList = { ...this.state.fileList };
    delete fileList[name];
    this.setState({
      email: "",
      resume: "",
      name: "",
      spinning: false,
      fileList: fileList,
    });
  };

  onInputChange = (e, name) => {
    let fields = { ...this.state.fields };
    fields[name] = e;
    this.setState({
      fields: fields,
    });
    if (name === "email") {
      this.setState({
        email: e,
        fields: fields,
      });
    }
  };
  onDatePickerChange = (name, e) => {
    let fields = { ...this.state.fields };
    fields[name] = e ? moment(e, "YYYY-MM-DD") : null;
    this.setState({
      fields: fields,
    });
  };
  setResumeData = (data) => {
    let fields = { ...this.state.fields };
    fields["name"] = data.candidate.name;
    fields["candidate_email"] = data.candidate.email;
    fields["phone_numbers"] = data.candidate.phone;
    fields["resume"] = data.resume;
    this.setState({
      fields: fields,
      resume: data.resume,
      name: data.candidate.name,
      spinning: false,
    });
  };

  onSubmit = () => {
    this.setState({
      spinning: true,
    });
    let obj = {};
    let fileObj = {};
    let n = "";
    let fileName = "";
    if (this.state.inputFields.length !== 0) {
      this.state.inputFields.map((field) => {
        n = field.name;
        if (
          field.required &&
          !this.state.fields[field.name] &&
          field.type !== "file"
        ) {
          obj[n] = true;
        } else {
          obj[n] = false;
        }
        return obj;
      });
    }
    if (this.state.inputFields.length !== 0) {
      this.state.inputFields.map((field) => {
        fileName = field.name;
        if (
          field.required &&
          field.type === "file" &&
          !this.state.fileList[field.name]
        ) {
          fileObj[fileName] = true;
        } else {
          fileObj[fileName] = false;
        }
        return fileObj;
      });
    }
    let flag =
      Object.values(obj).every((k) => k === false) &&
      Object.values(fileObj).every((k) => k === false);
    if (flag) {
      let value = {};
      let values_arr = [];
      this.state.inputFields.map((field) => {
        if (field.type !== "file") {
          value = {
            attribute_id: field.id,
            value: this.state.fields[field.name],
            name: field.name,
          };
          values_arr.push(value);
        }
        return null;
      });
      // console.log(values_arr);
      let formData = new FormData();
      formData.append("email", this.state.email);
      formData.append("link", this.state.link);
      formData.append("values", JSON.stringify(values_arr));
      for (let key in this.state.fileList) {
        formData.append(key, this.state.fileList[key][0]);
      }
      axiosJSON
        .post("/api/form/", formData)
        .then(({ data, status }) => {
          message.success("Form Submitted");
          this.setState({
            formSuccess: true,
            spinning: false,
          });
        })
        .catch((err) => {
          this.setState({
            disabled: false,
            spinning: false,
          });
          message.error(err.message);
        });
    } else {
      this.setState({
        checkFlag: obj,
        checkFileFlag: fileObj,
        spinning: false,
      });
      message.error("Please fill the required fields");
    }
  };

  validateEmail = (email) => {
    let re = /^\S+@\S+\.\S+$/;
    return re.test(email);
  };

  verifyEmail = () => {
    if (!this.validateEmail(this.state.email)) {
      message.error("Please enter a valid email");
    } else {
      this.setState({
        spinning: true,
        otpValue: "",
      });
      const body = {
        email: this.state.email,
        link: this.props.history.location.search.split("=")[1],
      };
      axiosJSON
        .post("/api/form/request_otp/", body)
        .then(({ data, status }) => {
          message.warn(data.message);
          if (data.message === "Please check your mail for the OTP") {
            this.setState({
              otpDialog: true,
              otpValue: "",
              showDataFields: false,
              removeVerify: false,
              disableEmail: true,
              disabled: false,
              spinning: false,
            });
          } else {
            this.setState({
              otpDialog: true,
              disabled: false,
              showDataFields: true,
              disableEmail: true,
              removeVerify: false,
              spinning: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            disabled: false,
          });
          if (err.message === "Candidate Does not exist") {
            message.warning("Please proceed to fill the form.");
            this.setState({
              otpDialog: false,
              disableEmail: true,
              showDataFields: true,
              removeVerify: false,
              spinning: false,
            });
          } else {
            message.error(err.message);
            this.setState({
              otpDialog: false,
              spinning: false,
            });
          }
        });
    }
  };

  reverifyEmail = () => {
    const body = {
      email: this.state.email,
      otp: this.state.otpValue,
      link: this.props.history.location.search.split("=")[1],
    };
    this.setState({
      btnPressed: true,
    });
    axiosJSON
      .post("/api/form/confirm_otp/", body)
      .then(({ data, status }) => {
        message.warn(data.message);
        this.setState({
          name: data.data.name,
          otpDialog: false,
          disabled: false,
          disableEmail: true,
          showDataFields: true,
          spinning: false,
        });
      })
      .catch((err) => {
        message.error(err.message);
        this.setState({
          spinning: false,
        });
      });
  };

  handleChange = async (info) => {
    const formData = new FormData();
    this.setState({
      // file: info.file,
      showDataFields: true,
      spinning: true,
    });
    formData.append("file", info.file.originFileObj);
    await axios
      .post(`${BASE_URL}/api/form/resume_parse/`, formData)
      .then(({ data, status }) => {
        if (status === 200) {
          let candidate = {
            name: data.data.name,
            email: data.data.email,
            phone: data.data.mobile_number,
            // file: this.state.file,
            spinning: false,
          };
          let d = {
            candidate: candidate,
            resume: data.resume,
          };
          this.setState({
            spinning: false,
          });
          this.setResumeData(d);
        } else {
          message.error("Parsing error");
          this.setState({
            spinning: false,
          });
        }
      })
      .catch(function (error) {
          message.error(error.message);
          this.setState({
            spinning: false,
          });
          this.onUploadRemove("resume");
      });
  };

  onOtpChange = (e) => {
    this.setState({
      otpValue: e,
    });
  };

  getAccept = (name) => {
    if (name === "resume") return ".pdf,.docx";
    else return ".pdf,.docx,.jpeg,.png,.jpg,.heic";
  };

  render() {
    return (
      <div className="gx-main-content main_application_page">
        {this.state.loading ? (
          <Spin spinning={this.state.spinning} tip="Please wait..." />
        ) : (
          <div className="main">
            <div className="header header-assert">
              <div className="logo-assert">
                <img
                  style={{ maxHeight: "36px" }}
                  alt="consultadd"
                  src="https://consultadd.com/wp-content/uploads/2022/11/consultadd-logo.svg"
                />
              </div>
              <div className="assert_nav">
                <div className="pr-4 center-assert">
                  <a href="https://consultadd.com/" className="home-assert">
                    Go to website home page <MdOpenInNew />
                  </a>
                </div>
                <div>
                  <a
                    className="contact_us-assert"
                    href="https://consultadd.com/contact/"
                  >
                    <FiHeadphones /> Contact Us
                  </a>
                </div>
              </div>
            </div>
            <Spin spinning={this.state.spinning} tip="Please wait...">
              {!this.state.formSuccess ? (
                <div className="main-app-form">
                  <div className="headline">
                    <div className="col-12">
                      <div className="heading-application mb-1">
                        <div style={{ marginBottom: "40px" }}>
                          {this.state.formName
                            ? this.state.formName
                            : "Internal Form"}
                        </div>
                      </div>
                      <div className="sub-heading-application">
                        {/* Please enter your email for verification. */}
                      </div>
                    </div>
                  </div>
                  {/* {this.state.validationRequired ? (
                    <div className="col-12">
                      <div className="textbox-email">
                        <div className="form-test-section">
                          <div className="verify-section col-8">
                           
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                  <div className="col-12">
                    {this.state.inputFields.length !== 0 &&
                      this.state.inputFields.map((field, i) => (
                        <div key={i}>
                          <div key={i}>
                            <div className="application-form">
                              {field.name === "email" ? (
                                <div className="textbox-email">
                                  <div className="form-test-section">
                                    <div className="verify-section col-8">
                                      <span className="gx-text-black gx-fs-xl input-label">
                                        {field.required && (
                                          <span className="gx-text-grey gx-fs-md right-required">
                                            *
                                          </span>
                                        )}
                                        {field.display_name}
                                        {field.description ? (
                                          <Popover
                                            className="gx-text-grey gx-fs-md right-required"
                                            content={field.description}
                                          >
                                            <BsInfoCircle
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                                color: "black",
                                                marginTop: "-3px",
                                              }}
                                            />
                                          </Popover>
                                        ) : null}
                                      </span>

                                      <CustomInput
                                        onChange={(e) =>
                                          this.onChange(
                                            field.type,
                                            field.name,
                                            e
                                          )
                                        }
                                        value={this.state.fields[field.name]}
                                        key={field.name}
                                        name={field.name}
                                        disabled={this.state.disableEmail}
                                        placeholder={field.display_name}
                                      />
                                      {!this.state.otpDialog &&
                                      this.state.removeVerify &&
                                      this.state.otpValue === "" &&
                                      this.state.validationRequired ? (
                                        <Button
                                          className="verify-external mt-2"
                                          // type="primary"
                                          disabled={this.state.fields === ""}
                                          onClick={this.verifyEmail}
                                        >
                                          Verify
                                        </Button>
                                      ) : null}
                                      {this.state.otpDialog &&
                                      this.state.validationRequired ? (
                                        <div className="mt-3">
                                          <span className="code-head">
                                            Enter Verification Code
                                          </span>
                                          <ReactCodeInput
                                            className="pass-code"
                                            // type="password"
                                            fields={6}
                                            value={this.state.otpValue}
                                            onChange={this.onOtpChange}
                                            autocomplete={false}
                                            autoFocus={false}
                                          />

                                          <div className="flex-center-code1 mt-4">
                                            <div className="flex-center-code mb-3">
                                              <div>
                                                Didn’t receive the code?
                                              </div>
                                              <div
                                                className="ml-2"
                                                style={{
                                                  color: "#F86011",
                                                  cursor: "pointer",
                                                }}
                                                onClick={this.verifyEmail}
                                              >
                                                Resend code
                                              </div>
                                            </div>
                                            <Button
                                              className="verify-external"
                                              onClick={this.reverifyEmail}
                                              disabled={
                                                !this.state.otpValue ||
                                                this.state.otpValue.length < 6
                                              }
                                            >
                                              Submit
                                            </Button>
                                          </div>
                                        </div>
                                      ) : null}
                                      {field.required &&
                                      this.state.checkFlag[field.name] ? (
                                        <span className="gx-text-red gx-fs-md">
                                          {field.display_name} cannot be empty
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ) : !this.state.disabled &&
                                this.state.showDataFields &&
                                field.type === "text" &&
                                field.name !== "email" ? (
                                <div className="textbox-email">
                                  <div className="form-test-section">
                                    <div className="verify-section col-8">
                                      <span className="gx-text-black gx-fs-xl input-label">
                                        {field.required && (
                                          <span className="gx-text-grey gx-fs-md right-required">
                                            *
                                          </span>
                                        )}
                                        {field.display_name}
                                        {field.description ? (
                                          <Popover
                                            className="gx-text-grey gx-fs-md right-required"
                                            content={field.description}
                                          >
                                            <BsInfoCircle
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                                color: "black",
                                                marginTop: "-3px",
                                              }}
                                            />
                                          </Popover>
                                        ) : null}
                                      </span>

                                      <CustomInput
                                        onChange={(e) =>
                                          this.onChange(
                                            field.type,
                                            field.name,
                                            e
                                          )
                                        }
                                        value={
                                          this.state.fields[`${field.name}`]
                                        }
                                        key={field.name}
                                        name={field.name}
                                        placeholder={field.display_name}
                                      />
                                      {field.required &&
                                      this.state.checkFlag[field.name] ? (
                                        <span className="gx-text-red gx-fs-md">
                                          {field.display_name} cannot be empty
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ) : !this.state.disabled &&
                                this.state.showDataFields &&
                                field.type === "number" ? (
                                <div className="textbox-email">
                                  <div className="form-test-section ">
                                    <div className="verify-section col-8">
                                      <span className="gx-text-black gx-fs-xl input-label">
                                        {field.required && (
                                          <span className="gx-text-grey gx-fs-md right-required">
                                            *
                                          </span>
                                        )}
                                        {field.display_name}
                                        {field.description ? (
                                          <Popover
                                            className="gx-text-grey gx-fs-md right-required"
                                            content={field.description}
                                          >
                                            <BsInfoCircle
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                                color: "black",
                                                marginTop: "-3px",
                                              }}
                                            />
                                          </Popover>
                                        ) : null}
                                      </span>

                                      <CustomInputNumber
                                        onChange={(e) =>
                                          this.onChange(
                                            field.type,
                                            field.name,
                                            e
                                          )
                                        }
                                        value={
                                          this.state.fields[`${field.name}`]
                                        }
                                        key={field.name}
                                        name={field.name}
                                        placeholder={field.display_name}
                                      />
                                      {field.required &&
                                      this.state.checkFlag[field.name] ? (
                                        <span className="gx-text-red gx-fs-md">
                                          {field.display_name} cannot be empty
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ) : !this.state.disabled &&
                                this.state.showDataFields &&
                                field.type === "longtext" ? (
                                <div className="textbox-email">
                                  <div className="form-test-section ">
                                    <div className="verify-section col-8">
                                      <span className="gx-text-black gx-fs-xl input-label">
                                        {field.required && (
                                          <span className="gx-text-grey gx-fs-md right-required">
                                            *
                                          </span>
                                        )}
                                        {field.display_name}
                                        {field.description ? (
                                          <Popover
                                            className="gx-text-grey gx-fs-md right-required"
                                            content={field.description}
                                          >
                                            <BsInfoCircle
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                                color: "black",
                                                marginTop: "-3px",
                                              }}
                                            />
                                          </Popover>
                                        ) : null}
                                      </span>

                                      <CustomTextArea
                                        onChange={(e) =>
                                          this.onChange(
                                            field.type,
                                            field.name,
                                            e
                                          )
                                        }
                                        value={
                                          this.state.fields[`${field.name}`]
                                        }
                                        key={field.name}
                                        name={field.name}
                                        placeholder={field.display_name}
                                      />
                                      {field.required &&
                                      this.state.checkFlag[field.name] ? (
                                        <span className="gx-text-red gx-fs-md">
                                          {field.display_name} cannot be empty
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ) : !this.state.disabled &&
                                this.state.showDataFields &&
                                field.type === "select" ? (
                                <div className="textbox-email">
                                  <div className="form-test-section ">
                                    <div className="verify-section col-8">
                                      <span className="gx-text-black gx-fs-xl input-label">
                                        {field.required && (
                                          <span className="gx-text-grey gx-fs-md right-required">
                                            *
                                          </span>
                                        )}
                                        {field.display_name}
                                        {field.description ? (
                                          <Popover
                                            className="gx-text-grey gx-fs-md right-required"
                                            content={field.description}
                                          >
                                            <BsInfoCircle
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                                color: "black",
                                                marginTop: "-3px",
                                              }}
                                            />
                                          </Popover>
                                        ) : null}
                                      </span>

                                      <CustomSelect
                                        onChange={(e) =>
                                          this.onChange(
                                            field.type,
                                            field.name,
                                            e
                                          )
                                        }
                                        value={
                                          this.state.fields[`${field.name}`]
                                        }
                                        key={field.name}
                                        name={field.name}
                                        options={field.option}
                                        placeholder={field.display_name}
                                      />
                                      {field.required &&
                                      this.state.checkFlag[field.name] ? (
                                        <span className="gx-text-red gx-fs-md">
                                          {field.display_name} cannot be empty
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ) : !this.state.disabled &&
                                this.state.showDataFields &&
                                field.type === "multiselect" ? (
                                <div className="textbox-email">
                                  <div className="form-test-section ">
                                    <div className="verify-section col-8">
                                      <span className="gx-text-black gx-fs-xl input-label">
                                        {field.required && (
                                          <span className="gx-text-grey gx-fs-md right-required">
                                            *
                                          </span>
                                        )}
                                        {field.display_name}
                                        {field.description ? (
                                          <Popover
                                            className="gx-text-grey gx-fs-md right-required"
                                            content={field.description}
                                          >
                                            <BsInfoCircle
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                                color: "black",
                                                marginTop: "-3px",
                                              }}
                                            />
                                          </Popover>
                                        ) : null}
                                      </span>

                                      <CustomSelect
                                        onChange={(e) =>
                                          this.onChange(
                                            field.type,
                                            field.name,
                                            e
                                          )
                                        }
                                        value={
                                          this.state.fields[`${field.name}`]
                                        }
                                        key={field.name}
                                        name={field.name}
                                        multiple={true}
                                        options={field.option}
                                        placeholder={field.display_name}
                                      />
                                      {field.required &&
                                      this.state.checkFlag[field.name] ? (
                                        <span className="gx-text-red gx-fs-md">
                                          {field.display_name} cannot be empty
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ) : !this.state.disabled &&
                                this.state.showDataFields &&
                                field.type === "datepicker" ? (
                                <div className="textbox-email">
                                  <div className="form-test-section ">
                                    <div className="verify-section col-8">
                                      <span className="gx-text-black gx-fs-xl input-label">
                                        {field.required && (
                                          <span className="gx-text-grey gx-fs-md right-required">
                                            *
                                          </span>
                                        )}
                                        {field.display_name}
                                        {field.description ? (
                                          <Popover
                                            className="gx-text-grey gx-fs-md right-required"
                                            content={field.description}
                                          >
                                            <BsInfoCircle
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                                color: "black",
                                                marginTop: "-3px",
                                              }}
                                            />
                                          </Popover>
                                        ) : null}
                                      </span>

                                      <CustomDatePicker
                                        value={
                                          this.state.fields[field.name]
                                            ? moment(
                                                this.state.fields[field.name]
                                              )
                                            : this.state.fields[field.name]
                                        }
                                        onChange={(e) =>
                                          this.onChange(
                                            field.type,
                                            field.name,
                                            e
                                          )
                                        }
                                        key={field.name}
                                        name={field.name}
                                      />
                                      {field.required &&
                                      this.state.checkFlag[field.name] ? (
                                        <span className="gx-text-red gx-fs-md">
                                          {field.display_name} cannot be empty
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ) : this.state.showDataFields &&
                                field.type === "file" &&
                                !this.state.fileList[field.name] ? (
                                <div className="textbox-email">
                                  <div className="form-test-section">
                                    <div className="verify-section col-8">
                                      <span className="gx-text-black gx-fs-xl input-label">
                                        {field.required && (
                                          <span className="gx-text-grey gx-fs-md right-required">
                                            *
                                          </span>
                                        )}
                                        {field.display_name}
                                        {field.description ? (
                                          <Popover
                                            className="gx-text-grey gx-fs-md right-required"
                                            content={field.description}
                                          >
                                            <BsInfoCircle
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                                color: "black",
                                                marginTop: "-3px",
                                              }}
                                            />
                                          </Popover>
                                        ) : null}
                                      </span>
                                      <CustomUpload
                                        fileList={this.state.fileList}
                                        onChange={(e) =>
                                          this.onChange(
                                            field.type,
                                            field.name,
                                            e
                                          )
                                        }
                                        dragger={true}
                                        onRemove={() => this.onFileRemove}
                                        key={field.name}
                                        name={field.name}
                                        accept={this.getAccept(field.name)}
                                      />
                                      {field.required &&
                                      this.state.checkFileFlag[field.name] ? (
                                        <span className="gx-text-red gx-fs-md">
                                          {field.display_name} cannot be empty
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ) : this.state.showDataFields &&
                                field.type === "file" &&
                                this.state.fileList[field.name] ? (
                                <div className="textbox-email">
                                  <div className="form-test-section ">
                                    <div className="verify-section col-8">
                                      <div className="gx-text-black gx-fs-xl input-label">
                                        {field.required && (
                                          <span className="gx-text-grey gx-fs-md right-required">
                                            *
                                          </span>
                                        )}
                                        {field.display_name}
                                        {field.description ? (
                                          <Popover
                                            className="gx-text-grey gx-fs-md right-required"
                                            content={field.description}
                                          >
                                            <BsInfoCircle
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                                color: "black",
                                                marginTop: "-3px",
                                              }}
                                            />
                                          </Popover>
                                        ) : null}
                                      </div>
                                      <div className="upload_input">
                                        <div className="col-9 p-0">
                                          <Tag>
                                            <div className="gx-text-black gx-fs-xl input-label tag-flex-container">
                                              <div className="">
                                                <GrDocumentText className="documentIcon" />
                                                {
                                                  this.state.fileList[
                                                    field.name
                                                  ][0].name
                                                }
                                              </div>
                                            </div>
                                          </Tag>
                                        </div>
                                        <div
                                          className="col-3 reupload"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.onUploadRemove(field.name)
                                          }
                                        >
                                          <BsArrowRepeat />
                                          Reupload
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  {!this.state.disabled && this.state.showDataFields && (
                    <div className="textbox-email flex-center mt-3">
                      {/* <Col xl={24} lg={10} md={10} sm={24} xs={24}> */}
                      <div className="verify-section col-8">
                        <Button
                          className="verify-external"
                          // type="primary"
                          onClick={this.onSubmit}
                        >
                          Submit
                        </Button>
                      </div>
                      {/* </Col> */}
                    </div>
                  )}
                </div>
              ) : (
                <div className="main" style={{ marginTop: "50px" }}>
                  <div className="thank-you-form">
                    <h1 style={{ color: "#000" }}>
                      <BsInfoCircle
                        style={{
                          color: "#0087f3",
                          fontSize: "22px",
                          marginRight: "10px",
                        }}
                      />
                      Your response has been recorded.
                    </h1>
                    <h3>Thankyou for filling out your information.</h3>
                  </div>
                </div>
              )}
            </Spin>
            <br clear="all" />
          </div>
        )}
      </div>
    );
  }
}

export default ExternalForm;
