import React, { useState, useEffect } from "react";
import {
  FiClock,
  FiArrowRight,
  FiMessageSquare,
  FiCheck,
  FiBriefcase,
  FiMapPin,
  FiUserCheck,
} from "react-icons/fi";
import { HiOutlineOfficeBuilding, HiOutlineStatusOnline } from "react-icons/hi";
import { AiOutlineDollar, AiOutlineStop } from "react-icons/ai";
import { style } from "./style";
import { CustomModal as Modal } from "../../../util/CustomModal";
import FeedbackDetails from "../../../modals/Candidate/FeedbackDetails";
import { Spin, message } from "antd";
import axiosJSON, { LOG1_API_KEY, LOG1_BASE_URL } from "../../../util/Api";
import axios from "axios";
import { calcHours } from "./utils";

const Project = () => {
  const [viewModal, setViewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const [feedback, setFeedback] = useState(null);

  useEffect(() => {
    const apiCallFunction = async () => {
      setLoading(true);
      const sliceArray = window.location.href.split("/");
      const candidateID = sliceArray[sliceArray.length - 2];
      try {
        const resp = await axiosJSON.get(`/api/candidate/${candidateID}`);
        if (resp.status === 200) {
          const data = await axios.get(
            `${LOG1_BASE_URL}api/log1_consultant/project/?email=${resp.data.data.email}&api_key=${LOG1_API_KEY}`
          );
          console.log("-->>", data);
          if (data.status === 200) {
            setProjectData(data.data.data);
            setLoading(false);
          } else {
            setLoading(false);
            message.error("something went wrong");
          }
        } else {
          setLoading(false);
          message.error("something went wrong");
        }
      } catch (error) {
        message.error("something went wrong, Please connect support");
        setLoading(false);
      }
    };
    apiCallFunction();
    // eslint-disable-next-line
  }, []);
  return (
    <div style={style.bodyWrapper}>
      <Spin spinning={loading}>
        <div style={style.outerWrapper}>
          {projectData.length > 0 ? (
            projectData.map((project) => (
              <div className="project_wrapper" style={style.cardWrapper}>
                <div style={style.topPartWrapper}>
                  <div style={style.topRightWrapper}>
                    <div style={style.startDateStyle}>{project.start_date}</div>
                    <div style={style.fontStyle}>
                      <FiArrowRight />
                    </div>
                    <div style={style.endDateStyle}>{project.end_date}</div>
                    <div style={style.totalHourStyle}>
                      <FiClock />
                      {calcHours(project.start_date, project.end_date)} Hours
                    </div>
                  </div>
                  <div style={style.rightPartStyle}>
                    <FiMessageSquare
                      style={style.messageIconStyle}
                      onClick={() => {
                        setViewModal(true);
                        setFeedback(project.feedback);
                      }}
                    />
                  </div>
                </div>
                <div style={style.topDownWrapper}>
                  {project.status === "Joined" ? (
                    <div style={style.projectStatusStyle}>
                      <HiOutlineStatusOnline />
                      Active
                    </div>
                  ) : null}
                  {project.status === "Project Terminated" ? (
                    <div
                      style={{
                        ...style.projectStatusStyle,
                        backgroundColor: "#FFF1F3",
                        color: "#DC2626",
                      }}
                    >
                      <AiOutlineStop />
                      Terminated
                    </div>
                  ) : null}
                  {project.status === "Project Completed" ? (
                    <div style={style.projectStatusStyle}>
                      <FiCheck />
                      Completed
                    </div>
                  ) : null}

                  {project.status === "On Boarded" ? (
                    <div
                      style={{
                        ...style.projectStatusStyle,
                        color: "#6535BA",
                        backgroundColor: "#EEEBFF",
                      }}
                    >
                      <FiUserCheck />
                      Onboarded
                    </div>
                  ) : null}

                  {project.status !== "On Boarded" &&
                  project.status !== "Project Terminated" &&
                  project.status !== "Project Completed" &&
                  project.status !== "Joined" ? (
                    <div
                      style={{
                        ...style.projectStatusStyle,
                        backgroundColor: "#FFF1F3",
                        color: "#DC2626",
                      }}
                    >
                      <AiOutlineStop />
                      {project.status}
                    </div>
                  ) : null}

                  <div style={style.projectTypeStyle}>
                    Project type: {project.work_type}
                  </div>
                  {project.is_remote ? (
                    <div style={style.projectRemoteTypeStyle}>
                      In-house remote project
                    </div>
                  ) : null}
                </div>
                <div style={style.lineStyle}></div>

                {/* client and role */}
                <div style={style.clientRoleWrapper}>
                  <div style={style.clientWrapper}>
                    <div>
                      <HiOutlineOfficeBuilding style={style.fontStyle} />
                    </div>
                    <div style={style.detailsWrapper}>
                      <div style={{ color: "#9AA1B0" }}>Client</div>
                      <div style={{ color: "#353D4A" }}>{project.client}</div>
                    </div>
                  </div>

                  <div style={style.clientWrapper}>
                    <div>
                      <FiBriefcase style={style.fontStyle} />
                    </div>
                    <div style={style.detailsWrapper}>
                      <div style={{ color: "#9AA1B0" }}>Role</div>
                      <div style={{ color: "#353D4A" }}>
                        {project.job_title}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Offer by and location */}
                <div style={style.clientRoleWrapper}>
                  <div style={style.clientWrapper}>
                    <div>
                      <AiOutlineDollar style={style.fontStyle} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ color: "#9AA1B0" }}>Offer by</div>
                      <div style={{ color: "#353D4A" }}>
                        {project.marketer_name} on ${project.rate}
                      </div>
                    </div>
                  </div>
                  <div style={style.clientWrapper}>
                    <div>
                      <FiMapPin style={style.fontStyle} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ color: "#9AA1B0" }}>Location</div>
                      <div style={{ color: "#353D4A" }}>{project.location}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                width: "100%",
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              No Data Available
            </div>
          )}
        </div>
      </Spin>
      {viewModal ? (
        <Modal
          title={"Project feedback"}
          visible={viewModal}
          onCancel={() => setViewModal(false)}
          width="664px"
        >
          <FeedbackDetails feedbacks={feedback} />
        </Modal>
      ) : null}
    </div>
  );
};

export default Project;
