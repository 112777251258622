import React, { Component } from "react";
import axiosJSON from "../../../../util/SetHeaderAPI";
import { withRouter } from "react-router-dom";
import { message, Popconfirm } from "antd";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdContentCopy } from "react-icons/md";

class FormList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copyLinkSpan: [],
      copySuccess: "",
      showRemove: false,
    };
    this.Mounted = false;
  }

  componentWillUnmount() {
    this.Mounted = false;
  }

  componentDidMount() {
    this.Mounted = true;
    let arr = [];
    if (this.props.data.length !== 0) {
      this.props.data.map((ele, i) => arr.push(i));
    }
    this.setState({
      copyLinkSpan: arr,
    });
  }

  copyToClipboard = (e, id, type) => {
    const body = {
      form_id: id,
    };
    axiosJSON
      .post("/api/link/", body)
      .then(({ data, status }) => {
        navigator.clipboard.writeText(
          window.location.href.split("#")[0] +
            "#/" +
            (type === "external" ? "external" : "internal") +
            "/?link=" +
            data.data.link
        );
        message.success("Copied to clipboard");
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  getFormLink = (id, type) => {
    const body = {
      form_id: id,
    };
    axiosJSON
      .post("/api/link/", body)
      .then(({ data, status }) => {
        this.props.history.push(
          "/" +
            (type === "external" ? "external" : "internal") +
            "/?link=" +
            data.data.link
        );
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  editForm = (id, type) => {
    this.props.history.push("/form?type=" + type + "&id=" + id);
  };

  delete = (id, type) => {
    axiosJSON
      .delete("/api/form_template/" + id)
      .then(({ data, status }) => {
        if (status === 204) {
          message.success("Form removed successfully!");
          this.props.getForms();
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  render() {
    return (
      <div>
        <div className="gx-contact-item gx-dragndrop-item">
          <div className="gx-col gx-dragndrop-name gx-text-truncate gx-px-2">
            Name
          </div>
          <div className="gx-col gx-dragndrop-phone gx-d-none gx-d-md-flex gx-px-2"></div>
          {this.props.settings ? (
            <div className="gx-dragndrop-actions gx-px-2 width-center">
              Actions
            </div>
          ) : null}
        </div>

        {this.props.data.length > 0 ? (
          this.props.data.map((form, index) => (
            <div key={form.id} className="gx-contact-item gx-dragndrop-item">
              <div
                className="gx-col gx-pointer gx-dragndrop-name gx-text-truncate gx-px-2 neutral-color"
                onClick={() => this.getFormLink(form.id, form.type)}
              >
                {form.name}
              </div>
              <div className="gx-col gx-dragndrop-phone gx-d-none gx-d-md-flex gx-px-2"></div>
              {this.props.settings ? (
                <div className="gx-dragndrop-actions center-icons gx-px-2">
                  <FiEdit2
                    className="icon-forms"
                    onClick={() => this.editForm(form.id, form.type)}
                  />
                  <div className="gx-dragndrop-actions center-icons gx-px-2">
                    <Popconfirm
                      title="Are you sure you want to remove this form?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => this.delete(form.id, form.type)}
                      onCancel={() => this.closeModal()}
                    >
                      <RiDeleteBinLine className="icon-forms" />
                    </Popconfirm>
                  </div>
                  <div className="gx-dragndrop-actions gx-px-2">
                    <span
                      className="gx-pointer gx-text-blue blue-import"
                      onClick={(e) =>
                        this.copyToClipboard(e, form.id, form.type)
                      }
                    >
                      {" "}
                      <MdContentCopy className="icon-forms2" />
                      Copy Link
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          ))
        ) : (
          <div className="gx-contact-item gx-dragndrop-item">
            No forms available
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(FormList);
