import { Input, Form, Button, message } from "antd";
import React, { useState } from "react";
import axiosJSON from "../../util/SetHeaderAPI";

const FormItem = Form.Item;

const urlRegex = /^(ftp|http|https|www):\/\/[^ "]+$/;

const AddGithubLink = (props) => {
  const [fields] = useState([
    {
      name: "githubLink",
      value: props.candidate?.link,
    },
  ]);

  const onFinish = (value) => {
    let data = {
      link: value.githubLink,
    };
    if (value) {
      axiosJSON
        .put(
          "api/training_candidate/" +
            props.candidate?.training_candidate_id +
            "/",
          data
        )
        .then(({ data, status }) => {
          message.success(data.message);
          props.closeModal();
          props.onComplete();
        })
        .catch(function (error) {
          message.error(error.message);
        });
    }
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content education-form">
        <Form
          name="basic"
          fields={fields}
          onFinish={onFinish}
          className="gx-signin-form gx-form-row0"
        >
          <FormItem
            rules={[
              { required: true, message: "Please enter the Github Link" },
              {
                pattern: urlRegex,
                message: "Please enter a valid Link",
              },
            ]}
            name="githubLink"
            label="Github Link"
          >
            <Input />
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default AddGithubLink;
