import React, {Component} from 'react';
import {Button, Checkbox, Col, DatePicker, Form, message, Row, Select,} from "antd";
import TextArea from "antd/lib/input/TextArea";
import axiosJSON from "../../util/SetHeaderAPI";
import moment from "moment";
const FormItem = Form.Item;

export default class addTodo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields:[
        {
          name: 'object_id',
          value: null
        },
        {
          name: 'title',
          value: ''
        },
        {
          name: 'priority',
          value: null
        },
        {
          name: 'due_by',
          value: ''
        },
        {
          name: 'people_tags',
          value: []
        },
        {
          name: 'labels',
          value: []
        },
      ],
      status:"",
      data: {},
      important: false,
      email:false,
      loading:false,
      isDone:false
    }
  }
  componentDidMount() {
    if(this.props.currentTodo !== null){
      let todo=this.props.currentTodo;
      let people_tags=[];
      todo.people_tags.length >0 && todo.people_tags.map(p=>people_tags.push(p.id))
     this.setState({
       fields:[
         {
           name: 'object_id',
           value: todo.object_id
         },
         {
           name: 'title',
           value: todo.title
         },
         {
           name: 'priority',
           value: todo.priority.id
         },
         {
           name: 'due_by',
           value: todo.due_by !== null ? moment(todo.due_by) :""
         },
         {
           name: 'people_tags',
           value: people_tags
         },
         {
           name: 'labels',
           value: todo.labels
         },
       ],
       important:todo.important,
       status:todo.status.id,
       isDone:todo.strike
     })
    }
  }

  onFinish=(value)=>{
    if(value['people_tags'].length === 0){
      value['people_tags'] = null
    }
    value['model']='candidate';
    value['important']=this.state.important;
    value['model']='candidate';
    value['due_by']=value['due_by'] !== "" ?moment(value['due_by']).format("YYYY-MM-DDTHH:mmZ") : null;
    if(this.props.currentTodo !== null &&this.props.currentTodo.hasOwnProperty('id')){
      value['status'] = this.state.status
      axiosJSON.put('/api/todo/'+this.props.currentTodo.id+'/', value)
        .then(({ data, status }) => {
          if (status === 202) {
            message.success("Task Updated")
            this.props.closeModal();
          }
        }).catch(function (error) {
        message.error(error.message)
      })
    }
    else{
      axiosJSON.post('/api/todo/', value)
        .then(({ data, status }) => {
          if (status === 201) {
            message.success("Task Added")
            this.props.closeModal()
          }
        }).catch(function (error) {
        message.error(error.message)
      })
    }

  }
  onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  render() {
    const {isDone,status}=this.state
    return (
      <div className="gx-login-container">
        <div className="main-experience-form">
          <div className="gx-login-content experience-form">
            <Form
              name="basic"
              fields={this.state.fields}
              onFieldsChange={(newFields) =>
                this.setState({ fields: newFields })
              }
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              className="gx-signin-form gx-form-row0">

              <div className="description-sec">
                <FormItem name="title" label="Title"
                          rules={[
                            {required: true, message: 'Please enter title!'}]}>
                  <TextArea className='gx-input-lineheight' type="text" />
                </FormItem>
              </div>

              <hr/>

              <Row>
                <Col xl={12} lg={6} md={6} sm={12} xs={12}>
                  <FormItem name="object_id" label="Candidate Name">
                    <Select showSearch onSearch={this.props.getCandidateList} optionFilterProp="children"
                            style={{width:'190px'}} filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                    >
                      {this.props.candidateList.map((user, index) => (
                        <Select.Option key={index} value={user.id}>{user.name}</Select.Option>
                      ))}
                    </Select>
                  </FormItem>

                  <FormItem name="people_tags" label="People Tagged">
                    <Select showSearch
                            onSearch={this.props.getUserList}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            mode="multiple"
                    >
                      {this.props.userList.map((user, index) => (
                        <Select.Option key={index} value={user.id}>{user.name}</Select.Option>
                      ))}
                    </Select>
                  </FormItem>

                  <FormItem name="priority" label="Priority" rules={[
                    {required: true, message: 'Please select a value!'}]}>
                    <Select optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                    >
                      {this.props.priorityList.map((priority, index) => (
                        <Select.Option key={index} value={priority.id}>{priority.display_name}</Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem>
                    Mark as Important
                    <Checkbox checked={this.state.important}
                              onChange={(e)=>this.setState({important:e.target.checked})}/>
                  </FormItem>

                </Col>

                <Col xl={12} lg={6} md={6} sm={12} xs={12}>

                  <FormItem name="due_by" label="Due Date">
                    <DatePicker
                      showTime
                      format={"YYYY-MM-DD hh:mm A"}
                    />
                  </FormItem>

                  <FormItem name="labels" label="Labels">
                    <Select mode={"multiple"}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                    >
                      {this.props.labelList.map((label, index) => (
                        <Select.Option key={index} value={label.name}>{label.display_name}</Select.Option>
                      ))}
                    </Select>
                  </FormItem>

                  { status === "" ?
                    <FormItem >
                    </FormItem>
                    :
                    <FormItem  label="Status">
                      <Select value={status}
                              onChange={(v)=>this.setState({status:v})}
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                      >
                        {this.props.statusList.map((s, index) => (
                          <Select.Option style={{color:s.description}} key={s.id} value={s.id}>{s.display_name}</Select.Option>
                        ))}
                      </Select>
                    </FormItem>
                  }

                  {/*<FormItem>*/}
                  {/*  Send Email Notifications*/}
                  {/*  <Checkbox*/}
                  {/*    checked={this.state.email}*/}
                  {/*    onChange={(e)=>this.setState({email:e.target.checked})}/>*/}
                  {/*</FormItem>*/}

                </Col>

              </Row>

              {/*<hr/>*/}
              {/*<div className="description-sec">*/}
              {/*  <FormItem className="description-text" name="description" label="Description">*/}
              {/*    <TextArea className='gx-input-lineheight' />*/}
              {/*  </FormItem>*/}
              {/*</div>*/}
              <FormItem>
                { isDone ?
                  <Button disabled={isDone} type="primary" htmlType="submit">
                    Task Completed
                  </Button>:
                  <Button loading={this.state.loading} type="primary" htmlType="submit">
                  Submit
                </Button>}
              </FormItem>

            </Form>

          </div>
        </div>
      </div>
    );
  }
}

