import React, {Component} from "react";
import axiosJSON from "../../util/SetHeaderAPI";
import IntlMessages from "util/IntlMessages";
import NotificationItem from "../../components/AppNotification/NotificationItem";
import {Card, message} from "antd";
import "nprogress/nprogress.css";
import nprogress from "nprogress";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      page: 1,
      prevY: 0,
      loading:true
    }
    this.Mounted=false
  }

  componentWillUnmount() {
    this.Mounted = false
  }

  componentDidMount() {
    nprogress.start()
    this.Mounted = true
    this.getNotifications(this.state.page);
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };
    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    this.observer.observe(this.loadingRef);
    nprogress.done()
  }


  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;

    if (this.state.prevY > y) {
      const curPage = this.state.page+1;
      this.getNotifications(curPage);
      this.setState({page: curPage});
    }
    this.setState({prevY: y});
  }

  getNotifications = (p) => {

    axiosJSON.get('api/notification/?page=' + p)
      .then(res => {
        let arr=this.state.notifications
        res.data.data.map((ele,i)=>{
          arr.push(ele);
          return arr
        })
        this.setState({

          notifications: arr,
          loading:false
        })
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  render() {
    const {notifications} = this.state;
    const loadingCSS = {
    };
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    return (
      <div>
        <h2 className="title gx-mb-4"><IntlMessages id="sidebar.feedBack.notification"/></h2>
        {notifications.map((notification, index) => {
          return <Card key={index}><NotificationItem get={this.get} hide={this.hide} key={index} id={notification.id}
                                                     notification={notification.message}
                                                     is_read={notification.is_read}/></Card>
        })
        }
        <div ref={loadingRef => (this.loadingRef = loadingRef)} style={loadingCSS}/>
        <span style={loadingTextCSS}>Loading...</span>
      </div>
    );
  }
};

export default Notifications;
