import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  MY,
  UNREAD_COUNTER,
  FETCHING,
  QUERY,
  FILTER_OBJ,
  SUB_FILTER_OBJ,
  SUB_QUERY,
  PERMISSION_DATA,
  PAGE,
  ALLCANDIDATE,
  SORTBY,
} from "constants/ActionTypes";
import {
  TIMEZONE,
  TRAINING_OBJ,
  CANDIDATE_DATA,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  message: "",
  my: false,
  query: "",
  fetching: true,
  unread_notify: 0,
  timezone: "EST",
  filterObj: {},
  headerData: null,
  permissionData: null,
  page: null,
  sub_query: "",
  sub_filter_obj: {},
  training_obj: {},
  toggle: true,
  allCandidate: "my",
  sortBy: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return { ...state, error: "", message: "", loading: true };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: "", message: "", loading: false };
    }
    case SHOW_MESSAGE: {
      return { ...state, error: "", message: action.payload, loading: false };
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, error: action.payload, message: "" };
    }
    case HIDE_MESSAGE: {
      return { ...state, loading: false, error: "", message: "" };
    }
    case MY: {
      return { ...state, my: action.payload };
    }
    case QUERY: {
      return { ...state, query: action.payload };
    }
    case FETCHING: {
      return { ...state, fetching: false };
    }
    case UNREAD_COUNTER: {
      return { ...state, unread_notify: action.payload };
    }
    case TIMEZONE: {
      return { ...state, timezone: action.payload };
    }
    case FILTER_OBJ: {
      return { ...state, filterObj: action.payload };
    }
    case PAGE: {
      return { ...state, page: action.payload };
    }
    case ALLCANDIDATE: {
      return { ...state, allCandidate: action.payload };
    }
    case SORTBY: {
      return { ...state, sortBy: action.payload };
    }
    case CANDIDATE_DATA: {
      return { ...state, headerData: action.payload };
    }
    case PERMISSION_DATA: {
      return { ...state, permissionData: action.payload };
    }
    case SUB_FILTER_OBJ: {
      return { ...state, sub_filter_obj: action.payload };
    }
    case SUB_QUERY: {
      return { ...state, sub_query: action.payload };
    }
    case TRAINING_OBJ: {
      return { ...state, training_obj: action.payload };
    }

    default:
      return state;
  }
};
