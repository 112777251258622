import React, { useCallback, useEffect, useState } from "react";
import { message, Table, Spin, Pagination } from "antd";
import { Link } from "react-router-dom";
import FilterList from "./filterList";
import { withRouter } from "react-router";
import axiosJSON from "../../../../util/SetHeaderAPI";
import { FiExternalLink } from "react-icons/fi";
import moment from "moment";
import { debounce } from "lodash";
const DATE_FORMAT = "YYYY-MM-DD";

const Recruiter = (props) => {
  const match = props.match;
  const [reportData, setReportData] = useState([]);
  const [reportColumns, setReportColumns] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [team, setTeam] = useState(null);
  const [query, setQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loader, setLoader] = useState(false);
  const [total, setTotal] = useState(0);
  const [current_page, setCurrentPage] = useState(1);
  const [current_size, setCurrentSize] = useState(10);

  useEffect(() => {
    getRecruitmentReport(
      query,
      team,
      startDate,
      endDate,
      current_page,
      current_size
    );
    getTeamList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRecruitmentReport = useCallback(
    debounce((query, team, start, end, page, size) => {
      let startDate = start !== "" ? moment(start).format(DATE_FORMAT) : "";
      let endDate = end !== "" ? moment(end).format(DATE_FORMAT) : "";
      setLoader(true);
      axiosJSON
        .get(
          `api/recruitment_report/recruiter?query=${query}&team=${
            team ? team : ""
          }&start=${startDate}&end=${endDate}&page=${page}&page_size=${size}`
        )
        .then(({ data }) => {
          setReportData(data.data);
          updateReportColumn(data.columns);
          setTotal(data.total);
          setLoader(false);
        })
        .catch(function (error) {
          message.error(error.message);
          setLoader(false);
        });
    }, 500),
    []
  );

  const getTeamList = () => {
    axiosJSON
      .get("api/recruitment_report/team_list/")
      .then(({ data }) => {
        setTeamList(data.data);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  const updateReportColumn = (columnList) => {
    let actionColumn = {
      title: "Action",
      dataIndex: "action",
      key: "3",
      render: (text, record) => (
        <div style={{ cursor: "pointer" }}>
          <Link
            to={{
              pathname: match.url + "/" + record.id + "/candidate/",
            }}
          >
            <span>Show in Detail</span>
            <FiExternalLink className="ml-2 right_iconTable" />
          </Link>
        </div>
      ),
    };
    columnList = [...columnList, actionColumn];
    setReportColumns(columnList);
  };

  const updateFilter = (key, value) => {
    switch (key) {
      case "team":
        setTeam(value ? value : null);
        setCurrentPage(1);
        getRecruitmentReport(
          query,
          value ? value : "",
          startDate,
          endDate,
          1,
          current_size
        );
        break;
      case "query":
        setQuery(value);
        setCurrentPage(1);
        getRecruitmentReport(value, team, startDate, endDate, 1, current_size);
        break;
      case "start":
        setStartDate(value);
        break;
      case "end":
        setEndDate(value);
        break;
      default:
        break;
    }
  };

  const applyDateFilter = () => {
    setCurrentPage(1);
    getRecruitmentReport(query, team, startDate, endDate, 1, current_size);
  };

  const onPageChange = (page, size) => {
    setCurrentPage(page);
    setCurrentSize(size);
    getRecruitmentReport(query, team, startDate, endDate, page, size);
  };
  return (
    <div className="row mt-2 pt-3 mx-0 border-top">
      <div className="col-12 p-0 mb-3">
        <FilterList
          teamList={teamList}
          team={team}
          query={query}
          start={startDate}
          end={endDate}
          updateFilter={updateFilter}
          applyDateFilter={applyDateFilter}
        />
      </div>
      <div className="col-12 p-0">
        <Spin spinning={loader}>
          <Table
            className="Candidates_ReportsTable"
            rowKey={(record) => record.id}
            columns={reportColumns}
            dataSource={reportData}
            pagination={false}
          />
        </Spin>
      </div>
      {total > 0 && (
        <div className="col-12 mt-2">
          <Pagination
            showSizeChanger
            onShowSizeChange={onPageChange}
            onChange={onPageChange}
            style={{ float: "right" }}
            current={current_page}
            total={total}
            pageSize={current_size}
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(Recruiter);
