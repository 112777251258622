import React from "react";
import { message, Table, Button, Pagination, Tooltip } from "antd";
import { EditFilled } from "@ant-design/icons";
import UpdateAttendance from "../../../modals/Training/updateAttendance";
import axiosJSON from "../../../util/SetHeaderAPI";
import EditAttendance from "../../../modals/Training/editAttendance";
import moment from "moment";
import CircularProgress from "../../../components/CircularProgress";
import { connect } from "react-redux";
// import CandidateDetails from "../../../modals/Training/candidateDetails";
import { RxDotFilled } from "react-icons/rx";
import { withRouter } from "react-router";
import "nprogress/nprogress.css";
import nprogress from "nprogress";
import { CustomModal as Modal } from "../../../util/CustomModal";

const dateFormat = "MMM DD";

class Attendance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
      visible: false,
      tableColumns: [],
      loader: false,
      page: 1,
      total: 0,
      attendanceData: [],
      dateList: [],
      currentDate: null,
      editAttendance: false,
      selectedDate: null,
      // viewCandidate: false,
      candidate: null,
      singleRowEdit: false,
      trainingId: this.props.history.location.pathname.split("/")[2],
    };
    this.Mounted = true;
  }

  componentWillUnmount() {
    this.Mounted = false;
  }

  componentDidMount() {
    nprogress.start();
    this.Mounted = true;
    this.getColumns();
    this.getTrainingAttendance(this.state.page);
    nprogress.done();
  }

  updateAttendanceDate = (currentDate) => {
    this.setState({ updateAttendanceDate: true, currentDate: currentDate });
  };

  getColumns = () => {
    axiosJSON
      .get("/api/attendance/?training_id=" + this.state.trainingId)
      .then(({ data, status }) => {
        let columns = [
          {
            title: "Consultant Name",
            dataIndex: "name",
            fixed: "left",
            width: 150,
            render(data, record) {
              return (
                <>
                  <p>
                    {record && record.consultant ? record.consultant.name : "-"}
                  </p>
                </>
              );
            },
            onCell: (record, rowIndex) => ({
              onClick: () => {
                this.viewCandidateDetails(record);
              },
            }),
          },
          {
            title: "Email",
            dataIndex: "email",
            width: 230,
            render(data, record) {
              return (
                <p>
                  {record && record.consultant ? record.consultant.email : "-"}
                </p>
              );
            },
            onCell: (record, rowIndex) => ({
              onClick: () => {
                this.viewCandidateDetails(record);
              },
            }),
          },
          {
            title: "Attendance %",
            dataIndex: "attendance",
            width: 230,
            render(data, record) {
              let totalDays = 0;
              let presentDays = 0;
              record.attendance.map((data) => {
                if (data.is_present === true) {
                  presentDays = presentDays + 1;
                }
                if (data.is_required === true) {
                  totalDays = totalDays + 1;
                }
                return 0;
              });

              let attendancePercent = 0;
              let roundedOffAttendancePrecent = 0;
              let anyDayRequired = true;
              if (totalDays !== 0) {
                attendancePercent = (presentDays / totalDays) * 100;
                roundedOffAttendancePrecent =
                  Math.round(attendancePercent * 10) / 10;
              } else {
                anyDayRequired = false;
              }

              return (
                <p>
                  {record && anyDayRequired
                    ? roundedOffAttendancePrecent + " %"
                    : "-"}
                </p>
              );
            },
            onCell: (record, rowIndex) => ({
              onClick: () => {
                this.viewCandidateDetails(record);
              },
            }),
          },
        ];

        data.data.map((date, index) => {
          columns.push({
            className: "attendenceSec",
            title: (
              <div className="edit_center">
                {date.remark ? (
                  <div>
                    <p>{moment(date.date).format(dateFormat)}</p>
                    <p>
                      ({" "}
                      {date.remark.length > 20 ? (
                        <Tooltip title={date.remark}>
                          {date.remark.substring(0, 20) + "..."}
                        </Tooltip>
                      ) : (
                        date.remark
                      )}{" "}
                      )
                    </p>
                  </div>
                ) : (
                  moment(date.date).format(dateFormat)
                )}{" "}
                <EditFilled onClick={() => this.updateAttendanceDate(date)} />
              </div>
            ),

            dataIndex: date.remark ? date.remark : date.date,
            key: date.id.toString(),
            onCell: (record, rowIndex) => ({
              onClick: () => {
                this.onHandleEdit([record], date);
              },
            }),
            render(data, record) {
              let dateRecord = record.attendance.find(
                (attendance) => attendance.date.id === date.id
              );
              let previousDate =
                new Date(date.date) < new Date() ? true : false;
              return (
                <div>
                  {dateRecord ? (
                    !dateRecord.is_required ? (
                      <div className="grey_icon">
                        <RxDotFilled />
                      </div>
                    ) : !dateRecord.is_present ? (
                      previousDate ? (
                        <div className="close_icon">
                          <RxDotFilled />
                        </div>
                      ) : dateRecord.is_present === false ? (
                        <div className="close_icon">
                          <RxDotFilled />
                        </div>
                      ) : (
                        <div className="minus_icon">-</div>
                      )
                    ) : (
                      <div className="check_icon">
                        <RxDotFilled />
                      </div>
                    )
                  ) : previousDate ? (
                    <div className="close_icon">
                      <RxDotFilled />
                    </div>
                  ) : (
                    <div className="minus_icon">-</div>
                  )}
                </div>
              );
            },
          });
          return null;
        });
        this.setState({
          tableColumns: columns,
          dateList: data.data,
        });
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  getTrainingAttendance = (page) => {
    this.setState({ loader: true });
    const status = this.props.subFilterObj.hasOwnProperty("status")
      ? this.props.subFilterObj.status
      : "";
    const language = this.props.subFilterObj.hasOwnProperty("language")
      ? this.props.subFilterObj.language
      : "";
    axiosJSON
      .get(
        "/api/training_candidate/" +
          this.state.trainingId +
          "/attendance/?query=" +
          this.props.subQuery +
          "&status=" +
          status +
          "&page=" +
          page +
          "&technology=" +
          language
      )
      .then(({ data, status }) => {
        this.setState({
          attendanceData: data.data,
          total: data.total,
          loader: false,
        });
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  handleClose = () => {
    this.setState(
      {
        updateAttendanceDate: false,
      },
      () => {
        this.getColumns();
        this.getTrainingAttendance(this.state.page);
      }
    );
  };

  onMarkAttendanceClick = () => {
    this.setState({
      visible: true,
      singleRowEdit: false,
    });
  };

  onHandleEdit = (e, date) => {
    if (date && new Date(date.date) > new Date()) {
      return message.error("Can't Mark Attendance for future !!!");
    }
    this.setState({
      visible: true,
      selectedRows: e,
      selectedDate: date,
      singleRowEdit: true,
    });
  };

  viewCandidateDetails = (record) => {
    this.props.history.push({
      pathname: `/trainingDetails/${record.training_candidate_id}/${record.consultant.id}/assignment`,
      state: { path: this.props.history.location.pathname },
    });

    this.setState({
      // viewCandidate: true,
      candidate: {
        name: record.consultant.name,
        training_candidate_id: record.training_candidate_id,
        candidateID: record.consultant.id,
      },
    });
  };

  onSelectChange = (selectedRows) => {
    if (selectedRows.length > 0) {
      let data = [];
      this.state.attendanceData.forEach((item) => {
        if (selectedRows.includes(item.training_candidate_id)) {
          data.push(item);
        }
      });
      this.setState({ selectedRows: data, editAttendance: true });
    } else {
      this.setState({ editAttendance: false });
    }
  };

  closeModal = () => {
    this.setState(
      {
        visible: false,
        editAttendance: false,
        selectedDate: null,
      },
      () => this.getTrainingAttendance(this.state.page)
    );
  };

  handleModalClose = () => {
    this.setState({
      visible: false,
      updateAttendanceDate: false,
      // viewCandidate: false,
    });
  };

  render() {
    const {
      selectedRows,
      loader,
      updateAttendanceDate,
      visible,
      // viewCandidate,
    } = this.state;
    const rowSelection = {
      selectedRows,
      onChange: this.onSelectChange,
    };

    return (
      <div className="gx-main-content attend_style">
        <div className="markAttendanceClass">
          {this.state.editAttendance && (
            <Button
              onClick={this.onMarkAttendanceClick}
              // className="button_rit"
            >
              Mark Attendance
            </Button>
          )}
        </div>
        {loader ? (
          <CircularProgress />
        ) : (
          <Table
            className="candidate_table attend_tab"
            rowKey={(record) => record.training_candidate_id}
            columns={this.state.tableColumns}
            dataSource={this.state.attendanceData}
            scroll={{ x: 1300 }}
            rowSelection={rowSelection}
            pagination={false}
          />
        )}
        {this.state.attendanceData.length > 0 && (
          <div className="Pagination-section">
            <Pagination
              current={this.state.page}
              total={this.state.total}
              pageSize={10}
              onChange={(page) =>
                this.setState({ page: page }, () =>
                  this.getTrainingAttendance(page)
                )
              }
            />
          </div>
        )}
        {updateAttendanceDate && (
          <Modal
            title="Update Date"
            visible={updateAttendanceDate}
            onCancel={() => this.handleModalClose()}
            footer={null}
          >
            <UpdateAttendance
              currentDate={this.state.currentDate}
              dateList={this.state.dateList}
              training_id={this.state.trainingId}
              //trainingObj={this.props.trainingObj}
              trainingObj={JSON.parse(localStorage.getItem("trainingObj"))}
              closeModal={this.handleClose}
            />
          </Modal>
        )}
        {visible && (
          <Modal
            title="Edit Attendance"
            width={540}
            visible={visible}
            footer={null}
            onCancel={this.closeModal}
          >
            <EditAttendance
              selectedRows={this.state.selectedRows}
              selectedCandidates={this.state.selectedRows.map((item) => {
                return item.training_candidate_id;
              })}
              selectCandidatesNames={this.state.selectedRows.map((item) => {
                return item.consultant.name;
              })}
              candidateList={this.state.attendanceData.map((item) => {
                return item.training_candidate_id;
              })}
              closeModal={this.closeModal}
              dateList={this.state.dateList}
              training_id={this.state.trainingId}
              singleRowEdit={this.state.singleRowEdit}
              selectedDate={this.state.selectedDate}
            />
          </Modal>
        )}
        {/* {viewCandidate && (
          <Modal
            title="Candidate Details"
            className="candidate-details"
            visible={viewCandidate}
            footer={null}
            onCancel={() => this.handleModalClose()}
          >
            <CandidateDetails
              candidate={this.state.candidate}
              training_id={this.state.trainingId}
              closeModal={() => this.handleModalClose()}
            />
          </Modal>
        )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  subFilterObj: state.common.sub_filter_obj,
  subQuery: state.common.sub_query,
  trainingObj: state.common.training_obj,
});

const routeAttendance = withRouter(Attendance);

export default connect(mapStateToProps)(routeAttendance);
