import React, {useState} from "react";
import {message, Spin} from "antd";
import axiosJSON from "../../util/SetHeaderAPI";
import { useHistory } from "react-router-dom";
import "nprogress/nprogress.css";
import nprogress from "nprogress";
const token_key = "AbfdjfalseqrtehgdfHG&"

const GmailAuth = (props) => {

  const history = useHistory();
  const [loading] = useState(true);
  nprogress.start()
  nprogress.done()
  let searchPropsArray = props.location.search.split("code=");
  let checkToken = searchPropsArray[0].split("=")[1];
  let getCode = searchPropsArray[1].split("code=");

  if (checkToken === token_key) {
    
    getCode = getCode[0].split("&scope=")[0]
    if (getCode.length !== 0) {

      const body = {'code': getCode}
      axiosJSON.post('/api/google_auth/',body).then(({data, status}) => {

          if (status === 200) {
            setTimeout(() => {
              localStorage.setItem("google_auth",true)
              window.close()
            }, 2000)
          }
          else{
            localStorage.setItem("google_auth",false)
          }
        }).catch((err) => {
        console.log(err);
      });
    }
  } else {
    message.error("Not a valid url")
    history.push("/settings")
  }
  return (
    <div>
      <Spin spinning={loading} tip="Please wait your data is get stored...."/>
    </div>
  );
};

export default GmailAuth;
