import React, {PureComponent} from "react";
import {Button, Checkbox, Drawer, Dropdown, Menu, message,} from "antd";
import CustomScrollbars from "util/CustomScrollbars";
import folders from "./data/folders";
import options from "./data/options";
import MailList from "../../components/mail/MailList";
import ComposeMail from "../../components/mail/Compose/index";
import AppModuleHeader from "../../components/AppModuleHeader/index";
import MailDetail from "../../components/mail/MailDetail/index";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "../../components/CircularProgress/index";
import Auxiliary from "../../util/Auxiliary";
import axiosJSON from "../../util/SetHeaderAPI";
import {RightOutlined,LeftOutlined} from "@ant-design/icons";
import { withRouter } from "react-router";
import "nprogress/nprogress.css";
import nprogress from "nprogress";
import {CustomModal as Modal} from "../../util/CustomModal"

const company_folders=[
  {
    'id': 5,
    'handle': 'company_inbox',
    'title': "Company's Inbox",
    'icon': 'inbox'
  },
]
const {confirm} = Modal;

class Mail extends PureComponent {
  reRunner = () =>{
    nprogress.start()
    this.Mounted = true;
    let hash = this.props.history.location.hash;
    
    let type = hash.split("#")[1].split("?")[0];
    let folder= folders.concat(company_folders)
    
    folder.map((folder, i) => {
      if (type.split("/")[0] === folder.handle) {
        this.setState({
          selectedName: type.split("/")[0],
          selectedFolder: folder.id,
          noContentFoundMessage: 'No Mail found in selected folder',
          currentMail: [],
          loader: true,
        })
      }
      return null
    })
    if (type.split("/")[1] > 0) {
      axiosJSON.get('/api/email_message/' + type.split("/")[1] + '/')
        .then(({data, status}) => {
          this.setState({
            currentMail: data.data,
            id: type.split("/")[1],
            loader: false
          })
        }).catch(function (error) {
        message.error(error.message)
      })
    } else if (type.split("/")[0]) {
      if(type.split("/")[0]=== "company_inbox"){
        this.getCompanyMails(1,"")
      }
      else{
        this.getMails(type.split("/")[0], 1, '');
      }

    }

    let search = hash.split("#")[1]
  
    if (search.split("compose=")[1]) {
      if (search.split("compose=")[1] === 'new') {
        const body = {
          'to': [],
          'cc': [],
          'bcc': [],
          'subject': '',
          'body': '<p></p>'
        }

        axiosJSON.post('/api/email_message/', body)
          .then(({data, status}) => {
            this.setState({
              newMail: {
                'id': data.data.id,
                '_to': [],
                'from': "",
                'from_name': "",
                'cc': [],
                'bcc': [],
                'subject': '',
                'body': '<p></p>',
                'attachments': []
              },
              id: data.data.id,
              composeMail: true,
            })
          }).catch(function (error) {
          message.error(error.message)
        })

      } else {
        axiosJSON.get('/api/email_message/' + search.split("compose=")[1] + '/')
          .then(({data, status}) => {
            this.setState({
              newMail: data.data,
              currentMail: data.data,
              composeMail: true,
              id: search.split("compose=")[1]
            })
          }).catch(function (error) {
          message.error(error.message)
        })
      }
    } 
    nprogress.done()  
  }



  MailSideBar = () => {
    return <div className="gx-module-side">

      <div className="gx-module-side-header">
        <div className="gx-module-logo">
          <i className="icon icon-chat gx-mr-4"/>
          <IntlMessages id="sidebar.mail.mailbox"/>
        </div>
      </div>

      <div className="gx-module-side-content">
        <CustomScrollbars className="gx-module-side-scroll">
          <div className="gx-module-add-task">
            <Button type="primary" className="gx-btn-block"
                    onClick={() => {
                      this.props.history.push('/mail#' + this.state.selectedName + '?/compose=new')
                      this.reRunner()
                      // this.setState({composeMail: true})
                    }}>
              <i className="icon icon-edit gx-mr-2"/>
              <IntlMessages id="sidebar.mail.compose"/></Button>
          </div>

          <ul className="gx-module-nav">
            {this.getNavFolders()}
          </ul>
          <hr/>
          <ul className="gx-module-nav">
            {this.getCompanyNavFolders()}
          </ul>
        </CustomScrollbars>
      </div>
    </div>
  };

  getNavFolders = () => {
    return folders.map((folder, index) =>
      <li key={index} onClick={() => {
        this.props.history.push('/mail#' + folder.handle)
        this.reRunner()
      }
      }>
        <span className={`${this.state.selectedFolder === folder.id ? 'active gx-link' : 'gx-link'}`}>
          <i className={`icon icon-${folder.icon}`}/>
          <span>{folder.title}</span>
        </span>
      </li>
    )
  };
  getCompanyNavFolders = () => {
    return company_folders.map((folder, index) =>
      <li key={index} onClick={() => {
        this.props.history.push('/mail#' + folder.handle)
        this.reRunner()
      }
      }>
        <span className={`${this.state.selectedFolder === folder.id ? 'active gx-link' : 'gx-link'}`}>
          <i className={`icon icon-${folder.icon}`}/>
          <span>{folder.title}</span>
        </span>
      </li>
    )
  };


  onFolderMenuItemSelect = (e) => {
    const id = +e.key;
    const mails = this.state.allMail.map(mail =>
      mail.selected && (mail.folder === this.state.selectedFolder) ?
        {...mail, folder: id, selected: false,} : mail
    );
    this.setState({
      selectedMails: 0,
      allMail: mails,
      noContentFoundMessage: 'No Mail found in selected folder',
      alertMessage: 'Mail has been moved successfully',
      showMessage: true,
      folderMails: []
    });
  };

  onDraftDiscard = (arr) => {
    const body = {
      'message_ids': arr
    }
    axiosJSON.put('/api/email_message/discard_draft/', body)
      .then(({data, status}) => {
        message.success(data.message)
        this.setState({
          selectedMails:0
        })
        this.getMails('draft', 1, '')
      }).catch(function (error) {
      message.error(error.message)
    })

  }

  onArchiveMails = (arr) => {
    const body = {
      'message_ids': arr
    }
    axiosJSON.put('/api/email_message/archive/', body)
      .then(({data, status}) => {
        message.success(data.message)
        this.setState({
          selectedMails:0
        })
        this.getMails(this.state.selectedName, 1, '')
      }).catch(function (error) {
      message.error(error.message)
    })

  }

  handleRequestClose = (data, id) => {
    // console.log("Here too",data)
    let self = this;
    if (data !== null) {
      confirm({
        title: 'Discard mail',
        content: 'Do you Want to discard mail?',
        okText: 'Discard',
        cancelText: 'Save Draft',
        onOk() {
          if (data.hasOwnProperty('message')) {
            self.onDraftDiscard([data.id])
          }

          self.setState({
            composeMail:false
          })
          self.props.history.push('/mail#' + self.state.selectedName)
          self.reRunner()
        },
        onCancel() {
          self.onDraftMailSend(data, id);
        },
      });
    } else {
      self.setState({
        composeMail:false
      })
      this.props.history.push('/mail#' + this.state.selectedName)
      self.reRunner()
    }


  };
  handleShowMessageRequest = () => {
    this.setState({
      showMessage: false,
    })
  }
  onOptionMenuItemSelect = (e) => {
    switch (e.key) {
      case 'Mark as Read':
        this.handleShowMessageRequest();
        this.getReadMail();
        break;
      case 'Mark as Unread':
        this.handleShowMessageRequest();
        this.getUnreadMail();
        break;
      case 'Archive Mails':
        this.onArchiveMails(this.state.selectedMailList);
        break;
      default:
        break;
    }
  };

  getReadMail = () => {
    const body = {
      'message_ids': this.state.selectedMailList
    }
    axiosJSON.put('/api/email_message/read/', body)
      .then(({data, status}) => {
        if (status === 202) {
          this.setState({
            selectedMailList: [],
            selectedMails: 0,
            optionName: '',
          }, () => this.getMails(this.state.selectedName, this.state.page, ''))

          message.success(data.message)
        }
      }).catch(function (error) {
      message.error(error.message)
    })
  };

  getUnreadMail = () => {
    const body = {
      'message_ids': this.state.selectedMailList
    }
    axiosJSON.put('/api/email_message/unread/', body)
      .then(({data, status}) => {
        if (status === 202) {
          this.setState({
            selectedMailList: [],
            selectedMails: 0,
            optionName: '',
          }, () => this.getMails(this.state.selectedName, this.state.page, ''))

          message.success(data.message)
        }
      }).catch(function (error) {
      message.error(error.message)
    })
  };
  onMailShift = (data) => {
    let currentMail = this.state.currentMail.filter(mail => mail.id !== data.id)
    this.setState({
      currentMail: currentMail
    })
  }
  renderPage=(folderMails)=>{
    return <div>
        <div className="right-pagination">
            <span className="view-section">Viewing {
              this.state.total < 25 ?
                this.state.total + " out of " + this.state.total:
                this.state.total < this.state.page * this.state.total_mails ? this.state.total +" out of " +this.state.total
                  : this.state.page * this.state.total_mails + " out of " + this.state.total}
                    </span>

          <Button disabled={this.state.page <= 1} onClick={()=>{
              let total_mails=folderMails.length;
              if(this.state.page >=1){

                this.setState({
                  page:this.state.page-1,
                  total_mails:total_mails
                },()=> {
                    if(this.state.selectedName === "Company' Inbox"){
                      this.getCompanyMails(this.state.page, this.state.query)
                    }
                    else{
                      this.getMails(this.state.selectedName, this.state.page, this.state.query)
                    }
                }
                )
              }
            }} >
              <LeftOutlined />
            </Button>
            <Button disabled={Math.floor(this.state.total /25) < this.state.page} onClick={()=>{
              let total_mails=folderMails.length;
              if(Math.floor(this.state.total /25) >= this.state.page){
                this.setState({
                  page:this.state.page+1,
                  total_mails:total_mails
                },()=>{
                  if(this.state.selectedName === "Company' Inbox"){
                    this.getCompanyMails(this.state.page, this.state.query)
                  }
                  else{
                    this.getMails(this.state.selectedName, this.state.page, this.state.query)
                  }
                })
              }
            }}>
              <RightOutlined />
            </Button>
        </div>
        <br clear="all"/>
    </div>

  }


  displayMail = (newMail, currentMail, folderMails, noContentFoundMessage, selectedFolder) => {
    return (<div className="gx-module-box-column">
      {currentMail.length === 0 ?
        folderMails.length === 0 ?
          <div className="gx-no-content-found gx-text-light gx-d-flex gx-align-items-center gx-justify-content-center">
            {noContentFoundMessage}
          </div>
          :
          <div>
            <MailList mails={folderMails}
                      selectedFolder={selectedFolder}
                      onMailSelect={this.onMailSelect.bind(this)}
                      onSelectAllMail={this.onSelectAllMail.bind(this)}
                      onMailChecked={this.onMailChecked.bind(this)}/>
          </div>
        :
        selectedFolder === 0 ?
          <MailDetail onMailShift={this.onMailShift} selectedName={this.state.selectedName}
                      selectedFolder={selectedFolder} mails={currentMail}/> :
          selectedFolder === 3 || selectedFolder === 1 ?
            <MailDetail onMailShift={this.onMailShift} selectedName={this.state.selectedName}
                        selectedFolder={selectedFolder} mails={[currentMail]}/> :
            <div>
              {this.renderPage(folderMails)}
              <MailList mails={folderMails}
                        selectedFolder={selectedFolder}
                        onSelectAllMail={this.onSelectAllMail.bind(this)}
                        onMailSelect={this.onMailSelect.bind(this)}
                        onMailChecked={this.onMailChecked.bind(this)}/>

            </div>
      }
    </div>)
  };

  optionMenu = () => (
    <Menu id="option-menu"
          onClick={this.onOptionMenuItemSelect.bind(this)}>
      {options.map(option =>
        <Menu.Item key={option.title}>
          {option.title}
        </Menu.Item>,
      )}
    </Menu>);

  folderMenu = () => (
    <Menu id="folder-menu"
          onClick={this.onFolderMenuItemSelect.bind(this)}>
      {folders.map(folder =>
        <Menu.Item key={folder.id}>
          {folder.title}
        </Menu.Item>,
      )}
    </Menu>);


  constructor() {
    super();
    this.state = {
      total_mails:"",
      query: '',
      noContentFoundMessage: 'No Mail found in selected folder',
      alertMessage: '',
      showMessage: false,
      drawerState: false,
      optionName: '',
      allMail: [],
      loader: true,
      currentMail: [],
      user: {
        name: 'Robert Johnson',
        email: 'robert@example.com',
        avatar: "https://via.placeholder.com/150"
      },
      selectedMails: 0,
      selectedFolder: 0,
      selectedName: 'inbox',
      composeMail: false,
      folderMails: [],
      selectedMailList: [],
      id: '',
      total: 0,
      page: 1,
      newMail: {}
    }
    this.Mounted = false
  }

  componentWillUnmount() {
    this.Mounted = false
  }

  componentDidMount() {
    this.reRunner()
  }



  getCompanyMails=(page, query)=>{
    axiosJSON.get('/api/email_message/company/?page='+page+'&query='+query)
      .then(({data, status}) => {
        let mailObj = {}
        let mailList = []
        data.data.map((mail, i) => {
          mailObj = {
            body: mail.body,
            cc: mail.cc || [],
            bcc: mail.bcc || [],
            read: mail.is_read,
            from: {
              name: mail.from_name,
              email: mail._from
            },
            id: mail.id,
            reply_to: mail.reply_to,
            subject: mail.subject,
            to: mail._to
          }
          mailList.push(mailObj)
          return mailList
        })

        this.setState({
          total: data.total,
          currentMail: [],
          newMail: {},
          selectedName: "Company's Inbox",
          folderMails: mailList,
          query: query,
          total_mails:page >1 ?this.state.total_mails :mailList.length,
          loader: false,
        })
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  getMails = (type, page, query) => {
    axiosJSON.get('/api/email_message/?type=' + type + '&page=' + page + '&query=' + query)
      .then(({data, status}) => {
        let mailObj = {}
        let mailList = []
        data.data.map((mail, i) => {
          mailObj = {
            body: mail.body,
            cc: mail.cc || [],
            bcc: mail.bcc || [],
            read: mail.is_read,
            from: {
              name: mail.from_name,
              email: mail._from
            },
            id: mail.id,
            reply_to: mail.reply_to,
            subject: mail.subject,
            to: mail._to,
            ct:mail.ct
          }
          mailList.push(mailObj)
          return mailList
        })

        this.setState({
          total: data.total,
          currentMail: [],
          newMail: {},
          selectedName: type,
          folderMails: mailList,
          query: query,
          total_mails:page >1 ?this.state.total_mails :mailList.length,
          loader: false,
        })
      }).catch(function (error) {
      message.error(error.message)
    })
  }
  onSelectAllMail = (data) => {
    let obj = {}
    let mailList = [];
    let selectedMailList = this.state.selectedMailList;
    if (data.target.checked) {
      mailList = this.state.folderMails.map((mail) => {
          obj = {
            ...mail,
            selected: true
          }
          selectedMailList.push(mail.id)
          return obj
        }
      );
    } else {
      mailList = this.state.folderMails.map((mail) => {
          obj = {
            ...mail,
            selected: false
          }
          selectedMailList = []
          return obj
        }
      );
    }

    this.setState({
      selectedMailList: selectedMailList,
      folderMails: mailList,
      selectedMails: data.target.checked ? mailList.length : 0,
      optionName: data.target.checked ? 'All' : '',
    });
  }

  onMailChecked(data) {
    data.selected = !data.selected;
    let selectedMails = this.state.selectedMails;
    let optionName;
    let mailList = this.state.selectedMailList;
    selectedMails >= 0 && this.state.folderMails.map(mail => {
        if (mail.id === data.id) {
          if (mail.selected) {
            selectedMails++;
            mailList.push(data.id);
          } else {
            var index = mailList.indexOf(mail.id);
            if (index !== -1) mailList.splice(index, 1);
            selectedMails--;
          }
        }
        return null
      }
    );
    if (selectedMails > 0 && (selectedMails < this.state.folderMails.length)) {
      optionName = selectedMails + " Selected"
    } else if (!(selectedMails < this.state.folderMails.length)) {
      optionName = "All"
    } else {
      optionName = ""
    }
    this.setState({
      selectedMails: selectedMails,
      optionName: optionName,
      selectedMailList: mailList

    });
  }

  onDraftMailPut = (data, id) => {
    axiosJSON.put('/api/email_message/' + id + '/', data)
      .then(({data, status}) => {
        message.success(data.message)
      }).catch(function (error) {
      message.error(error.message)
    })
    this.setState({
      composeMail:false
    })
    this.props.history.push('/mail#' + this.state.selectedName)
    this.reRunner()

  }
  onDraftMailSend = (data, id) => {
    if (id !== null) {
      this.onDraftMailPut(data, id)
    }
  }

  onMailSend = (data, id) => {

    if (id !== null) {
      axiosJSON.post('/api/email_message/' + id + '/send_mail/', data)
        .then(({data, status}) => {
          message.success(data.message)
        }).catch(function (error) {
        message.error(error.message)
      })
      this.setState({
        composeMail:false
      })
      this.props.history.push('/mail#inbox')
      this.reRunner()
    }
    this.reRunner()
  }

  onMailSelect(mail) {

    if (this.state.selectedFolder === 2) {
      this.setState({
        composeMail:false
      })
      this.props.history.push('/mail#' + this.state.selectedName + '?compose=' + mail.id)
      this.reRunner()
    } else {
      this.setState({
        composeMail:false
      })
      this.props.history.push('/mail#' + this.state.selectedName + '/' + mail.id)
      this.reRunner()
    }
  }

  updateSearch(evt) {
    this.setState({
      query: evt.target.value,
    });
  }

  onToggleDrawer() {
    this.setState({
      drawerState: !this.state.drawerState
    });
  }

  renderList = () => {
    const {selectedMails, folderMails, selectedFolder, optionName} = this.state;

    return <div className="gx-flex-row gx-align-items-center">
      {folderMails.length > 0 ?
        <Auxiliary>
          <Checkbox color="primary" className="gx-icon-btn"
                    indeterminate={selectedMails > 0 && selectedMails < folderMails.length}
                    checked={selectedMails > 0}
                    onChange={this.onSelectAllMail.bind(this)}
                    value="All"/>
          {
            selectedFolder === 2 && selectedMails > 0 &&
            <Button onClick={() => this.onDraftDiscard(this.state.selectedMailList)}>Discard Drafts</Button>
          }
          {selectedMails > 0 && selectedFolder !== 0 && selectedFolder !== 2  && selectedFolder !== 4 &&
          <Button onClick={()=>this.onArchiveMails(this.state.selectedMailList)}>Archive</Button>
          }

          {selectedMails > 0 && selectedFolder=== 0 &&
          <Dropdown overlay={this.optionMenu()} placement="bottomRight" trigger={['click']}>
            <div>
              <span className="gx-px-2"> {optionName}</span>
              <i className="icon icon-charvlet-down"/></div>
          </Dropdown>
          }
          {this.renderPage(folderMails)}
        </Auxiliary>
        : null}
    </div>
  }

  render() {
    const {selectedFolder, loader, currentMail, newMail, drawerState, folderMails, composeMail, user, alertMessage, showMessage, noContentFoundMessage} = this.state;
    return (

      <div className="gx-main-content">
        <div className="gx-app-module">

          <div className="gx-d-block gx-d-lg-none">
            <Drawer
              placement="left"
              closable={false}
              visible={drawerState}
              onClose={this.onToggleDrawer.bind(this)}>
              {this.MailSideBar()}
            </Drawer>

          </div>
          <div className="gx-module-sidenav gx-d-none gx-d-lg-flex">
            {this.MailSideBar()}
          </div>

          <div className="gx-module-box">
            <div className="gx-module-box-header">
              <span className="gx-drawer-btn gx-d-flex gx-d-lg-none">
                  <i className="icon icon-menu gx-icon-btn" aria-label="Menu"
                     onClick={this.onToggleDrawer.bind(this)}/>
              </span>

              <AppModuleHeader placeholder={"Search mails in " + this.state.selectedName}
                               user={this.state.user}
                               onPressEnter={() => {
                                 if(this.state.selectedName=== "Company' Inbox"){
                                   this.getCompanyMails(1, this.state.query)
                                 }
                                 else {
                                   this.getMails(this.state.selectedName, 1, this.state.query)
                                 }
                               }}
                               onChange={this.updateSearch.bind(this)}
                               value={this.state.query}/>

            </div>

            <div className="gx-module-box-content">
              <div className="gx-module-box-topbar">
                {currentMail.length === 0 ?
                  this.renderList() :
                  selectedFolder === 2 ?
                    this.renderList() :
                    <i className="icon icon-arrow-left gx-icon-btn" onClick={() => {
                      // this.reRunner()
                      this.props.history.push('/mail#' + this.state.selectedName)
                      this.reRunner()
                      
                    }}/>
                }

                <div classID="toolbar-separator"/>

              </div>

              {loader ?
                <div className="gx-loader-view">
                  <CircularProgress/>
                </div>
                : this.displayMail(newMail, currentMail, folderMails, noContentFoundMessage, selectedFolder)}

              {composeMail &&
              <ComposeMail id={this.state.id} mails={newMail} open={composeMail} user={user}
                           onClose={this.handleRequestClose.bind(this)}
                           onMailSend={this.onMailSend.bind(this)}/>
              }

            </div>
          </div>
        </div>
        {showMessage && message.info(<span id="message-id">{alertMessage}</span>, 3, this.handleShowMessageRequest)}
      </div>
    )
  }
}

const routeMail = withRouter(Mail);

export default routeMail;
