import React from "react";
import {Input, Progress, Select, Tooltip} from "antd";
import SlateEmailEditor from "../../EmailTemplateEditor";
import { DeleteOutlined} from '@ant-design/icons';

const beforeMatch = /{(\w+)$/
const beforeStr = '{{';
const afterStr = '}}';


class DisplayMail extends React.Component {

  render() {
    const {id, to, subject, message, cc, bcc, showCc, showBcc, emailList,prgObj,fileList} = this.props;
    return (
        <div>
        <div className="compose-mail-box">

          <label>To</label>
          <Select bordered={false} showSearch={true} onSearch={this.props.getEmailList} placeholder="To*"
                                   tagRender={this.props.tagRender} value={to} mode="tags" style={{width: '96%', marginBottom:'10px'}}
                                   onChange={(value) => this.props.setEmails('to', value)} tokenSeparators={[',']}>
          {emailList.map((email, i) =>
            <Select.Option key={email}>{email}</Select.Option>)}
        </Select>

<div className="right-input-mail">
          {!showCc &&
          <Tooltip title="Add recipients to Cc">
            <p className="gx-pointer" onClick={() => this.props.setFlag('showCc')}>
              <u>Cc</u></p>
          </Tooltip>
          }
          {!showBcc &&
          <Tooltip title="Add recipients to Bcc">
            <p className="gx-pointer" onClick={() => this.props.setFlag('showBcc')}>
              <u>Bcc</u></p>
          </Tooltip>
          }
</div>
        </div>


        {showCc && <div className="gx-form-group">
          Cc<Select bordered={false} placeholder="Cc" tagRender={this.props.tagRender} value={cc} mode="tags"
                    style={{width: '80%'}}
                    onChange={(value) => this.props.setEmails('cc', value)} tokenSeparators={[',']}>
          {emailList.map((email, i) =>
            <Select.Option key={email}>{email}</Select.Option>)}
        </Select>
        </div>
        }


        {showBcc &&
        <div className="gx-form-group">
          Bcc<Select bordered={false} placeholder="Bcc" tagRender={this.props.tagRender} value={bcc} mode="tags"
                     style={{width: '80%'}}
                     onChange={(value) => this.props.setEmails('bcc', value)} tokenSeparators={[',']}>
          {emailList.map((email, i) =>
            <Select.Option key={email}>{email}</Select.Option>)}
        </Select>
        </div>
        }


        <div className="gx-form-group">
          <Input
            placeholder="Subject"
            onChange={this.props.onSubChange}
            value={subject}
            margin="normal"
          />
        </div>


        <div className="email-text-editor-new">
          <SlateEmailEditor beforeMatch={beforeMatch} afterStr={afterStr}
                            beforeStr={beforeStr}
                            value={message}
                            setData={this.props.setMailBody} placeholdersList={[]}/>
        </div>

        <div className="gx-form-group">
          <label htmlFor="files" className="btn"><i className="icon icon-attachment"/> Attach File</label>
          <input id="files" type="file" accept=".docx,.pdf,image/*,.zip,.csv" style={{visibility: 'hidden'}}
                 onChange={this.props.handleChange}/>
          {fileList && fileList.length > 0 &&
          fileList.map((file, i) =>
            <div className="attach_box" key={i}>
              <span onClick={()=>this.props.onDownloadFile(file.id,id)}>{file.file_name}</span>
              {prgObj !== null && file.file_name === prgObj.file_name &&
              <Progress percent={prgObj.prg} size="small"/>
              }
              {file.id && <DeleteOutlined className="delete-icon" onClick={() => this.props.onDeleteFile(file.id,id)}/>}
            </div>)}
        </div>

        </div>
    );
  }
}

export default DisplayMail;
