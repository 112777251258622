import React from "react";
import {Button, Input, message} from "antd";
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import axiosJSON from "../../util/SetHeaderAPI";

class EditLinks extends React.Component {
  state = {
    listKeys: [],
    links_arr: []
  };
  Mounted=false

  componentWillUnmount() {
    this.Mounted = false
  }

  componentDidMount() {
    this.Mounted = true
    let list_arr=[];
    let links_arr=[]
    if(this.props.links !== null && this.props.links.length !==0){
      links_arr=this.props.links;
      this.props.links.map((ele,i)=>list_arr.push(i))
    }
    this.setState({
      listKeys: list_arr,
      links_arr: links_arr
    })
  }

  remove = k => {
    const {listKeys, links_arr} = this.state;

    let number = links_arr[k]

    this.setState({
      links_arr: links_arr.filter(key => number !== key),
      listKeys: listKeys.filter(key => key !== k),
    });


  };

  add = () => {
    const {listKeys, links_arr} = this.state;

    links_arr.push("")
    listKeys.push(listKeys.length)

    this.setState({
      links_arr: links_arr,
      listKeys: listKeys,
    });
  };
  onSubmit = () => {
    const body={
      "links":this.state.links_arr
    }
    axiosJSON.put('api/candidate/' + this.props.id + '/', body)
      .then(({data, status}) => {
        if (status === 202) {
          message.success(data.message)
          this.props.closeModal()

        }
      }).catch(function (error) {
      message.error(error.message)
    })
  }
  handleChange = (e, index) => {
    let links_arr = this.state.links_arr;
    links_arr[index] = e.target.value;

    this.setState({
      links_arr: links_arr,
    })
  }

  render() {
    const {listKeys, links_arr} = this.state;
    return (
      <div>
        {listKeys.length !== 0 && listKeys.map((key, index) => {
          return (
            <div key={key} className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
                <Input value={links_arr[index]} onChange={(e) => this.handleChange(e, index)}
                       placeholder="Link"/>
                <DeleteOutlined
                  className="dynamic-delete-button delete-icon-phone"
                  onClick={() => this.remove(key)}
                />
              </div>
          );
        })}
        <Button type="dashed" onClick={this.add}>
          <PlusOutlined/>
          Add more links
        </Button>
        <Button type="primary" onClick={this.onSubmit}>
          Submit
        </Button>
      </div>

    );
  }
}

export default EditLinks;
