import React, { useEffect, useState } from "react";
import { message, Rate, Tag } from "antd";
import axiosJSON from "../../../util/SetHeaderAPI";
import moment from "moment";
import { FiEdit2 } from "react-icons/fi";
import { AiOutlinePlus, AiOutlineUser } from "react-icons/ai";
import CircularProgress from "../../CircularProgress";
import TrainingFeedback from "../../../modals/Training/trainingFeedback";
import { CustomModal as Modal } from "../../../util/CustomModal";

// const Feedback_Types = {
//   mid_training: "Mid Training",
//   final: "Final Training",
//   interview: "interview"
// };

const CandidateFeedback = (props) => {
  const [feedbacklist, setFeedbacklist] = useState([]);
  const [feedbackLoader, setFeedbackLoader] = useState(false);
  const [editFeedback, setEditFeedback] = useState(false);
  const [isFeedbackEdit, setIsFeedbackEdit] = useState(false);
  const [feedBackObj, setFeedBackObj] = useState(null);

  useEffect(() => {
    getCandidateFeedback();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCandidateFeedback = () => {
    setFeedbackLoader(true);
    axiosJSON
      .get(
        "/api/training_candidate/" +
          props.candidate.training_candidate_id +
          "/feedback/"
      )
      .then(({ data, status }) => {
        setFeedbacklist(data.data);
        setFeedbackLoader(false);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };
  const handleFeedbackClose = () => {
    setEditFeedback(false);
    getCandidateFeedback();
    props.getActivity();
  };

  return (
    <>
      <div className="col-12 p-0 mt-3">
        <div className="card">
          <div className="card-header ch-clr">
            Feedback
            <span
              className="btn_ch"
              onClick={() => {
                setEditFeedback(true);
                setIsFeedbackEdit(false);
                setFeedBackObj(null);
              }}
            >
              <AiOutlinePlus /> Add New Feedback
            </span>
          </div>
          <div className="card-body feed_heig">
            <div className="col-12">
              {feedbackLoader ? (
                <CircularProgress />
              ) : (
                <div className="row row_cont">
                  {feedbacklist.length > 0 ? (
                    feedbacklist.map((item, i) => (
                      <div key={i} className="col-6 card_cont">
                        <div className="card card_bor">
                          <div className="bordr_cls card_head">
                            <div className="general_sub3 feed">
                              <div className="p-0 col-2">
                                <div className="circle_icon feedback">
                                  <AiOutlineUser />
                                </div>
                              </div>
                              <div className="ml-2 f_grow">
                                <div className="h4">{item.given_by}</div>
                                <div className="feedback_des">
                                  {moment
                                    .utc(
                                      item.modified
                                        ? item.modified
                                        : item.created
                                    )
                                    .utcOffset("-0500")
                                    .format("hh:mm A | MM-DD-YYYY")}{" "}
                                  EST
                                </div>
                              </div>
                              {item.training_feedback && (
                                <div className="edit_ico">
                                  <FiEdit2
                                    onClick={() => {
                                      setEditFeedback(true);
                                      setIsFeedbackEdit(true);
                                      setFeedBackObj(item);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="card_body">
                            <div className="row center mb-3">
                              <div className="col-4">
                                <Tag className="mb-0 custom-tag-pur">
                                  {item.feedback_type}
                                </Tag>
                                {/* {Feedback_Types[item.feedback_type] && (
                                  <div className="feed_type m-0">
                                    {Feedback_Types[item.feedback_type]}
                                    
                                  </div>
                                )} */}
                              </div>
                              <div className="col-8 right">
                                Rating
                                <Rate
                                  disabled
                                  value={item.rating}
                                  style={{
                                    fontSize: "16px",
                                    marginLeft: "8px",
                                  }}
                                />
                              </div>
                              <div className="col-12 mt-3">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.description,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="noData">No Data</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {editFeedback && (
        <Modal
          title="Feedback"
          visible={editFeedback}
          footer={null}
          onCancel={() => setEditFeedback(false)}
        >
          <TrainingFeedback
            candidate={props.candidate}
            feedbackObj={feedBackObj}
            edit={isFeedbackEdit}
            closeModal={handleFeedbackClose}
          />
        </Modal>
      )}
    </>
  );
};

export default CandidateFeedback;
