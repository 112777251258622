import { message, Modal, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import axiosJSON from "../../../util/Api";
import { styles } from "./style";
import AddComment from "../../../modals/Training/addComment";
import { getSortName } from "../utils";

const Comment = ({ traingId }) => {
  const [comments, setComments] = useState([]);
  const [userList, setUserList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const getUserList = async () => {
    try {
      setLoading(true);
      const data = await axiosJSON.get("api/user/tags/");
      setUserList(data.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      message.error("Something went wrong, please reach to support");
    }
  };

  const getAllComments = async () => {
    try {
      setLoading(true);
      const data = await axiosJSON.get(
        `api/training_candidate/${traingId}/comment/`
      );
      setComments(data.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong, please reach to support");
    }
  };

  useEffect(() => {
    getAllComments();
    getUserList();
    //eslint-disable-next-line
  }, []);

  return (
    <Spin spinning={loading}>
      <div>
        <div style={styles.mainWrapper}>
          <div style={styles.topPartWrapper}>
            <div style={styles.allFeedbackStyle}>All Comments</div>
            <div
              style={styles.addFeedbackButton}
              onClick={() => {
                setShowModal(true);
              }}
            >
              <FiPlus />
              Add comment
            </div>
          </div>
          {comments.length > 0 ? (
            comments.map((comment, index) => {
              return (
                <div
                key={index}
                  style={
                    index === 0
                      ? styles.firstDisplayFeedbackDivStyle
                      : styles.otherDisplayFeedbackDivStyle
                  }
                >
                  <div style={styles.nameDivCireleStyle}>
                    {getSortName(comment.user.name)}
                  </div>
                  <div style={styles.rightInnerDivStyle}>
                    <div style={styles.righetInnerTopPartDiv}>
                      <div style={{ color: "#353D4A", fontSize: "14px" }}>
                        {comment.user.name}
                      </div>
                    </div>
                    <div style={styles.innerMidDivStyle}>
                      <div style={{ color: "#9AA1B0", fontSize: "15px" }}>
                        {moment(comment.created).format("llll")}
                      </div>
                    </div>
                    <div style={styles.feedbackDescriptionStyle}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: comment.comment_text,
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div style={styles.noDataAvailble}>No data Available</div>
          )}
        </div>
        {showModal && (
          <Modal
            title="Add Comment"
            open={showModal}
            footer={null}
            onCancel={() => setShowModal(false)}
          >
            <AddComment
              candidateId={traingId}
              user_list={userList}
              closeModal={() => {
                setShowModal(false);
                getAllComments();
              }}
            />
          </Modal>
        )}
      </div>
    </Spin>
  );
};

export default Comment;
