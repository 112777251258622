import React, {Component} from "react";
import Auxiliary from "util/Auxiliary";
import {Button, } from "antd";
import EditVisa from "../../../../modals/Candidate/editvisa";
import {EditOutlined} from '@ant-design/icons';
import moment from 'moment-timezone';
import {CustomModal as Modal} from "../../../../util/CustomModal"

class VisaHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      visa_obj: null
    }
  }

  openVisaModal = () => {
    this.setState({
      openModal: true
    })
  }
  closeVisaModal = () => {
    this.setState({
      openModal: false,
      visa_obj:null
    },()=>this.props.getData())
  }

  setData=(visa)=>{
    this.setState({visa_obj:visa,openModal:true})
  }

  render() {
    const {openModal} = this.state;
    return (
      <Auxiliary>

        <div className="main-history-section">

          <div className="gx-media-body">
            {
              this.props.visaData.map((visa,index)=>
                <div className="history_section" key={index}>
                  <i className={`icon icon-auth-screen gx-fs-xlxl gx-text-orange`}/>
                  <span className="history-text"> {visa.type && visa.type.display_name +" ( "+ moment(visa.start).format('MM-DD-YYYY')+" to "+ moment(visa.end).format('MM-DD-YYYY') +")"} </span>
                  <EditOutlined className="gx-ml-2" onClick={()=>this.setData(visa)}/>
                </div>
              )
            }
          </div>

          <br clear="all" />

          <div className="gx-mr-3">
            <Button onClick={this.openVisaModal}>Add Visa</Button>
          </div>

        </div>
        {
          openModal &&
          <Modal
            title="Add Visa Details"
            visible={openModal}
            onCancel={this.closeVisaModal}
            footer={null}
          >
            <EditVisa visa_obj={this.state.visa_obj} closeModal={this.closeVisaModal} id={this.props.id}/>
          </Modal>
        }
      </Auxiliary>
    );
  }

};

export default VisaHistory;
