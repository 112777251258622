import React from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";

moment.locale("en");
const localizer = momentLocalizer(moment);

const allViews = Object.keys(Views).map((k) => Views[k]);

class CalendarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      Object.keys(this.props.events).length > 0 && (
        <>
          <Calendar
            localizer={localizer}
            toolbar={false}
            events={
              this.props.email
                ? this.props.events[this.props.email]
                : this.props.events
            }
            // step={35}
            views={allViews}
            view={this.props.view}
            eventPropGetter={(event) => {
              let newStyle = {
                backgroundColor: this.props.color
                  ? this.props.color
                  : event.color
                  ? event.color
                  : this.props.emailColorMapper[event.email],
                color: "white",
                borderRadius: "10px",
                border: "1px solid green",
              };
              return {
                className: "",
                style: newStyle,
              };
            }}
            date={new Date(this.props.date)}
            onNavigate={(date) => this.setState({ date })}
          />
        </>
      )
    );
  }
}

export default CalendarComponent;
