import React, {Component} from "react";
import Widget from "../../../../components/Widget";
import {Button, Col, Dropdown, Menu, message, Row, Tabs} from "antd";
import { DownOutlined } from '@ant-design/icons';
import axiosJSON from "../../../../util/SetHeaderAPI";
import TemplateList from "./templateList";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import "nprogress/nprogress.css";
import nprogress from "nprogress";

const {TabPane} = Tabs;

class EmailTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailTemplates: [],
      type: "",
      loading: false,
      activeTabKey: "yes"
    }
    this.Mounted=false
  }

  componentWillUnmount() {
    this.Mounted = false
  }

  componentDidMount() {
    nprogress.start()
    this.Mounted = true
    this.getTemplates("yes")
    nprogress.done()
  }

  getTemplates = (type) => {
    this.setState({
      loading: true,emailTemplates:[]
    })
    axiosJSON.get('/api/email_template/?is_private=' + type)
      .then(({data, status}) => {
          this.setState({
            emailTemplates: data.data,
            loading: false
          })
      }).catch((err) => {
      message.error(err.message)
    });
  }
  changeTab = (key) => {
    this.setState({
      activeTabKey: key
    }, () => this.getTemplates(this.state.activeTabKey))
  }

  handleMenuClick=(value)=>{
    return  this.props.history.push(`/template?type=email&is_private=`+value.key)
  }

  render() {
    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="yes">Personal</Menu.Item>
        <Menu.Item disabled={!this.props.auth.settings} key="no">Company</Menu.Item>
      </Menu>
    );
    return (
      <div style={{padding: '20px'}} className="gx-profile-content-right">
        <Row>
          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <Widget title="Email Templates" styleName="gx-card-profile">
              <Tabs tabBarExtraContent={
                <Dropdown overlay={menu}>
                  <Button>
                    Create Template <DownOutlined />
                  </Button>
                </Dropdown>} defaultActiveKey="yes" onChange={this.changeTab}>
                <TabPane tab="Personal" key="yes">
                  <TemplateList is_private={this.state.activeTabKey} data={this.state.emailTemplates}/>
                </TabPane>
                <TabPane tab="Company" key="no">
                  <TemplateList is_private={this.state.activeTabKey} data={this.state.emailTemplates}/>
                </TabPane>
              </Tabs>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

};

const mapStateToProps = state => ({
  auth: state.auth.authUser,
});

const routeEmailTemplates = withRouter(EmailTemplates)

export default connect(
  mapStateToProps,
)(routeEmailTemplates);
