import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { withRouter } from "react-router";
// import asyncComponent from "util/asyncComponent";
import ProfileHeader from "../../components/Candidate/profile/ProfileHeader";
import Auxiliary from "../../util/Auxiliary";
import axiosJSON from "../../util/SetHeaderAPI";
import { message } from "antd";
import { useSelector } from "react-redux";
import CandidateSummaryView from "./Summary/index";
import Email from "./Emails";
import Documents from "./Documents";
import Evaluation from "./Evaluation";
import Activity from "./Activity";
import CommentList from "./Comment";
import FormSubmission from "./FormSubmission";
import "nprogress/nprogress.css";
import nprogress from "nprogress";
import Project from "./Projects";
import Trainings from "./Training";
import CandidateFeedback from "./CandidateFeedback";

const CandidateDetailView = (props) => {
  const location = useLocation();
  let id = location.pathname.split("/")[2];
  const user = useSelector(({ auth }) => auth);
  const [data, setData] = useState(null);
  const [follow, setFollow] = useState(false);
  const [permissions, setPermissions] = useState(false);
  const [isBool, setBool] = useState(false);
  useEffect(() => {
    nprogress.start();
    if (data === null) {
      getDetails();
      getPermissions();
    }
    nprogress.done();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routes = () => {
    return (
      <Switch>
        <Route exact path={props.match.url + "/" + id + "/summary"}>
          <CandidateSummaryView data={data} getDetails={getDetails} />
        </Route>
        <Route exact path={props.match.url + "/" + id + "/emails"}>
          <Email />
        </Route>
        <Route exact path={props.match.url + "/" + id + "/documents"}>
          <Documents />
        </Route>
        <Route exact path={props.match.url + "/" + id + "/evaluation"}>
          <Evaluation />
        </Route>
        <Route exact path={props.match.url + "/" + id + "/activity"}>
          <Activity />
        </Route>
        <Route exact path={props.match.url + "/" + id + "/comment"}>
          <CommentList />
        </Route>
        <Route exact path={props.match.url + "/" + id + "/form-submission"}>
          <FormSubmission />
        </Route>
        {props.app === "us" ? 
        <>
        <Route exact path={props.match.url + "/" + id + "/projects"}>
          <Project />
        </Route>
        <Route exact path={props.match.url + "/" + id + "/trainings"}>
          <Trainings />
        </Route>
        <Route exact path={props.match.url + "/" + id + "/feedbacks"}>
          <CandidateFeedback />
        </Route>
        </>:null}
        {/* <Route exact path={props.path+"/"+id} component={asyncComponent(() => import('./Summary'))}/> */}
        {/* <Route exact path={match.url+"/"+id+"/emails"} component={asyncComponent(() => import('./Emails'))}/> */}
        {/* <Route exact path={match.url+"/"+id+"/documents"} component={asyncComponent(() => import('./Documents'))}/> */}
        {/* <Route exact path={match.url+"/"+id+"/evaluation"} component={asyncComponent(() => import('./Evaluation'))}/> */}
        {/* <Route exact path={match.url+"/"+id+"/activity"} component={asyncComponent(() => import('./Activity'))}/> */}
        {/* <Route exact path={match.url+"/"+id+"/comment"} component={asyncComponent(() => import('./Comment'))}/> */}
        {/* <Route exact path={match.url+"/"+id+"/form-submission"} component={asyncComponent(() => import('./FormSubmission'))}/> */}
      </Switch>
    );
  };

  const getPermissions = () => {
    axiosJSON
      .get("api/candidate/" + id + "/permissions/")
      .then(({ data, status }) => {
        if (status === 200) {
          setPermissions(data.data);
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  const getDetails = () => {
    console.log("called!!");
    axiosJSON
      .get("api/candidate/" + id + "/")
      .then(({ data, status }) => {
        if (status === 200) {
          setData(data.data);
          setFollow(false);
          data.data.followers &&
            data.data.followers.map((follower, i) => {
              if (user.authUser.employee_id === follower.employee_id) {
                setFollow(true);
              }
              return null;
            });
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };
  const followCandidate = () => {
    const body = {
      candidates: [data.id],
    };
    axiosJSON
      .put("api/candidate/follow/", body)
      .then(({ data, status }) => {
        if (status === 202) {
          message.success(data.message);
          getDetails();
          getPermissions();
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  const updateEdit = () => {
    //window.location.reload(false);
    setBool(!isBool);
    //return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
  };

  return (
    <Auxiliary>
      <ProfileHeader
        data={data}
        follow={follow}
        permissions={permissions}
        getDetails={getDetails}
        followCandidate={followCandidate}
        getPermissions={getPermissions}
        id={id}
        path={props.path}
        updateEdit={updateEdit}
        app={props.app}
      />
      {isBool ? <div>{routes()}</div> : <>{routes()}</>}
    </Auxiliary>
  );
};

export default withRouter(CandidateDetailView);
