import React, { Component } from "react";
import Widget from "../../../components/Widget";
import { Col, message, Row, Tabs, Dropdown, Button, Menu } from "antd";
import axiosJSON from "../../../util/SetHeaderAPI";
import FormList from "./Forms/formList";
import { DownOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "nprogress/nprogress.css";
import nprogress from "nprogress";

const { TabPane } = Tabs;
class CustomSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applicationForms: [],
      onboardingForms: [],
      type: "",
      loading: false,
    };
    this.Mounted = false;
  }

  componentWillUnmount() {
    this.Mounted = false;
  }

  componentDidMount() {
    nprogress.start();
    this.Mounted = true;
    this.getForms("external");
    nprogress.done();
  }

  getForms = (type) => {
    this.setState({
      loading: true,
      applicationForms: [],
      onboardingForms: [],
    });
    axiosJSON
      .get("/api/form_template/?form_type=" + type)
      .then(({ data, status }) => {
        if (type === "external") {
          this.setState({
            applicationForms: data.data,
            loading: false,
          });
        } else {
          this.setState({
            onboardingForms: data.data,
            loading: false,
          });
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  changeTab = (key) => {
    this.setState(
      {
        type: key,
      },
      () => this.getForms(this.state.type)
    );
  };

  handleMenuClick = (value) => {
    return this.props.history.push("/form?type=" + value.key);
  };

  render() {
    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="external">External</Menu.Item>
        <Menu.Item key="internal">Internal</Menu.Item>
      </Menu>
    );
    return (
      <div style={{ padding: "20px" }} className="gx-profile-content-right">
        <Row>
          <Col className="col-12">
            <Widget title="Forms" styleName="gx-card-profile">
              <Tabs
                tabBarExtraContent={
                  this.props.auth.settings ? (
                    <Dropdown overlay={menu}>
                      <Button>
                        Create Form <DownOutlined />
                      </Button>
                    </Dropdown>
                  ) : null
                }
                defaultActiveKey="external"
                onChange={this.changeTab}
              >
                <TabPane tab="External Form" key="external">
                  <FormList
                    settings={this.props.auth.settings}
                    getForms={() => this.getForms("external")}
                    data={this.state.applicationForms}
                  />
                </TabPane>
                <TabPane tab="Internal Form" key="internal">
                  <FormList
                    settings={this.props.auth.settings}
                    getForms={() => this.getForms("internal")}
                    data={this.state.onboardingForms}
                  />
                </TabPane>
              </Tabs>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.authUser,
});

const routeCustomSettings = withRouter(CustomSettings);

export default connect(mapStateToProps)(routeCustomSettings);
