import React from "react";
import { Button, Input, Radio, DatePicker, message, Select, Form } from "antd";
import moment from "moment";
import axiosJSON from "../../util/SetHeaderAPI";
import { connect } from "react-redux";
import FormItem from "antd/es/form/FormItem";

// const fieldsInitially = [
//   {
//     name: "legal_name",
//     value: "",
//   },
//   {
//     name: "name",
//     value: "",
//   },
//   {
//     name: "dob",
//     value: "",
//   },
//   {
//     name: "gender",
//     value: "",
//   },
//   {
//     name: "current_location",
//     value: "",
//   },
//   {
//     name: "location_preference",
//     value: "",
//   },
//   {
//     name: "country",
//     value: "",
//   },
//   {
//     name: "job_position",
//     value: "",
//   },
//   {
//     name: "ssn",
//     value: "",
//   },
//   {
//     name: "rate",
//     value: "",
//   },
//   {
//     name: "marital_status",
//     value: "",
//   },
// ];

class EditProfile extends React.Component {
  state = {
    legal_name: "",
    // name: "",
    // dob: "",
    // gender: "",
    // current_location: "",
    // location_preference: "",
    // job_position: null,
    cityArr: [],
    cityArrP: [],
    positionArr: [],
    // ssn: "",
    // rate: 0,
    // country: null,
    countryList: [],
    // marital_status: "",
    // fields: [...fieldsInitially],
    country: this.props.selectedCountryCode === "us" ? "USA" : "India",
  };
  formRef = React.createRef();

  Mounted = false;

  componentWillUnmount() {
    this.Mounted = false;
  }

  componentDidMount() {
    this.Mounted = true;
    this.getData(this.props.id);
    this.getCities("", this.state.country);
    this.getPCities("");
    this.getPosition();
    this.getCountryList();
    // let fields = [
    //   {
    //     name: "legal_name",
    //     value: this.state.legal_name,
    //   },
    //   {
    //     name: "name",
    //     value: this.state.name,
    //   },
    //   {
    //     name: "dob",
    //     value: this.state.dob,
    //   },
    //   {
    //     name: "gender",
    //     value: this.state.gender,
    //   },
    //   {
    //     name: "current_location",
    //     value: this.state.current_location,
    //   },
    //   {
    //     name: "location_preference",
    //     value: this.state.location_preference,
    //   },
    //   {
    //     name: "country",
    //     value: this.state.country,
    //   },
    //   {
    //     name: "job_position",
    //     value: this.state.job_position,
    //   },
    //   {
    //     name: "ssn",
    //     value: this.state.ssn,
    //   },
    //   {
    //     name: "rate",
    //     value: this.state.rate,
    //   },
    //   {
    //     name: "marital_status",
    //     value: this.state.marital_status,
    //   },
    // ];
    // if (this.props.data) {
    //   this.setState({
    //     legal_name: this.props.data.legal_name,
    //     name: this.props.data.name,
    //     marital_status: this.props.data.marital_status,
    //     fields: fields,
    //   });
    // }
  }

  getCountryList = () => {
    axiosJSON
      .get("api/choice/?type=country")
      .then(({ data, status }) => {
        if (status === 200) {
          let countries = [];
          data.data.map((position) => {
            countries.push(position);
            return null;
          });
          this.setState({
            countryList: countries,
          });
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  getData = (id) => {
    axiosJSON
      .get("api/candidate/" + id + "/about/")
      .then(({ data, status }) => {
        if (status === 200) {
          this.formRef.current.setFieldsValue({
            legal_name: this.props.data.legal_name,
            name: this.props.data.name,
            current_location: data.data.current_location
              ? data.data.current_location
              : "",
            gender: data.data.gender,
            location_preference: !data.data.location_preference
              ? ""
              : data.data.location_preference,
            job_position: data.data.position ? data.data.position.id : "",
            dob: data.data.dob ? moment(data.data.dob) : moment(),
            ssn: data.data.ssn ? data.data.ssn : "",
            rate: data.data.rate ? data.data.rate : 0,
            country: data.data.country ? data.data.country : null,
            marital_status: data.data.marital_status
              ? data.data.marital_status
              : null,
          });
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  onSubmit = (body) => {
    body["dob"] = moment(body["dob"]).format("YYYY-MM-DD");
    body["rate"] = parseInt(body["rate"], 10);
    axiosJSON
      .put("api/candidate/" + this.props.data.id + "/", body)
      .then(({ data, status }) => {
        if (status === 202) {
          message.success(data.message);
          this.props.closeModal();
          this.props.getData();
          this.props.getDetails();
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
    // this.formRef.current.resetFields();
  };

  onFinishFailed = (error) => {
    console.error(error.message);
  };

  getCities = (query, country) => {
    axiosJSON
      .get("/api/city/?query=" + query + "&country=" + country)
      .then(({ data, status }) => {
        this.setState({
          cityArr: data.data,
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  getPCities = (query) => {
    axiosJSON
      .get("/api/city/?query=" + query)
      .then(({ data, status }) => {
        this.setState({
          cityArrP: data.data,
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  getPosition = () => {
    axiosJSON
      .get("/api/position/dropdown/")
      .then(({ data, status }) => {
        this.setState({
          positionArr: data.data,
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  onValuesChange = (changedValues, values) => {
    if (changedValues["country"]) {
      this.getCities("", changedValues["country"]);
      this.formRef.current.setFieldsValue({ current_location: "" });
    }
  };

  render() {
    const labelCol = { span: 10 };
    const wrapperCol = { span: 30 };
    return (
      <div className="gx-login-container">
        <div className="edit_popup_form">
          <Form
            name="basic"
            // fields={this.state.fields}
            onFieldsChange={(newFields) => this.setState({ fields: newFields })}
            onFinish={this.onSubmit}
            onFinishFailed={this.onFinishFailed}
            ref={this.formRef}
            className="gx-signin-form gx-form-row0 form-pl-0"
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            labelAlign="left"
            onValuesChange={this.onValuesChange}
          >
            <FormItem
              rules={[{ required: true, message: "Please input name" }]}
              name="name"
              label="Name"
              style={{ width: "400px" }}
            >
              <Input className="gx-input-lineheight" type="text" />
            </FormItem>
            {/* <FormItem
              rules={[{ required: true, message: "Please input legal name" }]}
              name="legal_name"
              label="Legal Name"
            >
              <Input className="gx-input-lineheight" type="text" />
            </FormItem> */}
            <FormItem name="location_preference" label="Preferred Location">
              <Select
                showSearch
                notFoundContent={null}
                style={{ width: "100%" }}
                className={"selectCorrection"}
                optionFilterProp="children"
                onSearch={(e) => this.getPCities(e)}
              >
                {this.state.cityArrP.map((ele, i) => (
                  <Select.Option key={i} value={ele.name + ", " + ele.state}>
                    {ele.name + ", " + ele.state}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              rules={[{ required: true, message: "Please select country" }]}
              name="country"
              label="Country"
            >
              <Select
                notFoundContent={null}
                style={{ width: "100%" }}
                className={"selectCorrection"}
                onChange={(val) => this.setState({ country: val })}
              >
                {this.state.countryList.map((ele, i) => (
                  <Select.Option key={i} value={ele.display_name}>
                    {ele.display_name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              rules={[
                { required: true, message: "Please select current location" },
              ]}
              name="current_location"
              label="Current Location"
            >
              <Select
                showSearch
                notFoundContent={null}
                style={{ width: "100%" }}
                className={"selectCorrection"}
                optionFilterProp="children"
                onSearch={(e) => this.getCities(e, this.state.country)}
              >
                {this.state.cityArr.map((ele, i) => (
                  <Select.Option key={i} value={ele.name + ", " + ele.state}>
                    {ele.name + ", " + ele.state}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
            <FormItem name="job_position" label="Position">
              <Select showSearch style={{ width: "100%" }}>
                {this.state.positionArr.map((ele, i) => (
                  <Select.Option key={i} value={ele.id}>
                    {ele.title} - {ele.location}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              rules={[
                { required: true, message: "Please select Rate" },
                { pattern: /^[0-9]*$/, message: "Please enter a valid number" },
              ]}
              name="rate"
              label="Rate"
            >
              <Input style={{ width: "100%" }} />
            </FormItem>
            <FormItem
              rules={[
                { required: true },
                {
                  pattern: /^\d{9}$/,
                  message: "Please enter 9 digit SSN/SIN",
                },
              ]}
              name="ssn"
              label="SSN/SIN"
            >
              <Input style={{ width: "100%" }} />
            </FormItem>
            <FormItem
              rules={[
                { required: true, message: "Please select Date of Birth" },
              ]}
              name="dob"
              label="Date of Birth"
            >
              <DatePicker
                allowClear={false}
                style={{ width: "100%", height: "38px" }}
              />
            </FormItem>
            <FormItem
              rules={[{ required: true, message: "Please select gender" }]}
              name="gender"
              label="Gender"
            >
              <Radio.Group>
                <Radio value="male">Male</Radio>
                <Radio value="female">Female</Radio>
              </Radio.Group>
            </FormItem>
            <FormItem
              rules={[
                { required: true, message: "Please select marital status" },
              ]}
              name="marital_status"
              label="Marital Status : "
            >
              <Radio.Group>
                <Radio value={"married"} className="gx-mb-1">
                  Married
                </Radio>
                <Radio value={"unmarried"} className="gx-mb-1">
                  Unmarried
                </Radio>
              </Radio.Group>
            </FormItem>
            <FormItem>
              <div className="submit-btn-right">
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </div>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessData: state.auth.accessData,
});

export default connect(mapStateToProps)(EditProfile);
