import React from "react";
import {
  FiArrowLeft,
  FiBox,
  FiBriefcase,
  FiCalendar,
  FiCheck,
  FiUser,
} from "react-icons/fi";
import { AiOutlineGithub, AiOutlineHourglass } from "react-icons/ai";
import { HiOutlineDocumentMagnifyingGlass } from "react-icons/hi2";
import { MdContentCopy } from "react-icons/md";
import { styles } from "./topBarStyle";
import { message } from "antd";
import moment from "moment";

const TopBar = ({ history, location, candidateData }) => {
  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(e);
    message.success("Copied to clipboard");
  };

  return (
    <div style={styles.mostOuterWrap}>
      <div style={styles.leftWrapper}>
        <div
          style={styles.backArrowStyle}
          onClick={() => {
            let splitArray = location.pathname.split("/");
            history.push({
              pathname: location.state
                ? location.state.path
                : `/candidate/${splitArray[splitArray.length - 2]}/trainings`,
            });
          }}
        >
          <FiArrowLeft style={{ fontSize: "14px" }} />
        </div>
        <div style={styles.userIconWrapperStyle}>
          <FiUser style={{ fontSize: "18px" }} />
        </div>
        <div style={styles.infoWrapper}>
          <div style={styles.nameStyle}>{candidateData?.name}</div>
          <div style={styles.emailStyle}>{candidateData?.email}</div>
        </div>
      </div>

      <div style={styles.rightWrapper}>
        <div style={styles.outerSubDivWrapper}>
          <div style={styles.topDivWrapper}>
            <div>
              <AiOutlineHourglass style={styles.statusIconStyle} />
            </div>
            <div style={styles.downDivStyle}>
              <div style={styles.statusStyle}>Status</div>
              <div style={styles.confirmWrapper}>
                <div>
                  <FiCheck />
                </div>
                <div>{candidateData?.status}</div>
              </div>
            </div>
          </div>
          <div style={styles.topDivWrapper}>
            <div>
              <FiCalendar style={styles.lableStyle} />
            </div>
            <div style={styles.downDivWrapperStyle}>
              <div style={styles.lableStyle}>Enrolment Date</div>
              <div style={styles.heighlightLableStyle}>
                {moment(candidateData?.enrollmentDate).format("MM-DD-YYYY")}
              </div>
            </div>
          </div>
        </div>

        <div style={styles.outerSubDivWrapper}>
          <div style={styles.topDivWrapper}>
            <div>
              <HiOutlineDocumentMagnifyingGlass
                style={styles.statusIconStyle}
              />
            </div>
            <div style={styles.downDivStyle}>
              <div style={styles.statusStyle}>Screener</div>
              <div style={styles.heighlightLableStyle}>Not available</div>
            </div>
          </div>
          <div style={styles.topDivWrapper}>
            <div>
              <FiBriefcase style={styles.lableStyle} />
            </div>
            <div style={styles.downDivWrapperStyle}>
              <div style={styles.lableStyle}>Recruiter</div>
              <div style={styles.heighlightLableStyle}>
                {candidateData?.recruiter ? (
                  candidateData?.recruiter
                ) : (
                  <div style={styles.heighlightLableStyle}>Not available</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div style={styles.outerSubDivWrapper}>
          <div style={styles.topDivWrapper}>
            <div>
              <FiBox style={styles.statusIconStyle} />
            </div>
            <div style={styles.downDivStyle}>
              <div style={styles.statusStyle}>Module</div>
              <div style={styles.heighlightLableStyle}>
                {candidateData?.module ? (
                  candidateData?.module
                ) : (
                  <div style={styles.heighlightLableStyle}>Not available</div>
                )}
              </div>
            </div>
          </div>
          <div style={styles.topDivWrapper}>
            <div>
              <AiOutlineGithub style={styles.lableStyle} />
            </div>
            <div style={styles.downDivWrapperStyle}>
              <div style={styles.lableStyle}>Github link</div>
              <div
                style={{
                  ...styles.heighlightLableStyle,
                  ...styles.githubStyle,
                }}
              >
                {candidateData?.link ? (
                  candidateData?.link
                ) : (
                  <div style={styles.heighlightLableStyle}>Not available</div>
                )}
                <MdContentCopy
                  style={{ cursor: "pointer" }}
                  onClick={() => copyToClipboard(candidateData?.link)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
