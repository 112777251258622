import React, { useEffect } from "react";
import { Button, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userSignIn } from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import firebase from "firebase";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const FormItem = Form.Item;

const URL = "https://cdn.hipwallpaper.com/i/25/98/M36PXp.jpg";

const SignIn = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => auth.token);
  const messaging = firebase.messaging();
  let fcm_token;

  if (Notification.permission === "granted") {
    messaging.getToken().then((t) => {
      fcm_token = t;
    });
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          return messaging.getToken();
        } else return null;
      })
      .then((token) => {
        fcm_token = token;
      })
      .catch((error) => {
        if (error.code === "messaging/permission-blocked") {
          message.warning(
            "You have blocked the permission for push notifications"
          );
          fcm_token = null;
        }
      });
  } else {
    fcm_token = null;
  }

  const handleSubmit = (values) => {
    dispatch(userSignIn(values, fcm_token));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (token !== null) {
      props.history.push("/");
    }
  }, [token, props.history]);

  return (
    <div
      className="gx-app-login-wrap"
      style={{
        backgroundImage: URL,
      }}
    >
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img
                src={require("assets/images/signin-image.jpeg")}
                // src="http://hdwpro.com/wp-content/uploads/2019/01/Free-Nature-4K.jpg"
                alt="Nature"
              />
            </div>
            <div className="gx-app-logo-wid"></div>
            <div className="gx-app-logo">
              <img
                alt="example"
                src={require("assets/images/logo-white.png")}
              />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              name="basic"
              onFinish={handleSubmit}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item rules={[{ required: true }]} name="employeeId">
                <Input placeholder="Employee Id" className="loginInput" />
              </Form.Item>
              <Form.Item
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
                name="password"
              >
                <Input.Password
                  type="password"
                  placeholder="Password"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  className="loginInput"
                />
              </Form.Item>
              <FormItem name="remember">
                <Link className="gx-login-form-forgot" to="/forgot-password">
                  Forgot password
                </Link>
              </FormItem>
              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
              </FormItem>
            </Form>
          </div>
          <InfoView />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
