import React, {useEffect, useState} from "react";
import {Button, Form, message, Input} from "antd";
import axiosJSON from "../../util/SetHeaderAPI";

const FormItem = Form.Item;

const Addreferral = (props) => {
  const [referral, setReferral] = useState(props.referral);

  useEffect(() => {
    let mounted=true
    if(mounted) {

    }
    return function cleanup() {
      mounted = false
    }
  },[])

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = () => {
    const body = {
      'referred_by': referral,
    }
    axiosJSON.put('api/candidate/' + props.id + '/', body)
      .then(({data, status}) => {
        if (status === 202) {
          message.success(data.message)
          props.closeModal()

        }
      }).catch(function (error) {
      message.error(error.message)
    })

  };


  return (
    <div className="gx-login-content">
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="gx-signin-form gx-form-row0">
        <FormItem name="referred_by">
          <Input
            defaultValue={referral}
            placeholder="Referred By"
            onChange={(e)=>setReferral(e.target.value)}
            value={referral}
          />
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </FormItem>
      </Form>

    </div>
  );
};

export default Addreferral;
