import React from "react";
import {Button, Checkbox, Drawer, message, Select, Spin} from "antd";
import AppModuleHeader from "../../components/AppModuleHeader";
import axiosJSON from "../../util/SetHeaderAPI";
import CustomScrollbars from "util/CustomScrollbars";
import IntlMessages from "util/IntlMessages";
import JobsList from './list';
import AddJob from "../../modals/Jobs/addJobs";
import CircularProgress from "../../components/CircularProgress";
import "nprogress/nprogress.css";
import nprogress from "nprogress";
import {CustomModal as Modal} from "../../util/CustomModal"

const sortList = [{
  'key': 0,
  'value': 'title',
  'label': 'Title'
}
];

class Jobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerState: false,
      user: {
        name: '',
        email: '',
      },
      loader: false,
      total_pages: 0,
      jobsList: [],
      showAddJob: false,
      showJob: false,
      jobData: {},
      query: '',
      openFilterOption: [false],
      currentJobList: null,
      sortObj: '',
      filters: {
        'status': ['draft', 'published', 'closed'],
        'position': [],
        'portal': [],
        'shared': ['Shared', 'Private']
      },
      filterObj: {},
      filterList: [
        {
          'name': 'Status',
          'value': 'status'
        },
        {
          'name': 'Position',
          'value': 'position'
        },
        {
          'name': 'Portal',
          'value': 'portal'
        },
        {
          'name': 'Visibility',
          'value': 'shared'
        }
      ],
      portals_list: [],
      positions_list: [],
      prevY: 0,
      page: 1
    }
    this.Mounted = false
  }

  toggleFilterOption = (i) => {
    let openFilterOption = this.state.openFilterOption;
    openFilterOption[i] = !this.state.openFilterOption[i];
    this.setState({
      openFilterOption
    })
  }

  filterMenu = (filter) => {
    var filterList = Object.keys(this.state.filters);
    var options;
    filterList.map((ele, i) => {
      if (ele === filter) {
        if (filter !== "portal" && filter !== "position") {
          options =
            this.state.filters[ele].map((filterObj, j) =>
              <div key={j}>
                <Checkbox
                  checked={this.state.filterObj.hasOwnProperty(filter) && (
                    filter !== "shared" ?
                      this.state.filterObj[filter].includes(filterObj) :
                      filterObj === "Shared" ?
                        this.state.filterObj[filter] : !this.state.filterObj[filter])}
                  onChange={(e) => this.selectFilter(e.target.checked, filterObj, filter)}/>{filterObj}</div>
            )
        } else if (filter === "portal") {
          options =
            this.state.filters[ele].map((filterObj, j) =>
              <div key={j}>
                <Checkbox
                  checked={this.state.filterObj.hasOwnProperty(filter) && this.state.filterObj[filter].includes(filterObj.id)}
                  onChange={(e) => this.selectFilter(e.target.checked, filterObj.id, filter)}/>{filterObj.name}</div>
            )
        } else if (filter === "position") {
          options =
            this.state.filters[ele].map((filterObj, j) =>
              <div key={j}>
                <Checkbox
                  checked={this.state.filterObj.hasOwnProperty(filter) && this.state.filterObj[filter].includes(filterObj.id)}
                  onChange={(e) => this.selectFilter(e.target.checked, filterObj.id, filter)}/>{filterObj.display_name}
              </div>
            )
        }
      }
      return null
    })
    return options
  };

  selectFilter = (checked, obj, type) => {
    let filterObj = this.state.filterObj;
    let o = this.state.filterObj;
    if (checked) {
      if (o.hasOwnProperty(type)) {
        if (type === "status") {
          o[type] = obj;
        } else if (type === "shared") {
          if (obj === "Shared") {
            o['shared'] = "yes"
          } else {
            o['shared'] = "no"
          }
        } else {
          o[type].push(obj);
        }
      } else {
        if (type === "shared" && obj === "Shared") {
          o['shared'] = "yes"
          filterObj = (o)
        } else if (type === "shared" && obj === "Private") {
          o['shared'] = "no"
          filterObj = (o)
        } else if (type === "status") {
          o[type] = obj
        } else {
          o[type] = [obj]
          filterObj = (o)
        }
      }
    } else {
      if (o.hasOwnProperty(type) && (typeof (o[type]) === "string" || typeof (o[type]) === "boolean")) {
        delete o[type]
      } else if (o.hasOwnProperty(type)) {
        var index = o[type].indexOf(obj);
        if (index !== -1) o[type].splice(index, 1);
        if (o[type].length === 0) delete o[type]
      }
    }

    this.setState({
        filterObj: filterObj,
        jobsList: [],
        page: 1,
        total_pages: 0
      }, () =>
        this.updateCallback(this.state.page,[])
    )
  }

  onToggleDrawer() {
    this.setState({
      drawerState: !this.state.drawerState
    });
  }

  updateSearch(evt) {
    evt.preventDefault()
    this.setState({
      query: evt.target.value,
      jobsList: [],
      page: 1,
      total_pages: 0
    }, () => this.updateCallback(this.state.page,[]));
  }


  handleclose = () => {
    this.setState({
      showAddJob: false,
      showJob: false,
      jobsList: [],
      page: 1,
      total_pages: 0
    }, () => this.updateCallback(1,[]))
  }

  selectSortBy = (value) => {
    this.setState({
        sortObj: value,
        jobsList: [],
        page: 1,
        total_pages: 0
      }, () =>
        this.updateCallback(this.state.page,[])
    )
  }

  componentWillUnmount() {
    this.Mounted = false
  }

  getPortals = () => {
    let portal_list = []
    axiosJSON.get('api/portal/')
      .then(({data, status}) => {
        if (status === 200) {
          data.data.map((portal) => {
            portal_list.push(portal)
            return portal_list
          })
          this.setState(prevState => {
            let filters = Object.assign({}, prevState.filters);
            filters.portal = portal_list;
            return {filters};
          })
          this.setState({portals_list: data.data})
        }
      }).catch(function (error) {
      message.error(error.message)
    })
  }
  getPosition = () => {
    let position_list = []
    axiosJSON.get('api/choice/?type=position')
      .then(({data, status}) => {
        if (status === 200) {
          data.data.map((position) => {
            position_list.push(position)
            return position_list
          })
          this.setState(prevState => {
            let filters = Object.assign({}, prevState.filters);
            filters.position = position_list;
            return {filters};
          })
          this.setState({positions_list: data.data})
        }
      }).catch(function (error) {
      message.error(error.message)
    })
  }


  componentDidMount() {
    nprogress.start()
    this.Mounted = true
    this.getJobList(this.state.page)
    this.getPortals()
    this.getPosition()
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };
    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    this.observer.observe(this.loadingRef);
    nprogress.done()
  }

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    let t = (parseInt(this.state.total_pages) / 10)
    if (Math.ceil(t) >= this.state.page && this.state.prevY > y) {
      const curPage = this.state.page + 1;
      this.getJobList(curPage);
      this.setState({page: curPage});
    }
    this.setState({prevY: y});
  }

  getJobList = (page) => {
    this.updateCallback(page,this.state.jobsList)
  }

  selectJob = (id) => {
    axiosJSON.get('api/job/' + id + '/')
      .then(({data, status}) => {
        if (status === 200) {
          this.setState({jobData: data.data[0]})
          this.setState({showJob: true})
        }
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  updateCallback = (page,arr) => {
    this.setState({loader: true})
    axiosJSON.get('/api/job/?page=' + page + '&query=' + this.state.query + '&filter_json=' + JSON.stringify(this.state.filterObj) + '&sort_by=' + this.state.sortObj)
      .then(({data, status}) => {
        if (status === 200) {
          this.setState({})
          let jobsList = arr;
          let jobsObj = {};

          data.data.map((ele, i) => {
            jobsObj = {
              'id': ele.id,
              'title': ele.title,
              'email': ele.email,
              'portal': ele.portal__name,
              'position': ele.position__display_name,
              'location': ele.location,
              'status': ele.status,
              'created_by': ele.created_by__name,
              'selected': false,
            }
            if (ele.is_shared) {
              jobsObj['visibility'] = 'Shared';
            } else
              jobsObj['visibility'] = 'Private';
            jobsList.push(jobsObj);
            return null
          })
          this.setState({
            total_pages: data.total,
            jobsList: jobsList,
            loader: false
          })
        }
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  ToDoSideBar = () => {
    return <div className="gx-module-side">
      <div className="gx-module-side-header">
        <div className="gx-modul-logo">
          <i className="icon icon-card gx-mr-4"/>
          <IntlMessages id="sidebar.jobs"/>
        </div>
      </div>
      <div className="gx-module-side-content">
        <CustomScrollbars className="">
          <div className="gx-module-add-task">
            <Button variant="raised" type="primary" onClick={() => this.setState({showAddJob: true})}
                    className="gx-btn-block"> Add Portal Job</Button>
          </div>
          <ul className="gx-module-nav new-side-nav">
            <li className="gx-module-nav-label">
              <IntlMessages id="candidate.filters"/>
              {this.state.filterList.map((filter, i) =>
                <div className="sidebar_new" key={i}>
                  {filter.name}
                  {this.state.openFilterOption[i] ?
                    <i className="icon icon-menu-up gx-pointer" onClick={() => this.toggleFilterOption(i)}/> :
                    <i className="icon icon-menu-down gx-pointer" onClick={() => this.toggleFilterOption(i)}/>}
                  {this.state.openFilterOption[i] && <div>
                    {this.filterMenu(filter.value)}
                  </div>
                  }
                </div>
              )}

            </li>

          </ul>
        </CustomScrollbars>
      </div>
    </div>
  }

  render() {
    const {jobsList, loader, drawerState} = this.state;
    const loadingCSS = { };
    const loadingTextCSS = {display: this.state.loading ? "block" : "none"};
    return (
      <div className="gx-main-content">
        <div className="gx-app-module">
          <div className="gx-d-block gx-d-lg-none">
            <Drawer
              placement="left"
              closable={false}
              visible={drawerState}
              onClose={this.onToggleDrawer.bind(this)}>
              {this.ToDoSideBar()}
            </Drawer>
          </div>
          <div className="gx-module-sidenav gx-d-none gx-d-lg-flex">
            {this.ToDoSideBar()}
          </div>
          <div className="gx-module-box">
            <div className="gx-module-box-header">
                            <span className="gx-drawer-btn gx-d-flex gx-d-lg-none">
                                <i className="icon icon-menu gx-icon-btn" aria-label="Menu"
                                   onClick={this.onToggleDrawer.bind(this)}/>
                            </span>
              <AppModuleHeader placeholder="Search Jobs" onChange={this.updateSearch.bind(this)}
                               value={this.state.query}/>
            </div>
            <div className="gx-module-box-content">
              {this.state.currentJobList === null ?
                <div className="gx-module-box-topbar gx-module-box-topbar-todo">
                  <Select
                    allowClear={true}
                    style={{width: '15%'}}
                    className="right-button"
                    placeholder="Sort by"
                    onChange={this.selectSortBy}
                  >
                    {sortList.map((user, i) => <Select.Option key={i} value={user.value}>{user.label}</Select.Option>)}
                  </Select>
                </div>
                :
                <div className="gx-module-box-topbar">
                  <i className="icon icon-arrow-left gx-icon-btn" onClick={() => {
                    this.setState({currentJobList: null})
                  }}/>
                </div>
              }
              {loader ? <CircularProgress/> :
                <JobsList jobsList={jobsList} selectJob={this.selectJob} useDragHandle={true}/>
              }
              <div ref={loadingRef => (this.loadingRef = loadingRef)} style={loadingCSS}/>
              {
                jobsList.length > 0 ?
                  <span style={loadingTextCSS}><Spin/>Loading...</span>
                  : <span style={{textAlign: 'center'}}>No records found.</span>
              }
            </div>
          </div>
        </div>
        {this.state.showAddJob && <Modal
          title="Create Portal Job"
          visible={this.state.showAddJob}
          footer={null}
          onCancel={() => this.setState({showAddJob: false})}>
          <AddJob show={false} closeModal={this.handleclose}/>
        </Modal>}
        {this.state.showJob && <Modal
          title="Job Details"
          visible={this.state.showJob}
          footer={null}
          onCancel={() => this.setState({showJob: false})}>
          <AddJob show={true} data={this.state.jobData} closeModal={this.handleclose}/>
        </Modal>}
      </div>
    );
  }
}

export default Jobs;
