import React, { Component } from "react";
import { Route, Switch as RouteSwitch } from "react-router-dom";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Button, Modal } from "antd";
import Team from "../RecruitmentReport/Team";
import Recruiter from "../RecruitmentReport/Recruiter";
import RecruiterDetails from "../../../modals/RecruitmentReports/recruiterDetails";

class RecruitmentReport extends Component {
  handleModalClose = (match) => {
    this.props.history.push(match.url + "/recruiter");
  };

  render() {
    let match = this.props.match;
    return (
      <div className="col-12 p-0 recruiter_page">
        <div className="row m-0">
          <div className="col-12 mb-2 p-0 label_color">
            Show Data Accoding to
          </div>
          <div className="mb-3 col-12 p-0 recruitmentButtons">
            <Button
              className="teamButton"
              type={
                this.props.location.pathname?.includes("recruiter")
                  ? "default"
                  : "primary"
              }
            >
              <Link to={match.url}> Team </Link>
            </Button>
            <Button
              className="recruterButton"
              type={
                this.props.location.pathname?.includes("recruiter")
                  ? "primary"
                  : "default"
              }
            >
              <Link to={match.url + "/recruiter"}> Recruiter </Link>
            </Button>
          </div>
          <div className="width-wrapper">
            <RouteSwitch>
              <Route exact path={this.props.match.url + "/recruiter"}>
                <Recruiter />
              </Route>
              <Route exact path={this.props.match.url}>
                <Team />
              </Route>
              <Route
                exact
                path={this.props.location.pathName}
                children={({ match }) => {
                  return (
                    <Modal
                      title="Candidate Details"
                      className="candidate-details mod-w"
                      visible={true}
                      footer={null}
                      onCancel={() => this.handleModalClose(match)}
                    >
                      <RecruiterDetails />
                    </Modal>
                  );
                }}
              />
            </RouteSwitch>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(RecruitmentReport);
