import React,{useEffect,useState} from "react";
import {Button} from "antd";
import FileViewer from 'react-file-viewer';
import axiosJSON from "../../../../../util/SetHeaderAPI";
import AddResume from "../../../../../modals/Candidate/addResume";
import UploadResumeFileViewer from "../../../../../modals/Candidate/uploadResumeFileViewer";
import {CustomModal as Modal} from "../../../../../util/CustomModal"

const Resume = (props) => {
  let onError;
  onError = (e) => {
    setResume(false)
  }
  const [showMore , setShow] = useState(false);
  const[filepath, setFilePath] = useState("")
  const[filetype, setFileType] = useState("")
  const[candidateData, setCandidateData] = useState("")
  const [resumeLoader, setResumeLoader] = useState(false);
  const [resumeViewer, setResumeViewer] = useState(false)
  const[resume, setResume] = useState(false)

  const headers = {
    "Access-Control-Allow-Origin": "*" }

  const fetchResume = ()=>
  {
    axiosJSON.get('api/candidate/'+props.id+'/resume/',{headers : headers})
        .then(res => {
           const body = res.data.data[0];
           if(body){
            setFilePath(body.file);
            setFileType(body.file_ext);
            setResume(true)
           }
        })
  }
const handleShow=(flag)=>{
  setShow(!flag);
}

const changeResumeLoader=(flag)=>{
  setResumeLoader(flag)
}
const handleClose=()=>{
  fetchResume()
  setShow(false);
  setResumeViewer(false);
}

const setResumeData=(data)=>{
 setCandidateData(data)
  setResumeViewer(true);
}

useEffect(() => {
    if(filepath === "" && filetype === "" && !resume){
      fetchResume();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div key="resume">
      <Button onClick={()=>handleShow(showMore)}> Upload Resume </Button>
      { resume ?
      <FileViewer
        fileType={filetype}
        filePath={filepath}
        errorComponent={<div>Error</div>}
        onError={onError}
      />:
      <div>
      <h2>
        No Resume Present !!
      </h2>
      </div>}
     {showMore && <Modal
          title={null}
          className="upload-popup"
          visible={showMore}
          footer={null}
          onCancel={() => setShow(false)}>
          <AddResume id={props.id} resumeLoader={resumeLoader} setResumeData={setResumeData} changeResumeLoader={changeResumeLoader} closeModal={handleClose}/>
        </Modal>}
        {resumeViewer && <Modal
          title={null}
          className="upload-popup"
          closable={false}
          visible={resumeViewer}
          footer={null}
          onCancel={handleClose}>
          <UploadResumeFileViewer candidateData={candidateData} filePath={filepath} fileType={filetype} resume={resume} id={props.id}  closeModal={handleClose}/>
        </Modal>}
    </div>
  )
}

export default Resume;
