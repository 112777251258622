import React, { useState, useCallback, useRef, useEffect } from "react";
import { Avatar, Button, Form, Input, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import IntlMessages from "util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import axiosJSON from "../util/SetHeaderAPI";
import { myDetails } from "../appRedux/actions";
import { CustomModal as Modal } from "../util/CustomModal";

const pixelRatio = 4;

const FormItem = Form.Item;

const EditForm = ({
  onChange,
  fields,
  onFinish,
  onFinishFailed,
  avatarUpload,
}) => (
  <Form
    name="basic"
    fields={fields}
    onFieldsChange={(changedFields, allFields) => {
      onChange(allFields);
    }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    className="gx-signin-form gx-form-row0"
  >
    <FormItem
      rules={[{ required: true, message: "Please input your Name!" }]}
      name="name"
    >
      <Input className="gx-input-lineheight" type="text" placeholder="Name" />
    </FormItem>
    <FormItem name="phone">
      <Input
        className="gx-input-lineheight"
        type="text"
        placeholder="Phone Number"
      />
    </FormItem>
    <FormItem>
      <Button type="primary" htmlType="submit">
        <IntlMessages id="app.userAuth.submit" />
      </Button>
    </FormItem>
  </Form>
);

function getResizedCanvas(canvas, newWidth, newHeight) {
  const tmpCanvas = document.createElement("canvas");
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext("2d");
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  return tmpCanvas;
}

const EditUserProfile = (props) => {
  const user = useSelector(({ auth }) => auth.authUser);
  const [avatar, setAvatar] = useState(user.avatar);
  const [fields, setFields] = useState([
    {
      name: ["name"],
      value: user.name,
    },
    {
      name: ["email"],
      value: user.email,
    },
    {
      name: ["phone"],
      value: user.phone,
    },
    {
      name: ["employeeId"],
      value: user.employee_id,
    },
    {
      name: ["team"],
      value: user.team,
    },
  ]);
  const dispatch = useDispatch();
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 16 / 9 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [cropFlag, setCropFlag] = useState(false);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setCropFlag(true);
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const cropModal = () => {
    setCropFlag(false);
  };

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const generateDownload = (previewCanvas, crop) => {
    if (!crop || !previewCanvas) {
      return;
    }

    const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);
    canvas.toBlob(
      (blob) => {
        var file = new File([blob], user.name + "_avatar_" + Date.now(), {
          type: "image/jpeg",
          lastModified: Date.now(),
        });
        let formData = new FormData();
        formData.append("avatar", file);
        axiosJSON
          .put("api/user/profile/", formData)
          .then(({ data, status }) => {
            if (status === 202) {
              let token = localStorage.getItem("token");
              dispatch(myDetails(token));
              setAvatar(data.data.avatar);
              message.success(data.message);
              cropModal();
            }
          })
          .catch(function (error) {
            if (error.status === 400) {
              message.error(error.message);
            } else {
              message.error(error.message);
            }
          });
      },
      "image/png",
      1
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    axiosJSON
      .put("api/user/profile/", values)
      .then(({ data, status }) => {
        if (status === 202) {
          let token = localStorage.getItem("token");
          dispatch(myDetails(token));
          message.success(data.message);
          props.closeModal();
        }
      })
      .catch(function (error) {
        if (error.status === 400) {
          message.error(error.message);
        } else {
          message.error(error.message);
        }
      });
  };

  return (
    <div className="gx-login-container">
      <div style={{ padding: "20px" }} className="gx-login-content">
        <div className="gx-login-header">
          <img
            style={{ width: "30%", height: "30%" }}
            src={require("assets/images/logo.png")}
            alt="beats"
            title="beats"
          />
        </div>

        <div className="gx-mb-4">
          <h2> Edit Profile </h2>
          <p>
            <IntlMessages id="appModule.editProfile" />
          </p>
        </div>
        <div className="mainprofile">
          <div className="editprofile">
            <div className="imgtext">
              <Avatar
                src={avatar === null ? "" : avatar}
                className="gx-size-100 gx-pointer"
                alt=""
              />
            </div>

            <label>
              <PlusCircleOutlined />
              <input
                accept="image/*"
                type="file"
                onChange={onSelectFile}
                style={{ visibility: "hidden" }}
              />
            </label>
          </div>
        </div>
        <Modal visible={cropFlag} footer={null} closable={false}>
          <ReactCrop
            src={upImg}
            className="lest_img"
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
          />
          <div className="right_img">
            <canvas
              ref={previewCanvasRef}
              style={{
                width: completedCrop?.width ?? 0,
                height: completedCrop?.height ?? 0,
              }}
            />
          </div>
          <br clear="all" />

          <div className="popupbutton">
            <Button
              type="primary"
              onClick={() =>
                generateDownload(previewCanvasRef.current, completedCrop)
              }
            >
              Change Avatar
            </Button>
            <Button onClick={cropModal}>Cancel</Button>
          </div>
          <br clear="all" />
        </Modal>
        <EditForm
          fields={fields}
          onChange={(newFields) => {
            setFields(newFields);
          }}
          onFinishFailed={onFinishFailed}
          onFinish={onFinish}
        />
      </div>
    </div>
  );
};

export default EditUserProfile;
