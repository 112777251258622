import React, {Component} from "react";
import axiosJSON from "../../../../../util/SetHeaderAPI";
import {Alert, Button, Checkbox, Input, message, Select, Spin,} from "antd";
import SlateEmailEditor from "../../../../../components/EmailTemplateEditor";
import { Col, Row } from "antd";
import {deserialize, serialize} from "../../../../../components/Editor/parseJson";
import {connect} from "react-redux";
import { withRouter } from "react-router";
import "nprogress/nprogress.css";
import nprogress from "nprogress";


const beforeMatch = /{(\w+)$/
const beforeStr = '{{';
const afterStr = '}}';

class CreateEmailTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholdersList: [],
      template_name: '',
      template_type: '',
      is_private: false,
      template_type_list: [
        {'id': 1, 'name': 'Rejection', 'display_name': 'Rejection'},
        {'id': 2, 'name': 'Offer', 'display_name': 'Offer'},
        {'id': 3, 'name': 'Bulk', 'display_name': 'Bulk Email'},
      ],
      subject: '',
      email_body: [
        {
          type: 'paragraph',
          children: [
            {text: ''},
          ],
        },

      ],
      selected_placeholders: [],
      cc: [],
      bcc: [],
      email_list: [
        {id: 1, value: 'recruiment@consultadd.com'},
        {id: 2, value: 'hr@consultadd.com'},
      ],
      about: 'Placeholders will be replaced before the email is sent. They\'re useful when sending emails in bulk.\n' +
        '\n' +
        '            Type {{ to see the list of placeholders.',
      checkFlag: {},
      user: {},
      flag: false,
      template_id:0,
      parse_email_body:''
    }
    this.Mounted=false
  }

  componentWillUnmount() {
    this.Mounted = false
  }

  componentDidMount() {
    nprogress.start()
    this.Mounted = true
    let flag = this.props.history.location.search.split("&");
    let is_private = flag[1].split("=")[1] === "yes";
    if (flag.length > 2) {
      let template_id=flag[2].split("=")[1]
      axiosJSON.get('/api/email_template/' + template_id)
        .then(({data, status}) => {
          if (this.props.auth.employee_id === data.data.modified_by.employee_id) {
            this.setState({
              editFlag: true
            })
          }
          this.setState({
            template_id:template_id,
            template_name: data.data.name,
            template_type: data.data.email_type,
            cc: data.data.cc,
            bcc: data.data.bcc,
            is_private: data.data.is_private,
            subject: data.data.subject,
            user: data.data.modified_by.employee_id,
            email_body: deserialize(new DOMParser().parseFromString(data.data.body, 'text/html').body),
            parse_email_body:data.data.body,
            selected_placeholders: data.data.placeholders,
            flag: true
          })
        })
        .catch(err => {
          message.error(err.message)
        })
    } else {
      this.setState({
        editFlag: true,
        is_private: is_private,
        flag: true
      })
    }
    this.getPlaceholders()
    nprogress.done()
  }

  getPlaceholders = () => {
    axiosJSON.get('/api/placeholder/')
      .then(({data, status}) => {
        this.setState({placeholdersList: data.data})
      })
      .catch(err => {
        message.error(err.message)
      })
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  changeType = (key, value) => {
    this.setState({
      [key]: value
    })
  }
  handleBodyChange = e => {
    this.setState({
      email_body: e,
    });
  };
  onSelectPlaceholder = value => {
    let user = this.state.selected_placeholders
    user.push(value)
    this.setState({
      selected_placeholders: user
    })
  }
  setEmails = (e, type, value) => {
    let arr = this.state[type]
    if (e.target.checked) {
      arr.push(value)
    } else {
      var index = this.state[type].indexOf(value)
      arr.splice(index, 1)
    }
    this.setState({
      [type]: arr
    })
  }
  onSubmit = () => {
    let checkFlag = {template_name: false, subject: false, template_type: false, email_body: false};
    let keys = Object.keys(checkFlag);
    keys.map((k) => {
      if (this.state[k] === "") {
        checkFlag[k] = true
      }
      return checkFlag
    })
    this.setState({
      checkFlag: checkFlag
    })
    var flag = Object.values(checkFlag).every(k => k === false)
    if (flag) {
      let plchldr = []
      this.state.selected_placeholders.map((user) => {
        let i = this.state.placeholdersList.find((u) => u.name === user.name)
        plchldr.push(i.id)
        return null
      })
      const body = {
        "name": this.state.template_name,
        "body": serialize(this.state.email_body),
        "is_private": this.state.is_private,
        "subject": this.state.subject,
        "cc": this.state.cc,
        "bcc": this.state.bcc,
        "email_type": this.state.template_type,
        "placeholders": plchldr
      }
      if(this.state.template_id >0){
        axiosJSON.put('/api/email_template/'+this.state.template_id+"/", body)
          .then(({data, status}) => {
            message.success(data.message)
            this.props.history.goBack()
          })
          .catch(err => {
            message.error(err.message)
          })
      }else{
        axiosJSON.post('/api/email_template/', body)
          .then(({data, status}) => {
            message.success(data.message)
            this.props.history.goBack()
          })
          .catch(err => {
            message.error(err.message)
          })
      }

    } else {
      message.error("Please fill the required fields")
    }
  }

  render() {
    const {template_name, template_type, template_type_list, subject, placeholdersList, about, flag} = this.state
    return (
      flag ?
        <div className="template-page">

          <Row>

            <Col xl={16} lg={14} md={14} sm={24} xs={24}>

              <div className="tem-left-section">
                <Row>

                    <Col xl={12} lg={14} md={14} sm={24} xs={24}>

                      <div>
                          Name{"\t"}<span className="gx-text-grey gx-fs-md right-required">(Required)</span>
                          <Input disabled={!this.state.editFlag} value={template_name} name="template_name"
                          onChange={this.handleChange}/>
                          {this.state.checkFlag["template_name"] ?
                          <span className="gx-text-red gx-fs-md">Name cannot be empty</span> : null}
                      </div>

                      <div className="gx-mt-3">
                          Email Subject{"\t"}<span className="gx-text-grey gx-fs-md right-required">(Required)</span>

                          <Input disabled={!this.state.editFlag} value={subject} name="subject" onChange={this.handleChange}/>
                          {this.state.checkFlag["subject"] ?
                          <span className="gx-text-red gx-fs-md">Subject cannot be empty</span> : null}
                      </div>

                      <div className="gx-mt-3">
                          cc:
                          {
                            this.state.email_list.map((email, i) =>
                              <div key={i}>
                                <Checkbox disabled={!this.state.editFlag} checked={this.state['cc'].includes(email.value)}
                                          onChange={(e) => this.setEmails(e, 'cc', email.value)}/> {email.value}
                              </div>
                            )
                          }
                      </div>

                      <div className="gx-mt-3">
                        bcc:
                        {
                          this.state.email_list.map((email, i) =>
                            <div key={i}>
                              <Checkbox disabled={!this.state.editFlag} checked={this.state['bcc'].includes(email.value)}
                                        onChange={(e) => this.setEmails(e, 'bcc', email.value)}/> {email.value}
                            </div>
                          )
                        }
                      </div>

                    </Col>

                    <Col xl={12} lg={14} md={14} sm={24} xs={24}>
                          <div>
                              Type{"\t"}<span className="gx-text-grey gx-fs-md right-required">(Required)</span>
                              <Select
                                disabled={!this.state.editFlag}
                                style={{width: '100%',marginTop:'5px'}}
                                showSearch
                                value={template_type}
                                name="template_type"
                                onChange={(value) => this.changeType("template_type", value)}>

                                {template_type_list.map((type) => <Select.Option key={type.id} value={type.name}>
                                    {type.display_name}
                                  </Select.Option>
                                )}
                              </Select>
                              {this.state.checkFlag["template_type"] ?
                                <span className="gx-text-red gx-fs-md">Type cannot be empty</span> : null}
                          </div>
                     </Col>

                </Row>

              </div>



           <div className="gx-mt-3">
            Email Body {"\t"}<span className="gx-text-grey gx-fs-md right-required">(Required)</span>

            {this.state.editFlag ?
              <div  className="editeborder">
              <SlateEmailEditor beforeMatch={beforeMatch} afterStr={afterStr}
                                beforeStr={beforeStr}
                                onSelectPlaceholder={this.onSelectPlaceholder}
                                value={this.state.email_body}
                                setData={this.handleBodyChange} placeholdersList={placeholdersList}/>
                </div>
            :
              <p dangerouslySetInnerHTML={{__html:this.state.parse_email_body}}/>}
            {this.state.checkFlag["email_body"] ?
              <span className="gx-text-red gx-fs-md">Body cannot be empty</span> : null}

          </div>

            </Col>

            <Col xl={8} lg={14} md={14} sm={24} xs={24}>

               <div className="tem-right-section">
                  <div>
                    <div>
                      <Alert
                        message={<span> About placeholders</span>}
                        description={about}
                        type="info"
                        showIcon
                      />
                    </div>
                    <div className="email_placeholder">
                      <h4>Available placeholders</h4>
                      <div>
                        {placeholdersList.map((plchldr, i) =>
                          <div key={i}><span><strong>{plchldr.name + "\t"}</strong><span>{plchldr.field}</span></span><br/></div>
                        )}
                      </div>
                    </div>
                  </div>
              </div>

              <div className="submitbutton">
                <ul>
                  <li><Button type="secondary" onClick={() => this.props.history.goBack()}>Cancel</Button></li>
                  <li><Button type="primary" disabled={!this.state.editFlag} onClick={this.onSubmit}>Submit</Button></li>
                </ul>
               </div>

            </Col>

          </Row>

        </div>
        : <Spin tip={"Loading...."}/>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth.authUser,
});


const routeCreateEmailTemplate = withRouter(CreateEmailTemplate)

export default connect(
  mapStateToProps,
)(routeCreateEmailTemplate)
