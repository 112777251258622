import {
  Button,
  Divider,
  Input,
  message,
  Select,
  Spin,
  Upload,
  DatePicker,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import axiosJSON from "../../../../../util/Api";
import { styles } from "../../Forms/AssetInfoForm/assetsFormStyle";
import { styleSheet } from "./assetStyle";
import { PlusOutlined } from "@ant-design/icons";
import { BiCheckCircle, BiUpload } from "react-icons/bi";
import { CloseCircleOutlined } from "@ant-design/icons";

const star = (
  <span style={{ color: "#CC332D", fontSize: "15px", paddingRight: "3px" }}>
    *
  </span>
);

const Asset = ({ detailId, getActivity }) => {
  const inputRef = useRef(null);
  const [assetData, setAssetsData] = useState([]);
  const [owner, setOwner] = useState(null);
  const [edit, setEdit] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState();
  const [attachmentIds, setAttachmentIds] = useState([]);

  const getDeviceBrandWrapper = (brand, name) => {
    const brandDetails = brand.filter((brand_) => brand_.name === name);
    return brandDetails.length > 0
      ? {
          label: brandDetails[0].display_name,
          value: brandDetails[0].name,
          key: brandDetails[0].name,
        }
      : null;
  };

  const getAssetDetailsData = useCallback(
    (id) => {
      setLoading(true);
      axiosJSON
        .get("api/user_assets/" + id + "/")
        .then(({ data }) => {
          const asset = {
            deviceType: data.data.type.name,
            serialNumber: data.data.model,
            deviceBrand: getDeviceBrandWrapper(brandList, data.data.brand.name),
            assignDate: data.data.assigned_date,
            workingStatus: data.data.is_working,
            useStatus: data.data.in_use,
            comment: null,
            serialImage: null,
            deviceImage: [],
            deviceDisplayName: data.data.type.display_name,
            deviceBrandDisplayName: data.data.brand.display_name,
            isAssigned: data.data.is_assigned,
          };

          data.data.users.map((item) => {
            if (item.is_current === true) setOwner(item);
            return null;
          });

          if (data.data.attachments.length > 0) {
            const serilAttachments = data.data.attachments.filter(
              (img) => img.attachment_type !== "asset"
            );
            asset.serialImage =
              serilAttachments.length > 0 ? serilAttachments[0] : null;
            asset.deviceImage = [
              ...data.data.attachments.filter(
                (img) => img.attachment_type === "asset"
              ),
            ];
          }
          let newsetAttachmentIds = [];
          data.data.attachments.map((attachment) => {
            newsetAttachmentIds.push(attachment.id);
            return null;
          });
          setAttachmentIds(newsetAttachmentIds);
          setAssetsData(asset);
          setLoading(false);
        })
        .catch((error) => {
          message.error(error.message);
          setLoading(false);
        });
    },
    [brandList]
  );

  useEffect(() => {
    getAssetDetailsData(detailId);
  }, [detailId, getAssetDetailsData]);

  const getAssetList = useCallback(() => {
    getDeviceType();
    getDeviceBrand();
    //eslint-disable-next-line
  }, []);

  const getDeviceType = (key = "DT2zfLNz.kcOpgwFzb603w5HDJH9p1pVFZ7epzLmt") => {
    setLoading(true);
    axiosJSON
      .get(`/api/asset_form/type/?api_key=${key}`)
      .then(({ data }) => {
        setDeviceList(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.message);
      });
  };

  const getDeviceBrand = (
    key = "DT2zfLNz.kcOpgwFzb603w5HDJH9p1pVFZ7epzLmt"
  ) => {
    setLoading(true);
    axiosJSON
      .get(`api/asset_form/brand/?api_key=${key}`)
      .then(({ data }) => {
        setBrandList(data.data);
        setLoading(false);
      })
      .catch((error) => {
        message.error(error.message);
        setLoading(false);
      });
  };

  const addNewBrand = (e) => {
    e.preventDefault();
    let keyName = name
      .split(" ")
      .map((x) => x.toLowerCase())
      .join("_");
    if (keyName) {
      if (
        brandList.filter(
          (item) =>
            item.name === keyName ||
            item.display_name.toLowerCase() === name.toLowerCase()
        ).length > 0
      ) {
        message.error("Brand already Present");
      } else {
        setBrandList([...brandList, { name: keyName, display_name: name }]);
        setName("");
      }
    } else message.error("Please enter Name");
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const globalOnChnage = (name, value) => {
    let newFields = { ...assetData };
    if (name === "deviceImage") newFields[name].push(value);
    else newFields[name] = value;
    if (name === "useStatus" && value === false)
      newFields.workingStatus = value;
    setAssetsData(newFields);
  };

  const handleFileChange = (f) => {
    console.log(f);
  };

  useEffect(() => {
    getAssetDetailsData(detailId);
    //eslint-disable-next-line
  }, []);

  const idDataValid = (data) => {
    if (
      (data.isAssigned && data.assignDate === null) ||
      data.deviceBrand === null ||
      data.deviceImage.length < 1 ||
      data.deviceType === null ||
      (data.deviceType === "laptop" &&
        (data.serialImage === null || data.serialNumber === null)) ||
      data.useStatus === null ||
      data.workingStatus === null
    ) {
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    const formData = new FormData();
    setLoading(true);
    if (idDataValid(assetData)) {
      formData.append("asset_id", detailId);
      formData.append("device_type", assetData["deviceType"]);
      formData.append(
        "model",
        assetData["deviceType"] === "laptop" ? assetData["serialNumber"] : null
      );
      formData.append("in_use", assetData["useStatus"]);
      formData.append("is_working", assetData["workingStatus"]);
      formData.append(
        "assigned_date",
        moment(assetData["assignDate"]).format("YYYY-MM-DD")
      );
      formData.append("device_brand", JSON.stringify(assetData["deviceBrand"]));
      formData.append("attachment_ids", [attachmentIds]);
      if (assetData["serialImage"] && assetData["deviceType"] === "laptop") {
        if (assetData["serialImage"].id === undefined)
          formData.append(
            "model_image",
            assetData["serialImage"]["originFileObj"]
          );
      } else {
        formData.append("model_image", null);
      }

      if (assetData["deviceImage"] && assetData["deviceImage"].length > 0) {
        for (let index = 0; index < assetData["deviceImage"].length; index++) {
          if (assetData["deviceImage"][index]["originFileObj"] !== undefined) {
            formData.append(
              "image",
              assetData["deviceImage"][index]["originFileObj"]
            );
          }
        }
      }
    } else {
      setLoading(false);
      return message.error("Please enter all the mandatory fields");
    }
    if (owner) {
      formData.append("employee_id", owner.employee_id);
    }
    axiosJSON
      .put("api/user_assets/" + detailId + "/", formData)
      .then(({ data, status }) => {
        message.success(data.message);
        setLoading(false);
        setEdit(false);
        getAssetDetailsData(detailId);
        getActivity(detailId);
      })
      .catch(function (error) {
        message.error(error.message);
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <div style={styleSheet.boxStyle}>
        {/* assetdetails edit button */}
        <div style={styleSheet.bodyWrapper}>
          <div style={styleSheet.assetsDetailsText}>Asset Details</div>
          {edit ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <div
                style={{
                  ...styleSheet.editButtonStyle,
                  background: "#26305C",
                  color: "#FFFFFF",
                  cursor: "pointer",
                }}
                onClick={onSubmit}
              >
                Save Changes
              </div>
              <div
                style={styleSheet.editButtonStyle}
                onClick={() => {
                  setEdit(false);
                  getAssetDetailsData(detailId);
                }}
              >
                Cancel
              </div>
            </div>
          ) : (
            <div
              style={styleSheet.editButtonStyle}
              onClick={() => {
                getAssetList();
                setEdit(true);
              }}
            >
              Edit
            </div>
          )}
        </div>
        {/* assetinfo asset status */}
        <div style={styleSheet.statusWrapper}>
          <div style={styleSheet.statusInfoText}>Asset info</div>
          {/* need to add dynamic style */}
          {!assetData?.isAssigned ? (
            <div style={styleSheet.statusInfoButoon}>Return to office</div>
          ) : (
            <div
              style={{
                ...styleSheet.statusInfoButoon,
                color: "#0B6E40",
                background: "#D0F1E1",
                borderColor: "#0E9255",
              }}
            >
              Assigned
            </div>
          )}
        </div>
        {/* assetinfo details list */}
        <div style={styleSheet.assetInfoDetailsWrapper}>
          <div style={styleSheet.assetInfoDetailsWrapperInner}>
            <div style={styleSheet.titleTextStyle}>
              {edit && star}
              Asset Type{" "}
            </div>

            {edit ? (
              <div>
                <Select
                  placeholder="Device Type"
                  value={assetData.deviceType}
                  onChange={(value) => {
                    globalOnChnage("deviceType", value);
                  }}
                  style={styleSheet.commonStyle}
                >
                  {deviceList.map((device, i) => (
                    <Select.Option value={device.name} key={i}>
                      {device.display_name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            ) : (
              <div style={styleSheet.titleValueTextStyle}>
                {assetData?.deviceDisplayName}
              </div>
            )}
          </div>

          {assetData.deviceType === "laptop" && (
            <div style={styleSheet.assetInfoDetailsWrapperInner}>
              <div style={styleSheet.titleTextStyle}>
                {edit && star}
                Serial No.{" "}
              </div>
              {edit ? (
                <div>
                  {" "}
                  <Input
                    placeholder="Serial no."
                    style={styleSheet.commonStyle}
                    value={
                      assetData?.serialNumber === "null"
                        ? null
                        : assetData?.serialNumber
                    }
                    onChange={(e) =>
                      globalOnChnage("serialNumber", e.target.value)
                    }
                  />
                </div>
              ) : (
                <div style={styleSheet.titleValueTextStyle}>
                  {assetData?.serialNumber}
                </div>
              )}
            </div>
          )}

          <div style={styleSheet.assetInfoDetailsWrapperInner}>
            <div style={styleSheet.titleTextStyle}>
              {edit && star}
              Asset Brand{" "}
            </div>
            {edit ? (
              <div>
                <Select
                  placeholder="Asset Brand"
                  labelInValue
                  showSearch
                  value={assetData.deviceBrand}
                  style={styleSheet.commonStyle}
                  onChange={(value) => globalOnChnage("deviceBrand", value)}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider className="my-2" />
                      <div className="pb-3 px-2 w-100">
                        <Input
                          className="mr-2"
                          style={styles.fullWidth}
                          placeholder="Please enter item"
                          ref={inputRef}
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                        <Button
                          className="mb-0 pb-3 mt-2"
                          type="default"
                          icon={<PlusOutlined />}
                          onClick={addNewBrand}
                        >
                          Add
                        </Button>
                      </div>
                    </>
                  )}
                  options={brandList.map((item) => ({
                    label: item.display_name,
                    value: item.name,
                  }))}
                />
              </div>
            ) : (
              <div style={styleSheet.titleValueTextStyle}>
                {assetData?.deviceBrandDisplayName}
              </div>
            )}
          </div>
          {assetData?.isAssigned && (
            <div style={styleSheet.assetInfoDetailsWrapperInner}>
              <div style={styleSheet.titleTextStyle}>
                {edit && star}
                Assign Date{" "}
              </div>
              {edit ? (
                <div>
                  <DatePicker
                    placeholder="Select Date"
                    format={"MM-DD-YYYY"}
                    style={styleSheet.commonStyle}
                    value={
                      assetData?.assignDate
                        ? moment(assetData?.assignDate)
                        : null
                    }
                    disabledDate={(currentDate) => currentDate > Date.now()}
                    onChange={(value) => globalOnChnage("assignDate", value)}
                  />
                </div>
              ) : (
                <div style={styleSheet.titleValueTextStyle}>
                  {assetData && moment(assetData?.assignDate).format("ll")}{" "}
                </div>
              )}
            </div>
          )}
          {owner && (
            <>
              <div style={styleSheet.assetInfoDetailsWrapperInner}>
                <div style={styleSheet.titleTextStyle}>
                  {edit && star}
                  Owner{" "}
                </div>
                {edit ? (
                  <div>
                    <Input
                      placeholder="Employee Name"
                      style={styleSheet.commonStyle}
                      value={owner?.name}
                      disabled={true}
                    />
                  </div>
                ) : (
                  <div style={styleSheet.titleValueTextStyle}>
                    {owner?.name}
                  </div>
                )}
              </div>
              <div style={styleSheet.assetInfoDetailsWrapperInner}>
                <div style={styleSheet.titleTextStyle}>
                  {edit && star}
                  Employee ID
                </div>
                {edit ? (
                  <div>
                    <Input
                      placeholder="Employee ID"
                      style={styleSheet.commonStyle}
                      value={owner?.employee_id}
                      disabled={true}
                    />
                  </div>
                ) : (
                  <div style={styleSheet.titleValueTextStyle}>
                    {owner?.employee_id}
                  </div>
                )}
              </div>
            </>
          )}

          <div style={styleSheet.assetInfoDetailsWrapperInner}>
            <div style={styleSheet.titleTextStyle}>
              {edit && star}
              Status
            </div>
            {edit ? (
              <div style={styleSheet.editButtonGroupWrapperStyle}>
                <div
                  style={{
                    ...styleSheet.statusButtonStyle,
                    ...(assetData.useStatus
                      ? styleSheet.useButtonStyle
                      : {
                          ...styleSheet.useButtonStyle,
                          color: "#A6AAB1",
                          background: "#FFFFFF",
                          borderColor: "#D0D5DD",
                        }),
                  }}
                  onClick={(e) => {
                    globalOnChnage("useStatus", true);
                  }}
                >
                  <BiCheckCircle />
                  <div>In use</div>
                </div>
                <div
                  style={{
                    ...styleSheet.statusButtonStyle,
                    ...(assetData.useStatus
                      ? {
                          ...styleSheet.notInUseButtonStyle,
                          color: "#A6AAB1",
                          background: "#FFFFFF",
                          borderColor: "#D0D5DD",
                        }
                      : styleSheet.notInUseButtonStyle),
                  }}
                  onClick={(e) => {
                    globalOnChnage("useStatus", false);
                  }}
                >
                  <BiCheckCircle />
                  <div>Not In use</div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  ...styleSheet.statusButtonStyle,
                  ...(assetData?.useStatus
                    ? styleSheet.useButtonStyle
                    : styleSheet.notInUseButtonStyle),
                  ...{ width: "auto" },
                }}
              >
                {assetData?.useStatus ? "In use" : "Not in use"}
              </div>
            )}
          </div>
          <div style={styleSheet.assetInfoDetailsWrapperInner}>
            <div style={styleSheet.titleTextStyle}>
              {edit && star}
              Condition{" "}
            </div>
            {edit ? (
              <div style={styleSheet.editButtonGroupWrapperStyle}>
                <div
                  style={{
                    ...styleSheet.conditionButtonStyle,
                    ...(assetData.workingStatus
                      ? styleSheet.workingButtonStyle
                      : {
                          ...styleSheet.workingButtonStyle,
                          color: "#A6AAB1",
                          background: "#FFFFFF",
                          borderColor: "#D0D5DD",
                        }),
                  }}
                  onClick={(e) => {
                    globalOnChnage("workingStatus", true);
                  }}
                >
                  <BiCheckCircle style={{ color: "black" }} />
                  <div>working</div>
                </div>
                <div
                  style={{
                    ...styleSheet.conditionButtonStyle,
                    ...(assetData.workingStatus
                      ? {
                          ...styleSheet.notWorkingButtonStyle,
                          color: "#A6AAB1",
                          background: "#FFFFFF",
                          borderColor: "#D0D5DD",
                        }
                      : styleSheet.notWorkingButtonStyle),
                  }}
                  onClick={(e) => {
                    globalOnChnage("workingStatus", false);
                  }}
                >
                  <BiCheckCircle />
                  <div>Not working</div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  ...styleSheet.conditionButtonStyle,
                  ...(assetData.workingStatus
                    ? styleSheet.workingButtonStyle
                    : styleSheet.notWorkingButtonStyle),
                  ...{ width: "auto" },
                }}
              >
                {assetData?.workingStatus ? "Working" : "Not working"}
              </div>
            )}
          </div>
        </div>
        {/* attachments title */}
        <div style={styleSheet.attachmentsTitle}>
          <div style={styleSheet.attachmentsTitleText}>Attachments</div>
          <div style={styleSheet.attachmentsTitleTextDes}>
            Please find device images and serial number image here.
          </div>
        </div>
        {/* attachments images */}
        <div style={styleSheet.attachmentsImageWrapper}>
          <div style={styleSheet.deviceImageTextStyle}>
            {edit && star}Device Images
          </div>
          <div style={styleSheet.deviceImageWrapper}>
            {edit ? (
              <div>
                {/* uplodaded icons */}
                <div style={styleSheet.imageUploadedStyle}>
                  <Upload.Dragger
                    style={{ border: "none", background: "#ECEEF1" }}
                    onChange={(f) => globalOnChnage("deviceImage", f.file)}
                    customRequest={(f) => handleFileChange(f)}
                    showUploadList={false}
                    multiple={true}
                    accept=".png, .jpeg, .jpg, .gif, .pdf, .heic,"
                  >
                    <div style={styleSheet.innerImageStyle}>
                      <div>
                        <BiUpload fontSize={16} />
                      </div>
                      <div>Upload Images</div>
                    </div>
                  </Upload.Dragger>
                </div>

                {/* list */}
                {assetData.deviceImage.map((file, index) => {
                  return (
                    <div key={index} style={styleSheet.fileStyle}>
                      <div>{file.name ? file.name : file.file_name}</div>
                      <div
                        onClick={() => {
                          const fileId = file.id;
                          setAttachmentIds(
                            attachmentIds.filter((id) => id !== fileId)
                          );
                          let newFields = { ...assetData };
                          let fileList = [...newFields["deviceImage"]];
                          if (fileList.length === 1) {
                            newFields["deviceImage"] = [];
                          } else {
                            let newFileList = fileList.filter((f) => {
                              if (f.id !== undefined) return f.id !== file.id;
                              else return f.uid !== file.uid;
                            });
                            newFields["deviceImage"] = [...newFileList];
                          }
                          setAssetsData(newFields);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <CloseCircleOutlined />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div style={styleSheet.imagesWrap}>
                {assetData.deviceImage &&
                  assetData.deviceImage.length > 0 &&
                  assetData.deviceImage.map((image, index) => {
                    return (
                      <div key={index} style={styleSheet.imageFrameWrap}>
                        <img
                          style={styleSheet.imgTag}
                          src={image.file}
                          alt="deviceImage"
                        />
                      </div>
                    );
                  })}
              </div>
            )}
          </div>

          {assetData.deviceType === "laptop" && (
            <>
              <div style={styleSheet.serialNumberText}>
                {edit && star}Serial Number Image
              </div>
              <div style={styleSheet.deviceImageWrapper}>
                {edit && assetData.deviceType === "laptop" ? (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {/* uplodaded icons */}
                    <div style={styleSheet.imageUploadedStyle}>
                      <Upload.Dragger
                        style={{ border: "none", background: "#ECEEF1" }}
                        onChange={(f) => globalOnChnage("serialImage", f.file)}
                        customRequest={(f) => handleFileChange(f)}
                        showUploadList={false}
                        multiple={false}
                        accept=".png, .jpeg, .jpg, .gif, .pdf, .heic,"
                      >
                        <div style={styleSheet.innerImageStyle}>
                          <div>
                            <BiUpload fontSize={16} />
                          </div>
                          <div>Upload Images</div>
                        </div>
                      </Upload.Dragger>
                    </div>
                    {/* list */}
                    {assetData.serialImage && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "10px",
                          marginLeft: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <div>
                          {assetData.serialImage && assetData.serialImage.name
                            ? assetData.serialImage.name
                            : assetData.serialImage.file_name}
                        </div>
                        <div
                          onClick={() => {
                            const slId = assetData["serialImage"].id;
                            setAttachmentIds(
                              attachmentIds.filter((id) => id !== slId)
                            );
                            let newFields = { ...assetData };
                            newFields["serialImage"] = null;
                            setAssetsData(newFields);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <CloseCircleOutlined />
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    {assetData.serialImage && (
                      <div style={styleSheet.imageFrameWrap}>
                        <img
                          style={styleSheet.imgTag}
                          src={assetData.serialImage?.file}
                          alt="serialImage"
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default Asset;
