import {
  AutoComplete,
  Button,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  message,
  Pagination,
  Spin,
  Table,
  Tooltip,
  Select,
  Checkbox,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineCloudDownload, AiOutlineEye } from "react-icons/ai";
import AppModuleHeader from "../../components/AppModuleHeader";
import axiosJSON from "../../util/Api";
import FileViewer from "react-file-viewer";
import { CustomModal as Modal } from "../../util/CustomModal";
import { FiCheck, FiEdit3, FiX } from "react-icons/fi";
import { withRouter } from "react-router";
import { DownOutlined } from "@ant-design/icons";
import moment from "moment";
import { debounce } from "lodash";


const getColumns = (
  viewResume,
  editList,
  onSelect,
  onSearch,
  options,
  selectCandidate
) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      onCell: (record, rowIndex) => ({
        onClick: () => {
          if (!window.getSelection().toString()) {
            selectCandidate(record.id)
          }
        },
      }),
      render: (text, record) => (
          <div>
            {text ? text : "N/A"}
            <br /> {record.email}
          </div>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone_numbers",
      key: "3",
      render: (text, record) => <div>{text[0] ? text[0] : "-"}</div>,
    },
    {
      title: "Position",
      dataIndex: "job_position",
      key: "4",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "5",
    },
    {
      title: "Referred By",
      dataIndex: "referred_by",
      key: "5",
    },
    // {
    //   title: "Resume",
    //   dataIndex: "resume",
    //   key: "6",
    //   //   hidden: !checkRole(),
    //   //   onCell: (record, rowIndex) => ({
    //   //     onClick: () => {
    //   //       changeTeam(record);
    //   //     },
    //   //   }),
    //   render: (text, record) => (
    //     <div className="drop_correction">
    //       <AiOutlineCloudDownload
    //         onClick={() => window.open(text[0].file)}
    //         style={{ fontSize: "20px", cursor: "pointer" }}
    //         className="mr-2"
    //       />
    //       <AiOutlineEye
    //         onClick={() => viewResume(text[0])}
    //         style={{ fontSize: "20px", cursor: "pointer" }}
    //       />
    //     </div>
    //   ),
    // },
    {
      title: "Referred Date",
      dataIndex: "created",
      key: "6",
      //   hidden: !checkRole(),
      //   onCell: (record, rowIndex) => ({
      //     onClick: () => {
      //       changeTeam(record);
      //     },
      //   }),
      render: (text, record) => (
        <div className="drop_correction">
          {moment(text).format("MM-DD-YYYY")}
        </div>
      ),
    },

    {
      title: "Recruiter",
      dataIndex: "recruiter",
      key: "7",
      render: (text, record) => (
        <div>
          {record.isEdit ? (
            <AutoComplete
              autoFocus
              style={{ width: 150 }}
              options={options}
              onSelect={(val) => onSelect(record.id, val, "recruiter")}
              onSearch={onSearch}
              placeholder="Please search"
            />
          ) : text ? (
            text
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      key: "8",
      width: "10%",
      render: (text, record) => (
        <div>
          {record.isEdit ? (
            <FiX
              style={{ cursor: "pointer", fontSize: "20px" }}
              onClick={() => editList(record, "isEdit", false)}
              className="mr-2"
            />
          ) : (
            <Tooltip title="Edit Recruiter">
              <FiEdit3
                style={{ cursor: "pointer" }}
                onClick={() => editList(record, "isEdit", true)}
                className="mr-2"
              />
            </Tooltip>
          )}
          <span className="drop_correction">
            <Tooltip title="Download Resume">
              <AiOutlineCloudDownload
                onClick={() => window.open(record.resume[0].file)}
                style={{ fontSize: "20px", cursor: "pointer" }}
                className="mr-2"
              />
            </Tooltip>
            <Tooltip title="View Resume">
              <AiOutlineEye
                onClick={() => viewResume(record.resume[0])}
                style={{ fontSize: "20px", cursor: "pointer" }}
              />
            </Tooltip>
          </span>
        </div>
      ),
    },
  ].filter((item) => !item.hidden);
};

const Referral = (props) => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [total, setTotal] = useState(0);
  const [query, setQuery] = useState("");
  const [range, setRange] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageLoader,setPageLoader] = useState(true);
  const [commentObj, setCommentOBj] = useState({});
  const [recruiterId, setRecruiterId] = useState("");
  const [positionList, setPositionList] = useState([]);
  const [candidateList, setCandidateList] = useState([]);
  const [recruiterList, setRecruiterList] = useState([]);
  const [selectedResume, setSelectedResume] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchPositionTerm, setSearchPositionTerm] = useState("");
  const [selectedPositionIdList, setSelectedPositionIdList] = useState([]);

  const getRecruiters = async () => {
    try {
      const response = await axiosJSON.get("api/candidate_referral/recruiter/");
      setRecruiterList(response.data.data);
    } catch (error) {
      message.error(error);
    }
  };

  const getPositions = async () => {
    try {
      const response = await axiosJSON.get("/api/candidate/filter/");
      setPositionList(response.data.data.position);
    } catch (error) {
      message.error(error);
    }
  };

  const getCandidates = (page, size, query = "", range, recruiter = "") => {
    let start = "",
      end = "";
    if (range && range.length > 0) {
      start = moment(range[0]).format("YYYY-MM-DD");
      end = moment(range[1]).format("YYYY-MM-DD");
    }
    setLoading(true);
    axiosJSON
      .get(
        `/api/candidate_referral?page=${page}&size=${size}&query=${query}&start=${start}&end=${end}&positions=${JSON.stringify(
          selectedPositionIdList
        )}&recruiter=${recruiter ? recruiter : recruiterId}`
      )
      .then(({ data, status }) => {
        setCandidateList(data.data);
        setTotal(data.total);
        setPageLoader(false);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error);
      });
  };

  const getSerachCandidate = useCallback(
    debounce((page, size, value, range) => {
      getCandidates(page, size, value, range);
    }, 500),
    []
  );

  const viewResume = (resume) => {
    window.open(resume.file);
    // setSelectedResume(resume);
  };

  const editList = (record, type, val) => {
    if (type === "editComment" && val)
      onComment(record.id, record.remark ? record.remark : "");
    let arr = [...candidateList];
    arr[arr.indexOf(record)][type] = val;
    setCandidateList(arr);
  };

  const onSearch = (str) => {
    setLoading(true);
    axiosJSON
      .get("/api/user/?role=recruiter&query=" + str)
      .then(({ data, status }) => {
        let arr = [];
        data.data.map((item) => {
          let obj = {};
          obj["label"] = item.name;
          obj["value"] = item.id;
          arr.push(obj);
          return null;
        });
        setOptions(arr);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error);
      });
  };

  const onSelect = (id, val, type) => {
    const body = {};
    body[type] = val;
    setLoading(true);
    axiosJSON
      .put(`api/candidate_referral/${id}/`, body)
      .then((res) => {
        message.success(res.data.message);
        getCandidates(page, size, query, range);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(error.message);
      });
  };

  const selectCandidate = (id) => {
    props.history.push({
      pathname: "/candidate/" + id,
      params: { id: id },
    });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onComment = (id, val) => {
    let obj = { ...commentObj };
    obj[id.toString()] = val;
    setCommentOBj(obj);
  };

  const moveCandidates = (type) => {
    const body = {
      candidate_ids: selectedRowKeys,
      status: type,
    };
    axiosJSON
      .put(`api/candidate_referral/change_status/`, body)
      .then((res) => {
        message.success(res.data.message);
        setSelectedRowKeys([]);
        getCandidates(1, size, query, range);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  const handlePositionChange = (e, positionId) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedPositionIdList((prevSelected) => [
        ...prevSelected,
        positionId,
      ]);
    } else {
      setSelectedPositionIdList((prevSelected) =>
        prevSelected.filter((id) => id !== positionId)
      );
    }
  };

  useEffect(() => {
    getRecruiters();
    getPositions();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCandidates(1, size, query, range, recruiterId);
    // eslint-disable-next-line
  }, [recruiterId, selectedPositionIdList]);

  const filterMenu = (
    <Menu className="filter-menu-referral">
      <Menu.Item key="search">
        <Input
          className="search"
          onClick={(e) => e.stopPropagation()}
          placeholder="Search position"
          value={searchPositionTerm}
          onChange={(e) => setSearchPositionTerm(e.target.value)}
        />
      </Menu.Item>
      {positionList
        ?.filter((position) =>
          position.display_name
            .toLowerCase()
            .includes(searchPositionTerm.toLowerCase())
        )
        .map((position, i) => (
          <Menu.Item key={i}>
            <label
              className="item"
              onClick={(e) => e.stopPropagation()}
            >
              <Checkbox
                value={position.id}
                checked={selectedPositionIdList.includes(position.id)}
                onChange={(e) => handlePositionChange(e, position.id)}
              />
              <span style={{ marginLeft: "8px" }}>{position.display_name}</span>
            </label>
          </Menu.Item>
        ))}
    </Menu>
  );

  const optionMenu = () => {
    return (
      <Menu onClick={(e) => moveCandidates(e.key)}>
        <Menu.Item key={"referred_hold"}>Hold</Menu.Item>
        <Menu.Item key={"referred_rejected"}>Rejected</Menu.Item>
      </Menu>
    );
  };

  const onError = (e) => {
    console.log(e);
  };

  return (
    <Spin spinning={pageLoader}>
    <div className="gx-main-content">
      <div className="gx-app-module">
        <div className="gx-module-box width-100">
          <div className="gx-module-box-header">
            <div className="mar_min">
              <div className="col-12 vertical_center">
                <div className="row top_rev">
                  <div className="search col-2 pr-0">
                    <AppModuleHeader
                      placeholder="Search Candidate"
                      user={[]}
                      onChange={(e) => {
                        setQuery(e.target.value);
                        getSerachCandidate(1, size, e.target.value, range);
                      }}
                      value={query}
                    />
                  </div>
                  <div>
                    <Select
                      showSearch
                      style={{ width: "200px", marginLeft: "15px" }}
                      placeholder="Select Recruiter"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        const data = value === undefined ? "" : value;
                        setRecruiterId(data);
                      }}
                      allowClear
                    >
                      {recruiterList?.length > 0 &&
                        recruiterList.map((recruiter) => (
                          <Select.Option
                            key={recruiter.id}
                            value={recruiter.id}
                          >
                            {recruiter.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </div>
                  <div>
                    <DatePicker.RangePicker
                      value={range}
                      onChange={(val) => {
                        setRange(val);
                        getCandidates(1, size, query, val);
                      }}
                    />
                  </div>
                  <div>
                    {selectedPositionIdList.length > 0 && (
                      <span
                        className="gx-text-blue gx-pointer"
                        onClick={() => {
                          setSearchPositionTerm("");
                          setSelectedPositionIdList([]);
                        }}
                        style={{
                          marginRight: "10px",
                        }}
                      >
                        <em>Clear Positions</em>
                      </span>
                    )}
                    <Dropdown
                      overlay={filterMenu}
                      trigger={["hover"]}
                      autoAdjustOverflow={true}
                    >
                      <span
                        className="position-filter-referral-indexjs"
                        onClick={(e) => e.preventDefault()}
                      >
                        Filter By Position
                      </span>
                    </Dropdown>
                  </div>
                  {selectedRowKeys.length > 0 && (
                    <>
                      <div>
                        <Button
                          type="primary"
                          className="m-0 mr-3"
                          onClick={() => moveCandidates("new")}
                        >
                          Move to Candidates
                        </Button>
                      </div>
                      <div>
                        <Dropdown
                          overlay={optionMenu()}
                          placement="bottomRight"
                          trigger={["click"]}
                          className="m-0 mr-3"
                        >
                          <Button type="primary">
                            Change status
                            <DownOutlined />
                          </Button>
                        </Dropdown>
                      </div>
                    </>
                  )}
                  <div className="gx-module-logo grw">
                    <i className="icon icon-refer gx-mr-4"></i>Referral
                  </div>
                </div>
              </div>
            </div>
            <Spin spinning={loading && !pageLoader}>
              <div className="gx-module-box-content mt-4">
                {/* <Spin spinning={loading}> */}
                <Table
                  className="candidate_table p-0"
                  rowKey={(record) => record.id}
                  columns={getColumns(
                    viewResume,
                    editList,
                    onSelect,
                    onSearch,
                    options,
                    selectCandidate
                  )}
                  dataSource={candidateList}
                  pagination={false}
                  rowSelection={rowSelection}
                  // onRow={(record) => {
                  //   return {
                  //     onClick: () => selectCandidate(record.id),
                  //   };
                  // }}
                  expandable={{
                    expandedRowRender: (record) =>
                      record.editComment ? (
                        <div>
                          <Input.TextArea
                            value={commentObj[record.id.toString()]}
                            onChange={(e) =>
                              onComment(record.id, e.target.value)
                            }
                          />
                          <div className="mt-1">
                            <FiCheck
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                                color: "green",
                              }}
                              onClick={() =>
                                onSelect(
                                  record.id,
                                  commentObj[record.id.toString()],
                                  "remark"
                                )
                              }
                              className="mr-2"
                            />
                            <FiX
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                                color: "red",
                              }}
                              onClick={() =>
                                editList(record, "editComment", false)
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          {
                            <span>
                              {record.remark
                                ? record.remark
                                : "No remarks added"}
                            </span>
                          }
                          <FiEdit3
                            className="ml-2"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              editList(record, "editComment", true)
                            }
                          />
                        </div>
                      ),
                  }}
                />
                {/* </Spin> */}
              </div>
              {/* {recruiterData.length > 0 && ( */}
              <div className="Pagination-section">
                <Pagination
                  current={page}
                  total={total}
                  pageSize={size}
                  showSizeChanger
                  pageSizeOptions={["20", "50", "100", "200"]}
                  onChange={(page, size) => {
                    setPage(page);
                    setSize(size);
                    getCandidates(page, size, query, range);
                  }}
                />
              </div>
            </Spin>
            {/* )} */}
          </div>
        </div>
      </div>
      {selectedResume && (
        <Modal
          visible={selectedResume}
          title="Preview"
          footer={null}
          onCancel={() => setSelectedResume(null)}
          width={750}
        >
          {selectedResume.file && (
            <FileViewer
              fileType={selectedResume.file_ext}
              filePath={selectedResume.file}
              errorComponent={<div>Error</div>}
              onError={onError}
            />
          )}
        </Modal>
      )}
    </div>
    </Spin>
  );
};

export default withRouter(Referral);
