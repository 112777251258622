import { Select, DatePicker, Input, Button } from "antd";
import React from "react";
import { FiFilter } from "react-icons/fi";

const { RangePicker } = DatePicker;

const FilterList = (props) => {
  const onRangeChange = (value) => {
    if (value === null) {
      props.updateFilter("start", "");
      props.updateFilter("end", "");
    } else {
      props.updateFilter("start", value[0]);
      props.updateFilter("end", value[1]);
    }
  };

  return (
    <div className="col-12 p-0">
      <div className="row m-0">
        <div className="felx_cells">
          <FiFilter className="filter_icon mr-1" />
          Filters
        </div>
        <div className="felx_cells width_fixing">
          <Select
            showSearch
            style={{ width: "12rem" }}
            allowClear={true}
            value={props.team}
            placeholder="Show by Team Name"
            onChange={(value) => props.updateFilter("team", value)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {props.teamList.map((team, i) => (
              <Select.Option key={i} value={team.name}>
                {team.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="felx_cells">
          <RangePicker
            className="rangePickerStyle"
            value={[props.start, props.end]}
            onChange={(value) => onRangeChange(value)}
          />
          <Button
            onClick={props.applyDateFilter}
            type="primary applyButton2 mb-0"
          >
            Apply
          </Button>
        </div>
        <div className="felx_cells2">
          <Input.Search
            placeholder={"Search Reports"}
            onChange={(e) => props.updateFilter("query", e.target.value)}
            autoFocus="autoFocus"
            value={props.query}
            style={{ width: 220, zIndex: "0", margin: "0" }}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterList;
