import React, { useEffect, useState } from "react";
import { Form, Button, message, Input } from "antd";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import axiosJSON from "../../util/SetHeaderAPI";
import moment from "moment";

const FormItem = Form.Item;
const dateFormat = "MM/DD/YYYY";

const PlanTraining = ({ training, closeModal, training_id }) => {
  const [fields, setFields] = useState([
    {
      name: "date",
      value: null,
    },
    {
      name: "trainingTitle",
      value: training.title,
    },
  ]);

  useEffect(() => {
    getTrainingDates();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getTrainingDates = () => {
    axiosJSON
      .get("api/attendance/?training_id=" + training_id)
      .then(({ data, status }) => {
        let previousDates = [];
        data.data.map((date) => {
          previousDates.push(moment(date.date).format(dateFormat));
          return null;
        });
        let dateList = [
          {
            name: "date",
            value: previousDates,
          },
        ];
        setFields(dateList);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  const onFinish = (value) => {
    let data = {
      dates: [],
      training_id: training.id,
    };
    value.date.map((item) => {
      data.dates.push(item.format("YYYY-MM-DD"));
      return null;
    });
    axiosJSON
      .post("api/attendance/", data)
      .then(({ data, status }) => {
        if (status === 201) {
          message.success(data.message);
          closeModal();
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content p-0 custom_box">
        <Form
          name="basic"
          fields={fields}
          onFinish={onFinish}
          className="gx-signin-form gx-form-row0"
        >
          <FormItem
            rules={[{ required: false, message: "Please select Training" }]}
            name="trainingTitle"
            label="Training Name"
          >
            <Input readOnly={true} />
          </FormItem>
          <FormItem
            rules={[{ required: true, message: "Please select Dates" }]}
            name="date"
            label="Select Training Dates"
            className="date_custom"
          >
            <DatePicker
              multiple
              format={dateFormat}
              sort
              plugins={[<DatePanel />]}
              minDate={training.start}
              maxDate={training.end}
            />
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default PlanTraining;
