export const styles = {
  fullWidth: {
    width: "100%",
  },
  startStyle: {
    color: "red",
    fontSize: "14px",
    marginRight: "6px",
    marginTop: "12px",
  },
  serialImageWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "2px",
    background: "#edbfa4",
    padding: "5px",
  },
  deviceImageStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "2px",
    background: "#edbfa4",
    padding: "5px",
  },
  worningStyle: {
    color: "red",
    padding: "0px",
    margin: "0px",
    width: "100%",
  },
  contantWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
  },
  leftWrapper: {
    height: "14vh",
    display: "flex",
    flexDirection: "column",
    padding: "2px",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  headingStyle: {
    background: "#F86011",
    borderRadius: "32px",
    width: "80px",
    textAlign: "center",
    color: "#FFFFFF",
    position: "absolute",
    top: "10px",
    left: "10px",
    height: "23px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  deviceNameText: {
    position: "absolute",
    left: "10px",
    top: "6vh",
    width: "200px",
    display: "flex",
    justifyContent: "flex-start",
  },
  actionWrapper: {
    display: "flex",
    justifyContent: "space-between",
    color: "#9A9CB0",
    cursor: "pointer",
    paddingLeft: "10px",
    fontSize: "20px",
  },
  deviceTypeStyle: {
    position: "absolute",
    left: "10px",
    top: "10vh",
  },
  displayImageWrapper: {
    width: "192px",
    overflow: "hidden",
    borderRadius: "10px",
  },
  backButtonStyle: {
    padding: "10px",
    margin: "10px",
    cursor: "pointer",
  },
  imageSizeStyle:{
    height: "140px",
    width: "180px",
  },
  lableStyle:{
    color: "#1b1c20", 
    fontSize: "16px"
  }
};
