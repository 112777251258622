import React, { useEffect, useState } from "react";
import CircularProgress from "../../CircularProgress";
import { message, Table } from "antd";
import { EditFilled } from "@ant-design/icons";
import Assignment from "../../../modals/Training/assignment";
import axiosJSON from "../../../util/SetHeaderAPI";
import { CustomModal as Modal } from "../../../util/CustomModal";

const CandidateAssignment = (props) => {
  const [assignmentlist, setAssignmentlist] = useState([]);
  const [assignmentLoader, setAssignmentLoader] = useState(false);
  const [editAssignment, setEditAssignment] = useState(false);
  const [assignmentObj, setAssignmentObj] = useState(null);

  useEffect(() => {
    getCandidateAssignment();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const getCandidateAssignment = () => {
    setAssignmentLoader(true);
    axiosJSON
      .get(
        "/api/training_candidate/" +
          props.candidate.training_candidate_id +
          "/assignment/"
      )
      .then(({ data, status }) => {
        setAssignmentlist(data.data);
        setAssignmentLoader(false);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };
  const handleAssignmentClose = () => {
    setEditAssignment(false);
    getCandidateAssignment();
    props.getActivity();
  };
  const Assignment_columns = [
    {
      title: "Assignment No.",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Submission Date",
      dataIndex: "submit_date",
      key: "submit_date",
    },
    {
      title: "Comment",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Edit",
      key: "operation",
      render: (text, record) => (
        <h3>
          <EditFilled
            onClick={() => {
              setEditAssignment(true);
              setAssignmentObj(record);
            }}
          />
        </h3>
      ),
    },
  ];
  return (
    <>
      <div className="col-7 pr-0 Assignment">
        <div className="card">
          <div className="card-header ch-clr">Assignment</div>
          <div className="card-body">
            {assignmentLoader ? (
              <CircularProgress />
            ) : (
              <Table
                rowKey={(record) => record.number}
                columns={Assignment_columns}
                dataSource={assignmentlist}
                pagination={false}
                className="assignment"
                scroll={{ y: 250 }}
              />
            )}
          </div>
        </div>
      </div>
      {editAssignment && (
        <Modal
          title="Assignment"
          visible={editAssignment}
          footer={null}
          onCancel={() => setEditAssignment(false)}
        >
          <Assignment
            candidate={props.candidate}
            assignmentObj={assignmentObj}
            edit={true}
            closeModal={handleAssignmentClose}
          />
        </Modal>
      )}
    </>
  );
};

export default CandidateAssignment;
