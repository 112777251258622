import React, { Component } from "react";
import { Input, Select } from "antd";
import { connect } from "react-redux";
import AddTraingingDetails from "../../modals/Training/addTrainingDetails";
// import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import { BsArrowRight, BsArrowLeftCircleFill } from "react-icons/bs";

import { Route, Switch as RouteSwitch } from "react-router-dom";
import {
  onSubQuerySearch,
  setSubFilterObj,
  setTrainingObj,
} from "../../appRedux/actions";
import { withRouter } from "react-router";
import TrainingDetailList from "./TrainingDetailList";
import Attendance from "./Attendance";
import "nprogress/nprogress.css";
import nprogress from "nprogress";
import { CustomModal as Modal } from "../../util/CustomModal";
import { RxDotFilled } from "react-icons/rx";


class TrainingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      training_id: this.props.history.location.pathname.split("/")[2],
      query: this.props.subQuery,
      isCandidate: this.props.history.location.pathname.includes(
        "training_attendance"
      )
        ? false
        : true,
      showAddAttendance: false,
      showAddTrainingDetail: false,
      consultantLanguageList: [
        {
          name: "Java",
          display_name: "Java",
        },
        {
          name: "Python",
          display_name: "Python",
        },
      ],
      consultantStatusList: [
        {
          name: "Confirmed",
          display_name: "Confirmed",
        },
        {
          name: "Removed",
          display_name: "Removed",
        },
      ],
      consultantSessionTypeList: [
        {
          name: "Live",
          display_name: "Live",
        },
        {
          name: "Recorded",
          display_name: "Recorded",
        },
      ],
      subFilterObj: this.props.subFilterObj,
      trainingObj: JSON.parse(localStorage.getItem("trainingObj")),
      trainingCandidateCount: {},
      needRender: true,
    };
    this.Mounted = false;
  }

  componentDidMount() {
    this.Mounted = true;
  }

  componentWillUnmount() {
    nprogress.start();
    this.Mounted = false;
    nprogress.done();
  }

  handleClose = () => {
    this.setState({
      showAddTrainingDetail: false,
    });
  };

  onToggleDrawer() {
    this.setState({
      drawerState: !this.state.drawerState,
    });
  }

  updateSearch(evt) {
    this.setState({
      query: evt.target.value,
    });
    this.props.onSubQuerySearch(evt.target.value);
    this.setState({
      needRender: !this.state.needRender,
    });
  }

  onToggleChange = (checked) => {
    if (checked === "candidate") {
      this.props.history.push({
        pathname: this.props.match.url + "/" + this.state.training_id,
        params: { training_id: this.state.training_id },
      });
    } else {
      this.props.history.push({
        pathname:
          this.props.match.url +
          "/" +
          this.state.training_id +
          "/training_attendance",
        params: { training_id: this.state.training_id },
      });
    }
    this.setState({
      isCandidate: !this.state.isCandidate,
    });
  };

  selectStatus = (value, filterName) => {
    if (value) {
      this.selectFilter(true, value, filterName);
    } else {
      this.selectFilter(false, value, filterName);
    }
  };

  selectFilter = (checked, obj, type) => {
    let filterObj = this.state.subFilterObj;
    let o = this.state.subFilterObj;
    if (checked) {
      if (o.hasOwnProperty(type)) {
        o[type] = obj;
        filterObj = o;
      } else if (type === "status") {
        o[type] = "";
        o[type] = obj;
      } else if (type === "session_type") {
        o[type] = "";
        o[type] = obj;
      } else if (type === "language") {
        o[type] = "";
        o[type] = obj;
      }
    } else {
      if (o.hasOwnProperty(type)) {
        delete o[type];
      }
    }
    this.props.setSubFilterObj(filterObj);
    this.setState({
      needRender: !this.state.needRender,
    });
  };

  onBackButtonClick = () => {
    this.props.history.push({
      pathname: this.props.match.url,
    });
  };

  populateData = () => {
    this.setState({
      trainingCandidateCount: localStorage.getItem("training_candidate_count")
        ? JSON.parse(localStorage.getItem("training_candidate_count"))
        : {},
    });
  };

  render() {
    return (
      <div className="gx-main-content">
        <div className="gx-app-module">
          <div className="gx-module-box width-100">
            <div className="gx-module-box-header border_no">
              <span className="gx-drawer-btn gx-d-flex gx-d-lg-none">
                <i
                  className="icon icon-menu gx-icon-btn"
                  aria-label="Menu"
                  onClick={this.onToggleDrawer.bind(this)}
                />
              </span>
              <div className="row p-0 m-0 center_col mt-2">
                <div className="col-12 p-0">
                  <div className="col-12 row p-0 m-0">
                    <div className="col-4 row p-0 m-0">
                      <div className="back_icon p-0 m-2 col-1">
                        <BsArrowLeftCircleFill
                          onClick={this.onBackButtonClick}
                        />
                      </div>
                      {this.state.trainingObj && (
                        <div className="col-10 p-0">
                          <div className="h1 m-0">
                            {this.state.trainingObj.title}
                          </div>
                          <div className="h4 m-0">
                            <span className="start_d">
                              {moment(this.state.trainingObj.start).format(
                                "MM/DD/YYYY"
                              )}
                            </span>
                            <span className="arrow arw_two">
                              {" "}
                              <BsArrowRight />{" "}
                            </span>
                            {this.state.trainingObj.end ? (
                              <span className="end_d">
                                {" "}
                                {moment(this.state.trainingObj.end).format(
                                  "MM/DD/YYYY"
                                )}
                              </span>
                            ) : (
                              <span className="end_d">NA</span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-8 row p-0 right-flex">
                      <div className="">
                        <div className="tag-flex back-1">
                          <div className="tag-icon">
                            <RxDotFilled />
                          </div>
                          <div className="">
                            <span className="mr-2">Total </span>
                            <span>
                              {this.state.trainingCandidateCount.total}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="tag-flex back-2">
                          <div className="tag-icon">
                            <RxDotFilled />
                          </div>
                          <div className="">
                            <span className="mr-2">Confirmed </span>
                            <span>
                              {this.state.trainingCandidateCount.confirmed}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="tag-flex back-3">
                          <div className="tag-icon">
                            <RxDotFilled />
                          </div>
                          <div className="">
                            <span className="mr-2">Removed </span>
                            <span>
                              {this.state.trainingCandidateCount.removed}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="tag-flex back-4">
                          <div className="tag-icon">
                            <RxDotFilled />
                          </div>
                          <div className="">
                            <span className="mr-2">Live </span>
                            <span>
                              {this.state.trainingCandidateCount.live}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="tag-flex back-5 m-0">
                          <div className="tag-icon">
                            <RxDotFilled />
                          </div>
                          <div className="">
                            <span className="mr-2">Recorded </span>
                            <span>
                              {this.state.trainingCandidateCount.recorded}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 sec_set row">
                    <div className="switch-field">
                      <div className="cover_wrap">
                        <input
                          readOnly
                          type="radio"
                          id="radio-one"
                          name="switch-one"
                          value="yes"
                          onClick={() => this.onToggleChange("candidate")}
                          checked={this.state.isCandidate}
                        />
                        <label htmlFor="radio-one">Candidate</label>
                        <input
                          readOnly
                          type="radio"
                          id="radio-two"
                          name="switch-one"
                          value="no"
                          onClick={() => this.onToggleChange("attendance")}
                          checked={!this.state.isCandidate}
                        />
                        <label htmlFor="radio-two">Attendance</label>
                      </div>
                    </div>
                    <div className="">
                      <div className="col-12 pr-0 right_col">
                        <Select
                          showSearch
                          allowClear={true}
                          value={
                            this.state.subFilterObj.hasOwnProperty("language")
                              ? this.state.subFilterObj["language"]
                              : null
                          }
                          style={{ marginRight: "5px" }}
                          placeholder="Select Language"
                          className="margin_right"
                          onChange={(value) => {
                            this.selectStatus(value, "language");
                          }}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.state.consultantLanguageList.map(
                            (Language, i) => (
                              <Select.Option
                                key={Language.name}
                                value={Language.name}
                              >
                                {Language.display_name}
                              </Select.Option>
                            )
                          )}
                        </Select>
                        <Select
                          showSearch
                          allowClear={true}
                          value={
                            this.state.subFilterObj.hasOwnProperty("status")
                              ? this.state.subFilterObj["status"]
                              : null
                          }
                          style={{ marginRight: "5px" }}
                          placeholder="Select Status"
                          className="margin_right"
                          onChange={(value) =>
                            this.selectStatus(value, "status")
                          }
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.state.consultantStatusList.map((status, i) => (
                            <Select.Option
                              key={status.name}
                              value={status.name}
                            >
                              {status.display_name}
                            </Select.Option>
                          ))}
                        </Select>
                        {this.state.isCandidate && (
                          <Select
                            showSearch
                            allowClear={true}
                            value={
                              this.state.subFilterObj.hasOwnProperty(
                                "session_type"
                              )
                                ? this.state.subFilterObj["session_type"]
                                : null
                            }
                            style={{ marginRight: "5px" }}
                            placeholder="Select Session Type"
                            className="margin_right"
                            onChange={(value) =>
                              this.selectStatus(value, "session_type")
                            }
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.state.consultantSessionTypeList.map(
                              (sessionType, i) => (
                                <Select.Option
                                  key={sessionType.name}
                                  value={sessionType.name}
                                >
                                  {sessionType.display_name}
                                </Select.Option>
                              )
                            )}
                          </Select>
                        )}
                        <Input.Search
                          placeholder={"Search Candidiate Name"}
                          onChange={this.updateSearch.bind(this)}
                          autoFocus="autoFocus"
                          value={this.props.subQuery}
                          style={{ width: 220, zIndex: "0", margin: "0" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="gx-module-box-content">
              {this.state.needRender ? (
                <>
                  <></>
                  <RouteSwitch>
                    <Route
                      exact
                      path={this.props.match.url + "/" + this.state.training_id}
                    >
                      <TrainingDetailList populateData={this.populateData} />
                    </Route>
                    <Route
                      exact
                      path={
                        this.props.match.url +
                        "/" +
                        this.state.training_id +
                        "/training_attendance"
                      }
                    >
                      <Attendance />
                    </Route>
                  </RouteSwitch>
                </>
              ) : (
                <RouteSwitch>
                  <Route
                    exact
                    path={this.props.match.url + "/" + this.state.training_id}
                  >
                    <TrainingDetailList populateData={this.populateData} />
                  </Route>
                  <Route
                    exact
                    path={
                      this.props.match.url +
                      "/" +
                      this.state.training_id +
                      "/training_attendance"
                    }
                  >
                    <Attendance />
                  </Route>
                </RouteSwitch>
              )}
            </div>
          </div>
        </div>

        {this.state.showAddTrainingDetail && (
          <Modal
            title="Add Training Details"
            visible={this.state.showAddTrainingDetail}
            width="60%"
            footer={null}
            onCancel={this.handleClose}
          >
            <AddTraingingDetails closeModal={this.handleClose} />
          </Modal>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSubFilterObj: (data) => dispatch(setSubFilterObj(data)),
  onSubQuerySearch: (query) => dispatch(onSubQuerySearch(query)),
  setTrainingObj: (payload) => dispatch(setTrainingObj(payload)),
});

const mapStateToProps = (state) => ({
  auth: state.auth.authUser,
  subFilterObj: state.common.sub_filter_obj,
  subQuery: state.common.sub_query,
  accessData: state.auth.accessData,
  trainingObj: state.common.training_obj,
  toggle: state.common.toggle,
});

const routeTrainingDetail = withRouter(TrainingDetail);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(routeTrainingDetail);
