// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";
export const MY = "MY";
export const QUERY = "QUERY";
export const FETCHING = "FETCHING";
export const UNREAD_COUNTER = "UNREAD_COUNTER";
export const TIMEZONE = "TIMEZONE";

//Auth const
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const INIT_URL = "INIT_URL";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const USER = "USER";
export const NEW_USER = "NEW_USER";
export const USER_ACCESS = "USER_ACCESS";
export const IMPERSONATE = "IMPERSONATE";

//Sticky
export const GET_STICKY = "GET_STICKY";
export const NOTES_UPDATE = "NOTES_UPDATE";
export const FETCH_ALL_NOTES_SUCCESS = "FETCH_ALL_NOTES_SUCCESS";
export const UPDATE_ALL_NOTES_SUCCESS = "UPDATE_ALL_NOTES_SUCCESS";

//Contact
export const GET_All_CONTACT_SUCCESS = "GET_All_CONTACT_SUCCESS";
export const ON_ADD_CONTACT_SUCCESS = "ON_ADD_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";

//Reports
export const FILTER_MY = "FILTER_MY";
export const FILTER_FOR = "FILTER_FOR";
export const QUERY_REPORTS = "QUERY_REPORTS";

// Candidate List
export const FILTER_OBJ = "FILTER_OBJ";
export const CANDIDATE_DATA = "CANDIDATE_DATA";
export const PERMISSION_DATA = "PERMISSION_DATA";
export const PAGE = "PAGE";
export const ALLCANDIDATE = "ALLCANDIDATE";
export const SORTBY = "SORTBY";

// Training Candidates List
export const SUB_FILTER_OBJ = "SUB_FILTER_OBJ";
export const SUB_QUERY = "SUB_QUERY";

// Training List
export const TRAINING_OBJ = "TRAINING_OBJ";
