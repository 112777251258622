import React from "react";
import { Input, Select, Tooltip, Tag, Button, message, Spin } from "antd";
import { deserialize, serialize } from "../../components/Editor/parseJson";
import SlateEmailEditor from "../../components/EmailTemplateEditor";
import axiosJSON from "../../util/SetHeaderAPI";
import getDeserializeText from "../../util/DeserializingText";
import SlateEmailEditorTemp from "../../components/EmailTemplateEditorTemp";

const beforeMatch = /{(\w+)$/;
const beforeStr = "{{";
const afterStr = "}}";

class BulkEmailPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      template: "",
      placeholders: [],
      selected_placeholders: [],
      cc: [],
      bcc: [],
      from: "",
      subject: "",
      body: [
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ],
      showCc: false,
      showBcc: false,
      templateList: [],
      emailList: [],
      template_id: 0,
      toggle: true,
      loading: false,
    };
  }

  componentDidMount() {
    this.getPlaceholders();
    let m = [
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ];
    let body;
    if (this.props.templateList.length > 0) {
      if (this.props.templateList[0].body === `<p></p>`) {
        body = m;
      } else {
        body = getDeserializeText(this.props.templateList[0].body);
      }
      this.setState({
        template_id: this.props.templateList[0].id,
        template: this.props.templateList[0].name,
        templateList: this.props.templateList,
        selected_placeholders: this.props.templateList[0].placeholders,
        cc: this.props.templateList[0].cc,
        bcc: this.props.templateList[0].bcc,
        subject: this.props.templateList[0].subject,
        body: body,
      });
    }
  }

  onChangeTemplate = (val) => {
    let template_id = val;
    let { selected_placeholders, cc, bcc, from, subject, body } = this.state;
    let m = [
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ];
    this.state.templateList.map((template, i) => {
      if (template.id === template_id) {
        selected_placeholders = template.placeholders;
        cc = template.cc;
        bcc = template.bcc;
        from = template.from;
        subject = template.subject;
        if (template.body === `<p></p>`) {
          body = m;
        } else {
          body = getDeserializeText(template.body);
        }
      }
      return null;
    });
    this.setState({
      template_id: val,
      selected_placeholders,
      cc,
      bcc,
      from,
      subject,
      body,
      toggle: !this.state.toggle,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  tagRender(props) {
    const { label, value, closable, onClose } = props;

    var re = /\S+@\S+\.\S+/;
    return (
      <Tag
        color={re.test(String(value).toLowerCase()) ? "gray" : "darkred"}
        closable={closable}
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  }
  getEmailTemplates = () => {
    this.setState({
      flag: false,
    });
    axiosJSON
      .get("api/candidate/bulk_template/")
      .then(({ data, status }) => {
        this.setState({
          template: data.data.name,
          cc: data.data.cc,
          showCc: data.data.cc.length > 0,
          bcc: data.data.bcc,
          showBcc: data.data.bcc.length > 0,
          subject: data.data.subject,
          selected_placeholders: data.data.placeholders,
          body: deserialize(
            new DOMParser().parseFromString(data.data.body, "text/html").body
          ),
          flag: true,
        });
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  setEmails = (type, value) => {
    this.setState({
      [type]: value,
    });
  };
  handleBodyChange = (e) => {
    this.setState({
      body: e,
    });
  };
  onSubmit = () => {
    this.setState({ loading: true });
    const body = {
      cc: this.state.cc,
      bcc: this.state.bcc,
      candidate_ids: this.props.selectedCandidatesList,
      template_id: this.state.template_id,
      body: serialize(this.state.body),
      subject: this.state.subject,
      placeholders: this.state.selected_placeholders,
    };
    axiosJSON
      .post("api/candidate/bulk_email/", body)
      .then(({ data, status }) => {
        this.setState({ loading: false });
        message.success(data?.message);
        this.props.closeModal();
      })
      .catch(function (error) {
        this.setState({ loading: false });
        message.error(error.message);
      });
  };
  getPlaceholders = () => {
    axiosJSON
      .get("/api/placeholder/")
      .then(({ data, status }) => {
        this.setState({ placeholders: data.data });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  onSelectPlaceholder = (value) => {
    let user = this.state.selected_placeholders;
    user.push(value);
    this.setState({
      selected_placeholders: user,
    });
  };

  render() {
    const {
      template_id,
      templateList,
      cc,
      emailList,
      showCc,
      bcc,
      showBcc,
      subject,
      body,
    } = this.state;
    return this.state.templateList.length > 0 ? (
      <Spin spinning={this.state.loading} tip="Please Wait, Sending Mail">
        <div>
          <div>
            <label> Select template:</label>
            <Select
              style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
              value={template_id}
              onChange={this.onChangeTemplate}
            >
              {templateList.map((t) => (
                <Select.Option value={t.id} key={t.id}>
                  {t.name + ":" + t.email_type}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div>
            <div className="email-cc">
              {showCc ? null : (
                <Tooltip title="Add recipients to Cc">
                  <span
                    className="gx-fs-sm gx-text-grey"
                    onClick={() => this.setState({ showCc: !showCc })}
                  >
                    +Cc
                  </span>
                </Tooltip>
              )}
            </div>
            <div className="email-bcc" style={{ width: "50%", float: "left" }}>
              {showBcc ? null : (
                <Tooltip title="Add recipients to Bcc">
                  <span
                    className="gx-fs-sm gx-text-grey"
                    onClick={() => this.setState({ showBcc: !showBcc })}
                  >
                    +Bcc
                  </span>
                </Tooltip>
              )}
            </div>
          </div>

          {showCc && (
            <div>
              <label> Cc </label>
              <br />
              <Select
                placeholder="Cc"
                tagRender={this.tagRender}
                value={cc}
                mode="tags"
                style={{ width: "100%" }}
                onChange={(value) => this.setEmails("cc", value)}
                tokenSeparators={[","]}
              >
                {emailList.map((email, i) => (
                  <Select.Option key={email}>{email}</Select.Option>
                ))}
              </Select>
            </div>
          )}
          {showBcc && (
            <div>
              <label> Bcc </label>
              <br />
              <Select
                placeholder="Bcc"
                tagRender={this.tagRender}
                value={bcc}
                mode="tags"
                style={{ width: "100%" }}
                onChange={(value) => this.setEmails("bcc", value)}
                tokenSeparators={[","]}
              >
                {emailList.map((email, i) => (
                  <Select.Option key={email}>{email}</Select.Option>
                ))}
              </Select>
            </div>
          )}
          <div className="email-sub">
            <label>Subject</label>
            <Input
              placeholder="Subject"
              value={subject}
              name="subject"
              onChange={this.handleChange}
            />
          </div>

          <div>
            <label>Description</label>
            <div className="email-text-editor-new">
              {this.state.toggle ? (
                <SlateEmailEditor
                  beforeMatch={beforeMatch}
                  afterStr={afterStr}
                  beforeStr={beforeStr}
                  value={body}
                  onSelectPlaceholder={this.onSelectPlaceholder}
                  setData={this.handleBodyChange}
                  placeholdersList={this.state.placeholders}
                />
              ) : (
                <SlateEmailEditorTemp
                  beforeMatch={beforeMatch}
                  afterStr={afterStr}
                  beforeStr={beforeStr}
                  value={body}
                  onSelectPlaceholder={this.onSelectPlaceholder}
                  setData={this.handleBodyChange}
                  placeholdersList={this.state.placeholders}
                />
              )}
            </div>
          </div>

          <div>
            <Button type="primary" onClick={this.onSubmit}>
              Send Email
            </Button>
          </div>
          <br clear="all" />
        </div>
      </Spin>
    ) : (
      <div>No templates found</div>
    );
  }
}

export default BulkEmailPreview;
