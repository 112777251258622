import React, { useState } from "react";
import IntlMessages from "util/IntlMessages";
import { Avatar, Button, Dropdown, Input, message } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import ResetPassword from "../../modals/resetPassword";
import EditUserProfile from "../../modals/editUserProfile";
import axiosJSON from "../../util/SetHeaderAPI";
import { myDetails } from "../../appRedux/actions";
import "nprogress/nprogress.css";
import nprogress from "nprogress";
import { CustomModal as Modal } from "../../util/CustomModal";

const MyProfile = () => {
  const checkUser = () => {
    let currentToken = localStorage.getItem("current_token");
    if (currentToken && impersonateFlag) {
      return newUser;
    } else {
      return authUser;
    }
  };
  nprogress.start();
  const authUser = useSelector(({ auth }) => auth.authUser);
  const newUser = useSelector(({ auth }) => auth.newUser);
  const impersonateFlag = useSelector(({ auth }) => auth.impersonateFlag);
  const dispatch = useDispatch();
  const [editFlag, editProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [mailIntergationFlag,setMailIntergationFlag] = useState(false);
  const [changeFlag, changePassword] = useState(false);
  const [visible, setVisibility] = useState(false);
  const [user] = useState(checkUser());
  const [email, setEmail] = useState(user.email);

  nprogress.done();
  const closeModal = () => {
    editProfile(false);
    changePassword(false);
  };
  const menu = (
    <div className="outlook_mail" onBlur={() => setVisibility(false)}>
      Your Outlook email:
      <Input
        name="email"
        value={email}
        onChange={(event) => {
          setEmail(event.target.value);
        }}
      />
      <Button
        type="primary"
        loading={loading}
        onClick={() => emailIntergation(email)}
      >
        Ok
      </Button>
      <br clear="all" />
    </div>
  );
  const emailIntergation = (email) => {
    nprogress.start();
    setLoading(true);
    const body = {
      email: email,
    };
    axiosJSON
      .post("api/email_integration/", body)
      .then(({ data, status }) => {
        // setMailIntergationFlag(false)
        message.success(data.message);
        setLoading(false);
        let token = localStorage.getItem("token");
        dispatch(myDetails(token));

        setVisibility(false);
      })
      .catch((error) => {
        message.error(error.message);
        setVisibility(false);
      });
    nprogress.done();
  };
  return (
    <div>
      <div className="ant-row">
        <div className="ant-col ant-col-24">
          <div className="gx-card">
            <div className="gx-card-body">
              <div className="ant-row">
                <div className="ant-col gx-mr-3 ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-2">
                  {user.avatar === null && user.name.split(" ").length !== 0 ? (
                    user.name.split(" ").length >= 2 ? (
                      <Avatar className="gx-size-80 gx-pointer gx-mr-2">
                        {user.name.split(" ")[0].charAt(0).toUpperCase() +
                          user.name.split(" ")[1].charAt(0).toUpperCase()}
                      </Avatar>
                    ) : (
                      <Avatar className="gx-size-80 gx-pointer gx-mr-2">
                        {user.name.split(" ")[0].charAt(0).toUpperCase()}
                      </Avatar>
                    )
                  ) : (
                    <Avatar
                      src={user.avatar}
                      className="gx-size-80 gx-pointer gx-mr-2"
                      alt=""
                    />
                  )}
                </div>
                <div className="ant-col gx-visit-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-12">
                  <h3>{user.name + " (" + user.employee_id + ")"}</h3>
                  <h4>{user.email}</h4>
                  {user && user.team && user.role ? (
                    <h4>
                      {" "}
                      {user.team +
                        " | " +
                        user.role.split(" ")[0].charAt(0).toUpperCase() +
                        user.role.slice(1)}
                    </h4>
                  ) : null}
                </div>

                <div className="right-padding ant-col gx-audi-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-9">
                  <div className="btn-right gx-mt-3">
                    <Button onClick={() => editProfile(true)}>
                      <i className="icon icon-setting gx-mr-2" /> Edit profile{" "}
                    </Button>
                    <Button onClick={() => changePassword(true)}>
                      <i className="icon icon-edit gx-mr-2" /> Change Password{" "}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="gx-card">
            <div className="gx-card-body">
              <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-24">
                <Avatar
                  style={{ backgroundColor: "#f3f0f0" }}
                  className="gx-size-50 gx-pointer"
                >
                  <MailOutlined
                    className="gx-fs-xl"
                    style={{ color: "#005B9E" }}
                    type="google"
                  />
                </Avatar>{" "}
                Microsoft Mail
                {user.integration ? (
                  <Button
                    className="settings-msIntegration"
                    onClick={() =>
                      message.info(
                        "Your outlook account: " +
                          user.email +
                          " is already linked to Beats."
                      )
                    }
                  >
                    <IntlMessages id="sidebar.settings-msIntegration-true" />
                  </Button>
                ) : (
                  <Dropdown
                    visible={visible}
                    onClick={() => setVisibility(true)}
                    overlay={menu}
                    trigger={["click"]}
                  >
                    <Button className="settings-msIntegration">
                      <IntlMessages id="sidebar.settings-msIntegration" />
                    </Button>
                  </Dropdown>
                )}
              </div>
            </div>
          </div>
          {/*<-------------- THEME SETTINGS ------------>*/}
          {/*<div className="gx-card">*/}
          {/*  <div className="gx-card-body">*/}
          {/*    <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-lg-12 ant-col-xl-24">*/}
          {/*        {getCustomizerContent()}*/}

          {/*    </div>*/}

          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>

      <Modal
        className="gx-container"
        title={null}
        visible={editFlag}
        onCancel={() => editProfile(false)}
        closable={false}
        footer={null}
      >
        <EditUserProfile closeModal={closeModal} />
      </Modal>
      <Modal
        className="gx-container"
        title={null}
        visible={changeFlag}
        onCancel={() => changePassword(false)}
        closable={false}
        footer={null}
      >
        <ResetPassword closeModal={closeModal} />
      </Modal>
    </div>
  );
};

export default MyProfile;
