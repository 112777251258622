import React, {Component} from "react";
import FileViewer from 'react-file-viewer';
import {Button, Form, Input, message} from "antd";
import axiosJSON from "../../util/SetHeaderAPI";
import {BASE_URL} from "../../util/Api";


const FormItem = Form.Item;

class UploadResumeFileViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [{
        name: ['name'],
        value: this.props.candidateData ? this.props.candidateData.candidate.name : '',
      }, {
        name: ['email'],
        value: this.props.candidateData ? this.props.candidateData.candidate.email : '',
      }, {
        name: ['phone'],
        value: this.props.candidateData ? this.props.candidateData.candidate.phone : '',
      }],
      editFlag:true
    }
  }

  onError = (e) => {
    console.log(e)
  }

  editCandidate = (values) => {
    let formData = new FormData();
    formData.append('name', values.name)
    formData.append('email', values.email)
    formData.append('file', this.props.candidateData.candidate.file)
    axiosJSON.put('api/candidate/' + this.props.id + '/', formData)
      .then(({data, status}) => {
        message.success(data.message)
        this.props.closeModal()
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  discard = () => {
    const body = {
      "file": this.props.candidateData.resume.path
    }
    axiosJSON.put('api/candidate/discard/', body)
      .then(({data, status}) => {
        if (status === 200) {
          message.warn("Update Discarded!")
          this.props.closeModal()
        }
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  render() {
    return (

      <div>
        <div className="left-fileviewer">
          <FileViewer
            fileType={this.props.candidateData.resume.extension}
            filePath={BASE_URL + "/" + this.props.candidateData.resume.path}
            errorComponent={<div>Error</div>}
            onError={this.onError}
          />
        </div>

        <div className="rightform">
          <Form
            fields={this.state.fields}
            onFieldsChange={(newFields)=>{
              this.setState({
                fields:newFields
              })
            }}
            name="basic"
            onFinish={this.editCandidate}
            onFinishFailed={this.discard}
            className="gx-signin-form gx-form-row0">

            <FormItem name="name">
              <Input className='gx-input-lineheight' type="text" readOnly={this.state.editFlag}  placeholder="Candidate's Name"/>
            </FormItem>

            <FormItem name="email">
              <Input className='gx-input-lineheight' type="text" readOnly={this.state.editFlag}  placeholder="Candidate's Email"/>
            </FormItem>

            <FormItem name="phone">
              <Input className='gx-input-lineheight' type="text" readOnly={this.state.editFlag}
                     placeholder="Candidate's Phone number"/>
            </FormItem>

            <FormItem>
              <Button className="resumse-btn" type="primary" htmlType="submit">
                Submit
              </Button>
              {this.state.editFlag &&
              <Button className="resumse-btn" type="primary" onClick={() => this.setState({editFlag: false})}>
                Edit
              </Button>
              }
              <Button className="resumse-btn" onClick={this.discard} type="primary" htmlType="button">
                Discard
              </Button>
            </FormItem>
          </Form>
        </div>

      </div>
    );
  }

};

export default UploadResumeFileViewer;
