import moment from "moment";
import { FiEdit2 } from "react-icons/fi";
import axiosJSON from "../../../util/Api";
import React, { useEffect, useReducer } from "react";
import { Table, Calendar, message, Modal, Spin } from "antd";
import EditAssigment from "../../../modals/Training/assignment";
import EditAttendance from "../../../modals/Training/editAttendance";

const INITAL_STATE = {
  loading: false,
  attendance: null,
  candidateObj: null,
  assignmentList: [],
  selectedDate: null,
  candidateData: null,
  assignmentObj: null,
  editAttendance: false,
  editAssignment: false,
};

const reducer = (state, action) => {
  return {
    ...state,
    assignmentList:
      action.type === "set_assignment_list"
        ? action.data
        : state.assignmentList,
    editAssignment:
      action.type === "set_edit_assignment"
        ? action.data
        : state.editAssignment,
    assignmentObj:
      action.type === "set_assignment_obj" ? action.data : state.assignmentObj,
    editAttendance:
      action.type === "set_edit_attendance"
        ? action.data
        : state.editAttendance,
    selectedDate:
      action.type === "set_selected_date" ? action.data : state.selectedDate,
    candidateData:
      action.type === "set_candidate_data" ? action.data : state.candidateData,
    attendance:
      action.type === "set_attendance" ? action.data : state.attendance,
    candidateObj:
      action.type === "set_candidate_obj" ? action.data : state.candidateObj,
    loading: action.type === "set_loading" ? action.data : state.loading,
  };
};

const Assignment = ({ traingId }) => {
  const [state, dispatch] = useReducer(reducer, INITAL_STATE);

  const handleAssignmentClose = () => {
    dispatch({
      type: "set_edit_assignment",
      data: false,
    });
    getCandidateAssignment();
  };

  const columns = [
    {
      title: "Submission No.",
      dataIndex: "number",
      key: "number",
      width: "15%",
    },
    {
      title: "Submision Date",
      dataIndex: "submit_date",
      key: "submit_date",
      width: "20%",
      render:(text)=>{
        return <p>
          {moment(text).format("MM-DD-YYYY")}
        </p>
      }
    },
    {
      title: "Remark",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Edit",
      key: "operation",
      width: "10%",
      render: (text, record) => (
        <h3>
          <FiEdit2
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch({
                type: "set_edit_assignment",
                data: true,
              });
              dispatch({
                type: "set_assignment_obj",
                data: record,
              });
            }}
          />
        </h3>
      ),
    },
  ];

  const getCandidateDate = async () => {
    try {
      dispatch({
        type: "set_loading",
        data: true,
      });
      const data = await axiosJSON.get(`api/training_candidate/${traingId}/`);
      const res = data.data.data;
      dispatch({
        type: "set_candidate_obj",
        data: res,
      });

      dispatch({
        type: "set_candidate_data",
        data: {
          training_candidate_id: res?.candidate?.id,
          name: res?.candidate?.name,
          email: res?.candidate?.email,
          recruiter: res?.candidate?.recruiter,
          status: res?.status,
          enrollmentDate: res?.enrollment_date,
          link: res?.link,
          technology: res?.technology,
          module: res?.module,
        },
      });
      dispatch({
        type: "set_attendance",
        data: res.attendance,
      });
      dispatch({
        type: "set_loading",
        data: false,
      });
    } catch (error) {
      dispatch({
        type: "set_loading",
        data: false,
      });
      message.error("Something went wrong, please reach to support");
    }
  };

  useEffect(() => {
    getCandidateAssignment();
    getCandidateDate();
    // eslint-disable-next-line
  }, []);

  const getCandidateAssignment = () => {
    dispatch({
      type: "set_loading",
      data: true,
    });
    axiosJSON
      .get("/api/training_candidate/" + traingId + "/assignment/")
      .then(({ data, status }) => {
        dispatch({
          type: "set_assignment_list",
          data: data.data,
        });
        dispatch({
          type: "set_loading",
          data: false,
        });
      })
      .catch(function (error) {
        message.error(error.message);
        dispatch({
          type: "set_loading",
          data: false,
        });
      });
  };
  const dateCellRender = (value) => {
    if (value) {
      let secVal = moment(value).format("YYYY-MM-DD");
      let attendanceObj = state.attendance?.find((sample) =>
        moment(sample.date.date).isSame(secVal)
      );
      if (attendanceObj && attendanceObj.is_present === true) {
        return <div className="green_circle">{moment(value).date()}</div>;
      } else if (
        attendanceObj &&
        (attendanceObj.is_present === false ||
          moment(attendanceObj.date.date) < moment())
      ) {
        return <div className="red_circle">{moment(value).date()}</div>;
      }
    }
  };
  const onDateSelect = (date) => {
    if (date) {
      let secVal = moment(date).format("YYYY-MM-DD");
      let attendanceObj = state.attendance?.find((sample) =>
        moment(sample.date.date).isSame(secVal)
      );
      if (attendanceObj) {
        if (
          attendanceObj.is_present === true ||
          attendanceObj.is_present === false ||
          moment(attendanceObj.date.date) < moment()
        ) {
          state.candidateObj.consultant = state.candidateObj.candidate;
          state.candidateObj.training_candidate_id = state.candidateObj.id;
          dispatch({
            type: "set_edit_attendance",
            data: true,
          });
          dispatch({
            type: "set_selected_date",
            data: attendanceObj,
          });
        } else {
          message.error("Can't mark attendance for future");
        }
      }
    }
  };
  const disabledDate = (date) => {
    if (date) {
      let secVal = moment(date).format("YYYY-MM-DD");
      let attendanceObj = state.attendance?.find((sample) =>
        moment(sample.date.date).isSame(secVal)
      );
      if (attendanceObj) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Spin spinning={state.loading}>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "20px",
          }}
        >
          <div
            className="gx-module-box-content mt-4"
            style={{ display: "flex", flex: 3 }}
          >
            <Table
              className="candidate_table_training p-0"
              columns={columns}
              dataSource={state.assignmentList}
              pagination={false}
            />
          </div>
          <div
            className="mt-4"
            style={{
              display: "flex",
              flex: 1,
              gap: "16px",
            }}
          >
            <Calendar
              fullscreen={false}
              dateCellRender={dateCellRender}
              className="candidateCalender"
              onSelect={(date) => onDateSelect(date)}
              onChange={(date) => {
                // onDateSelect(date, "1");
              }}
              disabledDate={disabledDate}
            />
          </div>
        </div>
        {state.editAssignment && (
          <Modal
            title="Assignment"
            open={state.editAssignment}
            footer={null}
            onCancel={() => {
              dispatch({
                type: "set_edit_assignment",
                data: false,
              });
            }}
          >
            <EditAssigment
              candidate={state.candidateData}
              assignmentObj={state.assignmentObj}
              edit={true}
              closeModal={handleAssignmentClose}
            />
          </Modal>
        )}
        {state.editAttendance && (
          <Modal
            title="Edit Attendance"
            width={540}
            open={state.editAttendance}
            footer={null}
            onCancel={() => {
              dispatch({
                type: "set_edit_attendance",
                data: false,
              });
            }}
          >
            <EditAttendance
              selectedRows={[state.candidateObj]}
              selectedCandidates={[state.candidateObj.id]}
              selectCandidatesNames={[state.candidateObj.candidate.name]}
              candidateList={[]}
              closeModal={() => {
                dispatch({
                  type: "set_edit_attendance",
                  data: false,
                });
                getCandidateDate();
              }}
              dateList={state.attendance.map((item) => {
                return item.date;
              })}
              training_id={traingId}
              singleRowEdit={true}
              selectedDate={state.selectedDate.date}
            />
          </Modal>
        )}
      </div>
    </Spin>
  );
};

export default Assignment;
