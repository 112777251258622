import React, { useEffect, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import axiosJSON from "../../util/Api";
import {
  Button,
  Form,
  Input,
  message,
  Pagination,
  Select,
  Spin,
  Table,
} from "antd";
// import AppModuleHeader from "../../components/AppModuleHeader";
import { CustomModal as Modal } from "../../util/CustomModal";
import { useSelector } from "react-redux";
import useDebounce from "../../util/useDebouncing";

const getColumns = (changeTeam, checkRole) => {
  return [
    {
      title: "Name",
      width: 150,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "1",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "3",
      render: (text, record) => <div>{text ? text : "-"}</div>,
    },
    {
      title: "Team",
      dataIndex: "team",
      key: "4",
    },
    {
      title: "Bench Count",
      dataIndex: "bench_count",
      key: "5",
    },
    {
      title: "Change Team",
      key: "6",
      hidden: !checkRole(),
      onCell: (record, rowIndex) => ({
        onClick: () => {
          changeTeam(record);
        },
      }),
      render: (text, record) => (
        <div className="drop_correction">
          <AiFillEdit />
        </div>
      ),
    },
  ].filter((item) => !item.hidden);
};

const ChangeTeamModal = ({ teamList, record, onFinish }) => {
  return (
    <div className="gx-login-container">
      <div className="gx-login-content experience-form">
        <Form
          name="basic"
          initialValues={{ name: record.name }}
          onFinish={onFinish}
          className="gx-signin-form gx-form-row0"
        >
          <Form.Item name="name" label="Recruiter Name" readOnly={true}>
            <Input
              className="gx-input-lineheight"
              type="text"
              placeholder="Job Title"
              readOnly={true}
            />
          </Form.Item>
          <Form.Item
            name="team"
            label="Select Team"
            rules={[{ required: true, message: "Please select Team" }]}
          >
            <Select showSearch placeholder="Select Team">
              {teamList.map((team, i) => (
                <Select.Option key={team.id} value={team.id}>
                  {team.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const RecruitmentDetail = () => {
  const [recruiterData, setRecruiterData] = useState([]);
  const [page, setPage] = useState(1);
  const [prevPage, setPrevPage] = useState(1);
  const [disable, setDisable] = useState(true);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [prevQuery, setPrevQuery] = useState("");
  const [teamList, setTeamList] = useState([]);
  const [team, setTeam] = useState(null);
  const [prevTeam, setPrevTeam] = useState(null);
  const [record, setRecord] = useState(null);
  const { authUser, newUser } = useSelector(({ auth }) => auth);
  const debouceSearchTerm = useDebounce(query, 400);

  const getRecruiterData = (
    incomingPage,
    incomingSize,
    incomingQuery = "",
    incomingTeam
  ) => {
    if (incomingQuery !== "" || incomingTeam) {
      incomingPage = 1;
      incomingSize = size;

      if (
        incomingQuery === prevQuery &&
        incomingTeam === prevTeam &&
        prevPage !== page
      ) {
        incomingPage = page;
      }
    }
    setPage(incomingPage);

    setLoading(true);
    axiosJSON
      .get(
        `api/recruiter_details/?team=${
          incomingTeam ? incomingTeam : ""
        }&query=${incomingQuery}&page=${incomingPage}&page_size=${incomingSize}`
      )
      .then(({ data }) => {
        setRecruiterData(data.data);
        setTotal(data.total);
        setLoading(false);
        setDisable(false);
      })
      .catch(function (error) {
        message.error(error.message);
        setLoading(false);
        setDisable(false);
      });
  };

  const getTeamList = () => {
    axiosJSON
      .get("api/util/teams/?dept=Recruitment")
      .then(({ data, status }) => {
        setTeamList([...data.data]);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  useEffect(() => {
    getRecruiterData(page, size, query, team);
    getTeamList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (debouceSearchTerm) {
      getRecruiterData(page, size, debouceSearchTerm, team);
    } else {
      if (query === "") {
        getRecruiterData(page, size, query, team);
      }
    }
    // eslint-disable-next-line
  }, [page, size, debouceSearchTerm, team]);

  const changeTeam = (record) => {
    setRecord(record);
  };

  const onFinish = (values) => {
    const body = { team_id: values.team };
    axiosJSON
      .put(`api/recruiter_details/${record.id}/`, body)
      .then(({ data, status }) => {
        message.success(data.message);
        setRecord(null);
        getRecruiterData(page, size, query, team);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  const checkRole = () => {
    if (newUser) {
      return newUser.role === "admin" || newUser.role === "superadmin"
        ? true
        : false;
    } else {
      return authUser.role === "admin" || authUser.role === "superadmin"
        ? true
        : false;
    }
  };

  return (
    <div className="gx-main-content">
      <div className="gx-app-module">
        <div className="gx-module-box width-100">
          <div className="gx-module-box-header">
            <div className="mar_min">
              <div className="col-12 vertical_center">
                <div className="row top_rev">
                  <div className="search col-2 pr-0">
                    <Input
                      placeholder="Search Recruiter"
                      disabled={disable}
                      onChange={(e) => {
                        setPrevQuery(query);
                        setPrevTeam(team);
                        setPrevPage(page);
                        setQuery(e.target.value);
                      }}
                      value={query}
                    />
                  </div>
                  <div>
                    <Select
                      showSearch
                      allowClear={true}
                      value={team}
                      disabled={disable}
                      style={{ width: "9.5rem" }}
                      placeholder="Select Team"
                      onChange={(val) => {
                        setPrevQuery(query);
                        setPrevTeam(team);
                        setPrevPage(page);
                        setTeam(val);
                        // getRecruiterData(1, size, query, val);
                      }}
                    >
                      {teamList.map((team, i) => (
                        <Select.Option key={team.id} value={team.name}>
                          {team.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  <div className="gx-module-logo grw">
                    <i className="icon icon-graduation gx-mr-4"></i>Recruiter
                    Detail
                  </div>
                </div>
              </div>
            </div>
            <div className="gx-module-box-content mt-4">
              <Spin spinning={loading}>
                <Table
                  className="candidate_table p-0"
                  rowKey={(record) => record.id}
                  columns={getColumns(changeTeam, checkRole)}
                  dataSource={recruiterData}
                  pagination={false}
                />
              </Spin>
            </div>
            {recruiterData.length > 0 && (
              <div className="Pagination-section">
                <Pagination
                  current={page}
                  total={total}
                  pageSize={size}
                  onChange={(pg, size) => {
                    setPrevQuery(query);
                    setPrevTeam(team);
                    setPrevPage(page);
                    setPage(pg);
                    setSize(size);
                    // getRecruiterData(page, size, query, team);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {record && (
        <Modal
          title="Change Recruiter Team"
          visible={record}
          footer={null}
          onCancel={() => setRecord(null)}
        >
          <ChangeTeamModal
            teamList={teamList}
            record={record}
            onFinish={onFinish}
          />
        </Modal>
      )}
    </div>
  );
};

export default RecruitmentDetail;
