import React, { Component } from "react";
import {Col, Row, Select, Upload, Button, message, Card, Progress, Spin} from "antd";
import Widget from "../../../components/Widget";
import { Menu, Dropdown } from 'antd';
import { UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import axiosJSON from "../../../util/SetHeaderAPI";
import ViewFile from '../../../modals/Candidate/fileViewer';
import docx from "../../../../src/assets/images/fileTypes/docx-file.png"
import pdf from "../../../../src/assets/images/fileTypes/pdf-file.png"
import jpg from "../../../../src/assets/images/fileTypes/images.png"
import "nprogress/nprogress.css";
import nprogress from "nprogress";
import {CustomModal as Modal, confirm} from "../../../util/CustomModal"

// const { confirm } = Modal;

class Documents extends Component {
  constructor(props) {
    super(props);
    const splitArray = window.location.href.split("/")
    this.id = splitArray[splitArray.length-2];
    this.state = {
      fileList: [],
      uploading: false,
      doc_type: "",
      doc_type_arr: [],
      docs: [],
      showFile: false,
      doc_id: '',
      file_name: '',
      prgObj:null,
      loading: false
    }
    this.Mounted = false
  }

  componentWillUnmount() {
    this.Mounted = false
  }

  componentDidMount() {
    nprogress.start()
    this.Mounted = true
    this.getDocuments();
    this.getDocumentArray()
    nprogress.done()
  }

  getDocuments = () => {
    // const id = this.props.history.location.pathname.split("/")[2];
    axiosJSON.get('api/candidate/' + this.id + '/document/')
      .then(({ data, status }) => {
        if (status === 200) {
          this.setState({
            docs: data.data
          })
        }
      }).catch(function (error) {
        message.error(error.message)
      })
  }

  showDeleteConfirm = (doc) => {
    // const id = this.props.history.location.pathname.split("/")[2];
    let self = this;
    confirm({
      title: 'Are you sure delete this document',
      icon: <ExclamationCircleOutlined />,
      content: doc.file_name,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        self.setState({
          loading: true
        })
        axiosJSON.delete('api/candidate/' + self.id + '/document/?id=' + doc.id)
          .then(({ data, status }) => {
            if (status === 204) {
              self.setState({
                loading: false
              })
              message.success("Document Deleted!!")
              self.getDocuments()
            }
          }).catch(function (error) {
              self.setState({
                loading: false
              })
            message.error(error.message)
          })
      }
    });
  }

  getDocumentArray = () => {
    axiosJSON.get('api/choice/?type=attachment')
      .then(({ data, status }) => {
        if (status === 200) {
          let arr = [];
          data.data.map(ele => {
            if (ele.name !== "resume") {
              arr.push(ele)
            }
            return arr
          })
          this.setState({
            doc_type_arr: arr
          })
        }
      }).catch(function (error) {
        message.error(error.message)
      })
  }

  handleDocTypeChange = (value) => {
    this.setState({
      doc_type: value
    })
  }
  getDocumentUrl=(doc)=>
  {
    axiosJSON.get('api/attachment_url/'+doc.id+'/')
      .then(res => {
        window.open(res.data.data.file)
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  menu = (doc) => (
    <Menu>
      <Menu.Item key="0">
        <span onClick={() => this.showDeleteConfirm(doc)}>Delete</span>
      </Menu.Item>
      <Menu.Item key="1">
        <span onClick={() => this.getDocumentUrl(doc)}>Download</span>
      </Menu.Item>
    </Menu>
  );

  handleUpload = () => {
    this.setState({
      uploading:true
    })
    const formData = new FormData();
    formData.append('attachment_type', this.state.doc_type);
    formData.append('file', this.state.fileList[0]);
    // const id = this.props.history.location.pathname.split("/")[2];
    axiosJSON.post('api/candidate/' + this.id + '/document/', formData,
      {
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null) {
            let prgObj = {
              'file_name': this.state.fileList[0].name,
              'prg': Math.round((progressEvent.loaded * 100) / totalLength)
            }
            this.setState({
              prgObj: prgObj
            })
            if (Math.round((progressEvent.loaded * 100) / totalLength) === 100) {
              this.setState({
                prgObj: null
              })
            }
          }
        }})
      .then(({ data, status }) => {
        if (status === 201) {
          message.success("Documents Added!")
          this.setState({
            fileList: [],
            doc_type: '',
            uploading:false
          }, () =>
            this.getDocuments())
        }
      }).catch(function (error) {
        message.error(error.message)
      })
  }

  viewFile = (flag, doc) => {
    this.setState({
      showFile: !flag,
      doc_id: doc.id,
      file_name: doc.file_name
    })
  }

  render() {
    const { doc_type_arr, doc_type, uploading, fileList } = this.state;
    const props = {
      accept: '.docx,.pdf,image/*',
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
      showUploadList:false
    };
    return (

      <div className="gx-profile-content">
        <Spin spinning={this.state.loading}>
          <Widget title="Documents" styleName="gx-card-profile">
            <Row>
              <Col xl={4} lg={14} md={14} sm={24} xs={24}>
                <Select
                  value={doc_type}
                  style={{ width: '100%' }}
                  placeholder="Select Documenent Type"
                  onChange={this.handleDocTypeChange}
                >
                  {
                    doc_type_arr.length !== 0 && doc_type_arr.map((type, i) =>
                      <Select.Option key={i} value={type.display_name}>{type.display_name}</Select.Option>
                    )
                  }
                </Select>
              </Col>

              <Col xl={4} lg={14} md={14} sm={24} xs={24}>
                {doc_type !== "" &&
                  <Upload {...props}>
                    <Button>
                      <UploadOutlined /> Select File
                  </Button>
                  </Upload>}
                {
                  this.state.fileList.length > 0 && <hr/>
                }
                <span>{this.state.fileList.length > 0 && this.state.fileList[0].name}</span>
                {this.state.prgObj !== null && this.state.fileList.length > 0 && this.state.fileList[0].name === this.state.prgObj.file_name &&
                <Progress percent={this.state.prgObj.prg} size="small"/>
                }
              </Col>

              <Col xl={4} lg={14} md={14} sm={24} xs={24}>
                {doc_type_arr.length !== 0 &&
                  <Col>
                    <Button
                      type="primary"
                      onClick={this.handleUpload}
                      disabled={fileList.length === 0}
                      loading={uploading}
                    >
                      {doc_type !== "" && uploading ? 'Uploading' : 'Start Upload'}
                    </Button>
                  </Col>
                }
              </Col>

            </Row>

            <div className="pdf-uploaded-box">
              {
                this.state.docs.length > 0 && <h1>Uploaded:</h1>
              }
              {this.state.docs.map((doc, index) => {
                if (doc.file_ext === 'docx')
                  return <Card className="new-box" key={index}>
                    <Dropdown.Button overlay={this.menu(doc)} trigger={['click']} />
                    <img src={docx} alt='resume' height="100px" width="100px"
                      onClick={() => this.viewFile(this.state.showFile, doc)} />
                    <div className="filenName">{doc.file_name}</div>
                    <div className="ant-tag tag_res m-0">Resume</div>
                    </Card>
                else if (doc.file_ext === 'pdf')
                  return <Card className="new-box" key={index}>
                    <Dropdown.Button overlay={this.menu(doc)}  />
                    <img src={pdf} alt='resume' height="100px" width="100px"
                      onClick={() => this.viewFile(this.state.showFile, doc)} />
                    <div className="filenName">{doc.file_name}</div>
                    <div className="ant-tag tag_res m-0">Resume</div>
                  </Card>
                else if (doc.file_ext === 'jpg'||'jpeg'||'png')
                  return <Card className="new-box" key={index}>
                    <Dropdown.Button overlay={this.menu(doc)} trigger={['click']} />
                    <img src={jpg} alt='resume' height="100px" width="100px"
                      onClick={() => this.viewFile(this.state.showFile, doc)} />
                    <div className="filenName">{doc.file_name}</div>
                    <div className="ant-tag tag_res m-0">{doc.attachment_type}</div>
                  </Card>
                else
                  return null
              })}

            </div>

          </Widget>
        </Spin>
        {this.state.showFile && <Modal
          className="document_modal"
          title={this.state.file_name}
          visible={this.state.showFile}
          footer={null}
          onCancel={() => this.setState({ showFile: false })}>
          <ViewFile doc_id={this.state.doc_id} />
        </Modal>}
      </div>

    );
  }
};

export default Documents;
