import React from 'react';
import {Select} from 'antd';

const CustomSelect = ({multiple,disabled, name, value, onChange, options, placeholder}) => {

  return (
    <Select
      className="form-field"
      disabled={disabled}
      placeholder={placeholder}
      multiple={multiple}
      showSearch
      name={name}
      value={value}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={onChange}
    >
      {options.map((ele, i) =>
        <Select.Option key={i} value={ele}>{ele}</Select.Option>
      )}
    </Select>
  );
}
export default CustomSelect;

