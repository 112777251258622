export const styles = {
  outerWrapperStyle: {
    marginLeft: "-2.3vw",
    padding: "16px",
    paddingLeft: "20px",
    paddingRight: "30px",
    boxSizing: "border-box",
    position: "relative",
    zIndex: "1",
    background: "#D5D8E1",
    width: "106%",
  },
  innerWrapper: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #EAEDF2",
    background: "#FFFFFF",
    borderRadius: "12px",
    gap: "8px",
    fontSize: "16px",
    marginTop: "0px",
  },
  feedbackOuterWrapper: {
    display: "flex",
    gap: "15px",
    padding: "12px 16px",
  },
  logoWrapper: {
    height: "45px",
    width: "45px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#D7DDFA",
    padding: "12px",
    color: "#26305C",
  },
  rightWrapper: {
    display: "flex",
    gap: "8px",
    padding: "0px",
    alignItems: "center",
  },
  midDivStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    padding: "2px 10px",
    background: "#FFF8EA",
    borderRadius: "4px",
    color: "#E8930B",
    fontSize: "14px",
  },
  descriptionStyle: {
    color: "#717885",
    lineHeight: "25px",
    fontWeight: "400px",
  },
  rightOuterWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  userStyle: {
    color: "#353D4A",
    fontSize: "15px",
  },
};
