import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import axiosJSON from "../../util/SetHeaderAPI";

const FormItem = Form.Item;

const AddNewSource = (props) => {
  const [fields, setFields] = useState([
    {
      name: ["source"],
      value: "",
    },
  ]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    const body = {
      name: values.source,
      display_name: values.display_name,
    };
    axiosJSON
      .post("api/source/", body)
      .then(({ data, status }) => {
        if (status === 201 || status === 200) {
          message.success("Source Added!");
          props.closeModal();
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  return (
    <div>
      <Form
        fields={fields}
        name="basic"
        onFieldsChange={(newFields) => {
          setFields(newFields);
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="gx-signin-form gx-form-row0"
      >
        <FormItem name="source">
          <Input
            className="gx-input-lineheight"
            type="text"
            placeholder="Add Source Name/Sheet Name"
          />
        </FormItem>
        <FormItem name="display_name">
          <Input
            className="gx-input-lineheight"
            type="text"
            placeholder="Add Source Display Name"
          />
        </FormItem>

        <FormItem>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </FormItem>
      </Form>
    </div>
  );
};

export default AddNewSource;
