import { deserialize } from "../components/Editor/parseJson";

export default function getDeserializeText(data) {
  var text = null;
  if (data === "<p></p>") {
    text = [
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ];
  } else {
    text = deserialize(new DOMParser().parseFromString(data, "text/html").body);
  }
  return text;
}
