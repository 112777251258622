import { Button, message } from "antd";
import React, { useEffect, useState } from "react";
import SlateEditor from "../../components/CommentTagEditor";
import { serialize } from "../../components/Editor/parseJson";
import axiosJSON from "../../util/SetHeaderAPI";

const beforeMatch = /^@(\w+)$/;
const beforeStr = "@";
const afterStr = "";

const AddComment = (props) => {
  const [selected_user, setSelectedUser] = useState([]);
  const [flag, setFlag] = useState(false);
  const [comment, setComment] = useState([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);

  useEffect(() => {
    setFlag(true);
  }, []);

  const handleChange = (e) => {
    setComment(e);
  };

  const onSelectUser = (value) => {
    let user = selected_user;
    user.push(value);
    setSelectedUser(user);
  };

  const onSubmitClick = () => {
    let text = serialize(comment);
    if (text === "<p></p>") {
      message.error("Comment is required!");
      return;
    }
    let eid = [];
    if (selected_user.length > 0) {
      selected_user.map((user) => {
        let i = props.user_list.find((u) => u.username === user.username);
        eid.push(i.id);
        return null;
      });
    }
    const data = {
      tagged_user: eid,
      comment_text: text,
    };
    axiosJSON
      .put("api/training_candidate/" + props.candidateId + "/", data)
      .then(({ data, status }) => {
        if (status === 202) {
          message.success(data.message);
          props.closeModal();
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  return (
    <div>
      <div className={""}>
        <span style={{ color: "red", fontSize: 12 }}>*</span>
        <label className="pt-2 font-weight-500">Comment :</label>
      </div>
      <div className="col-md-12 my-1 border-radius-10 border px-4 py-2 slate_sty">
        {flag && (
          <SlateEditor
            beforeMatch={beforeMatch}
            afterStr={afterStr}
            beforeStr={beforeStr}
            onSelectUser={onSelectUser}
            setData={handleChange}
            user_list={props.user_list}
            value={comment}
          />
        )}
      </div>
      <div className=" mt-3 text-right" style={{ clear: "both" }}>
        <Button className="float-none" type="primary" onClick={onSubmitClick}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default AddComment;
