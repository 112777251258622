import React from "react";
import {Button, Input, message} from "antd";
import {DeleteOutlined, PlusOutlined, EditOutlined} from '@ant-design/icons';
import axiosJSON from "../../util/SetHeaderAPI";

const TextArea = Input.TextArea;

class EditAddress extends React.Component {
  state = {
    editAddress: [false],
    listKeys: [],
    address_arr: []
  };
  Mounted = false

  componentWillUnmount() {
    this.Mounted = false
  }

  componentDidMount() {
    this.Mounted = true
    this.getAddress(this.props.id)
    this.setState({
      listKeys: [],
      changeAddress: {},
      address_arr: []
    })
  }

  getAddress = (id) => {
    axiosJSON.get('api/candidate/' + id + '/address/')
      .then(({data, status}) => {
        if (status === 200) {
          let obj = {}
          let arr = [], listKeys = []
          data.data.map((ele, i) => {
            obj = {
              'key': i,
              'id': ele.id,
              'street': ele.street,
              'state': ele.state,
              'city': ele.city,
              'country': ele.country,
              'zip_code': ele.zip_code,
            }
            arr.push(obj)
            listKeys.push(i)
            return null
          })
          this.setState({
            listKeys: listKeys,
            address_arr: arr
          })
        }
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  remove = (k, i) => {
    const {listKeys, address_arr} = this.state;
    if (address_arr[i].hasOwnProperty('id') && address_arr[i].key === k) {
      //delete api call
      axiosJSON.delete('api/candidate/' + this.props.id + '/address/' + address_arr[i].id)
        .then(({data, status}) => {
          message.success("Address Removed")
          this.setState({
            address_arr: address_arr.filter(key => key.key !== k),
            listKeys: listKeys.filter(key => key !== k),
            changedAddress: {},
            editAddress: [false]
          });
        }).catch(function (error) {
        message.error(error.message)
      })

    } else {
      this.setState({
        address_arr: address_arr.filter(key => key.key !== k),
        listKeys: listKeys.filter(key => key !== k),
        editAddress: [false]
      });
    }

  };

  add = () => {
    const {listKeys, address_arr} = this.state;
    let arr = [false];
    arr[address_arr.length] = true;
    let obj={
      key: address_arr.length,
      street: "",
      city: "",
      state: "",
      country: "",
      zip_code: ""
    }
    address_arr.push(obj)
    listKeys.push(listKeys.length)
    this.setState({
      address_arr: address_arr,
      listKeys: listKeys,
      editAddress: arr
    });
  };
  onSubmit = (index) => {
    if(this.state.changeAddress.hasOwnProperty("id")){
      const body = {
        address_id:this.state.changeAddress.id,
        street: this.state.changeAddress.street,
        city: this.state.changeAddress.city,
        state: this.state.changeAddress.state,
        country: this.state.changeAddress.country,
        zip_code: this.state.changeAddress.zip_code
      }
      axiosJSON.put('api/candidate/' + this.props.id + '/address/'+this.state.changeAddress.id+"/", body)
        .then(({data, status}) => {
          if (status === 202) {
            message.success(data.message)
            let editAddress = this.state.editAddress;
            editAddress[index] = false
            this.getAddress(this.props.id )
            this.setState({
              editAddress: editAddress,
              changeAddress: {} 
            })

          }
        }).catch(function (error) {
        message.error(error.message)
      })
    }
    else{
      const body = {
        street: this.state.changeAddress.street,
        city: this.state.changeAddress.city,
        state: this.state.changeAddress.state,
        country: this.state.changeAddress.country,
        zip_code: this.state.changeAddress.zip_code
      }
      axiosJSON.post('api/candidate/' + this.props.id + '/address/', body)
        .then(({data, status}) => {
          if (status === 201) {
            message.success(data.message)
            let editAddress = this.state.editAddress;
            editAddress[index] = false
            this.getAddress(this.props.id )
            this.setState({
              editAddress: editAddress,
              changeAddress: {}
            })

          }
        }).catch(function (error) {
        message.error(error.message)
      })
    }

  }
  handleChange = (e, type, index) => {
    let address_arr = this.state.address_arr;
    let changedArr = {}
    address_arr.map((field, i) => {
      if (i === index) {
        changedArr = field;
        field[type] = e.target.value
      }
      return null
    })
    this.setState({
      address_arr: address_arr,
      changeAddress: changedArr
    })
  }
  setEditAddress = (index) => {
    let arr = [false];
    arr[index] = true;
    this.setState({
      editAddress: arr,
      changeAddress: this.state.address_arr[index]
    })
  }

  render() {
    const {listKeys, address_arr, editAddress} = this.state;

    return (
      <div>
        {listKeys.length !== 0 && listKeys.map((key, index) => {
          return (
            <div className='gx-input-lineheight address-section'>
              <div>Address Line {index + 1}:

                {!editAddress[index] && <EditOutlined onClick={() => this.setEditAddress(index)}/>}

                <DeleteOutlined
                  className="dynamic-delete-button"
                  onClick={() => this.remove(key, index)}
                />

              </div>
              {address_arr.length !== 0 && editAddress[index] ?
                <div key={key}>
                  <TextArea className='gx-input-lineheight' value={address_arr[index].street}
                            onChange={(e) => this.handleChange(e, 'street', index)}
                            placeholder="Street"/>
                  <Input className='gx-input-lineheight' value={address_arr[index].city}
                         onChange={(e) => this.handleChange(e, 'city', index)}
                         placeholder="City"/>
                  <Input className='gx-input-lineheight' value={address_arr[index].state}
                         onChange={(e) => this.handleChange(e, 'state', index)}
                         placeholder="State"/>
                  <Input className='gx-input-lineheight' value={address_arr[index].country}
                         onChange={(e) => this.handleChange(e, 'country', index)}
                         placeholder="Country"/>
                  <Input className='gx-input-lineheight' value={address_arr[index].zip_code}
                         onChange={(e) => this.handleChange(e, 'zip_code', index)}
                         placeholder="Zip"/>
                </div> :
                address_arr.length !== 0 &&
                <div key={key} className='gx-mb-4'>
                  <TextArea onClick={() => this.setEditAddress(index)} className='gx-input-lineheight'
                            value={address_arr[index].street +
                            ", " + address_arr[index].city +
                            ", " + address_arr[index].country +
                            " - " + address_arr[index].zip_code
                            }
                            placeholder="Address"/>
                </div>
              }
              {editAddress[index] &&
              <Button type="primary" htmlType="submit" onClick={() => this.onSubmit(index)}>
                Submit
              </Button>
              }
            </div>
          );
        })}
        <Button type="dashed" onClick={this.add}>
          <PlusOutlined/>
          Add address
        </Button>

      </div>

    );
  }
}

export default EditAddress;
