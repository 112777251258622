import React, {Component} from "react";
import { withRouter } from 'react-router-dom';

class TemplateList extends Component {

  getTemplateLink=(id)=>{
        this.props.history.push("/template?type=email&is_private="+this.props.is_private+"&id="+id)
  }

  render() {
    return (
      <div>
        <div className="gx-contact-item gx-dragndrop-item">
          <div className="gx-col gx-dragndrop-name gx-text-truncate gx-px-2">Name</div>
          <div className="gx-col gx-dragndrop-phone gx-d-none gx-d-md-flex gx-px-2">Type</div>
        </div>

        {this.props.data.length > 0 ? this.props.data.map((template, index) =>
            <div key={template.id} className="gx-contact-item gx-dragndrop-item" onClick={()=>this.getTemplateLink(template.id)}>
              <div className="gx-col gx-pointer gx-dragndrop-name gx-text-truncate gx-px-2">{template.name}</div>
              <div className="gx-col gx-dragndrop-phone gx-d-none gx-d-md-flex gx-px-2">

                <span className="gx-pointer gx-text-blue">{template.email_type}</span>

              </div>
            </div>
          )
          :
          <div className="gx-contact-item gx-dragndrop-item">No templates available</div>
        }
      </div>
    );
  }

};

export default withRouter(TemplateList);
