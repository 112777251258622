import React from "react";
import {useSelector} from "react-redux";
import {UserOutlined,ScheduleOutlined,BellOutlined} from "@ant-design/icons"

const WelComeCard = ({data}) => {
  const authUser = useSelector(({auth}) => auth.authUser);
  const newUser = useSelector(({auth}) => auth.newUser);

  return (
    <div className="gx-wel-ema gx-pt-xl-2">
      <h1 className="gx-mb-3">Welcome {newUser ? newUser.name : authUser && authUser.name}!</h1>
      <p className="gx-fs-md gx-text-uppercase">You Have</p>
      <ul className="gx-list-group" style={{padding:"5px"}}>
        <li>
          <UserOutlined />
          <span>{data && data.new_status_candidates} New Candidates</span>
        </li>
        <li>
          <ScheduleOutlined />
          <span>{data && data.screening_count} Screenings Scheduled</span>
        </li>
        <li>
          <BellOutlined />
          <span>{data && data.notification} New Notifications</span>
        </li>
      </ul>
    </div>

  );
};

export default WelComeCard;
