import React from 'react';
import {InputNumber} from 'antd';

const CustomInputNumber = ({name, value, disabled, placeholder, onChange}) => {

  return (

    <InputNumber className="form-field" disabled={disabled} min={1} max={1000000} name={name} value={value}
                 placeholder={placeholder} onChange={onChange}/>

  );
}
export default CustomInputNumber;

