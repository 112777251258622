import React, {Component} from "react";
import WelComeCard from "../../components/dashboard/CRM/WelComeCard";
import {Col, message, Row, Select,} from "antd";
import axiosJSON from "../../util/SetHeaderAPI";
import CandidatePipelinePie from "../../components/dashboard/CRM/CandidatePipelinePie";
import IconWithTextCard from "../../components/dashboard/CRM/IconWithTextCard";
import "nprogress/nprogress.css";
import nprogress from "nprogress";

// import Widget from "../../components/Widget";
// import {ClockCircleOutlined} from '@ant-design/icons';
// import moment from "moment";

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      dataCards: [],
      timeFilter: 'last_week',
      forFilter: 'team'
    }
    
  }

  componentDidMount() {
      nprogress.start();
      this.getDashboardData()
      this.getDashboardFilterData(this.state.timeFilter, this.state.forFilter)
      nprogress.done();
  }

   getDashboardData = () => {
     this.setState({data: null})
     let token = localStorage.getItem("token");
     let currentToken = localStorage.getItem("current_token");
     let t;
     if (currentToken) {
       t = currentToken
     } else {
       t = token
     }
     axiosJSON.defaults.headers['authorization'] = "Token " + t;
     axiosJSON.get('api/dashboard/')
       .then(({data, status}) => {
         this.setState({data: data.data})
       })
       .catch(error => {
         message.error(error.message)
       })
   }

  getDashboardFilterData = (time, f) => {
    nprogress.start()
    let color=['green','orange','cyan','purple','blue','pink','yellow']
    let token = localStorage.getItem("token");
    let currentToken = localStorage.getItem("current_token");
    let t;
    if (currentToken) {
      t = currentToken
    } else {
      t = token
    }
    axiosJSON.defaults.headers['authorization'] = "Token " + t;
    axiosJSON.get('api/dashboard/boxes/?time=' + time + '&for=' + f)
      .then(({data, status}) => {
        let arr=[];
        let obj={};
        data.data.map((ele,i)=>{
          obj={...ele, color: color[i],icon:this.getIcons(ele.name)}
          arr.push(obj)
          return arr
        })
        this.setState({dataCards: arr,timeFilter: time, forFilter: f })
      })
      .catch(error => {
        message.error(error.message)
      })
      nprogress.done()
  }
  getIcons=(key)=>{
    switch (key) {
      case 'new':
        return 'wall'
      case 'hired':
        return 'important'
      case 'screening':
        return 'hotel-booking'
      case 'rejected':
        return 'close-circle'
      case 'evaluation':
        return 'hotel-booking'
      case 'qualified':
        return 'graduation'
      case 'training':
        return 'auth-screen'
      default:
        return 'wall'

    }
  }

  // shouldComponentUpdate(nextProps, nextState, nextContext) {
  //   return nextState.data !== null && nextState.dataCards === this.state.dataCards
  // }

  render() {
    const {data, dataCards, timeFilter, forFilter} = this.state
    return (
      <div>
        <Row>
          <Col span={8}>
            <div className="gx-card">
              <div className="gx-card-body">
                <Row>
                  <Col span={24}>
                    <WelComeCard data={data}/>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="gx-audi-col">
                    <CandidatePipelinePie data={data}/>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xl={16} lg={24} md={24} sm={24} xs={24} className="gx-order-sm-1">
            <Row>
              <Col xl={24} lg={6} md={6} sm={12} xs={12}>

                <div className="dashboard-section">
                  <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                      <div className="dash_dropdown">
                        <Select
                          onChange={(value) => {
                          this.setState({
                            timeFilter: value,
                            dataCards:[]
                          }, () => this.getDashboardFilterData(value, forFilter))
                        }} value={timeFilter}>
                          <Select.Option key="1" value="last_month">Last Month</Select.Option>
                          <Select.Option key="2" value="last_week">Last Week</Select.Option>
                        </Select>
                      </div>
                    </Col>

                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                      <div className="dash_dropdown">
                        <Select onChange={(value) => {
                          this.setState({
                            forFilter: value,
                            dataCards: []
                          }, ()=> this.getDashboardFilterData(timeFilter, value))
                        }}
                                value={forFilter}>
                          <Select.Option key="1" value="my">My</Select.Option>
                          <Select.Option key="2" value="team">Team</Select.Option>
                        </Select>
                      </div>
                    </Col>
                  </Row>
                </div>

              </Col>


              <div className="text-card-sec">
                <ul>
                  {
                    dataCards.map(ele=><li>

                      <IconWithTextCard cardColor={ele.color} icon={ele.icon}
                                        title={ele.count} subTitle={ele.display_name}/>
                    </li>
                    )}

                  {/*<li>*/}
                  {/*  <IconWithTextCard cardColor="orange" icon="hotel-booking"*/}
                  {/*                    title={dataCards ? dataCards.evaluation_candidate : "..."} subTitle="Evaluation"/>*/}
                  {/*</li>*/}

                  {/*<li>*/}
                  {/*  <IconWithTextCard cardColor="cyan" icon="auth-screen"*/}
                  {/*                    title={dataCards ? dataCards.training_candidate : "..."}*/}
                  {/*                    subTitle="Training"/>*/}
                  {/*</li>*/}

                  {/*<li>*/}
                  {/*  <IconWithTextCard cardColor="purple" icon="graduation"*/}
                  {/*                    title={dataCards ? dataCards.qualified_candidate : "..."}*/}
                  {/*                    subTitle="Qualified"/>*/}
                  {/*</li>*/}

                  {/*<li>*/}
                  {/*  <IconWithTextCard cardColor="blue" icon="important"*/}
                  {/*                    title={dataCards ? dataCards.hired_candidate : "..."}*/}
                  {/*                    subTitle="Hired"/>*/}
                  {/*</li>*/}

                </ul>
              </div>

            </Row>

            {/*  <Widget title="Your Activity" styleName="gx-card-profile">*/}
            {/*  {activity.length !== 0 ?*/}
            {/*    <Timeline>*/}
            {/*      {activity.map((item, index) =>*/}
            {/*        <Timeline.Item key={index} dot={<ClockCircleOutlined*/}
            {/*          style={{color: item.activity_type === "created" ? '#1A90FF' : item.activity_type === "updated" ? "green" : "red"}}/>}*/}
            {/*                      color={item.activity_type === "created" ? '#1A90FF' : item.activity_type === "updated" ? "green" : "red"}>*/}
            {/*          {item.desc}*/}
            {/*          <p><span className="gx-fs-xs">{moment(item.created).format(" MMMM Do YYYY , hh:mm A")}</span></p>*/}
            {/*        </Timeline.Item>)}*/}
            {/*    </Timeline>*/}
            {/*    : <span>No Activity</span>*/}
            {/*  }*/}
            {/*</Widget>*/}

          </Col>
        </Row>
        <Row>
          <Col>

          </Col>
        </Row>

      </div>
    );
  }
}

export default Dashboard;
