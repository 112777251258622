import React from 'react';
import {Input} from 'antd';

const CustomInput = ({name,value,disabled,placeholder, onChange}) => {

  return (
    <Input className="form-field" onChange={onChange} disabled={disabled} value={value} name={name} placeholder={placeholder}/>
  );
}
export default CustomInput;

