import React, { Component } from "react";
import axiosJSON from "../../util/SetHeaderAPI";
import { BASE_URL } from "../../util/Api";
import PhoneInputType from "../../util/PhoneInput";
import FileViewer from "react-file-viewer";
import { Button, Form, Input, message, Tabs } from "antd";

const FormItem = Form.Item;

class ResumeFileViewer extends Component {
  constructor(props) {
    super(props);
    this.childKey = 0;
    this.state = {
      filePath: this.props.newCandidate[0].resume.path,
      fileType: this.props.newCandidate[0].resume.extension,
      childKey: 0,
      defaultKey: 0
      // fields: [
      //   {
      //     name: ["name"],
      //     value: this.props.newCandidate ? this.props.newCandidate.name : "",
      //   },
      //   {
      //     name: ["email"],
      //     value: this.props.newCandidate ? this.props.newCandidate.email : "",
      //   },
      //   {
      //     name: ["phone"],
      //     value: this.props.newCandidate ? this.props.newCandidate.phone : "",
      //   },
      // ],
      // editFlag: true,
    };
  }

  onError = (e) => {
    console.log(e);
  };

  createCandidate = (values, i, file) => {
    let formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("primary_no", values.phone);
    formData.append("file", file);
    axiosJSON
      .post("api/candidate/", formData)
      .then(({ data, status }) => {
        if (status === 201) {
          message.success("Candidate Added!");
          this.props.updateCandidate(i);
          this.setState({defaultKey: 0})
          this.updateView(0);
          // this.props.closeModal();
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  discard = (resume, i) => {
    const body = {
      // file: this.props.resume.path,
      file: resume.path,
    };
    axiosJSON
      .put("api/candidate/discard/", body)
      .then(({ data, status }) => {
        if (status === 200) {
          message.warn("Candidate Discarded!");
          this.props.updateCandidate(i);
          this.setState({defaultKey: 0})
          this.updateView(0);
          // this.props.closeModal();
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  updateView = (key) => {
    this.setState({
      filePath: this.props.newCandidate[key].resume.path,
      fileType: this.props.newCandidate[key].resume.extension,
      childKey: this.state.childKey + 1,
      defaultKey: key
    });
  };
  getCandidateNumber = (number) => {
   if(this.props.selectedCountry === 'in' && !number.startsWith('91')){
    number='91'+number;
   }
   if(this.props.selectedCountry === 'us' && !number.startsWith('1')){
    number='1'+number;
   }
   return number
  }
  render() {
    ++this.childKey;
    return (
      <div key={this.state.childKey}>
        <Tabs defaultActiveKey={this.state.defaultKey} onChange={this.updateView}>
          {this.props.newCandidate.map((candidate, i) => (
            <Tabs.TabPane tab={`Resume ${i + 1}`} key={i}>
              <>
                <div className="left-fileviewer" key={this.state.childKey}>
                  <FileViewer
                    fileType={this.state.fileType}
                    filePath={BASE_URL + "/" + this.state.filePath}
                    errorComponent={<div>Error</div>}
                    onError={this.onError}
                  />
                </div>

                <div className="rightform">
                  <Form
                    fields={[
                      {
                        name: ["name"],
                        value: candidate.candidate.name
                          ? candidate.candidate.name
                          : "",
                      },
                      {
                        name: ["email"],
                        value: candidate.candidate.email
                          ? candidate.candidate.email
                          : "",
                      },
                      {
                        name: ["phone"],
                        value: candidate.candidate.phone
                          ? this.getCandidateNumber(candidate.candidate.phone)
                          : "",
                      },
                    ]}
                    // onFieldsChange={(newFields) => {
                    //   this.setState({
                    //     fields: newFields,
                    //   });
                    // }}
                    name="basic"
                    onFinish={(values) =>
                      this.createCandidate(values, i, candidate.candidate.file)
                    }
                    // onFinishFailed={this.discard}
                    className="gx-signin-form gx-form-row0"
                  // initialValues={{
                  //   name: candidate.candidate.name,
                  //   email: candidate.candidate.email,
                  //   phone: candidate.candidate.phone,
                  // }}
                  // ref={this.formRef}
                  >
                    <FormItem name="name"
                    rules={[
                      { required: true, message: "Please input Candidate's Name!" },
                    ]}
                    >
                      <Input
                        className="gx-input-lineheight py-0"
                        type="text"
                        readOnly={!candidate.isEdit}
                        placeholder="Candidate's Name"
                      />
                    </FormItem>

                    <FormItem name="email" 
                     rules={[
                      { required: true, message: "Please input Candidate's Email!" },
                      { type: "email", message: "Invalid Email" },
                    ]}
                    >
                      <Input
                        className="gx-input-lineheight py-0"
                        type="text"
                        readOnly={!candidate.isEdit}
                        placeholder="Candidate's Email"
                      />
                    </FormItem>

                    {/* <FormItem name="phone">

                      <Input
                        className="gx-input-lineheight"
                        type="text"
                        readOnly={!candidate.isEdit}
                        placeholder="Candidate's Phone number"
                      />
                    </FormItem> */}
                 <Form.Item
                    className="candidate-add-from"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Phone Number!",
                      },
                      {
                        validator: async (_, value) => {
                          if (!value) return;
                          if (value.startsWith("91") && value.length < 12) {
                            return Promise.reject(
                              new Error("Please enter valid Phone Number")
                            );
                          } else if (
                            value.startsWith("1") &&
                            value.length < 11
                          ) {
                            return Promise.reject(
                              new Error("Please enter valid Phone Number")
                            );
                          }
                        },
                      },
                    ]}
                  >
                    <PhoneInputType
                      selectedCountryCode={this.props.selectedCountry}
                      disableDropdown={true}
                      disabled={!candidate.isEdit}
                    />
                  </Form.Item>

                    <FormItem>
                      <Button
                        className="resumse-btn"
                        type="primary"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                      {!candidate.isEdit && (
                        <Button
                          className="resumse-btn"
                          type="primary"
                          onClick={() => this.props.setEdit(candidate)}
                        >
                          Edit
                        </Button>
                      )}
                      <Button
                        className="resumse-btn"
                        onClick={() => this.discard(candidate.resume, i)}
                        type="primary"
                        htmlType="button"
                      >
                        Discard
                      </Button>
                    </FormItem>
                  </Form>
                </div>
              </>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    );
  }
}

export default ResumeFileViewer;
