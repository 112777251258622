import React, {useState} from "react";
import {Button, Form, message, Input,} from "antd";
import axiosJSON from "../../util/SetHeaderAPI";

const FormItem = Form.Item;

const EditSkype = (props) => {
  const [skype_fields, setFields] = useState([
    {
      name:['skype_id'],
      value: props.skype
    }
  ]);

  const onFinish = values => {
    axiosJSON.put('api/candidate/' + props.id + '/', values)
      .then(({data, status}) => {
        if (status === 202) {
          message.success(data.message)
          props.closeModal()

        }
      }).catch(function (error) {
      message.error(error.message)
    })
  };

  return (
    <div className="gx-login-content">

      <Form
        name="basic"
        onFinish={onFinish}
        onFieldsChange={(newFields)=>setFields(newFields)}
        fields={skype_fields}
        className="gx-signin-form gx-form-row0">

        <FormItem name="skype_id">
          <Input
            placeholder="Skype"/>
        </FormItem>


        <FormItem style={{ marginBottom:'0'}}>
          <Button style={{ marginBottom:'0'}} type="primary" htmlType="submit">
            Submit
          </Button>
        </FormItem>
      </Form>
    </div>
  );
};

export default EditSkype;
