import React, { Component } from "react";
import { Button, Form, DatePicker, Select, Rate } from "antd";
import TextArea from "antd/lib/input/TextArea";

const FormItem = Form.Item;

class AddTraingingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: this.props.edit,
      rating: 0,
      fields: [
        {
          name: "title",
          value: "",
        },
        {
          name: "assignment_count",
          value: "",
        },
        {
          name: "session_count",
          value: "",
        },
        {
          name: "start_date",
          value: "",
        },
        {
          name: "end_date",
          value: "",
        },
      ],
    };
    this.Mounted = false;
  }

  componentWillUnmount() {
    this.Mounted = false;
  }

  componentDidMount() {
    this.Mounted = true;
    if (this.props.data) {
      this.setState({
        fields: [
          {
            name: "title",
            value: this.props.data.title,
          },
          {
            name: "assignment_count",
            value: this.props.data.assignment_count,
          },
          {
            name: "session_count",
            value: this.props.data.session_count,
          },
          {
            name: "start_date",
            value: this.props.data.start_date,
          },
          {
            name: "end_date",
            value: this.props.data.end_date,
          },
        ],
      });
    }
  }

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  changeRating = (newRating, name) => {
    this.setState({
      rating: newRating,
    });
  };

  render() {
    return (
      <div className="gx-login-container">
        <div
          className="gx-login-content education-form"
          style={{ width: "600px" }}
        >
          <Form
            name="basic"
            fields={this.state.fields}
            onFieldsChange={(newFields) => this.setState({ fields: newFields })}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            className="gx-signin-form gx-form-row0"
          >
            <FormItem
              rules={[{ required: true, message: "Please enter Feedback" }]}
              name="feedback"
              label="Training Feedback"
            >
              <Select
                showSearch
                notFoundContent={null}
                style={{ width: "100%" }}
                placeholder="Select Update Type"
                optionFilterProp="children"
                onChange={this.handleUpdateTypeChange}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={"Project"}
              >
                <Select.Option value={"2week"}>2 week</Select.Option>
                <Select.Option value={"finalTraining"}>
                  final training
                </Select.Option>
              </Select>
            </FormItem>
            <FormItem name="remark" label="Remark">
              <TextArea
                placeholder="Add Remark"
                className="gx-input-lineheight"
              />
            </FormItem>

            <FormItem
              rules={[
                {
                  required: true,
                  message: "Please enter Assignment submission date",
                },
              ]}
              name="assignment_submission_dates"
              label="Submission date"
            >
              <DatePicker
                format="DD/MM/YYYY"
                placeholder="Add sssignment submission date"
                className="gx-input-lineheight"
              />
            </FormItem>

            <FormItem name="rating" label="Rating">
              <Rate
                allowHalf={true}
                value={this.state.rating}
                onChange={this.changeRating}
              />
            </FormItem>
            <FormItem name="additional_info" label="Additional Info">
              <TextArea
                placeholder="Add Remark"
                className="gx-input-lineheight"
              />
            </FormItem>

            <div>
              <Button
                style={{ background: "#000033", color: "white" }}
                htmlType="submit"
              >
                Add Training
              </Button>
              <Button style={{ marginRight: "-10px" }}>Cancel</Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default AddTraingingDetails;
