import React, { Component } from "react";
import axiosJSON from "../../../../util/SetHeaderAPI";
import { message, Pagination, Spin, Table, Tag, Tooltip } from "antd";
// import ReportList from "../list";
import Filters from "../filters/filters";
import "nprogress/nprogress.css";
import nprogress from "nprogress";
import { search$ } from "../Service/Api";

class ArchivedReport extends Component {
  constructor(props) {
    super(props);
    this.subscription = null;
    this.state = {
      archivedColumns: [],
      archivedData: [],
      filterObj: {},
      page: 1,
      prevY: 0,
      total: 0,
      app: "",
      page_size: 50,
      loading: false,
      query:"",
      filter_for:""
    };
  }

  componentDidMount() {
    this.subscription=search$.subscribe((res)=>{
      this.setState(res,() => this.getArchivedReport(this.state.page));
    })
    nprogress.start();
    this.getApp();
    if (this.state.app === "us") {
      var options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      };
      this.observer = new IntersectionObserver(
        this.handleObserver.bind(this),
        options
      );
      this.observer.observe(this.loadingRef);
    }
    nprogress.done();
  }
  
  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    let t = parseInt(this.state.total) / 10;
    if (Math.ceil(t) >= this.state.page && this.state.prevY > y) {
      const curPage = this.state.page + 1;
      this.getArchivedReport(curPage);
      this.setState({ page: curPage });
    }
    this.setState({ prevY: y });
  }

  getArchivedReport = (page) => {
    this.setState({ loading: true });
    // let currentData = this.state.archivedData;
    let currentColumns = [];
    axiosJSON
      .get(
        "/api/report/archived/?filter_for=" +
          this.state.filter_for +
          "&page=" +
          page +
          "&page_size=" +
          this.state.page_size +
          "&query=" +
          this.state.query +
          "&filter_json=" +
          JSON.stringify(this.state.filterObj)
      )
      .then(({ data }) => {
        data.columns.map((column, index) => {
          let obj = {};
          obj = {
            ...column,
            key: index,
            render: (text, record) => this.createRowData(text, column),
          };
          currentColumns.push(obj);
          return null;
        });
        // data.data.map((ele) => currentData.push(ele));
        this.setState({
          // archivedData: this.state.app === "us" ? currentData : data.data,
          archivedData: data.data,
          archivedColumns: currentColumns,
          loading: false,
          total: data.total
        });
      })
      .catch((error) => {
        message.error(error.message);
        this.setState({ loading: false });
      });
  };

  getApp = () => {
    axiosJSON
      .get("/api/util/app/")
      .then(({ data, status }) => {
        this.setState({
          app: data.data,
        });
      })
      .catch((error) => message.error(error));
  };

  createRowData = (text, column) => {
    if (column.tag) {
      return (
        <div>
          {text ? (
            <Tag color={"blue"} className={"reports"}>
              {text}
            </Tag>
          ) : null}
        </div>
      );
    } else if (text && Array.isArray(text)) {
      return text.map((item, index) => {
        if (index < 2) {
          return (
            <span>
              {item}
              {index === 1 ? "" : ", "}
            </span>
          );
        }
        if (text.length > 2) {
          let tooltipData = "";
          text.map((item, index) => {
            if (index === text.length - 1) {
              tooltipData = tooltipData + item;
            } else {
              tooltipData = tooltipData + item + ", ";
            }
            return null;
          });
          return <Tooltip title={tooltipData}> ...</Tooltip>;
        }
        return null;
      });
    } else {
      return <span>{text ? text : ""}</span>;
    }
  };

  selectFilter = (checked, obj, type) => {
    this.setState({
      archivedData: [],
    });
    let filterObj = this.state.filterObj;
    let o = this.state.filterObj;

    if (checked) {
      if (obj && obj.length > 0) {
        if (o.hasOwnProperty(type)) {
          o[type] = [];
          o[type] = obj;
        } else {
          o[type] = obj;
          filterObj = o;
        }
      } else {
        delete filterObj[type];
      }
    } else {
      filterObj = {};
    }
    this.setState(
      {
        filterObj: filterObj,
      },
      () => this.getArchivedReport(this.state.page)
    );
  };

  onPageChange = (page, size) => {
    this.setState(
      {
        page: page,
        page_size: size
      },
      () => this.getArchivedReport(page)
    );
  };

  render() {
    // const loadingCSS = {
    //   margin: "30px",
    // };
    // const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
    return (
      <div>
        <Filters
          filterObj={this.state.filterObj}
          selectFilter={this.selectFilter}
          history={this.props.history}
          app={this.state.app}
        />
        {/* {this.state.app === "us" ? (
          <>
            <ReportList
              reportList={this.state.archivedData}
              columns={this.state.archivedColumns}
            />
            <div
              ref={(loadingRef) => (this.loadingRef = loadingRef)}
              style={loadingCSS}
            />
            {this.state.archivedData.length > 0 ? (
              <span style={loadingTextCSS}>
                <Spin />
                Loading...
              </span>
            ) : (
              <p style={{ textAlign: "center" }}>No records found.</p>
            )}
          </>
        ) : ( */}
        <>
          <div className="report-tab-table-container">
            <Spin spinning={this.state.loading}>
              <Table
                rowKey={(record) => record.id.toString()}
                dataSource={this.state.archivedData}
                columns={this.state.archivedColumns}
                pagination={false}
                scroll={{ y: 430 }}
              />
            </Spin>
          </div>
        </>
        {/* )} */}
        {this.state.archivedData.length > 0 ? (
          <Pagination
            onChange={this.onPageChange}
            onShowSizeChange={this.onPageChange}
            className="report-pagination"
            current={this.state.page}
            total={this.state.total}
            pageSize={this.state.page_size}
          />
        ) : null}
      </div>
    );
  }
}

export default ArchivedReport;
