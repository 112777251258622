export default [

  {
    'title': 'Mark as Read',
  },{
    'title': 'Mark as Unread',
  },{
    'title': 'Archive Mails',
  },
];
