import React, {useEffect, useState} from "react";
import CustomScrollbars from "util/CustomScrollbars";
import Auxiliary from "util/Auxiliary";
import axiosJSON from "../../util/SetHeaderAPI";
import {Link} from "react-router-dom";
import {message} from 'antd';
import ActivityItem from "../../routes/UserActivity/activityItem";

const PersonalActivity = React.memo((props) => {
  const [activityList,setList]=useState([])
  const [flag,setFlag]=useState(false)

  const getActivity = (p) => {
    setFlag(true)
    axiosJSON.get('api/dashboard/activity/?page=' + p)
      .then(res => {
        let arr=[];
        res.data.data.map((ele,i)=>{
          arr.push(ele);
          return arr
        })
        setList(arr)
        setFlag(false)
      }).catch(function (error) {
      message.error(error.message)
    })
  }
  useEffect(()=>{
    getActivity(1)
  },[])
  return (
    !flag && activityList.length > 0 ? <Auxiliary>
        <div className="gx-popover-header">
          <h3 className="gx-mb-0">Your Activity</h3>
        </div>
        <CustomScrollbars className="gx-popover-scroll">
          <ul className="gx-sub-popover" >
            {activityList.map((ele, index) => <ActivityItem key={index} id={ele.id}
                                                            created={ele.created}
                                                            desc={ele.desc}
                                                            activity_type={ele.activity_type}/>)
            }
          </ul>
        </CustomScrollbars>
        <Link to='/activity'><span onClick={props.hide}>Show All</span></Link>
      </Auxiliary>
      :
      <Auxiliary>
        No activity
      </Auxiliary>
  )
});

export default PersonalActivity;

