import React from 'react';
import {Button, Input, Form, DatePicker, message, Select} from 'antd';
import axiosJSON from "../../util/SetHeaderAPI";
import moment from 'moment';

const FormItem = Form.Item;
const {TextArea} = Input;
const {Option} = Select;
const dateFormat = 'YYYY-MM-DD';
export default class AddJob extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: [
        {
          name: 'title',
          value: ''
        }, {
          name: 'email',
          value: ''
        }, {
          name: 'description',
          value: ''
        }, {
          name: 'is_shared',
          value: ''
        }, {
          name: 'position',
          value: ''
        }, {
          name: 'portal',
          value: ''
        }, {
          name: 'location',
          value: ''
        }, {
          name: 'publish_date',
          value: ''
        },
      ],
      job_position_list: [],
      job_portal_list: [],
      visibility: [{
        id: 1,
        name: "Shared",
        value: true
      }, {
        id: 2,
        name: "Private",
        value: false
      }
      ]
    }
    this.Mounted = false
  }

  componentWillUnmount() {
    this.Mounted = false
  }

  componentDidMount() {
    this.Mounted = true
    if (this.props.show) {
      let fields = [
        {
          name: 'description',
          value: this.props.data.description
        }
      ]
      this.setState({fields: fields})
    }

    axiosJSON.get('api/choice/?type=position')
      .then(({data, status}) => {
        if (status === 200) {
          this.setState({job_position_list: data.data})
        }
      }).catch(function (error) {
      message.error(error.message)
    })
    axiosJSON.get('api/portal/')
      .then(({data, status}) => {
        if (status === 200) {
          this.setState({job_portal_list: data.data})
        }
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  onFinish = (values) => {
    const body = values;
    body["publish_date"] = moment(body["publish_date"]).format(dateFormat);

    axiosJSON.post('api/job/', body)
      .then(({data, status}) => {
        if (status === 201) {
          message.success("Job Added!")
          this.props.closeModal()
        }
      }).catch(function (error) {
      message.error(error.message)
    })


  }


  render() {
    return (
      <div className="gx-login-container">
        <div className="gx-login-content education-form">

          <Form
            name="basic"
            fields={this.state.fields}
            onFieldsChange={(newFields) => this.setState({fields: newFields})}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            className="gx-signin-form gx-form-row0">

            <FormItem rules={[{required: true, message: 'Please enter Job title'}]} name="title" label="Job Title">
              {this.props.show ? this.props.data.title :
                <Input className='gx-input-lineheight' type="text" readOnly={this.props.show}/>}
            </FormItem>

            <FormItem name="email" label="E-mail">
              {this.props.show ? this.props.data.email :
                <Input className='gx-input-lineheight' type="email" readOnly={this.props.show}/>}
            </FormItem>

            <FormItem name="description" label="Job Description">
              <TextArea className='gx-input-lineheight' type="textarea" readOnly={this.props.show}/>
            </FormItem>

            <FormItem rules={[{required: true, message: 'Select a value'}]} name="is_shared" label="Visibility">
              {this.props.show ? this.props.data.description ? "Shared" : "Private"
                : <Select style={{width: '100%'}}>
                  {this.state.visibility.map((visible, index) => (
                    <Option key={index} value={visible.value}>{visible.name}</Option>
                  ))}
                </Select>}
            </FormItem>

            <FormItem name="position" label="Job Position">
              {this.props.show ? this.props.data.position && this.props.data.position.display_name
                : <Select style={{width: '100%'}}>
                  {this.state.job_position_list.map((job_position, index) => (
                    <Option key={index} value={job_position.id}>{job_position.display_name}</Option>
                  ))}
                </Select>}
            </FormItem>

            <FormItem name="portal" label="Portal">
              {this.props.show ? this.props.data.portal && this.props.data.portal.name
                : <Select style={{width: '100%'}}>
                  {this.state.job_portal_list.map((job_portal, index) => (
                    <Option key={index} value={job_portal.id}>{job_portal.name}</Option>
                  ))}
                </Select>}
            </FormItem>

            <FormItem name="location" label="Job Location">
              {this.props.show ? this.props.data.location :
                <Input className='gx-input-lineheight' type="text" readOnly={this.props.show}/>}
            </FormItem>

            <FormItem rules={[{required: true, message: 'Select a public date'}]} name="publish_date"
                      label="Publish Date">
              {this.props.show ? this.props.data.publish_date
                : <DatePicker placeholder="Publish Date"/>}
            </FormItem>

            {!this.props.show && <FormItem>
              <Button type="primary" htmlType="submit">
                Add  Job
              </Button>
            </FormItem>}
          </Form>
        </div>
      </div>
    );
  }
}
