export const style = {
    paragraphStyle:{
        color: "#2A2B2C",
        letterSpacing: "0.4px",
        fontSize: "12px",
        fontWeight: "500",
        marginTop: "8px",
    },
    activityWrapper:{
        background: "#FFFFFF",
        borderRadius: "6px",
        padding: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "12px",
        marginTop: "23px",
    },
    iconStyle:{
        color: "#000000", fontSize: "26px", fontWeight: "500"
    },
    activityTextStyle:{
        color: "#535558",
        fontSize: "12px",
        letterSpacing: "0.4px",
        fontWeight: "500",
    }
}