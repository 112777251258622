import React, { useState } from "react";
import { Form, DatePicker, Button, Input, message } from "antd";
import axiosJSON from "../../util/SetHeaderAPI";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";

const FormItem = Form.Item;
const dateFormat = "YYYY-MM-DD";
const Assignment = ({ candidate, closeModal, assignmentObj, edit }) => {
  const [fields, setFields] = useState([
    {
      name: "assignmentNumber",
      value: assignmentObj ? assignmentObj.number : null,
    },
    {
      name: "date",
      value: assignmentObj ? moment(assignmentObj.submit_date) : null,
    },
    {
      name: "candidateName",
      value: candidate.name,
    },
    {
      name: "comment",
      value: assignmentObj.description,
    },
  ]);

  const onFinish = (value) => {
    let data = {
      number: parseInt(value.assignmentNumber),
      submit_date: moment(value.date).format(dateFormat),
      description: value.comment,
    };
    if (edit) {
      axiosJSON
        .put(
          "api/training_candidate/" +
            candidate.training_candidate_id +
            "/assignment/" +
            assignmentObj.id +
            "/",
          data
        )
        .then(({ data, status }) => {
          if (status === 202) {
            message.success(data.message);
            closeModal();
          }
        })
        .catch(function (error) {
          message.error(error.message);
        });
    } else {
      axiosJSON
        .post(
          "api/training_candidate/" +
            candidate.training_candidate_id +
            "/assignment/",
          data
        )
        .then(({ data, status }) => {
          if (status === 201) {
            message.success(data.message);
            closeModal();
          }
        })
        .catch((error) => {
          message.error(error.message);
          setFields([
            {
              name: "assignmentNumber",
              value: null,
            },
            {
              name: "date",
              value: moment(value.date),
            },
            {
              name: "candidateName",
              value: candidate.name,
            },
            {
              name: "comment",
              value: candidate.comment,
            },
          ]);
        });
    }
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content education-form ">
        <Form
          name="basic"
          fields={fields}
          onFieldsChange={(newFields) => setFields(newFields)}
          onFinish={onFinish}
          className="gx-signin-form gx-form-row0"
        >
          <FormItem
            rules={[{ required: false, message: "Please select Candidate" }]}
            name="candidateName"
            label="Candidate Name"
          >
            <Input readOnly={true} disabled={true} />
          </FormItem>
          <FormItem
            rules={[
              { required: true, message: "Please enter Assignment Number" },
            ]}
            name="assignmentNumber"
            label="Assigment Number"
          >
            <Input
              className="gx-input-lineheight"
              type="number"
              readOnly={edit}
              disabled={edit}
            />
          </FormItem>
          <FormItem
            rules={[
              { required: true, message: "Please enter Submission Date" },
            ]}
            name="date"
            label="Submission Date"
          >
            <DatePicker
              format={"MM/DD/YYYY"}
              placeholder="Select Date"
              className="gx-input-lineheight"
            />
          </FormItem>
          <FormItem
            rules={[{ required: true, message: "Please enter a comment" }]}
            name="comment"
            label="Add a comment"
          >
            <TextArea className="gx-input-lineheight" type="number" />
          </FormItem>
          <FormItem>
            <Button type="primary" htmlType="submit">
              {edit ? "Edit" : "Add"} Assignment
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default Assignment;
