import React, { useEffect, useState } from "react";
import { message, Modal, Rate, Spin } from "antd";
import { FiEdit2, FiPlus } from "react-icons/fi";
import { styles } from "./style";
import axiosJSON from "../../../util/Api";
import moment from "moment";
import TrainingFeedback from "../../../modals/Training/trainingFeedback";
import { getSortName } from "../utils";

const Feedback = ({ traingId, candidate }) => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [feedBackObj, setFeedBackObj] = useState(null);
  const [isFeedbackEdit, setIsFeedbackEdit] = useState(false);

  const getFeedbackData = async () => {
    try {
      setLoading(true);
      const data = await axiosJSON.get(
        `api/training_candidate/${traingId}/feedback/`
      );
      setFeedbacks(data.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Somthing went wrong, please reach support");
    }
  };

  const handleFeedbackClose = () => {
    setShowModal(false);
    setIsFeedbackEdit(false);
    getFeedbackData();
  };

  useEffect(() => {
    getFeedbackData();
    // eslint-disable-next-line
  }, []);

  return (
    <Spin spinning={loading}>
      <div style={styles.mainWrapper}>
        <div style={styles.topPartWrapper}>
          <div style={styles.allFeedbackStyle}>All feedback</div>
          <div
            style={styles.addFeedbackButton}
            onClick={() => {
              console.log("clicked");
              setShowModal(true);
            }}
          >
            <FiPlus />
            Add feedback
          </div>
        </div>
        {feedbacks.length > 0 ? (
          feedbacks.map((feedback, index) => {
            return (
              <div
                key={index}
                style={
                  index === 0
                    ? styles.firstDisplayFeedbackDivStyle
                    : styles.otherDisplayFeedbackDivStyle
                }
              >
                <div style={styles.nameDivCireleStyle}>
                  {getSortName(feedback.given_by)}
                </div>
                <div style={styles.rightInnerDivStyle}>
                  <div style={styles.righetInnerTopPartDiv}>
                    <div style={{ color: "#353D4A", fontSize: "14px" }}>
                      {feedback.given_by}
                    </div>
                    <div style={styles.dateWrapper}>
                      <div>{moment(feedback.created).format("llll")}</div>
                      <div style={styles.editButtonStyle}>
                        {feedback.training_feedback && (
                          <FiEdit2
                            onClick={() => {
                              setFeedBackObj(feedback);
                              setIsFeedbackEdit(true);
                              setShowModal(true);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div style={styles.innerMidDivStyle}>
                    <div>
                      <Rate
                        style={{ fontSize: "14px" }}
                        disabled={true}
                        allowHalf={false}
                        value={feedback.rating}
                      />
                    </div>
                    <div style={styles.traingStatusDiv}>
                      {feedback.feedback_type}
                    </div>
                  </div>
                  <div style={styles.feedbackDescriptionStyle}>
                    <p
                      dangerouslySetInnerHTML={{ __html: feedback.description }}
                    />
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div style={styles.noDataAvailble}>No data Avalible</div>
        )}
        {showModal && (
          <Modal
            title={isFeedbackEdit ? "Edit Feedback" : "Add Feedback"}
            open={showModal}
            onCancel={() => {
              setShowModal(false);
              setIsFeedbackEdit(false);
            }}
            footer={null}
          >
            <TrainingFeedback
              candidate={candidate}
              feedbackObj={feedBackObj}
              edit={isFeedbackEdit}
              closeModal={handleFeedbackClose}
              traingId={traingId}
            />
          </Modal>
        )}
      </div>
    </Spin>
  );
};

export default Feedback;
