import React, {PureComponent} from "react";
import { message} from "antd";
import axiosJSON from "../../../util/SetHeaderAPI";
import MailList from "../../../components/Candidate/mail/MailList";
import MailDetail from "../../../components/Candidate/mail/MailDetail/index";
import CircularProgress from "../../../components/CircularProgress/index";
import Widget from "../../../components/Widget";
import "nprogress/nprogress.css";
import nprogress from "nprogress";

class Email extends PureComponent {

  constructor() {
    super();
    this.state = {
      searchMail: '',
      noContentFoundMessage: 'No MailBox found in selected folder',
      alertMessage: '',
      showMessage: false,
      drawerState: false,
      optionName: 'None',
      anchorEl: null,
      allMail: [],
      loader: true,
      currentMail: null,
      user: {
        name: 'Robert Johnson',
        email: 'robert@example.com',
        avatar: "https://via.placeholder.com/150"
      },
      selectedMails: 0,
      selectedFolder: 0,
      composeMail: false,
      // folderMails: mails.filter(mailBox => mailBox.folder === 0)
    }
    this.Mounted=false
  }

  componentWillUnmount() {
    this.Mounted = false
  }

  componentDidMount() {
    nprogress.start()
    this.Mounted = true
    setTimeout(() => {
      this.setState({loader: false});
    }, 1500);
    this.getAllMail();
    nprogress.done()
  }

  //--------------------------MailBox Sidebar and components------------------

//--------------- getting mailBox list-----------

  getAllMail = () => {
    const splitArray = window.location.href.split("/")
    const id = splitArray[splitArray.length-2];
    axiosJSON.get('api/candidate/'+id+'/emails/')
    .then(({data, status}) => {
      if (status === 200) {
        this.setState({allMail:data.data})
      }
    }).catch(function (error) {
    message.error(error.message)
  })
    let mails = this.state.allMail.map((mail) => mail);
    this.setState({
      allMail: mails,
      optionName: 'All'
    });
  };

  handleRequestClose = () => {
    this.setState({
      composeMail: false,
      showMessage: false,
    });
  };

  searchMail = (searchText) => {
    if (searchText === '') {
      this.setState({folderMails: this.state.allMail.filter((mail) => !mail.deleted)});
    } else {
      const searchMails = this.state.allMail.filter((mail) =>
        !mail.deleted && mail.subject.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
      this.setState({
        folderMails: searchMails
      });
    }
  };

  displayMail = (currentMail, allMail, noContentFoundMessage) => {
    return (<div className="gx-module-box-column">
      {currentMail === null ?
        allMail.length === 0 ?
          <div className="gx-no-content-found gx-text-light gx-d-flex gx-align-items-center gx-justify-content-center">
            {noContentFoundMessage}
          </div>
          :
          <div><MailList mails={allMail} onMailSelect={this.onMailSelect.bind(this)}/></div>
        :
        <MailDetail mail={currentMail}/>
      }
    </div>)
  };

  onMailSelect(mail) {
    this.setState({
      loader: true,
      currentMail: mail,
    });
    setTimeout(() => {
      this.setState({loader: false});
    }, 1500);
  }


  onMailSend(data) {
    this.setState(
      {
        alertMessage: 'MailBox Sent Successfully',
        showMessage: true,
        folderMails: this.state.allMail.concat(data),
        allMail: this.state.allMail.concat(data)
      }
    );
  }

  updateSearch(evt) {
    this.setState({
      searchMail: evt.target.value,
    });
    this.searchMail(evt.target.value)
  }

  render() {
    const { loader, currentMail,allMail, alertMessage, showMessage, noContentFoundMessage} = this.state;
    return (

      <div className="gx-profile-content">
        <Widget title="Emails" styleName="gx-card-profile">

        <div className="main-email">

            <div className="gx-module-box-content">
              <div>
                {this.state.currentMail === null ?
                  null
                  :
                  <i className="icon icon-arrow-left gx-icon-btn" onClick={() => {
                    this.setState({currentMail: null})
                  }}/>
                }
                <div classID="toolbar-separator"/>
              </div>

              {loader ?
                <div className="gx-loader-view">
                  <CircularProgress/>
                </div>
                : this.displayMail(currentMail, allMail, noContentFoundMessage)}

            </div>
        </div>

        {showMessage && message.info(<span id="message-id">{alertMessage}</span>, 3, this.handleRequestClose)}
        </Widget>
        </div>
    )
  }
}

export default Email;
