import { message } from "antd";
import React from "react";
import { Redirect } from "react-router-dom";
import Dashboard from "../routes/Dashboard";

const Protected = ({ isAllowed, match, children }) => {
    if (!isAllowed) {
      message.error("Page Not Found!!")
      return <Redirect to={`/dashboard`} element={<Dashboard />}/>;
    }
    return children;
  };
  export default Protected;