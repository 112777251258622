import React from "react";
import { Tag, Menu, Dropdown } from "antd";
import PositionStatus from "../../../../modals/Positions/positionStatus";
import AddEditPositions from "../../../../modals/Positions/addEditPositions";
import moment from "moment";
import {CustomModal as Modal} from "../../../../util/CustomModal"

class PositionItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEdit: false,
      showStatus: false,
      positions: this.props.positions,
    }
  }

  handleclose = () => {
    this.setState({
      showEdit: false,
      showStatus: false
    },()=>this.props.getCall(1,[]))
  }

  onOptionMenuItemSelect=(e)=>{
    switch (e.key) {
      case "Edit":
        this.setState({ showEdit: true })
        break;
      case "Status":
        this.setState({ showStatus: true })
        break;
      default:
        break;

    }
  }

  menu = () => (<Menu onClick={(e)=>this.onOptionMenuItemSelect(e)}>
    <Menu.Item key="Edit">
      <span>Edit</span>
    </Menu.Item>
    <Menu.Item key="Status">
      <span>Change Status</span>
    </Menu.Item>
  </Menu>
  );
  render() {
    const { positions, showEdit, showStatus } = this.state;
    return (
      <div className="main-jobrow1">
        <div className="gx-module-list-item jobrow">
          <div className="gx-media-body gx-task-item-content">
            <div onClick={() => this.props.selectPosition(positions)} className="gx-task-item-content-left">
              <p
                className={`gx-text-hover`}>{positions.title}</p>
            </div>
            <div onClick={() => this.props.selectPosition(positions)} className="gx-task-item-content-left">
              <p
                className={`gx-text-truncate gx-text-hover`}>{positions.location}</p>
            </div>
            {/*<div onClick={() => this.props.selectPosition(positions)} className="gx-task-item-content-left">*/}
            {/*  <p*/}
            {/*    className={`gx-text-truncate gx-ml-4 gx-text-hover`}>{positions.created_by.name}</p>*/}
            {/*</div>*/}
            <div onClick={() => this.props.selectPosition(positions)} className="gx-task-item-content-left">
            <p
                className={`gx-text-truncate gx-text-hover`}>{moment(positions.created).format("DD-MMM-YYYY, hh:mm A")}</p>
            </div>
            <div onClick={() => this.props.selectPosition(positions)} className="gx-task-item-content-right">
              <Tag className="btn-color-left gx-text-white gx-mr-0 gx-rounded-xxl gx-order-sm-2">{positions.status ? positions.status : null}</Tag>
            </div>
            <div className="gx-task-item-content-right">
              <Dropdown.Button overlay={this.menu()} trigger={['click']} />
            </div>
          </div>
        </div>
        {showEdit && <Modal
          title="Edit Position"
          visible={showEdit}
          footer={null}
          onCancel={() => { this.setState({ showEdit: false }) }}>
          <AddEditPositions edit={true} data={positions} closeModal={this.handleclose} />
        </Modal>}
        {showStatus && <Modal
          title="Change Position Status"
          className="popupsize-new"
          visible={showStatus}
          footer={null}
          onCancel={() => { this.setState({ showStatus: false }) }}>
          <PositionStatus data={positions} closeModal={this.handleclose}/>
        </Modal>}
      </div>
    )
  }
}

export default PositionItems;
