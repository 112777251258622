import React from "react";

const AppModuleHeader = (props) => {

    const {placeholder, onChange, value, onPressEnter} = props;

    return (
      <div className={`gx-module-box-header-inner search-bar ${props.class ? props.class : null}`} >
        <div
          className="gx-search-bar gx-lt-icon-search-bar-lg gx-module-search-bar gx-d-none gx-d-sm-block">
          <div className="gx-form-group">
            <input style={{ height:'42px' }} className="ant-input gx-border-0" type="search" placeholder={placeholder}
                   onChange={onChange}
                   value={value}
                   autoFocus={true}
                   onKeyPress={(e)=>{
                     if(e.key=== "Enter"){
                       onPressEnter()
                     }
                   }}
            />
            <span className="gx-search-icon gx-pointer"><i className="icon icon-search"/></span>
          </div>
        </div>
      </div>
    )
};

export default AppModuleHeader;

AppModuleHeader.defaultProps = {
  styleName: '',
  value: '',
  notification: true,
  apps: true
};
