import React from "react";
import {Avatar} from "antd";

const MailListItem = ({mail, onMailSelect}) => {
  return (
    <div className="gx-module-list-item gx-mail-cell">
      <div className="gx-module-list-icon">

        <div className="gx-ml-2">
          {mail.from_name !== '' ?
            <Avatar className="gx-avatar gx-bg-blue gx-size-40"> {mail.from_name.charAt(0).toUpperCase()}</Avatar> :
            <Avatar className="gx-avatar gx-bg-blue gx-size-40"> {mail._from.charAt(0).toUpperCase()}</Avatar>
          }
        </div>
      </div>

      <div className="gx-mail-list-info" onClick={() => {
        onMailSelect(mail);
      }}>
        <div className="gx-module-list-content">
          <div className="gx-mail-user-des">

            <span className="gx-sender-name">{mail.from_name}</span>

            <span className="gx-toolbar-separator">&nbsp;</span>

            <span className="gx-d-inline-block gx-text-truncate gx-send-subject">{mail.subject}</span>

            {/* <div className="gx-time">{mailBox.time}</div> */}

          </div>


          <div className="gx-message">
            <p dangerouslySetInnerHTML={{__html:mail.body}} className="gx-text-truncate"/>

          </div>
        </div>
        </div>
    </div>
  )
};

export default MailListItem;
