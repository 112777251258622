import React, { useEffect, useState } from "react";
import axiosJSON from "../../util/SetHeaderAPI";
import { message, Calendar, Comment } from "antd";
import moment from "moment";
import { FiMessageCircle, FiLayers } from "react-icons/fi";
import {
  AiOutlineUser,
  AiOutlineFileSearch,
  AiFillGithub,
  AiFillCopy,
} from "react-icons/ai";
import { MdMailOutline, MdOutlineDateRange } from "react-icons/md";
import { BsExclamationCircle, BsCheck2, BsCodeSlash } from "react-icons/bs";
import { BiBriefcaseAlt } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import CircularProgress from "../CircularProgress";
import Activity from "./Activity";
import AddComment from "../../modals/Training/addComment";
import EditAttendance from "../../modals/Training/editAttendance";
import CandidateAssignment from "./CandidateAssignment";
import CandidateFeedback from "./CandidateFeedback";
import { CustomModal as Modal } from "../../util/CustomModal";

const CandidateDetail = (props) => {
  const [candidateObj, setCandidateObj] = useState(null);
  const [commentList, setCommentList] = useState([]);
  const [user_list, setUserList] = useState([]);
  const [addComment, setAddComment] = useState(false);
  const [editAttendance, setEditAttendance] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [deafultDate, setDefaultDate] = useState(null);
  const [activityChange, setActivity] = useState(false);
  useEffect(() => {
    getCandidateDetails();
    getUserList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCandidateDetails = () => {
    axiosJSON
      .get("/api/training_candidate/" + props.candidate.training_candidate_id)
      .then(({ data, status }) => {
        setCandidateObj(data.data);
        setCommentList(data.data.comments ? data.data.comments : []);
        if (data.data.attendance.length > 0) {
          setDefaultDate(moment(data.data.attendance[0].date.date));
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };
  const getUserList = () => {
    axiosJSON
      .get("api/user/tags/")
      .then(({ data, status }) => {
        setUserList(data.data);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };
  const handleClose = () => {
    setAddComment(false);
    getCandidateDetails();
    getActivity();
  };
  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(e);
    message.success("Copied to clipboard");
  };
  const getActivity = () => {
    setActivity(!activityChange);
  };
  const dateCellRender = (value) => {
    let secVal = moment(value).format("YYYY-MM-DD");
    let attendanceObj = candidateObj?.attendance?.find((sample) =>
      moment(sample.date.date).isSame(secVal)
    );
    if (attendanceObj && attendanceObj.is_present === true) {
      return <div className="green_circle"></div>;
    } else if (
      attendanceObj &&
      (attendanceObj.is_present === false ||
        moment(attendanceObj.date.date) < moment())
    ) {
      return <div className="red_circle"></div>;
    }
  };

  const onDateSelect = (date) => {
    let secVal = moment(date).format("YYYY-MM-DD");
    let attendanceObj = candidateObj?.attendance?.find((sample) =>
      moment(sample.date.date).isSame(secVal)
    );
    if (attendanceObj) {
      if (
        attendanceObj.is_present === true ||
        attendanceObj.is_present === false ||
        moment(attendanceObj.date.date) < moment()
      ) {
        candidateObj.consultant = candidateObj.candidate;
        candidateObj.training_candidate_id = candidateObj.id;

        setEditAttendance(true);
        setSelectedDate(attendanceObj);
      } else {
        message.error("Can't mark attendance for future");
      }
    }
  };
  const disabledDate = (date) => {
    let secVal = moment(date).format("YYYY-MM-DD");
    let attendanceObj = candidateObj?.attendance?.find((sample) =>
      moment(sample.date.date).isSame(secVal)
    );
    if (attendanceObj) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-8 p-0">
          <div className="card">
            <div className="card-header ch-clr">General</div>
            {candidateObj ? (
              <div className="card-body row m-0">
                <div className="col-5 r-border">
                  <div className="general_sub name_gnl">
                    <div className="circle_icon mr-3">
                      <AiOutlineUser />
                    </div>
                    <div className="">
                      <div className="h5 m-0 title_n">Consultant Name </div>
                      <div className="h4 m-0">{props.candidate.name}</div>
                    </div>
                  </div>
                  <div className="general_sub email_gnl">
                    <div className="circle_icon mr-3">
                      <MdMailOutline />
                    </div>
                    <div className="">
                      <div className="h5 m-0 title_n">Email </div>
                      <div className="h4 m-0">
                        {candidateObj.candidate.email}
                      </div>
                    </div>
                  </div>
                  <div className="general_sub no_gnl">
                    <div className="circle_icon mr-3">
                      <MdOutlineDateRange />
                    </div>
                    <div className="">
                      <div className="h5 m-0 title_n">Enrollment Date</div>
                      <div className="h4 m-0">
                        {moment(candidateObj.enrollment_date).format(
                          "MM/DD/YYYY"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-7 row m-0 pr-0 general_right">
                  <div className="col-12">
                    <div className="row">
                      <div className="general_sub col-6">
                        <div className="mr-3">
                          <BiBriefcaseAlt />
                        </div>
                        <div className="">
                          <div className="h5 m-0 title_n">Recruiter</div>
                          <div className="h4 m-0">
                            {candidateObj.candidate.recruiter
                              ? candidateObj.candidate.recruiter
                              : "Not Available"}
                          </div>
                        </div>
                      </div>
                      <div className="general_sub col-6">
                        <div className="mr-3">
                          <BsExclamationCircle />
                        </div>
                        <div className="">
                          <div className="h5 m-0 title_n">Status</div>
                          {candidateObj.status ? (
                            candidateObj.status === "Confirmed" ? (
                              <div className="alert_sec m-0 alert-success">
                                <BsCheck2 /> {candidateObj.status}
                              </div>
                            ) : (
                              <div className="alert_sec m-0 alert-danger">
                                <IoMdClose /> {candidateObj.status}
                              </div>
                            )
                          ) : (
                            <div className="h4 m-0">Not Available</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="general_sub col-6">
                        <div className="mr-3">
                          <AiOutlineFileSearch />
                        </div>
                        <div className="">
                          <div className="h5 m-0 title_n">Screener</div>
                          <div className="h4 m-0">
                            {candidateObj.candidate.screener
                              ? candidateObj.candidate.screener
                              : "Not Available"}
                          </div>
                        </div>
                      </div>
                      <div className="general_sub col-6">
                        <div className="mr-3">
                          <FiLayers />
                        </div>
                        <div className="">
                          <div className="h5 m-0 title_n">Module Name</div>
                          <div className="h4 m-0">
                            {candidateObj.module
                              ? candidateObj.module
                              : "Not Available"}
                          </div>
                        </div>
                      </div>
                      <div className="general_sub col-6">
                        <div className="mr-3">
                          <BsCodeSlash />
                        </div>
                        <div className="">
                          <div className="h5 m-0 title_n">Technology</div>
                          <div className="h4 m-0">
                            {candidateObj.technology ? (
                              <span>{candidateObj.technology} </span>
                            ) : (
                              "Not Available"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="general_sub email_gnl">
                        <div className="mr-3">
                          <AiFillGithub />
                        </div>
                        <div className="">
                          <div className="h5 m-0 title_n">Github Link</div>
                          <div className="h4 m-0">
                            {candidateObj.link ? (
                              <span>
                                {candidateObj.link}{" "}
                                <AiFillCopy
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "2px",
                                  }}
                                  onClick={() =>
                                    copyToClipboard(candidateObj.link)
                                  }
                                />
                              </span>
                            ) : (
                              "Not Available"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
        <div className="col-4 pr-0 comments">
          <div className="card">
            <div className="card-header ch-clr">
              Comments
              <span className="btn_ch" onClick={() => setAddComment(true)}>
                <FiMessageCircle /> New Comment
              </span>
            </div>
            <div className="card-body">
              {commentList.length > 0 ? (
                commentList.map((comment, i) => (
                  <div key={i} className="general_sub1 name_gnl">
                    <div className="p-0 col-2">
                      <div className="circle_icon">
                        <AiOutlineUser />
                      </div>
                    </div>
                    <div className="ml-2 grow">
                      <div className="h4">
                        {comment.user.name}
                        <span className="date_cls">
                          {moment(comment.created).format("MM/DD/YYYY")}
                        </span>
                      </div>
                      <div className="comment_des">
                        <Comment
                          key={comment.id}
                          content={
                            <p
                              dangerouslySetInnerHTML={{
                                __html: comment.comment_text,
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="noData">No Data</div>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 row m-0 p-0 my-3">
          <div className="col-8 hight_auto row m-0 p-0">
            <div className="col-5 p-0 Attendance">
              <div className="card">
                <div className="card-header ch-clr">Attendance</div>
                <div className="card-body">
                  <Calendar
                    fullscreen={false}
                    dateCellRender={dateCellRender}
                    className="candidateCalender"
                    onSelect={onDateSelect}
                    disabledDate={disabledDate}
                    value={deafultDate}
                    onChange={(date) => {
                      setDefaultDate(date);
                    }}
                    //onPanelChange={onPanelChange}
                  />
                </div>
              </div>
            </div>
            <CandidateAssignment
              candidate={props.candidate}
              getActivity={getActivity}
            />
            <CandidateFeedback
              candidate={props.candidate}
              getActivity={getActivity}
            />
          </div>
          <Activity
            candidate={props.candidate}
            activityUpdate={activityChange}
          />
        </div>
      </div>
      {addComment && (
        <Modal
          title="Add Comment"
          visible={addComment}
          footer={null}
          onCancel={() => setAddComment(false)}
        >
          <AddComment
            candidateId={props.candidate.training_candidate_id}
            user_list={user_list}
            closeModal={handleClose}
          />
        </Modal>
      )}
      {editAttendance && (
        <Modal
          title="Edit Attendance"
          width={540}
          visible={editAttendance}
          footer={null}
          onCancel={() => {
            setEditAttendance(false);
          }}
        >
          <EditAttendance
            selectedRows={[candidateObj]}
            selectedCandidates={[candidateObj.id]}
            selectCandidatesNames={[candidateObj.candidate.name]}
            candidateList={[]}
            closeModal={() => {
              setEditAttendance(false);
              getCandidateDetails();
              getActivity();
            }}
            dateList={candidateObj.attendance.map((item) => {
              return item.date;
            })}
            training_id={props.training_id}
            singleRowEdit={true}
            selectedDate={selectedDate.date}
          />
        </Modal>
      )}
    </>
  );
};

export default CandidateDetail;
