import {FILTER_MY,FILTER_FOR,QUERY_REPORTS} from 'constants/ActionTypes'


export const changeFilterMy = (my) => {
  return (dispatch) => {
    dispatch({type: FILTER_MY, payload: my});
  }
};

export const onSearchReports = (query) => {
  return (dispatch) => {
    dispatch({type: QUERY_REPORTS, payload: query});
  }
};

export const changeFilterFor = (filter_for) => {
  return (dispatch) => {
    dispatch({type: FILTER_FOR, payload: filter_for});
  }
};






