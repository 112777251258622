import React, { useState, useEffect } from "react";
import moment from "moment";
import AssetsForm from "./AssetsForm";
import { styles } from "./assetsFormStyle";
import { MdOpenInNew } from "react-icons/md";
import { useLocation, withRouter } from "react-router-dom";
import { FiHeadphones } from "react-icons/fi";
import axiosJSON from "../../../../../util/Api";
import { Popconfirm, Spin, Tag, message } from "antd";
import { IoArrowBackOutline } from "react-icons/io5";
import { FiEdit2, FiPlus, FiTrash2, FiUser } from "react-icons/fi";

const logo =
  "https://consultadd.com/wp-content/uploads/2022/11/consultadd-logo.svg";

const AssetInfoList = (props) => {
  const location = useLocation();
  const [asset, setAsset] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isListPage, setIsListPage] = useState(true);
  const [assetsForms, setAssetsForms] = useState([]);
  const [isEditForm, setIsEditForm] = useState(false);
  const [employeeId] = useState(
    location.state && location.state.empId !== undefined
      ? location.state.empId
      : localStorage.getItem("Employee_ID")
  );
  const [apiKey] = useState(
    location.state && location.state.apiKey !== undefined
      ? location.state.apiKey
      : localStorage.getItem("API_KEY")
  );
  const [name] = useState(
    location.state && location.state.apiKey !== undefined
      ? location.state.name
      : localStorage.getItem("Employee_Name")
  );

  useEffect(() => {
    if (employeeId && apiKey) {
      getFilledData(employeeId, apiKey);
    } else {
      props.history.push({
        pathname: "/assetinfo/",
      });
    }
    // eslint-disable-next-line
  }, []);

  const getFilledData = (id, key) => {
    setLoading(true);
    axiosJSON
      .get(`/api/asset_form/${id}/get_info/?api_key=${key}`)
      .then(({ data, status }) => {
        if (status === 200) {
          let newAssetsForms = [];
          let attachmentIds_ = [];
          data.data.asset_data.forEach((data) => {
            const attachments = data.attachment.filter(
              ({ id, attachment_type }) => {
                attachmentIds_.push(id);
                return attachment_type === "asset_model";
              }
            );
            const serialImage = attachments.length > 0 ? attachments[0] : null;

            newAssetsForms.push({
              assetId: data.asset_id,
              deviceType: data.asset.type,
              serialNumber: data.asset.model,
              deviceBrand: data.asset.brand,
              assignDate: moment(data.assigned_date),
              workingStatus: data.asset.is_working,
              useStatus: data.asset.in_use,
              comment: data.comment,
              serialImage: serialImage,
              deviceImage: data.attachment.filter(
                ({ attachment_type }) => attachment_type === "asset"
              ),
              attachmentIds: data.attachment.map((attachment) => attachment.id),
            });
          });
          setAssetsForms(newAssetsForms);
        }
        setLoading(false);
      })
      .catch((error) => {
        message.error(error);
        setLoading(false);
      });
  };

  const onDelete = (assetId) => {
    setLoading(true);
    axiosJSON
      .delete(`/api/asset_form/${assetId}/remove/?api_key=${apiKey}`)
      .then(({ data }) => {
        message.success(data.message);
        setLoading(false);
        getFilledData(employeeId, apiKey);
      })
      .catch((error) => {
        console.log("ERROR-->", error);
        message.error("Something went wrong, please contact to support!");
        setLoading(false);
      });
  };

  const resetPage = () => {
    setIsListPage(true);
    getFilledData(employeeId, apiKey);
  };

  return (
    <Spin spinning={loading}>
      <div
        className="gx-main-content main_application_page"
        style={{ height: "100vh" }}
      >
        <div className="main">
          <div className="header header-assert">
            <div className="logo-assert">
              <img style={{ maxHeight: "36px" }} alt="consultadd" src={logo} />
            </div>
            <div className="assert_nav">
              <div className="pr-4 center-assert">
                <a href="https://consultadd.com/" className="home-assert">
                  Go to website home page <MdOpenInNew />
                </a>
              </div>
              <div>
                <a
                  className="contact_us-assert"
                  href="https://consultadd.com/contact/"
                >
                  {" "}
                  <FiHeadphones /> Contact Us
                </a>
              </div>
            </div>
          </div>

          <div className="main-app-form custom_width_50 assetinfo">
            <div className="headline mb-5">
              <div className="heading_assert">Asset Information Form</div>
            </div>
            <div className="form_container mt-5">
              {!isListPage ? (
                <div
                  style={styles.backButtonStyle}
                  onClick={() => {
                    setIsListPage(true);
                  }}
                >
                  <IoArrowBackOutline /> Go Back
                </div>
              ) : null}

              <div className="button_wrapper">
                <div className="pb-3 px-3">
                  <Tag className="employee-id">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "5px",
                      }}
                    >
                      <div>
                        <FiUser />
                      </div>
                      <div>Employee: {name}</div>
                      <div>({employeeId})</div>
                    </div>
                  </Tag>
                </div>

                {isListPage ? (
                  <div
                    className="add_asset_button"
                    onClick={() => {
                      setIsEditForm(false);
                      setIsListPage(false);
                    }}
                  >
                    <FiPlus style={{ color: "#FFFFFF" }} />
                    Add new Device Info
                  </div>
                ) : null}
              </div>
              <div style={styles.fullWidth}>
                <>
                  {!isListPage ? (
                    <AssetsForm
                      apiKey={props.apiKey}
                      empI={props.empId}
                      asset={asset}
                      editForm={isEditForm}
                      reset={resetPage}
                    />
                  ) : (
                    <>
                      <div>
                        {assetsForms.length > 0 &&
                          assetsForms.map((assets, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className="asset_list_wrapper"
                                  style={{ marginBottom: "10px" }}
                                >
                                  <div style={styles.displayImageWrapper}>
                                    <img
                                      style={styles.imageSizeStyle}
                                      src={
                                        assets.deviceImage.length > 0
                                          ? assets.deviceImage[0].file
                                          : "https://cdn.nerdschalk.com/wp-content/uploads/2022/08/set-default-audio-device-windows-11-759x427.png"
                                      }
                                      alt="shdhfhss"
                                    />
                                  </div>
                                  <div style={styles.contantWrapper}>
                                    <div style={styles.leftWrapper}>
                                      <div style={styles.headingStyle}>
                                        device {index + 1}
                                      </div>
                                      <div
                                        className="large_text"
                                        style={styles.deviceNameText}
                                      >
                                        {assets.deviceType.display_name}
                                      </div>
                                      <div
                                        className="device_type"
                                        style={styles.deviceTypeStyle}
                                      >
                                        <div
                                          style={{
                                            color: "#f86011",
                                            width:
                                              assets.deviceBrand.display_name
                                                .length > 6
                                                ? "120px"
                                                : "auto",
                                          }}
                                        >
                                          {assets.deviceBrand.display_name}
                                        </div>
                                      </div>
                                    </div>
                                    <div style={styles.actionWrapper}>
                                      <div style={{ marginRight: "5px" }}>
                                        <FiEdit2
                                          onClick={() => {
                                            setAsset(assets);
                                            setIsEditForm(true);
                                            setIsListPage(false);
                                          }}
                                        />
                                      </div>
                                      <div>
                                        <Popconfirm
                                          title={
                                            "Are you sure to delete your asset info ?"
                                          }
                                          onConfirm={() =>
                                            onDelete(assets.assetId)
                                          }
                                          okText="Yes"
                                          cancelText="No"
                                        >
                                          <FiTrash2
                                            style={{ marginLeft: "5px" }}
                                          />
                                        </Popconfirm>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default withRouter(AssetInfoList);
