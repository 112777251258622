import React, { Component } from "react";
import { Drawer } from "antd";
import CustomScrollbars from "util/CustomScrollbars";
import IntlMessages from "util/IntlMessages";
import { Link, Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
// import asyncComponent from "../../util/asyncComponent";
import MyProfile from "../MyProfile";
import CustomFields from "./CustomSettings";
import EmailTemplates from "./CustomSettings/EmailTemplates";
import NotificationSettings from "./CustomSettings/Notifications";
import UserDirectory from "./CustomSettings/UserDirectory";
import Protected from "../../util/protected";
import { connect } from "react-redux";
import AssetsDirectory from "./CustomSettings/AssetsDirectory";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerState: false,
      query: "",
      swDrawer: true,
    };
  }

  changeSawDrawer = (value) => {
    this.setState({
      swDrawer: value,
    });
  };
  // componentDidMount = ()=>{
  //   const location = useLocation();
  //   (location.pathname === '/settings') ? this.setState({swDrawer: true}) : null
  // }

  ToDoSideBar = (authUser, self) => {
    return (
      <div className="gx-module-side">
        <div className="gx-module-side-header">
          <div className="gx-module-logo">
            <i className="icon icon-setting gx-mr-4" />
            <IntlMessages id="sidebar.settings" />
          </div>
        </div>
        <div className="gx-module-side-content">
          <CustomScrollbars className="gx-module-side-scroll">
            <div className="gx-tab-list dash-side-bar">
              <ul>
                <li>
                  <Link to={{ pathname: "/settings" }}>
                    <span className="gx-link">My Profile</span>
                  </Link>
                </li>
                <li>
                  <Link to={{ pathname: "/settings/custom-settings" }}>
                    <span className="gx-link">Custom Settings</span>
                  </Link>
                  <ul>
                    <li>
                      <Link
                        to={{ pathname: "/settings/custom-settings/forms" }}
                      >
                        <span className="gx-link">Forms</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to={{ pathname: "/settings/email-templates" }}>
                    <span className="gx-link">Email Templates</span>
                  </Link>
                </li>
                <li>
                  <Link to={{ pathname: "/settings/notification-settings" }}>
                    <span className="gx-link">Notifications</span>
                  </Link>
                </li>
                {this.props.app !== "us" &&
                  authUser &&
                  (authUser.role === "superadmin" ||
                    authUser.role === "admin") && (
                    <li>
                      <Link
                        to={{ pathname: "/settings/user-directory" }}
                        onClick={() => this.changeSawDrawer(false)}
                      >
                        <span className="gx-link">User Directory</span>
                      </Link>
                    </li>
                  )}
                <li>
                  {this.props.app !== "us" ? (
                    <Link
                      to={{ pathname: "/settings/assets-directory" }}
                      onClick={() => this.changeSawDrawer(false)}
                    >
                      <span className="gx-link">Assets Directory</span>
                    </Link>
                  ) : null}
                </li>
              </ul>
            </div>
          </CustomScrollbars>
        </div>
      </div>
    );
  };

  onToggleDrawer() {
    this.setState({
      drawerState: !this.state.drawerState,
    });
  }

  updateSearch(evt) {
    this.setState({
      query: evt.target.value,
    });
  }

  render() {
    const { drawerState } = this.state;
    let self = this;
    return (
      <div className="gx-main-content">
        <div className="gx-app-module custom-layoutCorrection">
          <div className="gx-d-block gx-d-lg-none">
            <Drawer
              placement="left"
              closable={false}
              open={drawerState}
              onClose={this.onToggleDrawer.bind(this)}
            >
              {this.ToDoSideBar(this.props.auth, self)}
            </Drawer>
          </div>
          {this.state.swDrawer &&
          this.props.history.location.pathname !== "/settings/user-directory" &&
          this.props.history.location.pathname !==
            "/settings/assets-directory" ? (
            <div className="gx-module-sidenav gx-d-none gx-d-lg-flex">
              {this.ToDoSideBar(this.props.auth, self)}
            </div>
          ) : null}

          <div className="gx-module-box">
            <div className="gx-module-box-header">
              <span className="gx-drawer-btn gx-d-flex gx-d-lg-none">
                <i
                  className="icon icon-menu gx-icon-btn"
                  aria-label="Menu"
                  onClick={this.onToggleDrawer.bind(this)}
                />
              </span>
            </div>
            <div className="gx-module-box-content">
              <Switch>
                <Route exact path={`${this.props.match.url}`}>
                  <MyProfile />
                </Route>

                <Route exact path={`${this.props.match.url}/custom-settings`}>
                  <CustomFields
                    path={`${this.props.match.url}/custom-settings`}
                  />
                </Route>

                <Route
                  exact
                  path={`${this.props.match.url}/custom-settings/forms`}
                >
                  <CustomFields
                    path={`${this.props.match.url}/custom-settings/forms`}
                  />
                </Route>

                <Route exact path={`${this.props.match.url}/email-templates`}>
                  <EmailTemplates />
                </Route>

                <Route
                  exact
                  path={`${this.props.match.url}/notification-settings`}
                >
                  <NotificationSettings />
                </Route>

                <Route
                  exact
                  path={`${this.props.match.url}/user-directory`}
                  children={
                    <Protected
                      isAllowed={
                        this.props.app === "india" &&
                        this.props.auth &&
                        (this.props.auth.role === "superadmin" ||
                          this.props.auth.role === "admin")
                      }
                      match={this.props.match}
                    >
                      <UserDirectory changeSawDrawer={this.changeSawDrawer} />
                    </Protected>
                  }
                ></Route>
                <Route
                  exact
                  path={`${this.props.match.url}/assets-directory`}
                  children={
                    <Protected
                      isAllowed={
                        this.props.app === "india" &&
                        this.props.auth &&
                        (this.props.auth.role === "superadmin" ||
                          this.props.auth.role === "admin")
                      }
                      match={this.props.match}
                    >
                      <AssetsDirectory changeSawDrawer={this.changeSawDrawer} />
                    </Protected>
                  }
                ></Route>

                {/* <Route exact path={`${this.props.match.url}`} component={asyncComponent(() => import('../MyProfile'))}/> */}
                {/* <Route exact path={`${this.props.path}/custom-settings`}
                       component={asyncComponent(() => import('./CustomSettings'))}/> */}
                {/* <Route exact path={`${this.props.path}/custom-settings/forms`}
                       component={asyncComponent(() => import('./CustomSettings'))}/> */}
                {/* <Route exact path={`${this.props.path}/email-templates`}
                       component={asyncComponent(() => import('./CustomSettings/EmailTemplates'))}/> */}
                {/* <Route exact path={`${this.props.path}/notification-settings`}
                       component={asyncComponent(() => import('./CustomSettings/Notifications'))}/> */}
                {/* <Route exact path={`${this.props.path}/user-directory`}
                       component={asyncComponent(() => import('./CustomSettings/UserDirectory'))}/> */}
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.authUser,
});

export default connect(mapStateToProps)(withRouter(Settings));
