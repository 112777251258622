import React, {useEffect, useState} from "react";
import Widget from "components/Widget";
import {SkypeOutlined, HomeOutlined, SearchOutlined, EditOutlined, LikeOutlined} from "@ant-design/icons"
import {message} from "antd";
import EditAddress from "../../../../modals/Candidate/editAddress";
import EditLinks from "../../../../modals/Candidate/editLinks";
import EditPhone from "../../../../modals/Candidate/editPhone";
import EditSkype from "../../../../modals/Candidate/editSkype";
import EditEmail from "../../../../modals/Candidate/editEmail";
import axiosJSON from "../../../../util/SetHeaderAPI";
import AddSource from "../../../../modals/Candidate/addSource";
import Addreferral from "../../../../modals/Candidate/addReferral";
import {CustomModal as Modal} from "../../../../util/CustomModal"

const Contact = ({id}) => {
  const [phone, valuePhone] = useState([])
  const [primaryPhone, valuePrimaryPhone] = useState("")
  const [email, valueEmail] = useState("")
  const [emails, valueEmails] = useState([])
  const [links, valueLinks] = useState([])
  const [skype, valueSkype] = useState("")
  const [address, valueAddress] = useState([])
  const [source, valueSource] = useState({})
  const [showMore, setShow] = useState(false)
  const [editEmail, setEmail] = useState(false)
  const [editPhone, setPhone] = useState(false)
  const [editLinks, setLinks] = useState(false)
  const [editSkype, setSkype] = useState(false)
  const [editAddress, setAddress] = useState(false)
  const [editSource, setSource] = useState(false)
  const [showAddress, setShowAddress] = useState(false)
  const [showLink, setShowLink] = useState(false)
  const [showPhone, setShowPhone] = useState(false)
  const [showSkype, setShowSkype] = useState(false)
  const [showEmail, setShowEmail] = useState(false)
  const [showSource, setShowSource] = useState(false)
  const [referral, valueReferral] = useState("")
  const [editReferral, setReferral] = useState(false)
  const [showReferral, setShowReferral] = useState(false)
  const [selectedCountryCode,setSelectedCountryCode] = useState('us');
  
  useEffect(() => {
    getAppName()
  },[])

  const getAppName = () => {
    axiosJSON
      .get("/api/util/app/")
      .then(({ data, status }) => {
        setSelectedCountryCode(data.data === 'india' ? 'in' : 'us');
      })
      .catch((error) => message.error(error));
  };

  const handleShow = (flag) => {
    setShow(!flag)
  }
   const closeModal=()=>{
     setSource(false);
     setEmail(false);
     setAddress(false);
     setSkype(false);
     setPhone(false);
     setLinks(false);
     setShowSource(false);
     setShowAddress(false);
     setShowLink(false);
     setShowPhone(false);
     setShowSkype(false);
     setShowEmail(false);
     setReferral(false)
     setShowReferral(false)
     getContact()
   }
const getContact=()=>{
  axiosJSON.get('api/candidate/' + id + '/contact/')
    .then(({data, status}) => {
      if (status === 200) {
        valuePrimaryPhone(data.data.primary_no)
        valuePhone(data.data.phone_numbers);
        valueEmail(data.data.email);
        valueEmails(data.data.emails);
        valueLinks(data.data.links);
        valueSkype(data.data.skype_id);
        valueAddress(data.data.address[0]);
        valueSource(data.data.source)
        valueReferral(data.data.referred_by)

      }
    }).catch(function (error) {
    message.error(error.message)
  })
}
  useEffect(() => {
        getContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Widget title="Contact" styleName="gx-card-profile-sm">

      <div key={1} className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list"
           onMouseLeave={() => setEmail(false)} onMouseOver={() => setEmail(true)}>
        <div className="gx-mr-3">
          <i className={`icon icon-email gx-fs-xxl gx-text-grey`}/>
        </div>
        <div>
          <span className="gx-mb-0 gx-text-grey gx-fs-md">Email</span>
          <p className="gx-mb-0" key="primary_email">
            <span key="email"><span className="gx-fs-sm gx-link">{email}</span><span className="gx-fs-xxs"> (primary)</span></span>
            <br/>
            {emails !== null && emails.map((e,i)=>
              <span key={"emails"+i}><span className="gx-fs-sm gx-link">{e}</span><br/></span>
            )}
          </p>
        </div>
        {editEmail ?
          <EditOutlined style={{ position:'absolute',right:'20px' }} onClick={() => setShowEmail(true)} className={`gx-fs-sm gx-text-grey gx-pointer`}/> : null}
      </div>

        <div key={2} className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list"
             onMouseLeave={() => setPhone(false)} onMouseOver={() => setPhone(true)}>
          <div className="gx-mr-3">
            <i className={`icon icon-phone gx-fs-xxl gx-text-grey`}/>
          </div>
          {primaryPhone ? (
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-md">Phone</span>
            <p className="gx-mb-0">
              <span className="gx-fs-sm gx-mb-0" key={2}>
                {primaryPhone.match(/\d+/g)[0].length === 11
                  ? primaryPhone.match(/\d+/g)[0].substring(1)
                  : primaryPhone.match(/\d+/g)[0].length === 12
                  ? primaryPhone.match(/\d+/g)[0].substring(2)
                  : primaryPhone}
              </span>
              {phone && phone.length > 0 && (
                <span
                  onClick={() => setShowPhone(true)}
                  className="gx-mb-0 gx-text-grey gx-fs-xs gx-pointer"
                >
                  {" "}
                  more..
                </span>
              )}
            </p>
          </div>
        ) : !primaryPhone && phone && phone.length !== 0 ? (
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-md">Phone</span>
            <p className="gx-mb-0">
              <span className="gx-fs-sm gx-mb-0" key={2}>
                {phone !== null && phone.length > 0
                  ? phone[0].match(/\d+/g)[0].length === 11
                    ? phone[0].match(/\d+/g)[0].substring(1)
                    : phone[0].match(/\d+/g)[0].length === 12
                    ? phone[0].match(/\d+/g)[0].substring(2)
                    : phone[0]
                  : ""}
              </span>
              {phone.length > 1 && (
                <span
                  onClick={() => setShowPhone(true)}
                  className="gx-mb-0 gx-text-grey gx-fs-xs gx-pointer"
                >
                  {" "}
                  more..
                </span>
              )}
            </p>
          </div>
        ) : (
          <div className="gx-media-body">No phone available</div>
        )}
          {editPhone ?
            <EditOutlined onClick={() => setShowPhone(true)} className={`gx-fs-sm gx-text-grey gx-pointer`}/> : null}
        </div>

      <div key={3} className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list"
           onMouseLeave={() => setSkype(false)} onMouseOver={() => setSkype(true)}>
        <div className="gx-mr-3">
          <SkypeOutlined className={`gx-fs-xxl gx-text-grey`}/>
        </div>
        <div className="gx-media-body">
          <span className="gx-mb-0 gx-text-grey gx-fs-md">Skype</span>
          <p className="gx-mb-0"><span className="gx-fs-sm gx-mb-0" key={2}>{skype}</span></p>
        </div>
        {editSkype ?
          <EditOutlined onClick={() => setShowSkype(true)} className={`gx-fs-sm gx-text-grey gx-pointer`}/> : null}
      </div>
      {showMore ?
        <div key={4} className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list"
             onMouseLeave={() => setSource(false)} onMouseOver={() => setSource(true)}>
          <div className="gx-mr-3">
            <SearchOutlined className={`gx-fs-xxl gx-text-grey`}/>
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">Source</span>
            <p className="gx-mb-0">
              <span className="gx-fs-sm gx-mb-0" key={4}>{source ? source.display_name :"No source"}</span>
            </p>
          </div>

          {editSource ?
            <EditOutlined onClick={() => setShowSource(true)} className={`gx-fs-sm gx-text-grey gx-pointer`}/> : null}

        </div> : null
      }
      {
        showMore ?
          <div key={4} className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list"
               onMouseLeave={() => setReferral(false)} onMouseOver={() => setReferral(true)}>
            <div className="gx-mr-3">
              <LikeOutlined className={`gx-fs-xxl gx-text-grey`}/>

            </div>
            <div className="gx-media-body">
              <span className="gx-mb-0 gx-text-grey gx-fs-sm">Referred By</span>
              <p className="gx-mb-0">
                <span className="gx-fs-sm gx-mb-0" key={4}>{referral ? referral :"Not Referred"}</span>
              </p>
            </div>

            {editReferral ?
              <EditOutlined onClick={() => setShowReferral(true)} className={`gx-fs-sm gx-text-grey gx-pointer`}/> : null}

          </div> : null

      }
      {showMore ?
        <div key={5} className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list"
             onMouseLeave={() => setLinks(false)} onMouseOver={() => setLinks(true)}>
          <div className="gx-mr-3">
            <i className={`icon icon-link gx-fs-xxl gx-text-grey`}/>
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">Links</span>
            <p className="gx-mb-0"><span className="gx-link" key={2}><span className="gx-link"
                                                                           key={2}>{links && links.length !== 0 ? links.toString() : "No links"} </span></span>
            </p>
          </div>
          {editLinks ?
            <EditOutlined onClick={() => setShowLink(true)} className={`gx-fs-sm gx-text-grey gx-pointer`}/> : null}
        </div>:
          null
      }
      {showMore ?
        <div key={6} className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list"
             onMouseLeave={() => setAddress(false)} onMouseOver={() => setAddress(true)}>
          <div className="gx-mr-3">
            <HomeOutlined className={`gx-fs-xxl gx-text-grey`}/>
          </div>
          <div className="gx-media-body">
            <span className="gx-mb-0 gx-text-grey gx-fs-sm">Address</span>
            <p className="gx-mb-0"><span className="gx-mb-0" key={2}>{
              address && address.length !== 0 ? address.street +
                " "
                + address.city +
                " " + address.country +
                "  " + address.zip_code : "No address"
            }</span></p>
          </div>
          {editAddress ? <EditOutlined onClick={() => setShowAddress(true)}
                                       className={`gx-fs-sm gx-text-grey gx-pointer gx-pointer`}/> : null}
        </div> :null
      }

      <span className="gx-mb-0 gx-text-blue gx-fs-sm gx-pointer"
            onClick={() => handleShow(showMore)}>{showMore ? "Show Less" : "Show More"}</span>
      {
        showAddress &&
        <Modal
          title="Edit/Add Address"
          visible={showAddress}
          footer={null}
          onCancel={closeModal}>
          <EditAddress id={id} closeModal={closeModal}/>
        </Modal>
      } {
      showLink &&
      <Modal
        title="Edit/Add Links"
        className="popupsize"
        visible={showLink}
        footer={null}
        onCancel={closeModal}>
        <EditLinks links={links} id={id} closeModal={closeModal}/>
      </Modal>
    }{
      showPhone &&
      <Modal
        title="Edit/Add Phone Number"
        visible={showPhone}
        footer={null}
        onCancel={closeModal}>
        <EditPhone phone={phone} primaryPhone={primaryPhone} id={id} selectedCountryCode={selectedCountryCode} closeModal={closeModal}/>
      </Modal>
    }{
      showSkype &&
      <Modal
        title="Edit/Add Skype"
        className="popupsize"
        visible={showSkype}
        footer={null}
        onCancel={closeModal}>
        <EditSkype skype={skype} id={id} closeModal={closeModal}/>
      </Modal>
    }{
      showEmail &&
      <Modal
        title="Edit/Add Email"
        visible={showEmail}
        footer={null}
        onCancel={closeModal}>
        <EditEmail email={email} emails={emails} id={id} closeModal={closeModal}/>
      </Modal>
    }{
      showSource &&
      <Modal
        title="Edit Source"
        visible={showSource}
        footer={null}
        onCancel={closeModal}>
        <AddSource source={source} candidateList={[id]} closeModal={closeModal}/>
      </Modal>
    }
      {
        showReferral &&
        <Modal
          title="Edit referral"
          visible={showReferral}
          footer={null}
          onCancel={closeModal}>
          <Addreferral referral={referral} id={id} closeModal={closeModal}/>
        </Modal>
      }
    </Widget>
  )
}

export default Contact;
