export const styles = {
  otherDisplayFeedbackDivStyle: {
    display: "flex",
    gap: "8px",
    padding: "2vh",
    border: "1px solid #C1C8E7",
    width: "76.5%",
    margin: "10px 0px 10px 25px",
    marginTop: "-10px",
    borderTop: "none",
  },
  firstDisplayFeedbackDivStyle: {
    display: "flex",
    gap: "8px",
    padding: "2vh",
    border: "1px solid #C1C8E7",
    width: "76.5%",
    margin: "10px 0px 10px 25px",
    borderRadius: "12px",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
  },

  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "0px",
    margin: "0px",
    background: "#FFFFFF",
    border: "1px solid #C1C8E7",
    borderRadius: "0px 0px 12px 12px",
    borderTop: "none",
    height: "37.5vw",
    overflowX: "auto",
  },
  topPartWrapper: {
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
    alignItems: "center",
    width: "80%",
    padding: "2vh 2vh 0vw 2vw",
  },
  allFeedbackStyle: {
    color: "#26305C",
    fontSize: "16px",
    fontWeight: "500px",
  },
  addFeedbackButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    padding: "8px 16px",
    borderRadius: "6px",
    background: "#26305C",
    color: "#FFFFFF",
    cursor: "pointer",
  },
  nameDivCireleStyle: {
    background: "#D7DDFA",
    borderRadius: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
    color: "#26305C",
    height: "50px",
    width: "50px",
  },
  rightInnerDivStyle: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  righetInnerTopPartDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  dateWrapper: {
    display: "flex",
    gap: "6px",
    color: "#9AA1B0",
  },
  editButtonStyle: {
    cursor: "pointer",
    marginLeft: "3px",
    marginTop: "-2px",
  },
  innerMidDivStyle: {
    display: "flex",
    gap: "8px",
  },
  traingStatusDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#FFF8EA",
    borderRadius: "4px",
    gap: "4px",
    padding: "2px 10px",
    color: "#E8930B",
    fontSize: "15px",
  },
  feedbackDescriptionStyle: {
    color: "#717885",
    fontSize: "15px",
    marginTop: "10px",
  },

  noDataAvailble: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
};
