import React, { Component } from "react";
import { message, Select, Tabs } from "antd";
import { Route, Switch } from "react-router-dom";
import AppModuleHeader from "../../../components/AppModuleHeader";
import { connect } from "react-redux";
import {
  onSearchReports,
  changeFilterFor,
} from "../../../appRedux/actions/Reports";
import axiosJSON from "../../../util/SetHeaderAPI";
import BasicReport from "./Basic";
import ScreeningReport from "./Screening";
import BenchReport from "./Bench";
import ArchivedReport from "./Archived";
import { withRouter } from "react-router-dom";
import "nprogress/nprogress.css";
import nprogress from "nprogress";
import { debounce } from "lodash";
import {search, getRecruiterList, getList, getPositionList} from './Service/Api';
const { TabPane } = Tabs;

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "",
      isFilter: false,
      filters: [],
      filter_for:"",
      query:"",
    };
  }

  componentDidMount() {
    getRecruiterList();
    getPositionList();
    getList('visa');
    getList('source');
    getList('stage');
    nprogress.start();
    this.getFilters();
    this.setState({
      currentTab: this.props.history.location.pathname.split("/reports/")[1],
    });
    nprogress.done();
  }
 
  getFilters = () => {
    axiosJSON
      .get("/api/report/filters/")
      .then(({ data }) => {
        this.setState({
          filters: data.data,
          filter: data.data[0],
        });
      })
      .catch((err) => message.error(err));
  };

  callback = (key) => {
    this.props.history.push("/reports/" + key);
  };
  
  debounceSearch = debounce((data) => {
    search(data);
    this.props.onSearchReports(data);
  },500)

  handleQueryChange = (e) => {
    e.preventDefault();
    this.setState({
      query: e.target.value,
    });
    let obj={query:e.target.value,filter_for:this.state.filter_for}
    this.debounceSearch(obj);
  };

  onPressEnter = () => {
    this.setState({
      query: "",
    });
  };

  filterChange = (value) => {
    this.setState({
      filter_for: value ? value : "",
    });
    let obj={query:this.state.query,filter_for:value}
    search(obj);
    this.props.changeFilterFor(value ? value : "");
  };

  onTabChange = (key) => {
    this.setState({
      filter_for: "",
      query:""
    });
    search({filter_for: "",query:""})
    this.props.history.push("/reports/" + key);
    this.setState({
      currentTab: key,
    });
  };

  renderTab = () => {
    return (
      <>
        <Switch>
          <Route exact path={`${this.props.match.url}`}>
            <BasicReport />
          </Route>
          <Route exact path={`${this.props.match.url}/basic`}>
            <BasicReport />
          </Route>
          <Route exact path={`${this.props.match.url}/screening`}>
            <ScreeningReport />
          </Route>
          <Route path={`${this.props.match.url}/bench`}>
            <BenchReport />
          </Route>
          <Route exact path={`${this.props.match.url}/archive`}>
            <ArchivedReport />
          </Route>
        </Switch>
      </>
    );
  };

  render() {
    return (
      <div className="main-report-page">
        <div className="gx-main-content">
          <div className="gx-app-module">
            <div
              style={{ backgroundColor: "#fff" }}
              className="gx-module-box report_head"
            >
              <div
                style={{ display: "inline" }}
                className="gx-module-box-header"
              >
                <Tabs
                  className="new_tabs"
                  type="card"
                  activeKey={this.state.currentTab}
                  tabBarExtraContent={
                    <>
                      <Select
                        style={{ width: "100px" }}
                        value={this.state.filter_for}
                        onChange={(value) => this.filterChange(value)}
                        allowClear
                      >
                        {this.state.filters.map((f, index) => (
                          <Select.Option key={index} value={f}>
                            {f}
                          </Select.Option>
                        ))}
                      </Select>
                      <AppModuleHeader
                        onPressEnter={this.onPressEnter}
                        placeholder="Search reports"
                        value={this.state.query}
                        onChange={this.handleQueryChange}
                      />
                    </>
                  }
                  onChange={this.onTabChange}
                >
                  <TabPane tab="Basic" key="basic"></TabPane>
                  <TabPane tab="Screening" key="screening"></TabPane>
                  <TabPane tab="Bench" key="bench"></TabPane>
                  <TabPane tab="Archived" key="archive"></TabPane>
                </Tabs>
              </div>
              <div>{this.renderTab()}</div>
              {/* <Switch>                       */}
              {/* <Route exact path={`${this.props.match.url}`} component={asyncComponent(() => import('./Basic'))}/> */}
              {/* <Route exact path={`${this.props.match.url}/basic`} component={asyncComponent(() => import('./Basic'))}/> */}
              {/* <Route exact path={`${this.props.match.url}/bench`} component={asyncComponent(() => import('./Bench'))}/> */}
              {/* <Route exact path={`${this.props.match.url}/screening`} component={asyncComponent(() => import('./Screening'))}/> */}
              {/* <Route exact path={`${this.props.match.url}/archive`} component={asyncComponent(() => import('./Archived'))}/> */}
              {/* </Switch> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reports: state.reports,
});

const mapDispatchToProps = (dispatch) => ({
  onSearchReports: (data) => dispatch(onSearchReports(data)),
  changeFilterFor: (data) => dispatch(changeFilterFor(data)),
});

const routeReports = withRouter(Reports);

export default connect(mapStateToProps, mapDispatchToProps)(routeReports);
