import React from "react";
import CustomScrollbars from 'util/CustomScrollbars'
import PositionItems from "./positionItem/positionitem";

const PositionsList = (({positionsList,selectPosition,getCall}) => {
  return (
    <div className="positions_table">
    <div className="gx-module-list-item" style={{backgroundColor:'#A8D0E6'}}>
      <div className="gx-media-body gx-task-item-content">
        <div className="gx-task-item-content-left">
          <div
            className={`gx-fs-md gx-text-black gx-order-sm-4`}>Title</div>
        </div>
        <div className="gx-task-item-content-left">
          <div
            className={`gx-fs-md gx-text-black gx-order-sm-4`}>Location</div>
        </div>
        {/*<div className="gx-task-item-content-left">*/}
        {/*  <div*/}
        {/*    className={`gx-fs-md gx-text-black gx-order-sm-4`}>Created By</div>*/}
        {/*</div>*/}
        <div className="gx-task-item-content-left">
          <div
            className={`gx-fs-md gx-text-black gx-order-sm-4`}>Created At</div>
        </div>
        <div className="gx-task-item-content-right">
          <span className="gx-fs-md gx-text-black gx-order-sm-4">Status</span>
        </div>
        <div className="gx-task-item-content-right">
          <span className="gx-fs-md gx-text-black gx-order-sm-4">Actions</span>
        </div>
      </div>
    </div>
      <CustomScrollbars className="gx-module-content-scroll">
        {positionsList.map((positions, index) =>
        <PositionItems key={index} positions={positions} selectPosition={selectPosition} getCall={getCall}/>
        )}
      </CustomScrollbars>
    </div>
  )
});

export default PositionsList;
