import { Input, Form, Rate, Select, Button, message, Spin } from "antd";
import React, { useState } from "react";
import axiosJSON from "../../util/SetHeaderAPI";

const FormItem = Form.Item;
const { TextArea } = Input;

const TrainingFeedback = ({
  candidate,
  closeModal,
  feedbackObj,
  edit,
  traingId,
}) => {
  const [fields] = useState([
    {
      name: "candidateName",
      value: candidate.name,
    },
    {
      name: "feedbackType",
      value: edit && feedbackObj ? feedbackObj.feedback_type : null,
    },
    {
      name: "rating",
      value: edit && feedbackObj ? feedbackObj.rating : null,
    },
    {
      name: "description",
      value: edit && feedbackObj ? feedbackObj.description : null,
    },
  ]);

  const [loading, setLoading] = useState(false);

  const onFinish = (value) => {
    if (value.rating <= 0) {
      message.error("Please Provide Rating");
      return;
    }
    let data = {
      rating: value.rating,
      feedback_type: value.feedbackType,
      description: value.description,
    };
    if (edit) {
      setLoading(true);
      axiosJSON
        .put(
          "api/training_candidate/" +
            candidate.training_candidate_id +
            "/feedback/" +
            feedbackObj.id +
            "/",
          data
        )
        .then(({ data, status }) => {
          message.success(data.message);
          setLoading(false);
          closeModal();
        })
        .catch(function (error) {
          setLoading(false);
          message.error(error.message);
        });
    } else {
      setLoading(true);

      const id = traingId ? traingId : candidate.training_candidate_id;
      axiosJSON
        .post("api/training_candidate/" + id + "/feedback/", data)
        .then(({ data, status }) => {
          message.success(data.message);
          setLoading(false);
          closeModal();
        })
        .catch(function (error) {
          setLoading(false);
          message.error(error.message);
        });
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="gx-login-container">
        <div className="gx-login-content education-form">
          <Form
            name="basic"
            fields={fields}
            onFinish={onFinish}
            className="gx-signin-form gx-form-row0"
          >
            <FormItem
              rules={[{ required: false, message: "Please select Candidate" }]}
              name="candidateName"
              label="Candidate Name"
            >
              <Input readOnly={true} disabled={true} />
            </FormItem>

            <FormItem
              rules={[
                { required: true, message: "Please select Feeback Type" },
              ]}
              name="feedbackType"
              label="FeedBack Type"
            >
              <Select placeholder="Select Type" open={edit ? false : undefined} disabled={edit}>
                <Select.Option value={"mid_training"}>
                  Mid Training
                </Select.Option>
                <Select.Option value={"final"}>Final Training</Select.Option>
              </Select>
            </FormItem>

            <FormItem
              rules={[{ required: true, message: "Please provide Rating" }]}
              name="rating"
              label="Rating"
            >
              <Rate />
            </FormItem>

            <FormItem
              rules={[{ required: false, message: "Please add Description" }]}
              name="description"
              label="Description"
            >
              <TextArea
                placeholder="Add Remark"
                rows={6}
                className="gx-input-lineheight"
              />
            </FormItem>

            <FormItem>
              <Button type="primary" htmlType="submit">
                {edit ? "Edit" : "Add"} Feedback
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default TrainingFeedback;
