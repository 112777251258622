import React, { useState, useEffect } from "react";
import { style } from "../Projects/style";
import { Spin, Table, message, DatePicker } from "antd";
import axiosJSON from "../../../util/Api";
import { FiArrowRight, FiChevronDown } from "react-icons/fi";
import moment from "moment";
import { withRouter } from "react-router";

const changeRoute = ({ history }, trainingId) => {
  const splitArray = window.location.href.split("/");
  const candidateId = splitArray[splitArray.length - 2];
  history.push({
    pathname: `/trainingDetails/${trainingId}/${candidateId}/assignment`,
    state: { path: history.location.pathname },
  });
};

const getColumns = (props) => {
  return [
    {
      title: "Training ID",
      width: "10%",
      dataIndex: "training_id",
      key: "training_id",
      fixed: "left",
      render: (text) => (
        <div style={{ color: "#717885", cursor: "pointer" }}>
          {text ? text : "N/A"}
        </div>
      ),
    },
    {
      title: "Training name",
      dataIndex: "training_name",
      width: "12%",
      key: "1",
      render: (text, record) => (
        <div
          style={{ color: "#353D4A", cursor: "pointer" }}
          onClick={() => changeRoute(props, record.id)}
        >
          {text ? text : "N/A"}
        </div>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      width: "10%",
      key: "3",
      render: (text, record) => (
        <div
          style={{ color: "#717885", cursor: "pointer" }}
          onClick={() => changeRoute(props, record.id)}
        >
          {text ? moment(text).format("MM-DD-YYYY") : "N/A"}
        </div>
      ),
    },
    {
      title: "End date",
      dataIndex: "end_date",
      width: "10%",
      key: "4",
      render: (text, record) => (
        <div
          style={{ color: "#717885", cursor: "pointer" }}
          onClick={() => changeRoute(props, record.id)}
        >
          {text ? moment(text).format("MM-DD-YYYY") : "N/A"}
        </div>
      ),
    },
    {
      title: "Module",
      dataIndex: "module",
      width: "12%",
      key: "5",
      render: (text, record) => (
        <div
          style={{ color: "#717885", cursor: "pointer" }}
          onClick={() => changeRoute(props, record.id)}
        >
          {text ? text : "N/A"}
        </div>
      ),
    },
    {
      title: "Staus",
      dataIndex: "status",
      width: "8%",
      key: "6",
      render: (text, record) => (
        <div
          style={{ color: "#717885", cursor: "pointer" }}
          onClick={() => changeRoute(props, record.id)}
        >
          {text ? text : "N/A"}
        </div>
      ),
    },
    {
      title: "Session Type",
      dataIndex: "session_type",
      width: "8%",
      key: "7",
      render: (text, record) => (
        <div
          style={{ color: "#717885", cursor: "pointer" }}
          onClick={() => changeRoute(props, record.id)}
        >
          {text ? text : "N/A"}
        </div>
      ),
    },
    {
      title: "Enrolment Date",
      key: "8",
      dataIndex: "enrolment_date",
      width: "10%",
      render: (text, record) => (
        <div
          style={{ color: "#717885", cursor: "pointer" }}
          onClick={() => changeRoute(props, record.id)}
        >
          {text ? moment(text).format("MM-DD-YYYY") : "N/A"}
        </div>
      ),
    },
    {
      title: "Attendance Percentage ",
      key: "9",
      dataIndex: "attendance",
      width: "15%",
      render: (text, record) => (
        <div
          style={{ color: "#717885", textAlign: "center", cursor: "pointer" }}
          onClick={() => changeRoute(props, record.id)}
        >
          {text ? text : "-"}
        </div>
      ),
    },
  ];
};

const Trainings = (props) => {
  const [traingingData, setTrainingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);

  const getTraingData = async (start = "", end = "") => {
    const splitArray = window.location.href.split("/");
    const candidateId = splitArray[splitArray.length - 2];
    setLoading(true);
    try {
      const data = await axiosJSON.get(
        `api/candidate/${candidateId}/training/?start=${start}&end=${end}`
      );
      setTrainingData(data.data.data);
      setLoading(false);
    } catch (error) {
      message.error("Something went wrong");
      setLoading(false);
    }
  };

  useEffect(() => {
    getTraingData();
    // eslint-disable-next-line
  }, []);

  return (
    <div style={style.bodyWrapper}>
      <div style={{ borderRadius: "8px", display: "flex", gap: "5px" }}>
        <DatePicker
          className="sidebar-datepicker"
          placeholder="Start Date"
          value={startDate}
          suffixIcon={<FiArrowRight />}
          onChange={(date) => {
            setStartDate(date);
            getTraingData(
              date ? moment(date).format("YYYY-MM-DD") : "",
              endDate ? moment(endDate).format("YYYY-MM-DD") : ""
            );
          }}
        />
        <DatePicker
          className="sidebar-datepicker"
          placeholder="End Date"
          value={endDate}
          suffixIcon={<FiChevronDown />}
          onChange={(date) => {
            setEndDate(date);
            getTraingData(
              startDate ? moment(startDate).format("YYYY-MM-DD") : "",
              date ? moment(date).format("YYYY-MM-DD") : ""
            );
          }}
        />
      </div>
      <div className="gx-module-box-content mt-4">
        <Spin spinning={loading}>
          <Table
            className="training_table p-0"
            style={{ borderRadius: "12px" }}
            columns={getColumns(props)}
            dataSource={traingingData}
            pagination={false}
          />
        </Spin>
      </div>
    </div>
  );
};

export default withRouter(Trainings);
