import React from "react";
import { Checkbox, Tag, Switch, Popconfirm, Popover } from "antd";
import { FiBell } from "react-icons/fi";
import { GiRingingBell } from "react-icons/gi";
import moment from "moment-timezone";

const CandidateItem = ({
  candidate,
  onCandidateSelected,
  selectCandidate,
  selectedCandidates,
  follow,
  onFollow,
  onNotify,
  onEditNotify,
  followUpDate,
}) => {
  return (
    <div
      className="main-candidaterow"
      onClick={() => selectCandidate(candidate.id)}
    >
      <div className="gx-module-list-item candidaterow">
        <div className="gx-module-list-icon">
          <Checkbox
            color="primary"
            checked={candidate.selected}
            onClick={(event) => {
              event.stopPropagation();
              onCandidateSelected(candidate);
            }}
            className="gx-icon-btn"
          />
        </div>

        <div className="gx-media-body gx-task-item-content">
          <div className="gx-task-item-content-left gx-task-align-center">
            <p className={`gx-text-truncate gx-mb-0 gx-text-hover`}>
              {candidate.name}
            </p>
          </div>

          <div className="gx-task-item-content-left gx-task-align-center">
            <p className={`gx-text-truncate gx-mb-0 gx-text-hover`}>
              {candidate.email}
            </p>
          </div>

          {/*<div className="gx-task-item-content-right">*/}
          {/*  <Tag*/}
          {/*    className="btn-color-right gx-text-white gx-ml-0 gx-mr-0 gx-mb-0 gx-rounded-xxl gx-order-sm-2">*/}
          {/*    {candidate.source ? candidate.source : null}*/}
          {/*  </Tag>*/}
          {/*</div>*/}

          <div className="gx-task-item-content-right last-stage-section gx-task-align-center">
            <span className="gx-fs-sm gx-text-black gx-ml-0 gx-task-date gx-order-sm-4">
              {candidate.source ? candidate.source : null}
            </span>
          </div>

          <div className="gx-task-item-content-right gx-task-align-center">
            <Tag className="btn-color-right gx-text-white gx-ml-0 gx-mr-0 gx-mb-0 gx-rounded-xxl gx-order-sm-2">
              {candidate.stage ? candidate.stage : null}
            </Tag>
          </div>
          <div className="gx-task-item-content-right">
            {/* <Tag.CheckableTag
              className="gx-ml-0 gx-mr-0 gx-mb-0 gx-rounded-xxl gx-order-sm-2" 
              style={{border: "1px solid"}}
              checked={!follow}
              onChange = {e => e.stopPropagation()}
            >
              {follow ? "Unfollow" : "Follow"}
            </Tag.CheckableTag> */}
            {/* {follow ? (
              <Button
                className="mb-0"
                disabled={selectedCandidates > 0}
                onClick={(e) => {
                  onFollow(e, false, candidate.id);
                }}
              >
                Unfollow
              </Button>
            ) : (
              <Button
                onClick={(e) => {
                  onFollow(e, true, candidate.id);
                }}
                type="primary"
                className="mb-0 nohover_one"
                style={{ float: "left" }}
                disabled={selectedCandidates > 0}
              >
                Follow
              </Button>
            )} */}
            <Popconfirm
              title={`Are you sure to ${
                follow ? "Unfollow" : "Follow"
              } Candidate?`}
              okText="Yes"
              cancelText="No"
              trigger="click"
              onConfirm={(e) => {
                onFollow(e, !follow, candidate.id);
              }}
              onCancel={(e) => e.stopPropagation()}
            >
              <Switch
                size="small"
                checked={follow}
                onChange={(checked, e) => {
                  e.stopPropagation();
                }}
                disabled={selectedCandidates > 0}
                className={
                  follow
                    ? "mb-0 nohover_one nohover_one_transparent"
                    : "mb-0 nohover_one_transparent"
                }
              />
            </Popconfirm>
          </div>
          <div className="gx-task-item-content-right gx-task-align-center">
            {followUpDate === null ? (
              <Popover
                title="No Reminder set for this candidate!!!"
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
              >
                <FiBell
                  style={{ cursor: "pointer" }}
                  onClick={(e) => onNotify(e, candidate.id)}
                />
              </Popover>
            ) : (
              <Popover
                title="Reminder is set !!!"
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={
                  <span>
                    Reminder Scheduled on{" "}
                    {moment(followUpDate).format("DD-MMM-YYYY HH:mm")} !
                  </span>
                }
              >
                <GiRingingBell
                  style={{ cursor: "pointer" }}
                  onClick={(e) => onEditNotify(e, candidate.id)}
                />
              </Popover>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateItem;
