import React, { useEffect, useState, useMemo } from "react";
import TopBar from "./TopBar";
import { styles } from "./detailsStyle";
import { Route, useLocation, withRouter, Switch } from "react-router";
import Assignment from "./DetailsView/Assignment";
import Activity from "./DetailsView/Activity";
import Comment from "./DetailsView/Comment";
import Feedback from "./DetailsView/Feedback";
import { message, Spin } from "antd";
import axiosJSON from "../../util/Api";

const tabs = {
  assignment: false,
  activity: false,
  comments: false,
  feedback: false,
};

const TrainingDetailsView = (props) => {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const candidateId = location.pathname.split("/")[3];
  const [tab, setTab] = useState(tabs);
  const [candidateData, seCandidateData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCandidateDate = async () => {
    try {
      setLoading(true);
      const data = await axiosJSON.get(
        `api/training_candidate/${location.pathname.split("/")[2]}/`
      );
      const res = data.data.data;
      seCandidateData({
        training_candidate_id: res?.candidate?.id,
        name: res?.candidate?.name,
        email: res?.candidate?.email,
        recruiter: res?.candidate?.recruiter,
        status: res?.status,
        enrollmentDate: res?.enrollment_date,
        link: res?.link,
        technology: res?.technology,
        module: res?.module,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong, please reach to support");
    }
  };

  useEffect(() => {
    const splitArray = location.pathname.split("/");
    const currentTab = splitArray[splitArray.length - 1];
    let currentTabs = { ...tab };
    currentTabs[`${currentTab}`] = true;
    setTab(currentTabs);
    getCandidateDate();
    // eslint-disable-next-line
  }, []);

  const routes = () => {
    return (
      <Switch>
        <Route
          exact
          path={props.match.url + "/" + id + "/" + candidateId + "/assignment"}
        >
          <Assignment traingId={location.pathname.split("/")[2]} />
        </Route>
        <Route
          exact
          path={props.match.url + "/" + id + "/" + candidateId + "/feedback"}
        >
          <Feedback
            traingId={location.pathname.split("/")[2]}
            candidate={candidateData}
          />
        </Route>
        <Route
          exact
          path={props.match.url + "/" + id + "/" + candidateId + "/comments"}
        >
          <Comment traingId={location.pathname.split("/")[2]} />
        </Route>
        <Route
          exact
          path={props.match.url + "/" + id + "/" + candidateId + "/activity"}
        >
          <Activity traingId={location.pathname.split("/")[2]} />
        </Route>
      </Switch>
    );
  };

  const chnageTab = (tab) => {
    let newTab = { ...tabs };
    newTab[`${tab}`] = true;
    setTab(newTab);
    props.history.push({
      pathname: `${tab}`,
    });
  };

  const topBar = useMemo(() => {
    return (
      <div>
        <TopBar
          history={props.history}
          location={location}
          candidateData={candidateData}
        />
      </div>
    );
    // eslint-disable-next-line
  }, [candidateData]);

  return (
    <Spin spinning={loading}>
      <div style={{ padding: "0px", margin: "-1.3rem" }}>
        {topBar}
        <div style={{ marginTop: "12px" }}>
          <div style={{ ...styles.mainDivStyle, gap: "25px" }}>
            <div
              style={tab.assignment ? styles.activeNavStyle : styles.navStyle}
              onClick={() => chnageTab("assignment")}
            >
              Assignments & Attendance
            </div>
            <div
              style={tab.feedback ? styles.activeNavStyle : styles.navStyle}
              onClick={() => chnageTab("feedback")}
            >
              Feedback
            </div>
            <div
              style={tab.comments ? styles.activeNavStyle : styles.navStyle}
              onClick={() => chnageTab("comments")}
            >
              Comments
            </div>
            <div
              style={tab.activity ? styles.activeNavStyle : styles.navStyle}
              onClick={() => chnageTab("activity")}
            >
              Activity
            </div>
          </div>
          <>{routes()}</>
        </div>
      </div>
    </Spin>
  );
};

export default withRouter(TrainingDetailsView);
