import {
  INIT_URL,
  ACCESS_TOKEN,
  SIGNOUT_USER,
  USER,NEW_USER,
  USER_ACCESS,
  IMPERSONATE
} from "constants/ActionTypes";

const INIT_STATE = {
  token: localStorage.getItem('token'),
  initURL: '/',
  authUser:'',
  newUser:'',
  accessData:'',
  impersonateFlag:false
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACCESS_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case USER: {
      return {
        ...state,
        authUser: action.payload,
      };
    }
    case NEW_USER: {
      return {
        ...state,
        newUser: action.payload,
      };
    }
    case IMPERSONATE: {
      return {
        ...state,
        impersonateFlag: action.payload,
      };
    }
    case USER_ACCESS: {
      return {
        ...state,
        accessData: action.payload,
      };
    }
    case INIT_URL: {
      return {...state, initURL: action.payload};
    }
    case SIGNOUT_USER: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: ''
      }
    }
    default:
      return state;
  }
}
