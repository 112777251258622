import React, { Component } from "react";
import { Button, Select, Form, DatePicker, message, Input, Radio } from "antd";
import moment from "moment";
import axiosJSON from "../../util/SetHeaderAPI";


const {TextArea} = Input;
const FormItem = Form.Item;
const dateFormat = "YYYY-MM-DD";

class EnrollCandidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [
        {
          name: "candidateName",
          value: null,
        },
        {
          name: "enrollmentDate",
          value: moment(new Date()),
        },
        {
          name: "trainingTitle",
          value: this.props.training.title,
        },
        {
          name: "sessionType",
          value: null,
        }
      ],
      candidateList: [],
      sessionType: null,
    };
    this.Mounted = false;
  }

  componentDidMount() {
    this.Mounted = true;
    this.getCandidateList("");
  }

  componentWillUnmount() {
    this.Mounted = false;
  }

  getCandidateList = (query) => {
    axiosJSON
      .get("api/candidate/dropdown/?query=" + query)
      .then(({ data, status }) => {
        this.setState({
          candidateList: data.data,
        });
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  onFinish = (value) => {
    const data = {
      training: this.props.training_id,
      candidate: value.candidateName,
      enrollment_date: moment(value.enrollmentDate).format(dateFormat),
      session_type: value.sessionType.toLowerCase(),
      reason: value.reason
    };
    axiosJSON
      .post("api/training_candidate/", data)
      .then(({ data, status }) => {
        message.success(data.message);
        this.props.closeModal();
      })
      .catch((error) => {
        message.error(error.message);
        this.setState({
          fields: [
            {
              name: "candidateName",
              value: null,
            },
            {
              name: "enrollmentDate",
              value: moment(value.enrollmentDate),
            },
            {
              name: "trainingTitle",
              value: this.props.training.title,
            },
            {
              name: "sessionType",
              value: null,
            },
          ],
        });
      });
  };

  render() {
    return (
      <div className="gx-login-container">
        <div className="gx-login-content education-form enroll-candidate-form">
          <Form
            name="basic"
            fields={this.state.fields}
            onFieldsChange={(newField) => {
              const updatedField = {
                name: newField[0].name[0],
                value: newField[0].value
              }
              let fields = [...this.state.fields];
              fields = fields.map((field, index) => {
                if (field.name === updatedField.name) {
                  field.value = updatedField.value
                }
                return field;
              })
              this.setState({ fields: fields });
            }}
            onFinish={this.onFinish}
            className="gx-signin-form gx-form-row0"
          >
            <FormItem
              rules={[{ required: false, message: "Please select Training" }]}
              name="trainingTitle"
              label="Training Name"
            >
              <Input
                readOnly={true}
                type="text"
                placeholder="Enter Training Title"
              ></Input>
            </FormItem>
            <FormItem
              rules={[{ required: true, message: "Please select Candidate" }]}
              name="candidateName"
              label="Select Candidate"
            >
              <Select
                showSearch
                placeholder="Select Candidate"
                onSearch={(query) => {
                  if (query.length > 3) {
                    this.getCandidateList(query);
                  }
                }}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              //onChange={this.handleChange}
              >
                {this.state.candidateList.map((item, i) => (
                  <Select.Option value={item.id} key={i}>
                    {item.name + " (" + item.email + ")"}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>

            <FormItem
              rules={[
                { required: true, message: "Please select Enrollment Date" },
              ]}
              name="enrollmentDate"
              label="Enrollment Date"
            >
              <DatePicker format={"MM/DD/YYYY"} />
            </FormItem>

            <FormItem
              rules={[
                { required: true, message: "Please select Session Type" },
              ]}
              name="sessionType"
              label="Session Type"
            >
              <Radio.Group>
                <Radio style={{ width: "80px" }} value={"Live"}>
                  Live
                </Radio>
                <Radio value={"Recorded"}>Recorded</Radio>
              </Radio.Group>
            </FormItem>
            {(this.state.fields[3].value === "Recorded") ? (
              <FormItem
                name="reason"
                label="Reason"
                rules={[{
                  required: true,
                  message: "Please mention the reason"
                }]}
              >
                <TextArea />
              </FormItem>
            ) : null}

            <FormItem>
              <Button type="primary" htmlType="submit">
                Enroll
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

export default EnrollCandidate;
