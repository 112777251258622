import React from "react";
import CustomScrollbars from 'util/CustomScrollbars'
import MeetingItems from "./meetingItem/meetingItem";

const MeetingsList = (({meetingsList,selectMeeting,menu}) => {
  return (
    <div>
    <div className="gx-module-list-item">
    </div>
      <CustomScrollbars className="gx-module-content-scroll">
        {meetingsList.map((meeting, index) =>
        <MeetingItems menu={menu} key={index} meeting={meeting} selectMeeting={selectMeeting}/>
        )}
      </CustomScrollbars>
    </div>
  )
});

export default MeetingsList;
