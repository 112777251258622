import React, { Component } from "react";

import { Tabs } from "antd";
import { FiPieChart } from "react-icons/fi";
import { BsTable } from "react-icons/bs";
import TeamTable from "./TeamTable";
import TeamAnalytics from "../../RecruitmentReport/Team/TeamAnalytics";

const { TabPane } = Tabs;
class Team extends Component {
  render() {
    return (
      <div>
        <Tabs
          defaultActiveKey="table"
          onChange={this.renderTab}
          //activeKey={this.state.currentTab}
        >
          <TabPane
            tab={
              <span className="view_change">
                <BsTable className="table_icons mr-2" />
                Table View
              </span>
            }
            key="table"
          >
            <TeamTable />
          </TabPane>
          <TabPane
            tab={
              <span className="view_change">
                <FiPieChart className="table_icons mr-2" />
                Analytical View
              </span>
            }
            key="analytics"
          >
            <TeamAnalytics />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default Team;
