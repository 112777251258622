import React from 'react';
import {DatePicker} from 'antd';

const CustomDatePicker = ({disabled,name,value, onChange}) => {

  return (
    <DatePicker  className="form-field" disabled={disabled} placeholder="dd-mm-yyyy" value={value} name={name} format="DD-MM-YYYY" onChange={onChange} />
  );
}
export default CustomDatePicker;

