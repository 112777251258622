import { Select, Form, Button, message, Radio } from "antd";
import React, { useState } from "react";
import axiosJSON from "../../util/SetHeaderAPI";
import moment from "moment";
import {confirm} from "../../util/CustomModal"
const FormItem = Form.Item;

const EditAttendance = (props) => {
  const [fields] = useState([
    {
      name: "candidate",
      value: props.selectedCandidates,
    },
    {
      name: "date",
      value: props.selectedDate ? props.selectedDate.id : null,
    },
    {
      name: "attendanceType",
      value: props.selectedDate
        ? (
          props.selectedRows[0].attendance
            .map((item) => item.date.id)
            .indexOf(props.selectedDate.id) > -1
          ? (
            props.selectedRows[0].attendance[
              props.selectedRows[0].attendance
                .map((item) => item.date.id)
                .indexOf(props.selectedDate.id)
            ].is_required !== false
               ?(
                    props.selectedRows[0].attendance[
                      props.selectedRows[0].attendance
                        .map((item) => item.date.id)
                        .indexOf(props.selectedDate.id)
                    ].is_present !== null
                    ? props.selectedRows[0].attendance[
                        props.selectedRows[0].attendance
                          .map((item) => item.date.id)
                          .indexOf(props.selectedDate.id)
                      ].is_present
                    : moment(props.selectedDate.date) < moment()
                    ? false
                    : null
                  )  
                : "not_required"
            )
          : moment(props.selectedDate.date) < moment()
          ? false
          : null
          )
        : null,
    },
  ]);

  const onFinish = (value) => {

    if(value.candidate.length >= 1){
      let msg = `Are you sure to mark `
      props.selectCandidatesNames.map((name, index) => {
        if (index === props.selectCandidatesNames.length - 1){
          return msg += name + ' '
        }
        else{
          return msg += name + ', '
        }
      })
      let selectedDate = props.dateList.find(date=> date.id === value.date)
      msg += 'as '
      msg += `${value.attendanceType === "not_required" ? "Not Required" : value.attendanceType ? "Present ": "Absent "} for the date ${moment(selectedDate.date).format("MMM DD YYYY (dddd)")}`
      confirm({
        title: msg,
        onOk: () => {
          markAttendance(value);
        },
      });
    }
    else{
      markAttendance(value)
    }

  };

  const markAttendance = (value) => {
    let data = {};

    if (value.attendanceType === "not_required") {
      data = {
        present_candidates: [],
        not_required_candidates: props.selectedCandidates,
        absent_candidates: [],
        date: value.date,
      };
    }
    else if(value.attendanceType){
      data = {
        present_candidates: props.selectedCandidates,
        not_required_candidates: [],
        absent_candidates: [],
        date: value.date,
      };
    } else {
      data = {
        present_candidates: [],
        not_required_candidates: [],
        absent_candidates: props.selectedCandidates,
        date: value.date,
      };
    }
    axiosJSON
      .post(
        "/api/training_candidate/" + props.training_id + "/attendance/",
        data
      )
      .then(({ data, status }) => {
        if (status === 201) {
          message.success(data.message);
          props.closeModal();
        }
      })
      .catch(function (error) {
        message.error(error.message);
        props.closeModal();
      });
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content education-form maxwidth">
        <Form
          name="basic"
          fields={fields}
          onFinish={onFinish}
          className="gx-signin-form gx-form-row0"
        >
          <FormItem name="candidate" label="Candidates">
            <Select
              mode={!props.singleRowEdit && "multiple"}
              disabled={!props.singleRowEdit}
              open={false}
              style={{ width: "100%" }}
              placeholder="Please select"
              //defaultValue={props.selectedCandidates}
            >
              {props.selectedRows.map((ele, i) => (
                <Select.Option key={i} value={ele.training_candidate_id}>
                  {ele.consultant.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            rules={[
              { required: true, message: "Please select Attendance Date" },
            ]}
            name="date"
            label="Attendance Date"
          >
            <Select
              style={{ width: "100%" }}
              allowClear={!props.singleRowEdit}
              placeholder="Please select"
              open={props.singleRowEdit ? false : undefined}
              //onSelect={onDateSelect}
            >
              {props.dateList.map((ele, i) => (
                <Select.Option
                  key={i}
                  value={ele.id}
                  disabled={new Date(ele.date) > new Date()}
                >
                  {moment(ele.date).format("MMM DD YYYY (dddd)")}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            rules={[
              { required: true, message: "Please select Attendance Type" },
            ]}
            name="attendanceType"
            label="Mark As"
          >
            <Radio.Group>
              <Radio style={{ width: "80px" }} value={true}>
                Present
              </Radio>
              <Radio value={false}>Absent</Radio>
              <Radio value = {"not_required"}>Not required</Radio>
            </Radio.Group>
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType="submit">
              Mark Attendance
              {/* Mark As {!selectedDateStatus.is_present ? "Present" : "Absent"} */}
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default EditAttendance;
