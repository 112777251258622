import React from 'react';
import FileViewer from 'react-file-viewer';
import {message} from 'antd';
import axiosJSON from "../../util/SetHeaderAPI";

export default class ViewFile extends React.Component{
    constructor(props){
        super(props)
        this.state={
            filetype:'',
            filepath:'',
            doc_id:this.props.doc_id,
        }
      this.Mounted = false
    }
    onError = (e) => {
      console.log(e)
    }
  componentWillUnmount() {
    this.Mounted = false
  }

  componentDidMount() {
    this.Mounted = true
        this.setState({
            filetype:"",
            filepath:""
        },()=> this.getDocumentUrl(this.props.doc_id))
    }


    getDocumentUrl=(id)=>
    {
        axiosJSON.get('api/attachment_url/'+id+'/')
        .then(res => {
           this.setState({
               filetype:res.data.data.file_ext,
               filepath: res.data.data.file
           })
        }).catch(function (error) {
            message.error(error.message)
          })
    }
    render()
    {
        return(
            this.state.filepath !== "" && this.state.filetype !== "" ?
              this.state.filetype.toLowerCase() === "jpeg" ||  this.state.filetype.toLowerCase() === "jpg"
              ||  this.state.filetype.toLowerCase() === "png" ?
                <div>
                  <img alt="file" src={this.state.filepath} />

                </div>
                :
            <div>
                <FileViewer
                key={this.props.doc_id}
                fileType={this.state.filetype}
                filePath={this.state.filepath}
                errorComponent={<div>Error</div>}
                onError={this.onError}
            />
            </div>
              :<div>Loading...</div>
        );
    }
}
