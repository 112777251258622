import React, { useEffect, useState } from "react";
import { message } from "antd";
import moment from "moment";
import { FiAlertTriangle } from "react-icons/fi";
import { AiOutlineFileText } from "react-icons/ai";
import CircularProgress from "../../CircularProgress";
import axiosJSON from "../../../util/SetHeaderAPI";

const Activity = (props) => {
  const [activityList, setActivityList] = useState([]);
  const [activityLoader, setActivityLoader] = useState(false);

  useEffect(() => {
    getCandidateActivity();
  }, [props.activityUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCandidateActivity = () => {
    setActivityLoader(true);
    axiosJSON
      .get(
        "/api/training_candidate/" +
          props.candidate.training_candidate_id +
          "/activity/"
      )
      .then(({ data, status }) => {
        setActivityList(data.data);
        setActivityLoader(false);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  return (
    <div className="col-4 pr-0 activity">
      <div className="card">
        <div className="card-header ch-clr">Activity</div>
        <div className="card-body">
          {activityLoader ? (
            <CircularProgress />
          ) : activityList.length > 0 ? (
            activityList.map((ele,i) => (
              <div key={i} className="bordr_cls">
                <div className="general_sub2 ">
                  <div className="p-0 col-2">
                    {ele.activity_type === "created" ? (
                      <div className="circle_icon notifi notify1">
                        <AiOutlineFileText />
                      </div>
                    ) : (
                      <div className="circle_icon notifi notify2">
                        <FiAlertTriangle />
                      </div>
                    )}
                  </div>
                  <div className="ml-2">
                    <div className="h4">
                      <span className="date_cls">
                        {moment(ele.created).format("MM/DD/YYYY")}
                      </span>
                    </div>
                    <div className="activity_des">{ele.desc}</div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="noData">No Data</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Activity;
