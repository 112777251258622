export const styles = {
  mainDivStyle: {
    height: "52px",
    background: "#1B2240",
    padding: "8px 24px",
    borderRadius: "10px 10px 0px 0px",
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  activeNavStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#FFFFFF",
    padding: "8px 16px",
    borderRadius: "6px",
    color: "#1B2240",
    cursor: "pointer",
  },
  navStyle: {
    color: "#C1C8E7",
    fontSize: "14px",
    cursor: "pointer",
  },
};
