import React from "react";
import {FiUser, FiMail,FiAlignLeft} from 'react-icons/fi'

const Reason = (props) => {
  return (
    <div >
      <div >
        <div>
            <div className = "header-reason-modal">
                <FiUser style = {{height: "16px", width: "27px"}} />
                <p className="text-header-reason-modal">Consultant Name</p>
            </div>
            <p style = {{marginLeft: "25px"}}> {props.candidate.name}</p>
        </div>
        <div>
            <div className = "header-reason-modal">
            <FiMail style = {{height: "16px", width: "27px"}} />
            <p className = "text-header-reason-modal">Email</p>  
            </div>
            
            <p style = {{marginLeft: "25px"}}>{props.candidate.email}</p>
        </div>
        <div>
            <div className="header-reason-modal">
                <FiAlignLeft style = {{height: "16px", width: "27px"}}  />
                <p className = "text-header-reason-modal">Reason</p>
            </div>
            <p style = {{marginLeft: "25px"}}>{props.candidate.reason}</p>
        </div>
      </div>
    </div>
  );
};

export default  Reason;
