import React from "react";
import { Button, message, Form, Tooltip, Radio } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import PhoneInputType from "../../util/PhoneInput";
import axiosJSON from "../../util/Api";

class EditPhone extends React.Component {
  state = {
    listKeys: [],
    numberList: [],
    loader: true,
  };
  Mounted = false;

  componentWillUnmount() {
    this.Mounted = false;
  }

  checkPhone = (number) => {
    if (this.props.selectedCountryCode === "us" && !number.startsWith("1")) {
      number = "1" + number;
    } else if (
      this.props.selectedCountryCode === "in" &&
      !number.startsWith("91")
    ) {
      number = "91" + number;
    }
    return number;
  };

  componentDidMount() {
    let phoneNumberList = [],
      count = 0;
    let listKeys = [];
    if (this.props.primaryPhone) {
      let obj = {
        key: count,
        phoneNumber: this.checkPhone(this.props.primaryPhone),
        is_primary: true,
      };
      phoneNumberList.push(obj);
      listKeys.push(count);
      count++;
    }
    if (this.props.phone) {
      for (let i of this.props.phone) {
        let number = i;
        number = this.checkPhone(number);
        let obj = {
          key: count,
          is_primary: false,
          phoneNumber: number,
        };
        phoneNumberList.push(obj);
        listKeys.push(count);
        count++;
      }
    }
    this.setState({
      numberList: phoneNumberList,
      listKeys: listKeys,
      loader: false,
    });
  }

  onSubmit = () => {
    let primaryNumber = "";
    let phoneNumbers = [];
    this.state.numberList.map((ele, i) => {
      if (ele.is_primary) {
        primaryNumber = ele.phoneNumber;
      } else {
        phoneNumbers.push(ele.phoneNumber);
      }
      return null;
    });
    if (primaryNumber === "") {
      message.error("Please provide primary phone number.");
    } else {
      const body = {
        primary_no: primaryNumber,
        phone_numbers: phoneNumbers,
        update_contact: true
      };

      axiosJSON
        .put("api/candidate/" + this.props.id + "/", body)
        .then(({ data, status }) => {
          if (status === 202) {
            message.success(data.message);
            this.props.closeModal();
          }
        })
        .catch(function (error) {
          message.error(error.message);
        });
    }
  };

  radioChecked = (e, index) => {
    let numberList = this.state.numberList;

    numberList.map((field, i) => {
      if (i === index) {
        field.is_primary = e.target.checked;
      } else {
        field.is_primary = false;
      }
      return null;
    });
    this.setState({
      numberList: numberList,
    });
  };

  remove = (k) => {
    const { listKeys, numberList } = this.state;
    // if (listKeys.length === 1) {
    //   this.setState({
    //     numberList: [{
    //       key: 0,
    //       email: "",
    //       is_primary: true

    //     }]
    //   })
    // } else {
    this.setState(
      {
        numberList: numberList.filter((key) => key.key !== k),
        listKeys: listKeys.filter((key) => key !== k),
      },
      () => {
        let arr = this.state.numberList;
        if (arr.length === 1) {
          arr[0].is_primary = true;
        }
        this.setState({ numberList: arr });
      }
    );
    // }
  };

  add = () => {
    const { listKeys, numberList } = this.state;
    numberList.push({
      key: listKeys[listKeys.length - 1] + 1,
      phoneNumber: "",
      is_primary: listKeys.length > 0 ? false : true,
    });
    listKeys.push(listKeys[listKeys.length - 1] + 1);

    this.setState({
      numberList: numberList,
      listKeys: listKeys,
    });
  };

  handleChange = (e, type, index) => {
    let numberList = this.state.numberList;
    numberList.map((field, i) => {
      if (i === index) {
        field[type] = e;
      }
      return null;
    });
    this.setState({
      numberList: numberList,
    });
  };

  render() {
    const { numberList, listKeys, loader } = this.state;
    return !loader ? (
      <div>
        <Form
          className="candidate-edit-from-modal"
          onFinish={this.onSubmit}
          // autoComplete="off"
          // initialValues={{ phone_numbers: numberList }}
        >
          <Form.List name="phone_numbers">
            {(fields, { add, remove }) => (
              <>
                {/* {console.log(fields, numberList)} */}
                {listKeys.map((key, index) => (
                  <div
                    key={key}
                    className="gx-media  gx-flex-nowrap gx-pro-contact-list"
                  >
                    <Tooltip title="Set as primary">
                      <Radio
                        checked={
                          numberList[index]
                            ? numberList[index].is_primary
                            : false
                        }
                        onChange={(e) => this.radioChecked(e, index)}
                      />
                    </Tooltip>
                    <Form.Item
                      style={{ width: "100%" }}
                      name={index}
                      rules={[
                        {
                          required: true,
                          message: "Please enter Phone Number!",
                        },
                        {
                          validator: async (_, value) => {
                            if (!value) return;
                            if (value.startsWith("91") && value.length < 12) {
                              return Promise.reject(
                                new Error("Please enter valid Phone Number")
                              );
                            } else if (
                              value.startsWith("1") &&
                              value.length < 11
                            ) {
                              return Promise.reject(
                                new Error("Please enter valid Phone Number")
                              );
                            }
                          },
                        },
                      ]}
                      initialValue={numberList[index].phoneNumber}
                    >
                      <PhoneInputType
                        selectedCountryCode={this.props.selectedCountryCode}
                        disableDropdown={true}
                        onChange={(number) =>
                          this.handleChange(number, "phoneNumber", index)
                        }
                      />
                    </Form.Item>
                    {listKeys.length > 1 ? (
                      <DeleteOutlined
                        className="dynamic-delete-button delete-icon-phone"
                        onClick={() => {
                          this.remove(key);
                          remove(index);
                        }}
                      />
                    ) : null}
                  </div>
                ))}
              </>
            )}
          </Form.List>
          <Button
            type="dashed"
            onClick={() => {
              this.add();
            }}
          >
            <PlusOutlined />
            Add more phone numbers
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </div>
    ) : null;
  }
}
export default EditPhone;
