import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const PhoneInputType = ({ disableDropdown = false ,selectedCountryCode = "us",value="", onChange, disabled }) => {
  return (
    <>
      <PhoneInput
        country={selectedCountryCode}
        onlyCountries={["in", "us", "ca"]}
        value={value}
        disableCountryCode={false}
        countryCodeEditable={false}
        onChange={onChange}
        disabled={disabled}
        inputClass="phoneNumberInput"
        disableCountryGuess={true}
        disableDropdown={disableDropdown}
      />
    </>
  );
};

export default PhoneInputType;
