import React, { Component } from "react";
import { Col, Row } from "antd";
import CustomSelect from "../../../../components/CustomFields/CustomSelect";
import CustomDatePicker from "../../../../components/CustomFields/CustomDatePicker";
import CustomTextArea from "../../../../components/CustomFields/CustomTextArea";
import CustomUpload from "../../../../components/CustomFields/CustomUpload";
import CustomInput from "../../../../components/CustomFields/CustomInput";
import CustomInputNumber from "../../../../components/CustomFields/CustomInputNumber";

class PreviewForm extends Component {
  render() {
    return (
      <Col xl={8} lg={10} md={10} sm={24} xs={24}>
        <div className="application-form">
          <Col xl={16} lg={10} md={10} sm={24} xs={24}>
            <span className="gx-fs-lg">{this.props.formName}</span>
          </Col>
          {this.props.inputFields.map((field, i) => (
            <Row key={i}>
              <div
                style={{ display: "inline-flex", marginBottom: "10px" }}
                key={i}
              >
                <ul>
                  {/* {this.props.desc.map((ele, k) =>
                    ele.name === field.name ?
                      ele.value === "" ? null: */}
                  {field.description && (
                    <li>
                      <span>{field.description}</span>
                    </li>
                  )}

                  <li>
                    {field.field_type === "text" ? (
                      <CustomInput
                        key={field.name}
                        name={field.name}
                        disabled={this.props.disabled}
                        placeholder={field.display_name}
                      />
                    ) : field.field_type === "number" ? (
                      <CustomInputNumber
                        key={field.name}
                        name={field.name}
                        disabled={this.props.disabled}
                        placeholder={field.display_name}
                      />
                    ) : field.field_type === "longtext" ? (
                      <CustomTextArea
                        key={field.name}
                        name={field.name}
                        disabled={this.props.disabled}
                        placeholder={field.display_name}
                      />
                    ) : field.field_type === "select" ? (
                      <CustomSelect
                        key={field.name}
                        name={field.name}
                        options={field.option}
                        disabled={this.props.disabled}
                        placeholder={field.display_name}
                      />
                    ) : field.field_type === "multiselect" ? (
                      <CustomSelect
                        key={field.name}
                        name={field.name}
                        options={field.option}
                        disabled={this.props.disabled}
                        placeholder={field.display_name}
                      />
                    ) : field.field_type === "datepicker" ? (
                      <CustomDatePicker
                        key={field.name}
                        name={field.name}
                        placeholder={field.display_name}
                        disabled={this.props.disabled}
                      />
                    ) : field.field_type === "file" ? (
                      <CustomUpload
                        fileList={[]}
                        key={field.name}
                        name={field.name}
                        accept={field.accept}
                        placeholder={field.display_name}
                        disabled={this.props.disabled}
                      />
                    ) : null}
                  </li>

                  {field.required ? (
                    <li>
                      <span style={{ color: "red" }}>Required</span>
                    </li>
                  ) : null}
                </ul>
              </div>
            </Row>
          ))}
        </div>
      </Col>
    );
  }
}

export default PreviewForm;
