import React from "react";
import {Avatar, Checkbox} from "antd";


const MailListItem = ({mail, onMailSelect, onMailChecked}) => {
  return (
    <div className="gx-module-list-item gx-mail-cell"
         style={{backgroundColor:mail.read?'rgba(244,248,248,0.38)':'rgba(227,237,240,0.56)'}}>
      <div className="gx-module-list-icon">
        <Checkbox color="primary" className="gx-icon-btn"
                  checked={mail.selected}
                  onClick={(event) => {
                    event.stopPropagation();
                    onMailChecked(mail)
                  }}
                  value="SelectMail"
        />

        <div className="gx-ml-2">
          {mail.from?
            <Avatar className="gx-avatar gx-bg-blue gx-size-40"> {mail.from.name.charAt(0).toUpperCase()}</Avatar> :
            null
          }
        </div>
      </div>

      <div className="gx-mail-list-info" onClick={() => {
        onMailSelect(mail);
      }}>

        <div className="gx-module-list-content">
          <div className="gx-mail-user-des">

            <span style={{color:mail.read?'gray':'black'}} className="gx-sender-name">{mail.from.name}</span>

            <span className="gx-toolbar-separator">&nbsp;</span>

            <span style={{color:mail.read?'gray':'black'}} className="gx-d-inline-block gx-text-truncate gx-send-subject">{mail.subject}</span>
            <span style={{color:mail.read?'gray':'black'}} className="gx-d-inline-block gx-text-right">{mail.ct > 0 ? mail.ct :null}</span>

            {/*{mail.hasAttachments &&*/}

            {/*<i className="icon icon-attachment"/>}*/}

            {/*<div className="gx-time">{mail.time}</div>*/}

          </div>


          <div className="gx-message">
            <p style={{color:mail.read?'gray':'black'}} className="gx-text-truncate" dangerouslySetInnerHTML={{__html:mail.body}}/>

          </div>
          {/*<div className="gx-labels">*/}
          {/*  {labels.map((label, index) => {*/}
          {/*    return (mail.labels).includes(label.id) &&*/}
          {/*      <div key={index} className={`gx-badge gx-text-white gx-bg-${label.color}`}>{label.title}</div>*/}
          {/*  })}*/}
          {/*</div>*/}
        </div>

      </div>

    </div>
  )
};

export default MailListItem;
