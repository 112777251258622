import React, { Component } from "react";
import CandidateDetail from "../../components/TrainingCandidateView";

export class CandidateDetails extends Component {
  render() {
    return (
      <div className="gx-login-container">
        <div className="col-12 card_coll">
          <CandidateDetail candidate={this.props.candidate} training_id={this.props.training_id} />
        </div>
      </div>
    );
  }
}
export default CandidateDetails;
