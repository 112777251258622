import React, { useEffect, useState } from "react";
import { Button, Dropdown, message, Popover, Tag, Menu } from "antd";
import { Link, useRouteMatch, withRouter } from "react-router-dom";
import {
  EditOutlined,
  ExclamationCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";
import AssignRecruiter from "../../../../modals/Candidate/assignRecruiter";
import EditProfile from "../../../../modals/Candidate/editProfile";
import ViewFollower from "../../../../modals/Candidate/viewFollower";
import axiosJSON from "../../../../util/SetHeaderAPI";
import EmailPreview from "../../../../modals/Candidate/emailPreview";
import EditJob from "../../../../modals/Candidate/editJob";
import MeetingForm from "../../../../modals/Candidate/scheduleMeeting";
import { useSelector } from "react-redux";
import { setHeaderData } from "../../../../appRedux/actions";
import "nprogress/nprogress.css";
import nprogress from "nprogress";
import { CustomModal as Modal, confirm } from "../../../../util/CustomModal";
import { BsArrowLeftCircle } from "react-icons/bs";
// const {confirm} = Modal;

const ProfileHeader = ({
  id,
  getDetails,
  data,
  follow,
  followCandidate,
  permissions,
  getPermissions,
  updateEdit,
  history,
  app
}) => {
  const match = useRouteMatch();
  const auth = useSelector(({ auth }) => auth);
  const { timezone } = useSelector(({ common }) => common);
  const [assignRecruiter, setAssignRecruiter] = useState(false);
  const [editProfile, setProfile] = useState(false);
  const [editRecruiter, setEditRecruiter] = useState(false);
  const [editJob, setJob] = useState(false);
  const [stages, setStagesArray] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [stage, setStage] = useState([]);
  const [openFollowers, setModalFollowers] = useState(false);
  const [openEmailPreview, setOpenEmailPreview] = useState(false);
  const [showScheduleForm, setShowScheduleForm] = useState(false);
  const [position, setPosition] = useState({});
  const [tzStatus, setTzStatus] = useState(true);
  const [navStep, setNavStep] = useState({
    Summary: true,
    Emails: false,
    Documents: false,
    Evaluation: false,
    "Form Submission": false,
    Activity: false,
    Comment: false,
    Projects: false,
    Trainings: false,
    Feedbacks:false,
  });

  const tzDate = new Date();
  // const tz= 0;

  const handleAssignRecruiter = () => {
    setAssignRecruiter(true);
  };
  useEffect(() => {
    const splitUrl = window.location.href.split("/");
    const tab = splitUrl[splitUrl.length - 1];
    let newNavStep = { ...navStep };

    Object.keys(newNavStep).forEach((key, value) => {
      if (tab === "emails" && "Emails" === key) {
        return (newNavStep[key] = true);
      } else if (tab === "documents" && "Documents" === key) {
        return (newNavStep[key] = true);
      } else if (tab === "evaluation" && "Evaluation" === key) {
        return (newNavStep[key] = true);
      } else if (tab === "form-submission" && "Form Submission" === key) {
        return (newNavStep[key] = true);
      } else if (tab === "activity" && "Activity" === key) {
        return (newNavStep[key] = true);
      } else if (tab === "comment" && "Comment" === key) {
        return (newNavStep[key] = true);
      } else if (tab === "projects" && "Projects" === key) {
        return (newNavStep[key] = true);
      } else if (tab === "summary" && "Summary" === key) {
        return (newNavStep[key] = true);
      } else if (tab === "trainings" && "Trainings" === key) {
        return (newNavStep[key] = true);
      }else if (tab === "feedbacks" && "Feedbacks" === key) {
        return (newNavStep[key] = true);
      }
      return (newNavStep[key] = false);
    });
    setNavStep(newNavStep);

    let mounted = true;
    if (mounted) {
      getStages();
      getData();
    }
    return function cleanup() {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStages = () => {
    axiosJSON
      .get("api/choice/?type=stage")
      .then(({ data, status }) => {
        if (status === 200) {
          setStagesArray(data.data);
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };
  const handleRequestClose = () => {
    setProfile(false);
    setAssignRecruiter(false);
    setModalFollowers(false);
    setOpenEmailPreview(false);
    setJob(false);
    getDetails();
    setShowScheduleForm(false);
    updateEdit();
  };
  const onClose = () => {
    setProfile(false);
    setAssignRecruiter(false);
    setModalFollowers(false);
    setOpenEmailPreview(false);
    setJob(false);
    setShowScheduleForm(false);
  };
  const openFollowersModal = () => {
    setModalFollowers(true);
  };

  const findFirstSundayNov = (d) => {
    var date = new Date(d.getFullYear(), 10, 1);
    var novDate = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
    if (date.getDay() === 0) {
      novDate.setDate(date.getDate());
    } else {
      novDate.setDate(date.getDate() + 7 - date.getDay());
    }
    return novDate;
  };
  const findSecondSundayMar = (d) => {
    var date = new Date(d.getFullYear(), 2, 1);
    var marDate = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
    if (date.getDay() === 0) {
      marDate.setDate(date.getDate() + 7);
    } else {
      marDate.setDate(date.getDate() + 14 - date.getDay());
    }
    return marDate;
  };

  const timeZoneCheck = () => {
    let startOfEST;
    let endOfEST;
    var tz = tzDate.getTimezoneOffset();
    endOfEST = findFirstSundayNov(new Date());
    startOfEST = findSecondSundayMar(new Date());
    if (timezone === "IST") {
      setTzStatus(false);
    } else {
      if (startOfEST < new Date() && new Date() < endOfEST) {
        if (tz === 240) {
          setTzStatus(false);
        }
      } else if (tz === 300) {
        setTzStatus(false);
      } else {
        setTzStatus(true);
      }
    }
  };

  const getData = () => {
    axiosJSON
      .get("api/candidate/" + id + "/about/")
      .then(({ data, status }) => {
        if (status === 200) {
          setPosition(data.data.position);
          setHeaderData(data.data);
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  const actionMenu = () => {
    return (
      <Menu id="long-menu">
        {
          <Menu.Item
            key="0"
            onClick={() => {
              timeZoneCheck();
              if (permissions.screening) setShowScheduleForm(true);
              else message.error("You don't have access");
            }}
          >
            Schedule Meeting
          </Menu.Item>
        }
        {/*<Menu.Item key="1" onClick={() => setJob(true)}>Add/ Edit Jobs</Menu.Item>*/}
        {
          <Menu.Item
            key="2"
            onClick={() => {
              if (permissions.update) setProfile(true);
              else message.error("You don't have access");
            }}
          >
            Edit Profile
          </Menu.Item>
        }
      </Menu>
    );
  };

  const changeCandidateStage = (value) => {
    if (parseInt(value) === 17) {
      axiosJSON
        .get("/api/candidate/" + id + "/field_check/")
        .then(({ data, status }) => {
          confirm({
            title: "Are you sure you want to move this candidate to Log1?",
            icon: <ExclamationCircleOutlined />,
            content: "Move to Marketing",
            onOk() {
              if (status === 200) {
                changeStage(value);
              }
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        })
        .catch(function (error) {
          message.error(error.message);
        });
    } else {
      changeStage(value);
    }
  };

  const changeStage = (value) => {
    nprogress.start();
    axiosJSON
      .get("api/candidate/get_template/?stage=" + value)
      .then(({ data, status }) => {
        if (data.data.length > 0) {
          setStage(value);
          setTemplateList(data.data);
          setOpenEmailPreview(true);
        } else {
          const body = {
            stage: parseInt(value),
            email: false,
          };
          axiosJSON
            .put("api/candidate/" + id + "/stage/", body)
            .then(({ data, status }) => {
              if (status === 202) {
                getDetails();
              }
            })
            .catch(function (error) {
              message.error(error.message);
            });
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
    nprogress.done();
  };

  const goBack = () => {
    history.goBack();
  };

  const onTabChange = (e) => {
    if (e.target.textContent.length < 16) {
      let newNavStep = { ...navStep };
      Object.keys(newNavStep).forEach((key, value) => {
        if (`${e.target.textContent}` === key) return (newNavStep[key] = true);
        return (newNavStep[key] = false);
      });
      setNavStep(newNavStep);
    }
  };

  return (
    <div className="gx-profile-banner">
      <div className="gx-profile-container">
        <div className="gx-profile-banner-top">
          <div className="gx-profile-banner-top-left">
            <div className="gx-profile-banner-avatar-info d-flex">
              <BsArrowLeftCircle className="back-btn" onClick={goBack} />
              <div className="head-line">
                <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
                  {data && data.name}
                </h2>
                {/* <EditOutlined onClick={() => setProfile(true)} className={`gx-fs-sm gx-text-white gx-pointer`} /> */}
              </div>
            </div>

            <div className="location-section">
              <p className="gx-mb-0 gx-fs-lg">
                {data && data.current_location}
              </p>
              {follow ? (
                <Button className="gx-text-darkblue">Following</Button>
              ) : (
                <Button
                  className="gx-bckg-blue gx-text-white"
                  onClick={followCandidate}
                >
                  Follow
                </Button>
              )}
            </div>

            <div className="location-section">
              <div className="job-section">
                <ul>
                  <li>
                    {data && data.position && (
                      <Tag color="darkgreen" style={{ width: "fit-content" }}>
                        {data.position}
                      </Tag>
                    )}
                  </li>
                  <li>
                    <Dropdown
                      className="job-dropdown"
                      overlay={
                        <Menu>
                          {stages.length > 0 &&
                            stages.map((stg, index) => (
                              <Menu.Item
                                key={stg.id}
                                onClick={(e) => changeCandidateStage(e.key)}
                              >
                                {stg.display_name}
                              </Menu.Item>
                            ))}
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Tag color="green" style={{ width: "fit-content" }}>
                        {data && data.stage && data.stage.display_name}{" "}
                        <DownOutlined
                          style={{ position: "relative", right: 0 }}
                        />
                      </Tag>
                    </Dropdown>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="gx-profile-banner-top-right">
            <ul className="gx-follower-list">
              {data && data.recruiter ? (
                <li>
                  <Popover
                    content={
                      <div>
                        <p>Email : {data.recruiter.email}</p>
                      </div>
                    }
                    title={null}
                  >
                    <span className="gx-pointer gx-follower-title gx-fs-lg gx-font-weight-medium">
                      {data.recruiter.name}
                    </span>
                    <span className="gx-fs-sm">Recruiter</span>
                    {auth.authUser.settings && (
                      <EditOutlined
                        onClick={() => {
                          setEditRecruiter(true);
                          handleAssignRecruiter();
                        }}
                      />
                    )}
                  </Popover>
                </li>
              ) : (
                <li>
                  {auth.accessData.assign_recruiter && (
                    <Button onClick={handleAssignRecruiter}>
                      Assign Recruiter
                    </Button>
                  )}
                </li>
              )}

              <li>
                <div onClick={openFollowersModal}>
                  <span className="gx-pointer gx-follower-title gx-fs-lg gx-font-weight-medium">
                    {data &&
                      data.followers &&
                      data.followers !== null &&
                      data.followers.length}
                  </span>
                  <span className="gx-fs-sm">Followers</span>
                </div>
              </li>
            </ul>
            <div className="schedule-button">
              <Dropdown overlay={actionMenu} trigger={["click"]}>
                <i
                  className="icon icon-ellipse-v"
                  style={{ cursor: "pointer" }}
                />
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="gx-profile-banner-bottom">
          <div className="gx-tab-list">
            <ul
              className="gx-navbar-nav"
              style={{ display: "flex", alignItems: "center" }}
            >
              <li>
                <div
                  style={navStep.Summary ? styles.viewWrapper : null}
                  onClick={(e) => onTabChange(e)}
                >
                  <Link to={{ pathname: match.url + "/" + id + "/summary" }}>
                    <span
                      style={{
                        color: navStep.Summary ? "#26305C" : "#bfbfbf",
                      }}
                    >
                      Summary
                    </span>
                  </Link>
                </div>
              </li>
              <li>
                <div
                  style={navStep.Emails ? styles.viewWrapper : null}
                  onClick={(e) => onTabChange(e)}
                >
                  <Link to={{ pathname: match.url + "/" + id + "/emails" }}>
                    <span
                      style={{
                        color: navStep.Emails ? "#26305C" : "#bfbfbf",
                      }}
                    >
                      Emails
                    </span>
                  </Link>
                </div>
              </li>
              <li>
                <div
                  style={navStep.Documents ? styles.viewWrapper : null}
                  onClick={(e) => onTabChange(e)}
                >
                  <Link to={{ pathname: match.url + "/" + id + "/documents" }}>
                    <span
                      style={{
                        color: navStep.Documents ? "#26305C" : "#bfbfbf",
                      }}
                    >
                      Documents
                    </span>
                  </Link>
                </div>
              </li>
              <li>
                <div
                  style={navStep.Evaluation ? styles.viewWrapper : null}
                  onClick={(e) => onTabChange(e)}
                >
                  <Link to={{ pathname: match.url + "/" + id + "/evaluation" }}>
                    <span
                      style={{
                        color: navStep.Evaluation ? "#26305C" : "#bfbfbf",
                      }}
                    >
                      Evaluation
                    </span>
                  </Link>
                </div>
              </li>
              {/*<li>*/}
              {/*  <Link to={{ pathname: match.url + "/" + id + "/feedback" }}><span*/}
              {/*    className="gx-link">Feedback</span></Link>*/}
              {/*</li>*/}
              <li>
                <div
                  style={navStep["Form Submission"] ? styles.viewWrapper : null}
                  onClick={(e) => onTabChange(e)}
                >
                  <Link
                    to={{ pathname: match.url + "/" + id + "/form-submission" }}
                  >
                    <span
                      style={{
                        color: navStep["Form Submission"]
                          ? "#26305C"
                          : "#bfbfbf",
                      }}
                    >
                      Form Submission
                    </span>
                  </Link>
                </div>
              </li>
              <li>
                <div
                  style={navStep.Activity ? styles.viewWrapper : null}
                  onClick={(e) => onTabChange(e)}
                >
                  <Link to={{ pathname: match.url + "/" + id + "/activity" }}>
                    <span
                      style={{
                        color: navStep.Activity ? "#26305C" : "#bfbfbf",
                      }}
                    >
                      Activity
                    </span>
                  </Link>
                </div>
              </li>
              <li>
                <div
                  style={navStep.Comment ? styles.viewWrapper : null}
                  onClick={(e) => onTabChange(e)}
                >
                  <Link to={{ pathname: match.url + "/" + id + "/comment" }}>
                    <span
                      style={{
                        color: navStep.Comment ? "#26305C" : "#bfbfbf",
                      }}
                    >
                      Comment
                    </span>
                  </Link>
                </div>
              </li>

              {app === "us" ? (
                <>
                  <li>
                    <div
                      style={navStep.Projects ? styles.viewWrapper : null}
                      onClick={(e) => onTabChange(e)}
                    >
                      <Link
                        to={{ pathname: match.url + "/" + id + "/projects" }}
                      >
                        <span
                          style={{
                            color: navStep.Projects ? "#26305C" : "#bfbfbf",
                          }}
                        >
                          Projects
                        </span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div
                      style={navStep.Trainings ? styles.viewWrapper : null}
                      onClick={(e) => onTabChange(e)}
                    >
                      <Link
                        to={{ pathname: match.url + "/" + id + "/trainings" }}
                      >
                        <span
                          style={{
                            color: navStep.Trainings ? "#26305C" : "#bfbfbf",
                          }}
                        >
                          Trainings
                        </span>
                      </Link>
                    </div>
                  </li>
                
                  <li>
                    <div
                      style={navStep.Feedbacks ? styles.viewWrapper : null}
                      onClick={(e) => onTabChange(e)}
                    >
                      <Link
                        to={{ pathname: match.url + "/" + id + "/feedbacks" }}
                      >
                        <span
                          style={{
                            color: navStep.Feedbacks ? "#26305C" : "#bfbfbf",
                          }}
                        >
                          Feedbacks
                        </span>
                      </Link>
                    </div>
                  </li>
                </>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
      {assignRecruiter && (
        <Modal
          title="Assign Recruiter"
          className="popupsize"
          visible={assignRecruiter}
          footer={null}
          onCancel={onClose}
        >
          <AssignRecruiter
            recruiter={data.recruiter}
            edit={editRecruiter}
            id={id}
            closeModal={handleRequestClose}
            getPermissions={getPermissions}
          />
        </Modal>
      )}
      {editProfile && (
        <Modal
          title="Edit Profile"
          visible={editProfile}
          footer={null}
          onCancel={onClose}
        >
          <EditProfile id={id} data={data} closeModal={handleRequestClose} />
        </Modal>
      )}
      {openFollowers && (
        <Modal
          title="See followers"
          visible={openFollowers}
          footer={null}
          onCancel={onClose}
        >
          <ViewFollower
            id={data.id}
            followers={data.followers}
            closeModal={handleRequestClose}
          />
        </Modal>
      )}
      {openEmailPreview && (
        <Modal
          title="Email Preview"
          visible={openEmailPreview}
          footer={null}
          className="mail_popup"
          onCancel={onClose}
        >
          <EmailPreview
            id={data.id}
            closeModal={handleRequestClose}
            stage={stage}
            templateList={templateList}
          />
        </Modal>
      )}
      {editJob && (
        <Modal
          title="Edit Job"
          visible={editJob}
          footer={null}
          onCancel={onClose}
        >
          <EditJob
            id={data.id}
            closeModal={handleRequestClose}
            getDetails={getDetails}
            jobs={data.jobs}
          />
        </Modal>
      )}
      {showScheduleForm && (
        <Modal
          title="Schedule Meeting"
          className="schedule-form-popup"
          visible={showScheduleForm}
          footer={null}
          onCancel={() => setShowScheduleForm(false)}
        >
          <MeetingForm
            timezone={timezone}
            position={position}
            tzStatus={tzStatus}
            edit={false}
            data={{}}
            closeModal={handleRequestClose}
            candidate={id}
          />
        </Modal>
      )}
    </div>
  );
};

const styles = {
  viewWrapper: {
    position: "relative",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    borderRadius: "6px",
    gap: "8px",
    padding: " 8px 16px",
    cursor: "pointer",
  },
};

export default withRouter(ProfileHeader);
