import { message, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FiAlertTriangle, FiMessageCircle } from "react-icons/fi";
import axiosJSON from "../../../util/Api";
import { styles } from "./style";

const Activity = ({ traingId }) => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAllActivity = async () => {
      try {
        setLoading(true);
        const data = await axiosJSON.get(
          `api/training_candidate/${traingId}/activity/`
        );
        setActivities(data.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error("Something went wrong, please reach to support");
      }
    };
    getAllActivity();
    // eslint-disable-next-line
  }, []);

  return (
    <Spin spinning={loading}>
      <div>
        {" "}
        <div style={styles.mainWrapper}>
          {activities.length > 0 ? (
            activities.map((activity, index) => {
              return (
                <div
                  style={
                    index === 0
                      ? activityStyle.firstDisplayFeedbackDivStyle
                      : activityStyle.otherDisplayFeedbackDivStyle
                  }
                  key={index}
                >
                  <div style={activityStyle.innerDivStyle}>
                    {activity.activity_type === "created" ? (
                      <div style={activityStyle.iconsStyle}>
                        <FiMessageCircle />
                      </div>
                    ) : (
                      <div
                        style={{
                          ...activityStyle.iconsStyle,
                          background: "#FFF8EA",
                          color: "#E8930B",
                        }}
                      >
                        <FiAlertTriangle />
                      </div>
                    )}

                    <div
                      style={{
                        ...styles.rightInnerDivStyle,
                        color: "#353D4A",
                        fontSize: "14px",
                      }}
                    >
                      {activity.desc}
                    </div>
                  </div>
                  <div style={activityStyle.fontStyle}>
                    {moment(activity.created).format("MM-DD-YYYY")}
                  </div>
                </div>
              );
            })
          ) : (
            <div style={styles.noDataAvailble}>No data</div>
          )}
        </div>
      </div>
    </Spin>
  );
};

const activityStyle = {
  firstDisplayFeedbackDivStyle: {
    display: "flex",
    justifyContent: "space-between",
    ...styles.firstDisplayFeedbackDivStyle,
    alignItems: "center",
    gap: "16px",
    padding: "40px 12px",
    height: "65px",
  },
  otherDisplayFeedbackDivStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ...styles.otherDisplayFeedbackDivStyle,
    gap: "16px",
    padding: "40px 12px",
    height: "65px",
  },
  fontStyle: {
    color: "#9AA1B0",
    fontSize: "15px",
  },
  innerDivStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
  },
  iconsStyle: {
    display: "flex",
    background: "#D7DDFA",
    padding: "8px",
    borderRadius: "50%",
    color: "#555F8B",
  },
};

export default Activity;
