export const styles = {
  mostOuterWrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 0px",
    gap: "10px",
    background: "#FFFFFF",
    borderRadius: "12px",
    border: "1px solid #EAEDF2",
  },
  leftWrapper: {
    display: "flex",
    padding: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "12px",
  },
  backArrowStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #B1B7C4",
    borderRadius: "48px",
    padding: "8px",
    gap: "10px",
    color: "#B1B7C4",
    cursor: "pointer",
  },
  userIconWrapperStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "52px",
    height: "52px",
    borderRadius: "67px",
    background: "#26305C",
    color: "#FFFFFF",
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  nameStyle: {
    fontSize: "16px",
    color: "#26305C",
  },
  emailStyle: {
    fontSize: "14px",
    color: "#717885",
  },
  rightWrapper: {
    display: "flex",
    justifyContent: "space-around",
    flex: "1",
  },
  outerSubDivWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  topDivWrapper: {
    display: "flex",
    justifyContent: "space-between",
    gap: "6px",
  },
  downDivStyle: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  statusStyle: {
    color: "#9AA1B0",
    fontSize: "15px",
  },
  confirmWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 10px",
    background: "#EAFEEF",
    borderRadius: "6px",
    gap: "4px",
    color: "#14882F",
  },
  statusIconStyle: {
    color: "#9AA1B0",
    fontSize: "15px",
  },
  lableStyle: {
    color: "#9AA1B0",
    fontSize: "15px",
  },
  heighlightLableStyle: {
    color: "#353D4A",
    fontSize: "16px",
  },
  downDivWrapperStyle: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: "4px",
  },
  githubStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
  },
};
