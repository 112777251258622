import React, {Component} from 'react';
import {Button, Form, Select,} from "antd";
const FormItem = Form.Item;

export default class addPeopleTags extends Component {
  render() {
    return (
      <div className="gx-login-container">
          <div className="gx-login-content">
            <Form
              name="basic"
              onFinish={()=>this.props.updateTodo(this.props.currentTodo, "people_tags", this.props.peopleTags)}
              className="gx-signin-form">

              <div>
                <FormItem>
                  <Select onChange={this.props.onSelectPeopleTags} value={this.props.peopleTags} showSearch
                          onSearch={this.props.getUserList}
                          mode="multiple"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                  >
                    {this.props.userList.map((user, index) => (
                      <Select.Option key={user.id} value={user.id}>{user.name}</Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </div>

              <FormItem>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </FormItem>

            </Form>

          </div>
      </div>
    );
  }
}

