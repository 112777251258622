import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Input,
  Radio,
  Select,
  Upload,
  message,
  Divider,
  Popover,
  DatePicker,
  Spin,
} from "antd";
import moment from "moment";
import { withRouter } from "react-router";
import { styles } from "./assetsFormStyle";
import { BsInfoCircle } from "react-icons/bs";
import { FiUploadCloud } from "react-icons/fi";
import axiosJSON from "../../../../../util/Api";
import { PlusOutlined } from "@ant-design/icons";
import { CloseCircleOutlined } from "@ant-design/icons";
import CustomTextArea from "../../../../../components/CustomFields/CustomTextArea";

const getContent = () => {
  return `Please add Brand Name if required Brand not present in list below.`;
};

const AssetsForm = (props) => {
  const inputRef = useRef(null);
  const [name, setName] = useState();
  const [forms, setForms] = useState([]);
  const [lastId, setLastId] = useState(1);
  const [fields, setFields] = useState(null);
  const [loading, setLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [isSubmited, setIsSubmited] = useState(false);
  const [attachmentIds, setAttachmentIds] = useState([]);
  const [employeeId] = useState(
    props.empId !== undefined
      ? props.empId
      : localStorage.getItem("Employee_ID")
  );
  const [apiKey] = useState(
    props.apiKey !== undefined ? props.apiKey : localStorage.getItem("API_KEY")
  );

  useEffect(() => {
    getDeviceType(apiKey);
    getDeviceBrand(apiKey);
    if (props.editForm !== false) {
      setForms([lastId]);
      let newFields = { ...fields };
      let modifyAsset = { ...props.asset };
      modifyAsset.deviceType = modifyAsset.deviceType.name;
      modifyAsset.deviceBrand = {
        value: modifyAsset.deviceBrand.name,
        label: modifyAsset.deviceBrand.display_name,
      };
      newFields[`${lastId}`] = { ...modifyAsset };
      setFields(newFields);
      setAttachmentIds(props.asset.attachmentIds);
      setLastId(lastId + 1);
    }
    // eslint-disable-next-line
  }, []);

  const addNewForm = () => {
    let forms_ = [...forms];
    forms_.push(lastId);
    let newFields = {};
    newFields[`${lastId}`] = {
      deviceType: null,
      serialNumber: null,
      deviceBrand: null,
      assignDate: null,
      workingStatus: null,
      useStatus: null,
      comment: null,
      serialImage: null,
      deviceImage: [],
    };
    setFields({ ...newFields, ...fields });
    setForms(forms_);
    setLastId(lastId + 1);
  };

  const addNewBrand = (e) => {
    e.preventDefault();
    let keyName = name
      .split(" ")
      .map((x) => x.toLowerCase())
      .join("_");
    if (keyName) {
      if (
        brandList.filter(
          (item) =>
            item.name === keyName ||
            item.display_name.toLowerCase() === name.toLowerCase()
        ).length > 0
      ) {
        message.error("Brand already Present");
      } else {
        setBrandList([...brandList, { name: keyName, display_name: name }]);
        setName("");
      }
    } else message.error("Please enter Name");
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const idDataValid = (data) => {
    if (
      data.assignDate === null ||
      data.deviceBrand === null ||
      data.deviceImage.length < 1 ||
      data.deviceType === null ||
      (data.deviceType === "laptop" &&
        (data.serialImage === null || data.serialNumber === null)) ||
      data.useStatus === null ||
      data.workingStatus === null
    ) {
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    const formData = new FormData();
    setLoading(true);
    let data = [];
    setIsSubmited(true);
    for (let key in fields) {
      if (idDataValid(fields[key])) {
        data.push({
          asset_id: fields[key]["assetId"],
          deviceType: fields[key]["deviceType"],
          modelNo:
            fields[key]["deviceType"] === "laptop"
              ? fields[key]["serialNumber"]
              : null,
          model_image:
            fields[key]["deviceType"] === "laptop"
              ? `model_image_${key}`
              : null,
          inUse: fields[key]["useStatus"],
          isWorking: fields[key]["workingStatus"],
          comment: fields[key]["comment"],
          assigned_date: moment(fields[key]["assignDate"]).format("YYYY-MM-DD"),
          brand: fields[key]["deviceBrand"],
          image: `device_${key}`,
          attachment_ids: attachmentIds,
        });
        if (
          fields[key]["serialImage"] &&
          fields[key]["deviceType"] === "laptop"
        ) {
          const imageKey = "model_image_" + key;
          if (fields[key]["serialImage"].id === undefined)
            formData.append(imageKey, fields[key]["serialImage"]);
        }

        if (
          fields[key]["deviceImage"] &&
          fields[key]["deviceImage"].length > 0
        ) {
          for (
            let index = 0;
            index < fields[key]["deviceImage"].length;
            index++
          ) {
            let imageKey = "device_" + key;
            if (
              fields[key]["deviceImage"][index]["originFileObj"] !== undefined
            ) {
              formData.append(
                imageKey,
                fields[key]["deviceImage"][index]["originFileObj"]
              );
            }
          }
        }
      } else {
        setLoading(false);
        return;
      }
    }

    formData.append("api_key", apiKey);
    formData.append("employee_id", employeeId);
    formData.append("data", JSON.stringify(data));

    axiosJSON
      .post("api/asset_form/add_info/", formData)
      .then(({ data, status }) => {
        message.success(data.message);
        setLoading(false);
        // localStorage.removeItem("API_KEY");
        // localStorage.removeItem("Employee_ID");
        props.reset();
      })
      .catch(function (error) {
        message.error(error.message);
        setLoading(false);
      });
  };

  const getDeviceType = (key) => {
    setLoading(true);
    axiosJSON
      .get(`/api/asset_form/type/?api_key=${key}`)
      .then(({ data }) => {
        setDeviceList(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.message);
      });
  };

  const getDeviceBrand = (key) => {
    setLoading(true);
    axiosJSON
      .get(`api/asset_form/brand/?api_key=${key}`)
      .then(({ data }) => {
        setBrandList(data.data);
        setLoading(false);
      })
      .catch((error) => {
        message.error(error.message);
        setLoading(false);
      });
  };

  const globalOnChnage = (id, name, value) => {
    let newFields = { ...fields };
    if (name === "deviceImage") newFields[id][name].push(value);
    else newFields[id][name] = value;
    setFields(newFields);
  };

  const handleFileChange = (f) => {
    console.log(f);
  };

  const deleteForm = (id) => {
    const newForms = forms.filter((formId) => id !== formId);
    setForms(newForms);
    let newFields = { ...fields };
    delete newFields[id];
    if (newForms.length < 1) {
      setLastId(newForms.length + 1);
    }
    setFields(newFields);
  };

  return (
    <Spin spinning={loading}>
      <div>
        <div>
          <>
            {forms.length > 0 &&
              forms.map((id) => (
                <div key={id}>
                  <div className="pb-3 px-3">
                    {props.editForm ? null : (
                      <div
                        className="deviceinfo d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="label-device mb-2">
                          Device Information {id}
                        </div>
                        <Button
                          className="width-custom"
                          onClick={() => {
                            deleteForm(id);
                            setIsSubmited(false);
                            message.success(
                              `Asset ${id} form Removed successfully`
                            );
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    )}
                    <div>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div style={styles.startStyle}>*</div>
                        <div
                          className="employee_id pb-3 mt-2"
                          style={styles.lableStyle}
                        >
                          Device Type
                        </div>
                      </div>
                      <div>
                        <Select
                          placeholder="Device Type"
                          className="gx-mb-2 gx-select-sm"
                          value={fields[id]["deviceType"]}
                          onChange={(value) => {
                            globalOnChnage(id, "deviceType", value);
                          }}
                          style={styles.fullWidth}
                        >
                          {deviceList.map((device, i) => (
                            <Select.Option value={device.name} key={i}>
                              {device.display_name}
                            </Select.Option>
                          ))}
                        </Select>
                        {isSubmited && fields[id]["deviceType"] === null ? (
                          <div style={styles.worningStyle}>
                            Please select Device Type
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {fields[id]["deviceType"] &&
                    fields[id]["deviceType"] === "laptop" ? (
                      <div>
                        <div style={{ display: "flex" }}>
                          <div style={styles.startStyle}>*</div>
                          <div
                            className="employee_id pb-3 mt-2"
                            style={styles.lableStyle}
                          >
                            Serial No. of the laptop
                          </div>
                        </div>
                        <div>
                          <Input
                            className="gx-mb-2 text_control"
                            placeholder="Device Serial Number"
                            value={fields[id]["serialNumber"]}
                            onChange={(e) =>
                              globalOnChnage(id, "serialNumber", e.target.value)
                            }
                          />
                          {isSubmited &&
                          (fields[id]["serialNumber"] === null ||
                            fields[id]["serialNumber"] === "") ? (
                            <div style={styles.worningStyle}>
                              Please enter the Laptop Serial Number
                            </div>
                          ) : null}
                        </div>
                        <div style={{ display: "flex" }}>
                          <div style={styles.startStyle}>*</div>
                          <div
                            className="employee_id pb-3 mt-2"
                            style={styles.lableStyle}
                          >
                            Serial No. Image
                          </div>
                        </div>
                        <div>
                          <div>
                            <Upload.Dragger
                              className="gx-mb-2 upload_image"
                              onChange={(f) => handleFileChange(f)}
                              multiple={false}
                              customRequest={(f) =>
                                globalOnChnage(id, "serialImage", f.file)
                              }
                              showUploadList={false}
                              accept=".png, .jpeg, .jpg, .gif, .pdf, .heic"
                            >
                              <div className="">
                                <div className="">
                                  <FiUploadCloud
                                    style={{
                                      color: "#F86011",
                                      height: "20px",
                                    }}
                                  />
                                </div>
                                <div className="" style={{ color: "#F86011" }}>
                                  Click or drag file to this area to upload
                                </div>
                              </div>
                            </Upload.Dragger>
                          </div>
                          <div>
                            {fields[id]["serialImage"] ? (
                              <div
                                className="mb-2 ml-0"
                                style={styles.serialImageWrapper}
                              >
                                <div style={{ color: "red", margin: "2px" }}>
                                  {fields[id]["serialImage"].name
                                    ? fields[id]["serialImage"].name
                                    : fields[id]["serialImage"].file_name}
                                </div>
                                <div style={{ color: "red", margin: "2px" }}>
                                  <CloseCircleOutlined
                                    onClick={() => {
                                      if (props.editForm) {
                                        const slId =
                                          fields[id]["serialImage"].id;
                                        setAttachmentIds(
                                          attachmentIds.filter(
                                            (id) => id !== slId
                                          )
                                        );
                                      }
                                      let newFields = { ...fields };
                                      fields[id]["serialImage"] = null;
                                      setFields(newFields);
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <>
                                {isSubmited &&
                                fields[id]["serialImage"] === null ? (
                                  <div style={styles.worningStyle}>
                                    Please upload Serial Number Picture
                                  </div>
                                ) : null}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div style={{ display: "flex" }}>
                      <div style={styles.startStyle}>*</div>
                      <div
                        className="employee_id pb-3 mt-2"
                        style={styles.lableStyle}
                      >
                        Device Brand
                        <Popover content={getContent()}>
                          <BsInfoCircle className="ml-1" />
                        </Popover>
                      </div>
                    </div>

                    <div style={styles.fullWidth} className="pb-3 mt-2">
                      <Select
                        placeholder="Device Brand"
                        className="gx-mb-2 gx-select-sm"
                        labelInValue
                        showSearch
                        value={fields[id]["deviceBrand"]}
                        style={styles.fullWidth}
                        onChange={(value) =>
                          globalOnChnage(id, "deviceBrand", value)
                        }
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider className="my-2" />
                            <div className="pb-3 px-2 w-100">
                              <Input
                                className="mr-2"
                                style={styles.fullWidth}
                                placeholder="Please enter item"
                                ref={inputRef}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                              <Button
                                className="mb-0 pb-3 mt-2"
                                type="default"
                                icon={<PlusOutlined />}
                                onClick={addNewBrand}
                              >
                                Add
                              </Button>
                            </div>
                          </>
                        )}
                        options={brandList.map((item) => ({
                          label: item.display_name,
                          value: item.name,
                        }))}
                      />
                      {isSubmited && fields[id]["deviceBrand"] === null ? (
                        <div style={styles.worningStyle}>
                          Please select Device Brand
                        </div>
                      ) : null}
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={styles.startStyle}>*</div>
                      <div
                        className="employee_id pb-3 mt-2"
                        style={styles.lableStyle}
                      >
                        Assign Date
                      </div>
                    </div>

                    <div>
                      <DatePicker
                        className="mr-2 paddingadjust"
                        placeholder="Device Assign Date"
                        format={"MM-DD-YYYY"}
                        value={fields[id]["assignDate"]}
                        disabledDate={(currentDate) => currentDate > Date.now()}
                        onChange={(value) =>
                          globalOnChnage(id, "assignDate", value)
                        }
                      />
                      {isSubmited && fields[id]["assignDate"] === null ? (
                        <div style={styles.worningStyle}>
                          Please enter Device assign Date!
                        </div>
                      ) : null}
                    </div>
                    <div
                      className="employee_id fullwidth pb-3 mt-3"
                      style={styles.lableStyle}
                    >
                      Add a Comment
                    </div>
                    <div style={styles.fullWidth}>
                      <CustomTextArea
                        className="mr-2 pb-3 mt-2"
                        placeholder={"Enter a comment"}
                        style={styles.fullWidth}
                        value={fields[id]["comment"]}
                        onChange={(e) =>
                          globalOnChnage(id, "comment", e.target.value)
                        }
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={styles.startStyle}>*</div>
                      <div
                        className="employee_id pb-3 mt-2"
                        style={styles.lableStyle}
                      >
                        Working Status
                      </div>
                    </div>
                    <div>
                      <Radio.Group
                        className="mb-3"
                        value={fields[id]["workingStatus"]}
                        onChange={(e) => {
                          globalOnChnage(id, "workingStatus", e.target.value);
                          globalOnChnage(
                            id,
                            "useStatus",
                            e.target.value === false ? false : null
                          );
                        }}
                      >
                        <Radio value={true}>Working</Radio>
                        <Radio value={false}>Not Working</Radio>
                      </Radio.Group>
                      {isSubmited && fields[id]["workingStatus"] === null ? (
                        <div style={styles.worningStyle}>
                          Please select Working Status
                        </div>
                      ) : null}
                    </div>

                    <div style={{ display: "flex" }}>
                      <div style={styles.startStyle}>*</div>
                      <div
                        className="employee_id pb-3 mt-2"
                        style={styles.lableStyle}
                      >
                        Usage Status
                      </div>
                    </div>
                    <div>
                      <Radio.Group
                        className="mb-3"
                        value={fields[id]["useStatus"]}
                        onChange={(e) =>
                          globalOnChnage(id, "useStatus", e.target.value)
                        }
                      >
                        <Radio value={true}>Currently In use</Radio>
                        <Radio value={false}>Not Using</Radio>
                      </Radio.Group>
                      {isSubmited && fields[id]["useStatus"] === null ? (
                        <div style={styles.worningStyle}>
                          Please select Device Usage Status
                        </div>
                      ) : null}
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={styles.startStyle}>*</div>
                      <div
                        className="employee_id pb-3 mt-2"
                        style={styles.lableStyle}
                      >
                        Device Picture
                      </div>
                    </div>
                    <div>
                      <Upload.Dragger
                        className="gx-mb-2 upload_image"
                        onChange={(f) =>
                          globalOnChnage(id, "deviceImage", f.file)
                        }
                        customRequest={(f) => handleFileChange(f)}
                        showUploadList={false}
                        multiple={true}
                        accept=".png, .jpeg, .jpg, .gif, .pdf, .heic,"
                      >
                        <div className="">
                          <div className="">
                            <FiUploadCloud
                              style={{
                                color: "#F86011",
                                height: "20px",
                              }}
                            />
                          </div>
                          <div className="" style={{ color: "#F86011" }}>
                            Click or drag file to this area to upload
                          </div>
                        </div>
                      </Upload.Dragger>
                      <div>
                        {fields[id]["deviceImage"] &&
                        fields[id]["deviceImage"].length > 0 ? (
                          <>
                            {fields[id]["deviceImage"].map((file) => (
                              <div
                                key={file.uid}
                                className="mb-2 ml-0"
                                style={styles.deviceImageStyle}
                              >
                                <div style={{ color: "red", margin: "2px" }}>
                                  {file.name ? file.name : file.file_name}
                                </div>
                                <div style={{ color: "red", margin: "2px" }}>
                                  <CloseCircleOutlined
                                    onClick={() => {
                                      let newFields = { ...fields };
                                      if (props.editForm) {
                                        const fileId = file.id;
                                        setAttachmentIds(
                                          attachmentIds.filter(
                                            (id) => id !== fileId
                                          )
                                        );
                                      }
                                      let fileList = [
                                        ...fields[id]["deviceImage"],
                                      ];
                                      if (fileList.length === 1) {
                                        fields[id]["deviceImage"] = [];
                                      } else {
                                        let newFileList = fileList.filter(
                                          (f) => {
                                            if (f.id !== undefined)
                                              return f.id !== file.id;
                                            else return f.uid !== file.uid;
                                          }
                                        );
                                        fields[id]["deviceImage"] = [
                                          ...newFileList,
                                        ];
                                      }
                                      setFields(newFields);
                                    }}
                                  />
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <>
                            {isSubmited ? (
                              <div style={styles.worningStyle}>
                                Please upload Device Picture
                              </div>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {props.editForm ? null : (
              <div className="pb-3 px-3" style={styles.fullWidth}>
                <Button
                  className="addDevice"
                  onClick={() => {
                    setIsSubmited(false);
                    addNewForm();
                  }}
                  style={styles.fullWidth}
                  icon={<PlusOutlined />}
                >
                  Add Device
                </Button>
              </div>
            )}
          </>
          <div className="pb-3 px-3 mb-4 ml-3" style={styles.fullWidth}>
            <Button
              className="submit_asset submit-mar mb-4"
              type="primary"
              htmlType="submit"
              style={styles.fullWidth}
              disabled={forms.length < 1}
              onClick={() => onSubmit()}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default withRouter(AssetsForm);
