import React, {useEffect, useState} from "react";
import {message, Select} from "antd";
import axiosJSON from "../util/SetHeaderAPI";
import {useDispatch} from "react-redux";
import {impersonatedMeDetails} from "../appRedux/actions";
import "nprogress/nprogress.css";
import nprogress from "nprogress";
import {CustomModal as Modal} from "../util/CustomModal"

const Impersonation = (props) => {
  const [userList, setUserList] = useState([]);
  const [selectedUser, setUser] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    getUserList()
  }, [])

  const getUserList = () => {
    axiosJSON.get('/api/user/')
      .then(({data}) => {
        setUserList(data.data)
      })
      .catch(error =>
        message.error(error.message)
      )
  }
  const handleChange = (value) => {
    setUser(value)
  }
  const onSubmit = () => {
    nprogress.start()
    const body={
      'employee_id':selectedUser
    }
    axiosJSON.post('/api/impersonate/',body)
      .then(({data}) => {
        localStorage.setItem("current_token",data.data.token);
        axiosJSON.defaults.headers['authorization'] = "Token " + data.data.token;
        dispatch(impersonatedMeDetails(data.data.token));
        props.closeModal()
      })
      .catch(error =>
        message.error(error.message)
      )
      nprogress.done()
  }

  return (
    <Modal
      title="Impersonate User"
      onCancel={() => props.openImpersonate(false)}
      visible={props.impersonateButton}
      onOk={onSubmit}
    >
      <Select
        showSearch
        value={selectedUser}
        onChange={handleChange}
        onSearch={handleChange}
        style={{width: '200px'}}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {
          userList.length > 0 && userList.map((user, i) =>
            <Select.Option key={user.id} value={user.employee_id}>{user.name}</Select.Option>)
        }
      </Select>
    </Modal>
  );
};

export default Impersonation;
