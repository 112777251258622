import React from "react";
import TrainingItem from "./TrainingItem";
import {Spin} from "antd";

const TrainingList = ({
  trainingList,
  selectTraining,
  getCall,
  getTrainingData,
  accessData,
  loadingTextCSS
}) => {
  return (
    <div className="col-12 py-5 px-0">
      <div className="row r-cover">
        {trainingList.length > 0 ? (
          <span style={loadingTextCSS}>
                    <Spin />
                    Loading...
                  </span>
        ) : (
          <div style={{textAlign: "center", width: '100%'}} >
            <h4>No records found</h4>
          </div>
        )}
        {/* <div className="gx-module-list-item" style={{backgroundColor:'#A8D0E6',paddingLeft:'30px'}}>
      <div className="gx-media-body gx-task-item-content">
        <div style={{ paddingTop:'12px' }} className="gx-task-item-content-left">
          <span className={`gx-fs-md gx-text-black gx-order-sm-2`}>Id</span>
        </div>

          <div
            style={{ paddingTop: "12px" }}
            className="gx-task-item-content-left"
          >
            <span className={`gx-fs-md gx-text-black gx-order-sm-6`}>
              Title
            </span>
          </div>
          <div
            style={{ paddingTop: "12px" }}
            className="gx-task-item-content-left"
          >
            <span className={`gx-fs-md gx-text-black gx-order-sm-6`}>
              Start Date
            </span>
          </div>
          <div
            style={{ paddingTop: "12px" }}
            className="gx-task-item-content-left"
          >
            <span className={`gx-fs-md gx-text-black gx-order-sm-6`}>
              End Date
            </span>
          </div>
          <div
            style={{ paddingTop: "12px" }}
            className="gx-task-item-content-right"
          >
            <span className="gx-fs-md gx-text-black gx-ml-3 gx-order-sm-4">
              Status
            </span>
          </div>
          <div
            style={{ paddingTop: "12px" }}
            className="gx-task-item-content-right"
          >
            <span className="gx-fs-md gx-text-black gx-ml-3 gx-order-sm-4">
              Actions
            </span>
          </div>
        </div>
      </div>
    </div> */}
        {trainingList.map((training, index) => (
          <TrainingItem
            key={index}
            index={index}
            training={training}
            selectTraining={selectTraining}
            getTrainingData={getTrainingData}
            getCall={getCall}
            accessData={accessData}
          />
        ))}
      </div>
    </div>
  );
};

export default TrainingList;
