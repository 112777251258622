import React from "react";
import CandidateItem from "./CandidateItem/CandidateItem";
import { Checkbox } from "antd";

const CandidateList = ({
  candidateList,
  onSelectAllCandidate,
  onCandidateSelected,
  selectCandidate,
  selectedCandidates,
  user,
  onFollow,
  onNotify,
  onEditNotify,
}) => {
  return (
    <div>
      <div
        className="gx-module-list-item"
        style={{ backgroundColor: "#A8D0E6", paddingLeft: "18px" }}
      >
        <div className="gx-media-body gx-task-item-content">
          <div className="gx-module-list-icon">
            <Checkbox
              className="gx-icon-btn"
              color="primary"
              indeterminate={
                selectedCandidates > 0 &&
                selectedCandidates < candidateList.length
              }
              checked={selectedCandidates > 0}
              onChange={(data) => onSelectAllCandidate(data)}
            />
          </div>
          <div
            style={{ paddingTop: "12px" }}
            className="gx-task-item-content-left"
          >
            <span className={`gx-fs-md gx-text-black gx-order-sm-4`}>Name</span>
          </div>
          <div
            style={{ paddingTop: "12px" }}
            className="gx-task-item-content-left"
          >
            <span className={`gx-fs-md gx-text-black gx-order-sm-4`}>
              Email
            </span>
          </div>
          <div
            style={{ paddingTop: "12px" }}
            className="gx-task-item-content-right"
          >
            <span className="gx-fs-md gx-text-black gx-ml-3 gx-order-sm-4">
              Source
            </span>
          </div>
          <div
            style={{ paddingTop: "12px" }}
            className="gx-task-item-content-right"
          >
            <span className="gx-fs-md gx-text-black gx-ml-3 gx-order-sm-4">
              Stage
            </span>
          </div>
          <div
            style={{ paddingTop: "12px" }}
            className="gx-task-item-content-right"
          >
            <span className="gx-fs-md gx-text-black gx-ml-3 gx-order-sm-4">
              Following
            </span>
          </div>
          <div
            style={{ paddingTop: "12px" }}
            className="gx-task-item-content-right"
          >
            <span className="gx-fs-md gx-text-black gx-ml-3 gx-order-sm-4">
              Reminder
            </span>
          </div>
        </div>
      </div>
      {candidateList.map((candidate, index) => {
        let follow = false;
        if (candidate.followers && candidate.followers.length > 0) {
          candidate.followers.map((item) => {
            if (user.employee_id === item.employee_id) {
              follow = true;
            }
            return null;
          });
        }
        return (
          <CandidateItem
            key={index}
            index={index}
            selectedCandidates={selectedCandidates}
            candidate={candidate}
            selectCandidate={selectCandidate}
            onCandidateSelected={onCandidateSelected}
            follow={follow}
            onFollow={onFollow}
            onNotify={onNotify}
            onEditNotify={onEditNotify}
            followUpDate={candidate.follow_up_date}
          />
        );
      })}
    </div>
  );
};

export default CandidateList;
