import firebase from "firebase";

// Initialize Firebase
export const config = {
  apiKey: "AIzaSyCRvwNHy6qC2yx7BFudDoTPr6imaE4BZv0",
  authDomain: "my-project-1493153017212.firebaseapp.com",
  databaseURL: "https://my-project-1493153017212.firebaseio.com",
  projectId: "my-project-1493153017212",
  storageBucket: "my-project-1493153017212.appspot.com",
  messagingSenderId: "395632051652",
  appId: "1:395632051652:web:769d063d3c080f490cd091"
};

firebase.initializeApp(config);

export default firebase;
