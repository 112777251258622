import React, {useEffect, useState} from "react";
import {Button, Form, Select, message} from "antd";
import axiosJSON from "../../util/SetHeaderAPI";
import AddNewSource from "./addNewSource";
import {CustomModal as Modal} from "../../util/CustomModal"

const FormItem = Form.Item;

const AddSource = (props) => {
  const [source, setSource] = useState(null);
  const [sourceList, setSourceList] = useState([])
  const [newSource, addNewSource] = useState(false)

  useEffect(() => {
    let mounted=true
    if(mounted) {
      getSourceList()
    }
    return function cleanup() {
      mounted = false
    }
  },[])

  const getSourceList =()=>{
    axiosJSON.get('api/choice/?type=source')
      .then(({data, status}) => {
        if (status === 200) {
          setSourceList(data.data)
        }
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    const body = {
      'source': source,
      'candidates':props.candidateList

    }
    axiosJSON.put('api/candidate/source/',body)
      .then(({data, status}) => {
        if (status === 202) {
          message.success(data.message);
          props.closeModal()
        }
      }).catch(function (error) {
      message.error(error.message)
    })

  };
  const handleAddSource = () => {

    addNewSource(true)
  };
  const handleRequestClose = () => {
    getSourceList()
    addNewSource(false)
  };

  return (
      <div className="gx-login-content">

        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0">

          <FormItem>
            <Select
              showSearch
              style={{width: 259}}
              placeholder="Select a source"
              optionFilterProp="children"
              onChange={(val)=>setSource(val)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {sourceList.length !== 0 &&
              sourceList.map((source, i) =>
                <Select.Option key={i}
                  value={source.id}>{source.display_name}</Select.Option>
              )}
            </Select>
            <Button className="add-btn" type="dashed" onClick={handleAddSource}>
              {/*<IntlMessages id="app.userAuth.reset"/>*/}
              Add +
            </Button>
          </FormItem>


          <FormItem>
            <Button type="primary" htmlType="submit">
              {/*<IntlMessages id="app.userAuth.reset"/>*/}
              Submit
            </Button>
          </FormItem>
        </Form>
        {newSource && <Modal
          title="Add Source"
          visible={newSource}
          style={{width:'40%',height:'20%'}}
          footer={null}
          onCancel={handleRequestClose}>
          <AddNewSource closeModal={handleRequestClose}/>
        </Modal>}
      </div>
  );
};

export default AddSource;
