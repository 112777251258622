import React from 'react';
import { Upload, Spin, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import axiosJSON from "../../util/SetHeaderAPI";

const { Dragger } = Upload;
export default class AddResume extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resumeViewer: true,
      file: ""
    }
  }


  handleChange = (info) => {
    this.props.changeResumeLoader(true)
    const formData = new FormData();
    this.setState({ file: info.file })
    formData.append('file', info.file);
    let id = this.props.id;
    axiosJSON.put('api/candidate/' + id + '/resume/', formData)
      .then(({ data, status }) => {
        if (status === 201) {
          this.props.changeResumeLoader(false);
          this.props.closeModal();
        }
      }).catch(function (error) {
        message.error(error.message)
      })
  }

  render() {
    return (<div>
      <Spin tip="Please wait...Getting information from resume.." spinning={this.props.resumeLoader}>
        <Dragger className="new_popop" customRequest={this.handleChange} multiple={false} name="file"
          showUploadList={false}
          accept=".docx,.pdf">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Support for PDF, DOCX or OTD.</p>
        </Dragger>
      </Spin>
    </div>);
  }
}
