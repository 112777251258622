import React, { Component } from "react";
import { Col, Row } from "antd";
import About from "../../../components/Candidate/profile/About";
import Contact from "../../../components/Candidate/profile/Contact";
import ResumeSummary from "../../../components/Candidate/profile/ResumeSummary";

class CandidateSummaryView extends Component {
  constructor(props) {
    super(props);
    let splitArray = window.location.href.split("/");
    this.state = {
      candidateId: splitArray[splitArray.length - 2],
    };
  }

  render() {
    return (
      <div className="gx-profile-content testing-new">
        <Row>
          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <About data={this.props.data} id={this.state.candidateId} getDetails={this.props.getDetails} />
            <ResumeSummary id={this.state.candidateId} />
          </Col>

          <Col xl={8} lg={10} md={10} sm={24} xs={24}>
            <Contact id={this.state.candidateId} />
            <Row>
              <Col xl={24} lg={24} md={24} sm={12} xs={24}></Col>
              <Col xl={24} lg={24} md={24} sm={12} xs={24}></Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CandidateSummaryView;
