import React from "react";
import ReactDOM from "react-dom";

import NextApp from './NextApp';
// Add this import:
import { unregister} from './registerServiceWorker.js';


// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    // Wrap App inside AppContainer
      <Component/>,
    document.getElementById('root')
  );
};

// Render once
render(NextApp);
unregister();


