import React from "react";
import moment from 'moment';
import {ClockCircleOutlined} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';


const ActivityItem = (props) => {
  const markAsRead=()=>{
    props.history.push("/activity/")
  }
  return (
    <li className="gx-media" onClick={markAsRead}>
      <div className="gx-media-body gx-align-self-center">
        <p className="gx-fs-sm gx-mb-0">
          <ClockCircleOutlined
          style={{color: props.activity_type === "created" ? '#1A90FF' : props.activity_type === "updated" ? "green" : "red"}}/>
          <span style={{marginLeft:'5px'}}>{props.desc}</span>
          <span style={{float:'right'}} className="gx-meta-date"><small>{moment(props.created).format(" MMMM DD YYYY , hh:mm A")}</small></span>

        </p>
      </div>
    </li>
  );
};

export default withRouter(ActivityItem);
