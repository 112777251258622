import { BehaviorSubject } from 'rxjs';
import {message} from 'antd';
import axiosJSON from "../../../../util/SetHeaderAPI";

export const search$ = new BehaviorSubject({query:"",filter_for:""});
export const recruiterList$=new BehaviorSubject([]);
export const visaList$=new BehaviorSubject([]);
export const sourceList$=new BehaviorSubject([]);
export const stageList$=new BehaviorSubject([]);
export const positionList$=new BehaviorSubject([]);

export function search(data) {
    search$.next(data);
} 


export const getRecruiterList = () => {
    axiosJSON
      .get("/api/user/?role=screener")
      .then(({ data }) => {
            recruiterList$.next(data.data);
      })
      .catch((error) => message.error(error));
  };

export const getList = (type) => {
    axiosJSON
      .get("/api/choice/?type="+type)
      .then(({ data }) => {
            if(type === 'visa'){
                  visaList$.next(data.data);  
            }
            else if(type === 'source'){
                  sourceList$.next(data.data);
            }
            else if(type === 'stage'){
                  stageList$.next(data.data);
            }
      })
      .catch((error) => message.error(error));
  };
  
export const getPositionList = () => {
      axiosJSON
        .get("/api/position/dropdown/")
        .then(({ data }) => {
            positionList$.next(data.data)
        })
        .catch((err) => message.error(err));
  };