import React, { Component } from "react";
import {
  Button,
  DatePicker,
  message,
  Radio,
  Select,
  Spin,
  Tag,
  Typography,
} from "antd";
import moment from "moment-timezone";
import CalendarComponent from "./calendarComponent";
import axiosJSON from "../../util/SetHeaderAPI";
import { connect } from "react-redux";
import { IoMdClose } from "react-icons/io";
import "nprogress/nprogress.css";
import nprogress from "nprogress";

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      prevUsers: [],
      schedules: {},
      currentDate: moment(new Date().toDateString()),
      endDate: null,
      view: "day",
      calendarObj: [],
      userList: [],
      params: null,
      isSingleCalendar: false,
      allSchadules: {},
      emailColorMapper: {},
      leading: false,
    };
  }

  componentDidMount() {
    nprogress.start();
    this.getScreenerList();
    this.getDefaultCalendarDetails();
    nprogress.done();
  }

  setLoading = (flag) => {
    this.setState({
      leading: flag,
    });
  };

  getDefaultCalendarDetails = () => {
    this.setLoading(true);
    axiosJSON
      .get("/api/calendar_info/get_default/")
      .then((res) => {
        // Need create calendarObj , schedules
        let users = [];
        let schedules = [];
        let calendarObj = [];
        let emailMapper = { ...this.state.emailColorMapper };
        for (let email in res.data.data) {
          let arr = [];
          users.push(email);
          if (!emailMapper[email]) {
            emailMapper[email] = this.getRandomColor();
          }
          res.data.data[email].busy.map((item) => {
            let ele = {
              start: new Date(item.start),
              end: new Date(item.end),
              title: "Busy",
              color: emailMapper[email],
              email: email,
            };
            arr.push(ele);
            return arr;
          });
          schedules[email] = arr;

          this.state.userList.map((user) => {
            if (email === user.email) {
              let obj = {
                email: email,
                calendar_open: true,
                name: user.name,
                color: emailMapper[email],
                loading: true,
                checked: true,
              };
              calendarObj.push(obj);
            }
            return null;
          });
          // index += 1;
        }

        let params = {
          start: moment(this.state.currentDate).format("YYYY-MM-DD"),
          end:
            this.state.endDate === null
              ? null
              : moment(this.state.endDate).format("YYYY-MM-DD"),
          emails: users,
        };
        this.setLoading(false);
        this.setState({
          users: users,
          calendarObj: calendarObj,
          schedules: schedules,
          params: params,
          emailColorMapper: emailMapper,
        });
        this.setAllSchedule(schedules);
      })
      .catch((error) => {
        this.setLoading(false);
        message.error(error);
      });
  };

  markDefault = () => {
    this.setLoading(true);
    const body = {
      emails: this.state.users,
    };
    axiosJSON
      .post("/api/calendar_info/", body)
      .then((res) => {
        message.success(res.data.message);
        this.setLoading(false);
      })
      .catch((error) => {
        message.error(error);
        this.setLoading(false);
      });
  };

  getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  makeSingleView = () => {
    this.setState({
      isSingleCalendar: !this.state.isSingleCalendar,
    });
  };

  setAllSchedule = (data) => {
    let shaduleData = [];
    for (let [key, value] of Object.entries(data)) {
      console.log(key, value);
      let newData = shaduleData;
      shaduleData = [...value];
      shaduleData = [...shaduleData, ...newData];
    }
    this.setState({ allSchadules: shaduleData });
  };

  getSchedule = (params) => {
    this.setLoading(true);
    axiosJSON
      .get("/api/user/schedule/?data=" + JSON.stringify(params))
      .then(({ data, status }) => {
        let result = data.data;
        let calendarObj = this.state.calendarObj;
        let schedules = {};
        let arr = [];
        let ele = {};
        let emailMapper = { ...this.state.emailColorMapper };
        params.emails.map((email) => {
          arr = [];
          if (result.hasOwnProperty(email)) {
            if (result[email].hasOwnProperty("busy")) {
              result[email]["busy"].map((item) => {
                ele = {
                  start: new Date(item.start),
                  end: new Date(item.end),
                  title: "Busy",
                  color: emailMapper[email],
                  email: email,
                };
                arr.push(ele);
                return arr;
              });
            }
            schedules[email] = arr;
            calendarObj.map((user) => {
              if (user.email === email) user.loading = true;
              return user;
            });
          }
          return schedules;
        });
        if (data.error && data.error.length > 0) {
          message.error("Email id not available for " + data.error.toString());
        }
        this.setState({
          schedules: schedules,
          calendarObj: calendarObj,
        });
        this.setAllSchedule(schedules);
        this.setLoading(false);
      })
      .catch((error) => {
        this.setLoading(false);
        message.error(error.message);
      });
  };

  onDateChange = (date, dateString) => {
    let params = this.state.params;
    params = {
      ...params,
      start: moment(date).format("YYYY-MM-DD"),
    };
    this.setState(
      {
        currentDate: date,
        params: params,
      },
      () => this.getSchedule(params)
    );
  };
  onCheckedChange = (checked, email, reset) => {
    if (reset) {
      this.setState({
        calendarObj: [],
        users: [],
        allSchadules: {},
        params: null,
      });
      return;
    }
    let userList = this.state.userList;
    let calendarObj = this.state.calendarObj;
    let obj;

    userList.map((user) => {
      if (email === user.email) {
        user.checked = checked;
        if (checked) {
          obj = {
            email: email,
            calendar_open: true,
            name: user.name,
            color: user.color,
            loading: false,
          };
          calendarObj.push(obj);
        } else if (!checked && this.props.authUser.email !== user.email) {
          calendarObj = calendarObj.filter((item) => item.email !== email);
        }
      }
      return calendarObj;
    });

    let arr = [];
    calendarObj.map((obj) => {
      if (obj.calendar_open) {
        arr.push(obj.email);
      }
      return arr;
    });
    let params = {
      start: moment(this.state.currentDate).format("YYYY-MM-DD"),
      end:
        this.state.endDate === null
          ? null
          : moment(this.state.endDate).format("YYYY-MM-DD"),
      emails: arr,
    };

    this.setState(
      {
        userList: userList,
        calendarObj: calendarObj,
        params: params,
      },
      () => {
        this.getSchedule(params);
      }
    );
  };

  onClose = (email) => {
    let calendarObj = this.state.calendarObj;
    // need to remove user from selected user
    let users = [];
    calendarObj = calendarObj.filter((obj) => obj.email !== email);
    this.state.users.map((user) => {
      if (user !== email) {
        users.push(user);
      }
      return null;
    });

    this.setState({
      calendarObj: calendarObj,
      users: users,
    });
  };

  getScreenerList = () => {
    axiosJSON
      .get("/api/user/")
      .then(({ data, status }) => {
        // let color = this.state.colorArray;
        let obj = {};
        let userList = [];
        let calenderList = [];
        let colorMapper = { ...this.state.emailColorMapper };
        data.data.map((user, i) => {
          if (!colorMapper[user.email]) {
            colorMapper[user.email] = this.getRandomColor();
          }
          obj = {
            id: user.id,
            email: user.email,
            name: user.name,
            checked: false,
            color: colorMapper[user.email],
          };
          userList.push(obj);
          return userList;
        });
        this.setState({
          userList: userList,
          calendarObj: calenderList,
          emailColorMapper: colorMapper,
        });
      })
      .catch((error) => message.error(error));
  };

  changeView = (v) => {
    let view = "";
    let params = this.state.params;
    let currentDate = "";
    let endDate = "";
    switch (v) {
      case "day":
        currentDate = moment(new Date());
        endDate = null;
        view = "day";
        break;
      // case "agenda":
      //   currentDate = moment(new Date());
      //   endDate = null;
      //   view = "agenda";
      //   break;
      case "week":
        currentDate = moment().startOf("isoWeek");
        endDate = moment().endOf("isoWeek");
        view = "week";
        break;
      default:
        currentDate = moment(new Date());
        endDate = null;
        view = "day";
    }
    params = {
      start: moment(currentDate).format("YYYY-MM-DD"),
      end: endDate === null ? null : moment(endDate).format("YYYY-MM-DD"),
      emails: this.state.params.emails,
    };
    this.setState(
      {
        view: view,
        currentDate: currentDate,
        endDate: endDate,
        params: params,
      },
      () => this.getSchedule(params)
    );
  };

  render() {
    const { currentDate, userList, calendarObj, schedules } = this.state;
    return (
      <div className="calendar-section">
        <Spin spinning={this.state.leading}>
          <div className="ant-row pr-0 mr-0">
            <div className="col-3 overflow-fixer-two" id="skill">
              <div className="people-style">Select Employees</div>
              <div className="ant-row m-0 mb-3">
                <Select
                  mode="multiple"
                  showSearch
                  allowClear={true}
                  notFoundContent={null}
                  value={this.state.users}
                  placeholder="Select User"
                  style={{ width: "100%" }}
                  onChange={(user) => {
                    let element = user.filter(
                      (value) => !this.state.users.includes(value)
                    );
                    if (element.length <= 0) {
                      element = this.state.users.filter(
                        (value) => !user.includes(value)
                      );
                    }
                    this.setState({ users: user, prevUsers: this.state.user });
                    this.onCheckedChange(
                      user.length > this.state.users.length ? true : false,
                      element[0],
                      user.length <= 0
                    );
                  }}
                  optionFilterProp="children"
                >
                  {userList.map((user) => (
                    <Select.Option key={user.id} value={user.email}>
                      {user.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="people-style">Selected Date</div>
              <Typography.Title level={4}>
                {moment(currentDate).format("DD MMM,YYYY")}
              </Typography.Title>
              <div
                style={{ padding: 100, height: 300, position: "relative" }}
                id="area"
              >
                <DatePicker
                  className="ant-picker-dropdown ant-picker-dropdown-placement-bottomLeft calendar-date"
                  dropdownClassName={"date-picker-calendar"}
                  onChange={this.onDateChange}
                  value={currentDate}
                  open={true}
                  getPopupContainer={() => document.getElementById("area")}
                />
              </div>
              <div className="mt-3">
                <div className="people-style"> Selected Employees</div>
                <div>
                  {this.state.calendarObj &&
                    this.state.calendarObj.map((ele) => {
                      // let borderStyle = "2px solid " + ele.color;
                      return (
                        <>
                          <Tag className="tag-bottom" color={ele.color}>
                            {ele.name}
                          </Tag>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="col-9">
              <div
                className="row mb-3"
                style={{ justifyContent: "flex-end", paddingRight: "15px" }}
              >
                <div className="sec_set-1 ml-0">
                  <div className="switch-field-1">
                    <div className="cover_wrap-1">
                      <div style={{ cursor: "pointer" }}>
                        <Radio.Group
                          onChange={() => this.makeSingleView()}
                          value={this.state.isSingleCalendar}
                        >
                          <Radio value={true}>Single View</Radio>
                          <Radio value={false}>Split View</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  className="mb-0 default-btn"
                  onClick={() => this.markDefault()}
                >
                  Set As Default
                </Button>
                {/* <Button onClick={() => this.changeView("day")}>Day</Button> */}
                {/* <Button onClick={() => this.changeView("agenda")}>Agenda</Button> */}
                {/* <Button onClick={() => this.changeView("week")}>Week</Button> */}
                <Radio.Group
                  value={this.state.view}
                  onChange={(e) => this.changeView(e.target.value)}
                >
                  <Radio.Button value={"day"}>Day</Radio.Button>
                  <Radio.Button
                    display={
                      this.state.users && this.state.users.length <= 0
                        ? true
                        : false
                    }
                    value={"week"}
                  >
                    Week
                  </Radio.Button>
                </Radio.Group>
              </div>
              <div className="overflow-fixer">
                <div className="" style={{ backgroundColor: "white" }}>
                  <div style={{ height: "100%", whiteSpace: "nowrap" }}>
                    {!this.state.isSingleCalendar ? (
                      <>
                        {calendarObj.map((obj, i) => (
                          <div
                            style={{
                              width:
                                calendarObj.length === 1 &&
                                this.state.view !== "week"
                                  ? "900px"
                                  : calendarObj.length === 2 &&
                                    this.state.view !== "week"
                                  ? "450px"
                                  : calendarObj.length > 2 &&
                                    this.state.view !== "week"
                                  ? "300px"
                                  : this.state.view === "week"
                                  ? "900px"
                                  : "500px",
                              paddingRight: "15px",
                              display: "inline-block",
                            }}
                          >
                            {obj.loading && (
                              <>
                                <div
                                  style={{
                                    display: "flow-root",
                                    padding: "5px",
                                    borderWidth: "0.8px",
                                    borderColor: "#DDDDDD",
                                    borderStyle: "solid",
                                  }}
                                >
                                  <h4>{obj.name.split(" ")[0]}'s Calendar</h4>
                                  {this.props.authUser.email ===
                                  obj.email ? null : (
                                    <IoMdClose
                                      style={{
                                        float: "right",
                                        marginTop: "-25px",
                                        fontSize: "18px",
                                        color: "black",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => this.onClose(obj.email)}
                                    />
                                  )}
                                </div>
                                <CalendarComponent
                                  color={obj.color}
                                  email={obj.email}
                                  events={schedules}
                                  obj={obj}
                                  date={currentDate}
                                  view={this.state.view}
                                />
                              </>
                            )}
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <div>
                          <CalendarComponent
                            color={null}
                            email={null}
                            events={
                              this.state.allSchadules &&
                              this.state.allSchadules.length > 0
                                ? this.state.allSchadules
                                : ["h"]
                            }
                            obj={null}
                            date={currentDate}
                            view={this.state.view}
                            emailColorMapper={this.state.emailColorMapper}
                          />
                        </div>
                      </>
                    )}

                    {/* </Spin> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.authUser,
});

export default connect(mapStateToProps)(Calendar);
