import React, { useState } from "react";
import { withRouter } from "react-router";
import { MdOpenInNew } from "react-icons/md";
import { FiHeadphones } from "react-icons/fi";
import axiosJSON from "../../../../../util/Api";
import { Button, Form, Input, message, Spin } from "antd";

const logo =
  "https://consultadd.com/wp-content/uploads/2022/11/consultadd-logo.svg";

const AssetInfoForm = (props) => {
  const [validationForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState("");
  const [otpSend, setOtpSend] = useState(false);

  const verifyEmpId = () => {
    const employeeId = validationForm.getFieldValue("employeeId");
    setLoading(true);
    axiosJSON
      .post("api/asset_form/request_otp/", { employee_id: employeeId })
      .then(({ data, status }) => {
        if (status === 200) {
          setLoading(false);
          setOtpSend(true);
          message.success(data.message);
        }
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  };

  const verifyOTP = () => {
    const employeeId = validationForm.getFieldValue("employeeId");
    const otp = validationForm.getFieldValue("otp");

    setLoading(true);
    axiosJSON
      .post("api/asset_form/confirm_otp/", {
        employee_id: employeeId,
        otp: otp,
      })
      .then(({ data, status }) => {
        if (status === 200) {
          setLoading(false);
          localStorage.setItem("API_KEY", data.data.api_key);
          localStorage.setItem("Employee_ID", data.data.employee_id);
          localStorage.setItem("Employee_Name", data.data.employee_name);
          message.success(data.message);
          props.history.push({
            pathname: "/assetinfo/list",
            state: {
              api_key: data.data.api_key,
              empId: data.data.employee_id,
              name: data.data.employee_name,
            },
          });
        }
      })
      .catch((err) => {
        message.error(err.message);
        setLoading(false);
      });
  };

  const onFieldsOneChange = (e) => {
    setFields(e);
  };

  return (
    <div className="gx-main-content main_application_page">
      <div className="main">
        <div className="header header-assert">
          <div className="logo-assert">
            <img style={{ maxHeight: "36px" }} alt="consultadd" src={logo} />
          </div>
          <div className="assert_nav">
            <div className="pr-4 center-assert">
              <a href="https://consultadd.com/" className="home-assert">
                Go to website home page <MdOpenInNew />
              </a>
            </div>
            <div>
              <a
                className="contact_us-assert"
                href="https://consultadd.com/contact/"
              >
                {" "}
                <FiHeadphones /> Contact Us
              </a>
            </div>
          </div>
        </div>

        <div className="main-app-form custom_width_50 assetinfo">
          <div className="headline mb-5">
            <div className="heading_assert">Asset Information Form</div>
          </div>
          <div className="form_container mt-5">
            <Spin spinning={loading}>
              <Form
                name="basic"
                autoComplete="off"
                form={validationForm}
                fields={fields}
                onFieldsChange={onFieldsOneChange}
                onFinish={otpSend ? verifyOTP : verifyEmpId}
              >
                <>
                  <div className="row m-0">
                    <div className="col-12">
                      <Form.Item
                        className="employee_id"
                        name="employeeId"
                        label="Employee ID"
                        rules={[
                          {
                            required: true,
                            validator: (_, value) => {
                              if (value === undefined || value === "") {
                                return Promise.reject(
                                  "Please enter Employee Id"
                                );
                              }
                              return /^[0-9]+$/.test(value)
                                ? Promise.resolve()
                                : Promise.reject(
                                    "Please enter valid Employee Id"
                                  );
                            },
                          },
                        ]}
                        key="1"
                      >
                        <Input
                          className="text_control"
                          placeholder="Employee Id"
                          disabled={otpSend}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {otpSend ? (
                    <div className="row m-0">
                      <div className="col-12">
                        <Form.Item
                          className="employee_id"
                          name="otp"
                          label="OTP"
                          rules={[
                            {
                              required: otpSend,
                              validator: (_, value) => {
                                if (value === undefined || value === "") {
                                  return Promise.reject("Please enter OTP");
                                }
                                return /^[0-9]+$/.test(value)
                                  ? Promise.resolve()
                                  : Promise.reject("Please enter valid OTP");
                              },
                            },
                          ]}
                          key="1"
                        >
                          <Input className="text_control" placeholder="OTP" />
                        </Form.Item>
                      </div>
                    </div>
                  ) : null}

                  <div className="flex-class">
                    <Button
                      className="submit_asset"
                      type="primary"
                      htmlType="submit"
                    >
                      {otpSend ? "Verify OTP" : "Verify"}
                    </Button>
                  </div>
                </>
              </Form>
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AssetInfoForm);
