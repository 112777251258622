import React, { Component } from "react";
import moment from "moment-timezone";
import {
  Table,
  Input,
  DatePicker,
  Button,
  Pagination,
  message,
  Spin,
} from "antd";
import axiosJSON from "../../../../../util/Api";
import { debounce } from "lodash";
const { RangePicker } = DatePicker;

class TeamTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: "",
      end: "",
      currentPage: 1,
      currentSize: 10,
      total: null,
      query: "",
      loading: false,
    };
  }

  getTableData = debounce((query, page, size, start, end) => {
    this.setState({
      loading: true,
    });
    if (start && end) {
      start = moment(this.state.start).format("YYYY-MM-DD");
      end = moment(this.state.end).format("YYYY-MM-DD");
    }
    axiosJSON
      .get(
        `api/recruitment_report/team/?query=${query}&page=${page}&page_size=${size}&start=${start}&end=${end}`
      )
      .then((res) => {
        let columns = res.data.columns.map((column) => {
          column["render"] = (text, record) => (
            <div>{typeof text === "object" ? text.count : text}</div>
          );
          return column;
        });
        this.setState({
          data: res.data.data,
          columns: columns,
          total: res.data.total,
          currentPage: page,
          loading: false,
        });
      })
      .catch((error) => {
        message.error(error);
        this.setState({
          currentPage: page,
          loading: false,
        });
      });
  }, 500);

  onSearch = (value) => {
    this.setState({ query: value }, () =>
      this.getTableData(
        value,
        1,
        this.state.currentSize,
        this.state.start,
        this.state.end
      )
    );
  };

  onPageChange = (page, size) => {
    this.setState(
      {
        currentPage: page,
        currentSize: size,
      },
      () =>
        this.getTableData(
          this.state.query,
          page,
          size,
          this.state.start,
          this.state.end
        )
    );
  };

  componentDidMount = () => {
    this.getTableData(
      this.state.query,
      this.state.currentPage,
      this.state.currentSize,
      this.state.start,
      this.state.end
    );
  };

  onRangeChange = (value) => {
    if (value === null) {
      this.setState({
        start: "",
        end: "",
      });
    } else {
      let start = value[0],
        end = value[1];

      this.setState({
        start: start,
        end: end,
      });
    }
  };

  render() {
    return (
      <div className="tabWrapper col-12">
        <div className="row">
          <div className="col-6 p-0">
            <RangePicker
              className="rangePickerStyle"
              onChange={(value) => this.onRangeChange(value)}
              style={{ marginLeft: "0px" }}
              value={[this.state.start, this.state.end]}
              // showTime={{ format: "h:mm A" }}
              format="MM-DD-YYYY"
            />
            <Button
              type="primary applyButton2 mb-0"
              onClick={this.componentDidMount}
            >
              Apply
            </Button>
          </div>
          <div className="col-6 p-0 right_search">
            <Input.Search
              placeholder="Search"
              onChange={(e) => this.onSearch(e.target.value)}
              style={{ width: 200, zIndex: "0" }}
            />
          </div>
          <div className="col-12 p-0">
            <Spin spinning={this.state.loading}>
              <Table
                className="Candidates_ReportsTable"
                columns={this.state.columns}
                dataSource={this.state.data}
                pagination={false}
              ></Table>{" "}
            </Spin>
            {this.state.total > 0 && (
              <div className="mt-3">
                <Pagination
                  showSizeChanger
                  onShowSizeChange={this.onPageChange}
                  onChange={this.onPageChange}
                  style={{ float: "right" }}
                  current={this.state.currentPage}
                  total={this.state.total}
                  pageSize={this.state.currentSize}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TeamTable;
