import React from "react";
import CustomScrollbars from 'util/CustomScrollbars'
import JobItems from "./jobItem/jobItem";

const JobsList = (({jobsList, selectJob}) => {
  return (
    <div>
    <div className="gx-module-list-item" style={{backgroundColor:'#A8D0E6'}}>
      <div className="gx-media-body gx-task-item-content">
        <div className="gx-task-item-content-left">
          <p
            className={`gx-fs-md gx-text-black gx-ml-3 gx-order-sm-4`}>Title</p>
        </div>
        <div className="gx-task-item-content-left">
          <p
            className={`gx-fs-md gx-text-black gx-ml-3 gx-order-sm-4`}>Portal</p>
        </div>
        <div className="gx-task-item-content-left">
          <p
            className={`gx-fs-md gx-text-black gx-ml-3 gx-order-sm-4`}>Position</p>
        </div>
        <div className="gx-task-item-content-left">
          <p
            className={`gx-fs-md gx-text-black gx-ml-3 gx-order-sm-4`}>Location</p>
        </div>
        <div className="gx-task-item-content-left">
          <p
            className={`gx-fs-md gx-text-black gx-ml-3 gx-order-sm-4`}>Created By</p>
        </div>
        <div className="gx-task-item-content-right">
          {/*<Avatar className="gx-ml-sm-3 gx-size-30 gx-order-sm-3" src={user.avatar}/>*/}

          <span className="gx-fs-md gx-text-black gx-ml-3 gx-order-sm-4">Status</span>
          <span className="gx-fs-md gx-text-black gx-ml-3 gx-order-sm-4">Visibility</span>
        </div>
      </div>
    </div>
      <CustomScrollbars className="gx-module-content-scroll">
        {jobsList.map((job, index) =>
          <JobItems key={index} index={index} job={job} selectJob={selectJob} />
        )}
      </CustomScrollbars>
    </div>
  )
});

export default JobsList;
