import React, { Component } from "react";
import { Button, Input, Form, DatePicker, message } from "antd";
import axiosJSON from "../../util/SetHeaderAPI";
import moment from "moment";

const FormItem = Form.Item;
const dateFormat = "YYYY-MM-DD";
class AddTraining extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [
        {
          name: "title",
          value: "",
        },
        {
          name: "start_date",
          value: "",
        },
        {
          name: "end_date",
          value: "",
        },
      ],
    };
    this.Mounted = false;
  }

  componentWillUnmount() {
    this.Mounted = false;
  }

  componentDidMount() {
    this.Mounted = true;
    if (this.props.data) {
      this.setState({
        fields: [
          {
            name: "title",
            value: this.props.data.title,
          },
          {
            name: "start_date",
            value: moment(this.props.data.start_date),
          },
          {
            name: "end_date",
            value: this.props.data.end_date
              ? moment(this.props.data.end_date)
              : null,
          },
        ],
      });
    }
  }

  onFinishFailed = (errorInfo) => {};

  onFinish = (value) => {
    if (value.end_date < value.start_date) {
      return message.error("Start date can't be greater then End date");
    }
    const data = {
      start: moment(value.start_date).format(dateFormat),
      end: value.end_date ? moment(value.end_date).format(dateFormat) : null,
      title: value.title,
    };
    if (this.props.edit) {
      axiosJSON
        .put("api/training/" + this.props.training_id + "/", data)
        .then(({ data, status }) => {
          message.success(data.message);
          this.props.closeModal();
        })
        .catch(function (error) {
          message.error(error.message);
        });
    } else {
      axiosJSON
        .post("api/training/", data)
        .then(({ data, status }) => {
          message.success(data.message);
          this.props.closeModal();
        })
        .catch(function (error) {
          message.error(error.message);
        });
    }
  };

  disabledDate = (current) => {
    return (
      current <
        moment(
          this.state.fields.find((item) => item.name[0] === "start_date")?.value
        ).endOf("day") ||
      current <
        moment(
          this.state.fields.find((item) => item.name === "start_date")?.value
        ).endOf("day")
    );
  };

  render() {
    return (
      <div className="gx-login-container">
        <div className="gx-login-content education-form ">
          <Form
            name="basic"
            fields={this.state.fields}
            onFieldsChange={(newFields) => this.setState({ fields: newFields })}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            className="gx-signin-form gx-form-row0"
          >
            <FormItem
              rules={[
                { required: true, message: "Please enter Training title" },
              ]}
              name="title"
              label="Title"
            >
              <Input className="gx-input-lineheight" type="text" />
            </FormItem>
            <FormItem
              rules={[{ required: true, message: "Please Enter Start Date" }]}
              name="start_date"
              label="Start Date"
            >
              <DatePicker
                format={"MM/DD/YYYY"}
                placeholder="Training Start Date"
                className="gx-input-lineheight"
                disabledDate={(current) =>
                  current < moment(new Date() - 864e5).endOf("day")
                }
              />
            </FormItem>
            <FormItem
              rules={[{ required: false, message: "Please Enter End Date" }]}
              name="end_date"
              label="End Date"
            >
              <DatePicker
                format={"MM/DD/YYYY"}
                placeholder="Training End Date"
                className="gx-input-lineheight"
                disabledDate={this.disabledDate}
              />
            </FormItem>

            <FormItem>
              <Button type="primary" htmlType="submit">
                {this.props.edit ? "Edit" : "Add"} Training
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

export default AddTraining;
