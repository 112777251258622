import React, { useEffect, useState } from "react";
import { Button, Col, message, Row, Tabs } from "antd";
import Widget from "../../../Widget";
import AboutItem from "./AboutItem";
import axiosJSON from "../../../../util/SetHeaderAPI";
import VisaHistory from "./VisaHistory";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { CustomModal as Modal } from "../../../../util/CustomModal";
import EditProfile from "../../../../modals/Candidate/editProfile";

const TabPane = Tabs.TabPane;

const About = (props) => {
  const { timezone } = useSelector(({ common }) => common);
  const [aboutData, setData] = useState(null);
  const [visaData, setVisaData] = useState([]);
  const [view, setView] = useState(true);
  const [editFlag, editProfile] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("us");

  let profileList =
    timezone === "EST"
      ? [
          {
            id: 1,
            key: "position",
            title: "Job Position",
            icon: "company",
          },
          {
            id: 2,
            key: "dob",
            title: "Birthday",
            icon: "birthday-new",
          },
          {
            id: 3,
            title: "Gender",
            key: "gender",
            icon: "user",
          },
          {
            id: 4,
            key: "current_location",
            title: "Current Location",
            icon: "home",
          },
          {
            id: 5,
            key: "location_preference",
            title: "Preferred Location",
            icon: "location",
          },
          {
            id: 6,
            key: "visa",
            title: "Visa Details",
            icon: "auth-screen",
          },
          {
            id: 7,
            key: "country",
            title: "Country",
            icon: "important-o",
          },
          {
            id: 8,
            key: "married",
            title: "Marital Status",
            icon: "birthday",
          },
          {
            id: 9,
            key: "referred_by",
            title: "Referred By",
            icon: "refer",
          },
          {
            id: 10,
            key: "ssn",
            title: "SSN",
            icon: "card",
          },
          {
            id: 11,
            key: "rate",
            title: "Rate",
            icon: "revenue-new",
          },
        ]
      : [
          {
            id: 1,
            key: "position",
            title: "Job Position",
            icon: "company",
          },
          {
            id: 2,
            key: "dob",
            title: "Birthday",
            icon: "birthday-new",
          },
          {
            id: 3,
            title: "Gender",
            key: "gender",
            icon: "user",
          },
          {
            id: 4,
            key: "current_location",
            title: "Current Location",
            icon: "home",
          },
          {
            id: 5,
            key: "location_preference",
            title: "Preferred Location",
            icon: "location",
          },
          {
            id: 6,
            key: "visa",
            title: "Visa Details",
            icon: "auth-screen",
          },
          {
            id: 7,
            key: "country",
            title: "Country",
            icon: "important-o",
          },
          {
            id: 8,
            key: "married",
            title: "Marital Status",
            icon: "birthday",
          },
          {
            id: 9,
            key: "referred_by",
            title: "Referred By",
            icon: "refer",
          },
        ];
  // let otherInfo =[]

  const getData = () => {
    axiosJSON
      .get("api/candidate/" + props.id + "/about/")
      .then(({ data, status }) => {
        if (status === 200) {
          let obj;
          obj = {
            referred_by: data.data.referred_by
              ? data.data.referred_by
              : "Not Referred",
            current_location: data.data.current_location
              ? data.data.current_location
              : "No location",
            gender:
              data.data.gender.charAt(0).toUpperCase() +
              data.data.gender.slice(1),
            location_preference: !data.data.location_preference
              ? "No preferred location"
              : data.data.location_preference,
            position: data.data.position
              ? data.data.position.display_name
              : "No position",
            dob: data.data.dob
              ? moment(data.data.dob).format("MM-DD-YYYY")
              : "No Data",
            ssn: data.data.ssn ? data.data.ssn : "No Data",
            rate: data.data.rate ? data.data.rate : "No Data",
            visa:
              data.data.visa.length !== 0
                ? data.data.visa[0].type &&
                  data.data.visa[0].type.display_name +
                    "(" +
                    moment(data.data.visa[0].start).format("MM-DD-YYYY") +
                    " to " +
                    moment(data.data.visa[0].end).format("MM-DD-YYYY") +
                    ")"
                : "No Visa available",
            country: data.data.country ? data.data.country : "No Data",
            married:
              data.data.marital_status === "married"
                ? "Married"
                : data.data.marital_status === "unmarried"
                ? "Unmarried"
                : "No Data",
          };
          if (data.data.country && data.data.country === "Canada") {
            profileList[9].title = "SIN";
          }
          setVisaData(data.data.visa_history);
          setData(obj);
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  const getAppName = () => {
    axiosJSON
      .get("/api/util/app/")
      .then(({ data, status }) => {
        setSelectedCountryCode(data.data === "india" ? "in" : "us");
      })
      .catch((error) => message.error(error));
  };

  useEffect(() => {
    getAppName();
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTabChange = (activeKey) => {
    if (activeKey === "1") setView(true);
    else setView(false);
  };

  const onEditClick = () => {
    editProfile(true);
  };

  return (
    <Widget title="About" styleName="gx-card-tabs-right gx-card-profile">
      <Tabs
        defaultActiveKey="1"
        onChange={onTabChange}
        tabBarExtraContent={
          view && <Button onClick={onEditClick}>Edit Profile</Button>
        }
      >
        <TabPane tab="Overview" key="1">
          <div className="gx-mb-2">
            <Row>
              {profileList.map((about, index) => (
                <Col key={index} xl={8} lg={12} md={12} sm={12} xs={24}>
                  {/* {about.key === "referred_by" ? (
                    (aboutData && aboutData[about.key]) && (
                      <AboutItem aboutData={aboutData} data={about} />
                    )
                  ) : ( */}
                  <AboutItem aboutData={aboutData} data={about} />
                  {/* )} */}
                </Col>
              ))}
            </Row>
          </div>
        </TabPane>
        {timezone === "EST" && (
          <TabPane tab="Visa history" key="2">
            <div className="gx-mb-2">
              <Row>
                <VisaHistory
                  getData={getData}
                  id={props.id}
                  visaData={visaData}
                />
              </Row>
            </div>
          </TabPane>
        )}
        {/* {timezone === "EST" && (
          <TabPane tab="Other Info" key="3">
            <div className="gx-mb-2">
              <Row>
                {otherInfo.map((about, index) => (
                  <Col key={index} xl={8} lg={12} md={12} sm={12} xs={24}>
                    <AboutItem aboutData={aboutData} data={about} />
                  </Col>
                ))}
              </Row>
            </div>
          </TabPane>
        )} */}
      </Tabs>
      <Modal
        className="gx-container"
        title={"Edit Profile"}
        visible={editFlag}
        onCancel={() => editProfile(false)}
        closable={true}
        footer={null}
      >
        <EditProfile
          id={props.id}
          data={props.data}
          closeModal={() => editProfile(false)}
          getData={getData}
          getDetails={props.getDetails}
          selectedCountryCode={selectedCountryCode}
        />
      </Modal>
    </Widget>
  );
};

export default About;
