import {
  Select,
  Input,
  Table,
  message,
  Spin,
  Pagination,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { withRouter } from "react-router";
import axiosJSON from "../../util/Api";

const STATUS = [
  { name: "new", display_name: "● New", color: "green" },
  { name: "in_review", display_name: " ● In Review", color: "purple" },
  { name: "in_progress", display_name: "● In Progress", color: "volcano" },
  { name: "complete", display_name: "● Complete", color: "geekblue" },
  { name: "cancelled", display_name: "● Cancelled", color: "red" },
];

const DATE_FORMAT = "YYYY-MM-DD";

function RecruiterDetails(props) {
  const recruiterId = props.location.pathname.split("/")[3];
  const [recruiterData, setRecruiterData] = useState({});
  const [recruitmentStages, setRecruitmentStages] = useState([]);
  const [stageData, setStageData] = useState([]);
  const [stageColumns, setStageColumn] = useState([]);
  const [selectedStage, setSelectedStage] = useState("candidate_added");
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [total, setTotal] = useState(0);
  const [current_page, setCurrentPage] = useState(1);
  const [current_size, setCurrentSize] = useState(10);

  useEffect(() => {
    getRecruiterInfo();
    getRecruitmentStages();
    getRecruitmentStageData(selectedStage, query, current_page, current_size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRecruiterInfo = () => {
    axiosJSON
      .get("api/recruitment_report/" + recruiterId + "/recruiter_info/")
      .then(({ data }) => {
        setRecruiterData(data.data);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  const getRecruitmentStages = () => {
    axiosJSON
      .get("api/recruitment_report/" + recruiterId + "/tabs/")
      .then(({ data }) => {
        setRecruitmentStages(data.data);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  const getRecruitmentStageData = (stage, query, page, size) => {
    setLoader(true);
    axiosJSON
      .get(
        "api/recruitment_report/" +
          recruiterId +
          "/recruiter?tab=" +
          stage +
          "&query=" +
          query +
          "&page=" +
          page +
          "&page_size=" +
          size
      )
      .then(({ data }) => {
        setStageData(getFormattedData(data.data));
        setStageColumn(getFormattedColumns(data.columns));
        setTotal(data.total);
        setCurrentPage(page);
        setLoader(false);
      })
      .catch(function (error) {
        message.error(error.message);
        setLoader(false);
      });
  };

  const getFormattedData = (data) => {
    let newData = [],
      count = 0;
    data.forEach((item) => {
      if (item.screener && item.screener.length > 0) {
        item.screener.forEach((ele, index) => {
          let obj = { ...item };
          obj["screener"] = ele.name;
          obj["screening_date"] = ele.screening_date;
          obj["rowspan"] = index === 0 ? item.screener.length : 0;
          obj["key"] = count;
          count++;
          newData.push(obj);
        });
      } else if (item) {
        newData.push(item);
      }
    });
    return newData;
  };

  const getFormattedColumns = (columns) => {
    columns.map((column) => {
      if (
        column.dataIndex === "screening_date" ||
        column.dataIndex === "screener"
      ) {
        column["render"] = (value, record, index) => {
          const obj = {
            children:
              typeof value === "object"
                ? value.length > 0
                  ? value.join(" | ")
                  : "N/A"
                : value
                ? moment(value).format(DATE_FORMAT)
                : "N/A",
            props: {},
          };
          obj.props.rowSpan = 1;
          return obj;
        };
      } else {
        column["render"] = (value, record, index) => {
          if (column.dataIndex === "status") {
            let obj = STATUS.find((item) => item.name === value);
          value = obj ? (
              <Tag color={obj.color}>{obj.display_name}</Tag>
            ) : (
              "N/A"
            );
          }
          if (column.dataIndex === "date_moved") {
            value = value ? moment(value).format(DATE_FORMAT) : "N/A";

          }
          const obj = {
            children: value ? value : "N/A",
            props: {},
          };
          obj.props.rowSpan = record.rowspan;
          return obj;
        };
      }
      return column;
    });
    return columns;
  };

  const onPageChange = (page, size) => {
    setCurrentPage(page);
    setCurrentSize(size);
    getRecruitmentStageData(selectedStage, query, page, size);
  };
  return (
    <div>
      <div className="general_sub name_gnl mb-n2">
        <div className="circle_icon mr-3">
          <AiOutlineUser />
        </div>
        <div className="">
          <div className="h5 m-0 title_n">Recruiter Name </div>
          <div className="h4 m-0">{recruiterData.name}</div>
        </div>
      </div>
      <div className="col-12 p-0 mb-3">
        <div className="row m-0">
          <div className="felx_cells2">
            <Tooltip title="Recruitment Stages">
              <Select
                style={{ width: "215px" }}
                className="mr-2"
                placeholder="Select Recruitment Stage"
                onChange={(value) => {
                  setSelectedStage(value);
                  getRecruitmentStageData(value, query, 1, current_size);
                }}
                value={selectedStage}
              >
                {recruitmentStages.map((ele, i) => (
                  <Select.Option key={i} value={ele.name}>
                    {ele.display_name} ({ele.count})
                  </Select.Option>
                ))}
              </Select>
            </Tooltip>
            <Input.Search
              placeholder={"Search Candidate"}
              onChange={(e) => {
                setQuery(e.target.value);
                getRecruitmentStageData(
                  selectedStage,
                  query,
                  1,
                  current_size
                );
              }}
              autoFocus="autoFocus"
              value={query}
              style={{ width: 220, zIndex: "0", margin: "0" }}
            />
          </div>
        </div>
      </div>
      <Spin spinning={loader}>
        <div className="mt-2">
          <Table
            rowKey={(record) => record.key}
            className="bulk-table candidateReport"
            columns={stageColumns}
            dataSource={stageData}
            pagination={false}
          />
        </div>
        {total > 0 && (
          <div className="col-12 p-0 mt-3 rightPagination">
            <Pagination
              showSizeChanger
              onShowSizeChange={onPageChange}
              onChange={onPageChange}
              current={current_page}
              total={total}
              pageSize={current_size}
            />
          </div>
        )}
      </Spin>
    </div>
  );
}

export default withRouter(RecruiterDetails);
