import React from "react";
import { IoOptionsOutline } from "react-icons/io5";
import { Menu, Dropdown, Tag, Tooltip } from "antd";

const UserItem = ({ user, authUser, selectOptions, log1RoleList }) => {
  const menu = (
    <Menu onClick={(e) => selectOptions(e, user)}>
      <Menu.Item key="0"> Edit User</Menu.Item>
      {/* {user.is_active && (
        <Menu.Item key="1">
          {user.is_active ? "Activate / Deactivate User" : null}
        </Menu.Item>
      )}
      {!user.is_active && (
        <Menu.Item key="2">
          {!user.is_active ? "Activate / Deactivate User" : null}
        </Menu.Item>
      )} */}
      <Menu.Item key="1"> Activate / Deactivate User</Menu.Item>
      <Menu.Item key="4"> Change Role</Menu.Item>
      {/*<Menu.Divider />*/}
      {/*<Menu.Item key="3">Handover</Menu.Item>*/}
    </Menu>
  );

  const getRoles = (roleList) => {
    let roles = roleList
      .split(",")
      .map((element) => element.trim())
      .map((ele) => {
        let item = log1RoleList.find((item) => item.name === ele);
        return item ? item.display_name : null;
      })
      .filter((item) => item)
      .join(", ");
    return roles ? roles : "N/A";
  };

  return (
    <div className="positions_table pos_user_table">
      <div className="main-jobrow1">
        <div className="gx-module-list-item jobrow">
          <div className="gx-media-body gx-task-item-content">
            <div className="gx-task-item-content-left">
              <Tooltip title={user ? user.name : null}>
                <p
                  className={`gx-text-hover`}
                  style={{ textOverflow: "ellipsis", overflow: "auto" }}
                >
                  {user ? user.name : null}
                </p>{" "}
              </Tooltip>
            </div>
            <div className="gx-task-item-content-left">
              <Tooltip title={user ? user.email : null}>
                <p className={`gx-text-truncate gx-text-hover`}>
                  {" "}
                  {user ? user.email : null}
                </p>
              </Tooltip>
            </div>
            <div className="gx-task-item-content-left">
              <div className={`gx-text-truncate gx-text-hover`}>
                <p>
                  {user.log1_is_active ? (
                    <Tag color="blue" style={{ width: "fit-content" }}>
                      Log1
                    </Tag>
                  ) : null}
                </p>
                <p>
                  {user.us_is_active ? (
                    <Tag color="blue" style={{ width: "fit-content" }}>
                      BEATS US
                    </Tag>
                  ) : null}{" "}
                </p>
                <p>
                  {user.is_active ? (
                    <Tag color="blue" style={{ width: "fit-content" }}>
                      BEATS INDIA
                    </Tag>
                  ) : null}{" "}
                </p>
              </div>
            </div>
            <div className="gx-task-item-content-left">
              <p className={`gx-text-truncate gx-text-hover`}>
                {" "}
                {user.team ? user.team.name : null}
              </p>
            </div>
            <div className="gx-task-item-content-right width50">
              <div className={`gx-text-truncate gx-text-hover`}>
                {/* {user.log1_role && (
                  <p>
                    Log1 -{" "}
                    {user.log1_role.map((data, index) => (
                      <Tag color="purple" style={{ width: "fit-content" }}>
                        {data.name}
                      </Tag>
                    ))}
                  </p>
                )} */}
                {user.log1_role && (
                  <div className="d-flex">
                    Log1 -{" "}
                    <Tooltip title={getRoles(user.log1_role)}>
                      <Tag
                        className="log1Roles"
                        color="purple"
                        style={{ width: "fit-content" }}
                      >
                        {" "}
                        {getRoles(user.log1_role)}
                      </Tag>
                    </Tooltip>
                  </div>
                )}
                {user.us_role && (
                  <p>
                    Beats US -{" "}
                    <Tag color="purple" style={{ width: "fit-content" }}>
                      {user.us_role}
                    </Tag>
                  </p>
                )}
                {user.role && (
                  <p>
                    Beats India -{" "}
                    <Tag color="purple" style={{ width: "fit-content" }}>
                      {user.role.name}
                    </Tag>
                  </p>
                )}
                {/* {user.log1.map((data, index) => (
                  <div>
                  {data.role.map((data, index) => (
                      <span>{data.name}</span>
                    ) )}
                  </div>
                ))} */}
              </div>
            </div>
            {authUser &&
              (authUser.role === "superadmin" || authUser.role === "admin") && (
                <div className="gx-task-item-content-right">
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <IoOptionsOutline />
                  </Dropdown>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserItem;
