import React, { useState } from "react";

import { Button, Form, DatePicker, message, Input } from "antd";
import axiosJSON from "../../util/SetHeaderAPI";
import {confirm} from "../../util/CustomModal"
import moment from "moment";

const FormItem = Form.Item;
const dateFormat = "YYYY-MM-DD";

const UpdateAttendance = (props) => {
  const [fields] = useState([
    {
      name: "currentDate",
      value: moment(props.currentDate.date),
    },
    {
      name: "updateDate",
      value: null,
    },
    {
      name: "remark",
      value: props.currentDate.remark ? props.currentDate.remark : null
    }
  ]);

  const updateAttendance = (value) => {
    let body = {}
    // const body = {
    //   date: value.updateDate
    //     ? moment(value.updateDate).format(dateFormat)
    //     : null,
    //   remark: value.remark ? value : null
    // };
    if(value.updateDate){
      body.date = value.updateDate
        ? moment(value.updateDate).format(dateFormat)
        : null
    }
    if(value.remark){
      body.remark = value.remark
    }
    axiosJSON
      .put("/api/attendance/" + props.currentDate.id + "/", body)
      .then(({ data, status }) => {
        if (status === 202) {
          message.success(data.message);
          props.closeModal();
        }
      })
      .catch(function (error) {
        if (error.status === 400) {
          message.error(error.message);
        } else {
          message.error(error.message);
        }
      });
  };

  const disabledDate = (current) => {
    let disabledDates = props.dateList.map((item) => item.date);
    return (
      current < moment(props.trainingObj.start).endOf("day") ||
      disabledDates.find(
        (date) => date === moment(current).format(dateFormat)
      ) ||
      current > moment(props.trainingObj.end).endOf("day")
    );
  };

  const handleSubmit = (value) => {
    if (!value.updateDate && !value.remark){
      return message.error('Its mandatory to update either date or remark !!!')
    }
    let msg = 'Are you sure to make the changes ?'
    confirm({
      title: msg,
      onOk: () => {
        updateAttendance(value);
      },
    });
  }

  return (
    <div className="gx-login-container">
      <div className="gx-login-content education-form">
        <Form
          name="basic"
          fields={fields}
          onFinish={handleSubmit}
          className="gx-signin-form gx-form-row0"
        >
          <FormItem name="currentDate" label="Current Date ">
            <DatePicker
              inputReadOnly={true}
              allowClear={false}
              open={false}
              format={"MM/DD/YYYY"}
              placeholder="Current Date"
              className="gx-input-lineheight"
            />
          </FormItem>
          <FormItem
            rules={[{ required: false, message: "Please Select Date" }]}
            name="updateDate"
            label="New Date "
          >
            <DatePicker
              format={"MM/DD/YYYY"}
              placeholder="Select Date"
              className="gx-input-lineheight"
              disabledDate={disabledDate}
            />
          </FormItem>
          <FormItem
            rules={[{ required: false}]}
            name="remark"
            label="Remark"
          >
            <Input placeholder={"Remark"}/>
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default UpdateAttendance;
