import React from 'react';
import {Input} from 'antd';

const CustomTextArea = ({name,value,disabled, readOnly,onChange,placeholder, style}) => {

  return (
    <Input.TextArea style={style} className="form-field" readOnly={readOnly} disabled={disabled} autoSize={{ minRows: 4, maxRows: 6 }} placeholder={placeholder} onChange={onChange} value={value} name={name}/>
  );
}
export default CustomTextArea;

