import React from "react";
import {Button, message, Select} from "antd";
import {DeleteOutlined, PlusOutlined, CheckOutlined} from '@ant-design/icons';
import axiosJSON from "../../util/SetHeaderAPI";

class EditJob extends React.Component {
  state = {
    jobs_arr: [],
    jobs_list: [],
  };
  Mounted = false

  componentWillUnmount() {
    this.Mounted = false
  }

  componentDidMount() {
    this.Mounted = true
    this.getJobList()
    let jobs_arr = []
    if (this.props.jobs !== null && this.props.jobs.length !== 0) {
      jobs_arr = this.props.jobs;
    }
    this.setState({
      jobs_arr: jobs_arr,
    })
  }

  getJobList = () => {
    axiosJSON.get('api/job/dropdown/')
      .then(({data, status}) => {

        this.setState({
          jobs_list: data.data,
        })

      }).catch(function (error) {
      message.error(error.message)
    })
  }

  remove = k => {
    const {jobs_arr} = this.state;
    let index = jobs_arr.indexOf(k)
    axiosJSON.delete('api/candidate/' + this.props.id + '/job?id='+k)
      .then(({data, status}) => {
        this.setState({
          jobs_arr: jobs_arr.filter(key => index === key),
        });
        message.success("Removed Job")
        this.props.closeModal()
      }).catch(function (error) {
      message.error(error.message)
    })
  };

  add = () => {
    const {jobs_arr} = this.state;

    jobs_arr.push("")

    this.setState({ 
      jobs_arr: jobs_arr,
    });
  };
  onSubmit = (key) => {
    const body = {
      "job": key
    }

    axiosJSON.put('api/candidate/' + this.props.id + '/job/', body)
      .then(({data, status}) => {
        if (status === 202) {
          message.success(data.message)
          this.props.closeModal()

        }
      }).catch(function (error) {
      message.error(error.message)
    })
  }
  handleChange = (e, index) => {
    let jobs_arr = this.state.jobs_arr;
    this.state.jobs_list.map((ele, i) => {
      if (ele.id === e) {
        jobs_arr[index] = ele;
      }
      return null
    })

    this.setState({
      jobs_arr: jobs_arr,
    })
  }

  render() {
    const {jobs_arr} = this.state;
    return (
      <div>
        {jobs_arr.length !== 0 && jobs_arr.map((ele, index) => {
          return (
            <div key={index} className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
              <Select style={{width: '70%'}} value={jobs_arr[index].id} onChange={(e) => this.handleChange(e, index)}
                      placeholder="Job">
                {this.state.jobs_list.map((job, i) =>
                  <Select.Option value={job.id} key={job.id}>{job.title + ":" + job.location}</Select.Option>
                )}
              </Select>
              <CheckOutlined
                className="dynamic-delete-button delete-icon-phone"
                onClick={() => this.onSubmit(ele.id)}
              />
              {ele.id &&
              <DeleteOutlined
                className="dynamic-delete-button delete-icon-phone"
                onClick={() => this.remove(ele.id)}
              />
              }
            </div>
          );
        })}
        <Button type="dashed" onClick={this.add}>
          <PlusOutlined/>
          Add more jobs
        </Button>
      </div>

    );
  }
}

export default EditJob;
