import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Select, Radio } from "antd";
import axiosJSON from "../../util/SetHeaderAPI";
import PhoneInputType from "../../util/PhoneInput";

const FormItem = Form.Item;

const AddCandidate = (props) => {
  const [fields, setFields] = useState([
    {
      name: ["name"],
      value: "",
    },
    {
      name: ["email"],
      value: "",
    },
  ]);
  const [positionList, setPositionList] = useState([]);
  const [position_id, setPositionArr] = useState("");
  const [maritalStatus, setmarital] = useState(null);

  useEffect(() => {
    getPositionList();
  }, []);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const getPositionList = () => {
    axiosJSON
      .get("api/position/dropdown/")
      .then(({ data, status }) => {
        setPositionList(data.data);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  const onFinish = (values) => {
    const body = {
      name: values.name,
      email: values.email,
      primary_no: values.phone,
      job_position: position_id,
      marital_status: maritalStatus,
    };
    // if (phone.length <= 10) {
      axiosJSON
        .post("api/candidate/", body)
        .then(({ data, status }) => {
          if (status === 201) {
            message.success("Candidate Added!");
            props.closeModal();
          }
        })
        .catch(function (error) {
          message.error(error.message);
        });
    // } else {
    //   message.error("Phone is not correct");
    // }
  };

  const onMarraigeChange = (event) => {
    setmarital(event.target.value);
  };

  const onPositionSelect = (arr) => {
    setPositionArr(arr);
  };
  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <Form
          fields={fields}
          name="basic"
          // onValuesChange={(changedValues, allValues) => {
          //   if (changedValues.hasOwnProperty("phone")) {
          //     let pattern = /[^0-9]/g
          //     let correctNum = changedValues.phone.replace(pattern, '')
          //     let f = [...fields, {name: "phone", value: correctNum}]
          //     setFields(f)
          //   }
          // }}
          onFieldsChange={(newFields) => {
            setFields(newFields);
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          <FormItem
            className="candidate-add-from"
            rules={[
              { required: true, message: "Please input Candidate's Name!" },
            ]}
            name="name"
            label="Candidate's Name"
          >
            <Input
              className="gx-input-lineheight"
              type="text"
              placeholder="Candidate's Name *"
            />
          </FormItem>

          <FormItem
           className="candidate-add-from"
            rules={[
              { required: true, message: "Please input Candidate's Email!" },
              { type: "email", message: "Invalid Email" },
            ]}
            name="email"
            label="Email Address"
          >
            <Input
              className="gx-input-lineheight"
              type="text"
              placeholder="Candidate's Email"
            />
          </FormItem>

          <Form.Item
                    className="candidate-add-from"
                    name="phone"
                    label="Phone Number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Phone Number!",
                      },
                      {
                        validator: async (_, value) => {
                          if (!value) return;
                          if (value.startsWith("91") && value.length < 12) {
                            return Promise.reject(
                              new Error("Please enter valid Phone Number")
                            );
                          } else if (
                            value.startsWith("1") &&
                            value.length < 11
                          ) {
                            return Promise.reject(
                              new Error("Please enter valid Phone Number")
                            );
                          }
                        },
                      },
                    ]}
                  >
                    <PhoneInputType
                      selectedCountryCode={props.selectedCountry}
                      disableDropdown={true}
                    />
                  </Form.Item>

          {/* <FormItem
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (value === undefined || value === "") {
                    return Promise.reject("Please enter phone number");
                  }
                  return value.length >= 10 && /^\d+(\.\d+)?$/.test(value)
                    ? Promise.resolve()
                    : Promise.reject("Please enter valid phone number");
                },
              },
            ]}
            name="phone"
            label="Phone Number"
          >
            <Input
              value={phone}
              onChange={(e) => {
                let pattern = /[^0-9]/g;
                let correctNum = e.target.value.replace(pattern, "");
                if (timezone === "EST") {
                  if (correctNum.startsWith("1")) {
                    correctNum = correctNum.slice(1, correctNum.length);
                  }
                } else {
                  if (correctNum.startsWith("91")) {
                    correctNum = correctNum.slice(2, correctNum.length);
                  } else {
                    if (correctNum.startsWith("0")) {
                      correctNum = correctNum.slice(1, correctNum.length);
                    }
                  }
                }
                onPhoneChange(correctNum);
              }}
              className="gx-input-lineheight"
              type="text"
              placeholder="Candidate's Phone number"
              maxLength={15}
            />
          </FormItem> */}

          <FormItem  className="candidate-add-from" name="position" label="Job Position">
            <Select
              style={{ width: "100%" }}
              value={position_id}
              onChange={onPositionSelect}
              placeholder="Job Position"
            >
              {positionList.map((position, i) => (
                <Select.Option value={position.id} key={position.id}>
                  {position.title + ":" + position.location}
                </Select.Option>
              ))}
            </Select>
          </FormItem>

          <FormItem
            className="candidate-add-from"
            rules={[
              { required: true, message: "Please select marital status" },
            ]}
            name="married"
            label="Marital Status"
          >
            <Radio.Group
              className="gx-radio-group-link gx-radio-group-link-news"
              onChange={onMarraigeChange}
            >
              <Radio value={"married"} className="gx-mb-1">
                Married
              </Radio>
              <Radio value={"unmarried"} className="gx-mb-1">
                Unmarried
              </Radio>
            </Radio.Group>
          </FormItem>

          <FormItem className="button_right">
            <Button type="primary" htmlType="submit" className="">
              Submit
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default AddCandidate;
