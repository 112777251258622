import React from "react";
import { Button, Input, Radio, Tooltip, message, Form } from "antd";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import axiosJSON from "../../util/SetHeaderAPI";


class EditEmail extends React.Component {
  state = {
    listKeys: [],
    emails_arr: [],
  };
  Mounted = false

  componentWillUnmount() {
    this.Mounted = false
  }

  componentDidMount() {
    this.Mounted = true
    let listKeys = [0];
    let email_arr = [];
    let email_obj = {}
    if (this.props.email !== null) {
      email_obj = {
        key: 0,
        email: this.props.email,
        is_primary: true
      }
      email_arr.push(email_obj)
    }
    if (this.props.emails !== null) {
      this.props.emails.map((ele, i) => {
        email_obj = {
          key: i + 1,
          email: ele,
          is_primary: false
        }
        listKeys.push(i + 1)
        email_arr.push(email_obj)
        return null
      })
    }

    this.setState({
      listKeys: listKeys,
      emails_arr: email_arr
    })
  }

  remove = k => {
    const { listKeys, emails_arr } = this.state;

    if (listKeys.length === 1) {
      this.setState({
        emails_arr: [{
          key: 0,
          email: "",
          is_primary: true

        }]
      })
    } else {
      this.setState({
        emails_arr: emails_arr.filter(key => key.key !== k),
        listKeys: listKeys.filter(key => key !== k),
      }, () => {
        let arr = this.state.emails_arr;
        if (arr.length === 1) {
          arr[0].is_primary = true
        }
        this.setState({ emails_arr: arr })
      });
    }


  };

  add = () => {
    const { listKeys, emails_arr } = this.state;

    emails_arr.push({
      key: emails_arr.length,
      email: "",
      is_primary: false
    })
    listKeys.push(listKeys.length)

    this.setState({
      emails_arr: emails_arr,
      listKeys: listKeys,
    });
  };
  onSubmit = () => {
    let primary_email = "";
    let alternate_emails = [];
    this.state.emails_arr.map((ele, i) => {
      if (ele.is_primary) {
        primary_email = ele.email
      } else {
        alternate_emails.push(ele.email)
      }
      return null
    })
    if (primary_email === "") {
      message.error("Please provide primary email.")
    }
    else {
      const body = {
        email: primary_email,
        emails: alternate_emails
      }

      axiosJSON.put('api/candidate/' + this.props.id + '/', body)
        .then(({ data, status }) => {
          if (status === 202) {
            message.success(data.message)
            this.props.closeModal()
          }
        }).catch(function (error) {
          message.error(error.message)
        })
    }

  }
  handleChange = (e, type, index) => {
    let emails_arr = this.state.emails_arr;
    emails_arr.map((field, i) => {
      if (i === index) {
        field[type] = e.target.value
      }
      return null
    })
    this.setState({
      emails_arr: emails_arr,
    })
  }
  radioChecked = (e, index) => {
    let emails_arr = this.state.emails_arr;

    emails_arr.map((field, i) => {
      if (i === index) {
        field.is_primary = e.target.checked
      } else {
        field.is_primary = false
      }
      return null
    })
    this.setState({
      emails_arr: emails_arr,
    })
  }

  checkIfExist = (enteredEmail) => {
    const { emails_arr } = this.state;
    return (
      (emails_arr.filter((ele) => ele.email === enteredEmail)).length > 1
    )
  }

  render() {
    const { listKeys, emails_arr } = this.state;
    return (
      <div>
        <Form
          onFinish={this.onSubmit}
        >
          <Form.List name="emails">
            {(listeKeys, { add, remove }) => {

              return (
                <div>
                  {listKeys.map((key, index) => {
                    return (

                      <div key={key} className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
                        <Tooltip title="Set as primary">
                          <Radio checked={emails_arr[index].is_primary} onChange={(e) => this.radioChecked(e, index)} />
                        </Tooltip>
                        <Form.Item
                          name={index}
                          rules={[
                            {
                              type: 'email',
                              message: 'The input is not valid E-mail!',
                            },
                            {
                              required: true,
                              message: 'Please enter something'
                            },
                            {
                              validator: (_, value) => {
                                return this.checkIfExist(value) ? Promise.reject(new Error()) : Promise.resolve()
                              },
                              message: 'matching emails'
                            }
                          ]
                          }
                          initialValue={emails_arr[index].email}

                        >
                          <Input className='gx-input-lineheight'
                            onChange={(e) => {
                              this.handleChange(e, 'email', index)
                            }
                            }
                            placeholder="Email"
                            style={{ marginLeft: `12px` }}
                          />
                        </Form.Item>
                        <DeleteOutlined
                          className="dynamic-delete-button delete-icon"
                          onClick={() => { this.remove(key); remove(index); }}
                        />
                      </div>
                    );
                  })}
                </div>
              )
            }}

          </Form.List>
          <Button type="dashed" onClick={(e) => (this.add())}>
            <PlusOutlined />
            Add email
          </Button>

          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </div>

    );
  }
}

export default EditEmail;
