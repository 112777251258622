import React from "react";
import {withRouter} from 'react-router-dom';
import {Avatar, Button, Dropdown, Menu, message, Tag,} from "antd";
import CustomScrollbars from '../../../util/CustomScrollbars'
import axiosJSON from "../../../util/SetHeaderAPI";
import {deserialize, serialize} from "../../Editor/parseJson";
import {DownloadOutlined} from '@ant-design/icons';
import DisplayMail from "../Compose/displayMail";
import {confirm} from "../../../util/CustomModal"

const scrollToRef = (ref) =>ref.scrollIntoView({ behavior: 'smooth', block: 'start' })

const options = [
  'Reply',
  'Forward',
];
const draft_options = [
  'Edit'
];
const failed_options = [
  'Resend',
];

// const {confirm} = Modal;

class MailDetail extends React.Component {

  constructor(props) {
    super(props);
    this.ref=null;
    this.state = {
      showDetail: [false],
      to: [],
      cc: [],
      bcc: [],
      showCc: false,
      showBcc: false,
      subject: "",
      body: [
        {
          type: 'paragraph',
          children: [
            {text: ''},
          ],
        },
      ],
      emailList: [],
      reply_to: false,
      forward_mail: false,
      fileList: [],
      prgObj: null,
      id: '',
    };
  }

  onCompose = (type, id) => {
    const {to, cc, bcc, subject, body} = this.state;
    const data = {
      'to': to,
      'cc': cc,
      'bcc': bcc,
      'subject': subject,
      'body': body,
      'message_type': type,
      'parent_message_id': id
    }
    axiosJSON.post('/api/email_message/', data)
      .then(({data, status}) => {
        let m = [
          {
            type: 'paragraph',
            children: [
              {text: ''},
            ],
          },
        ]
        if (body !== "<p></p>") {
          m = deserialize(new DOMParser().parseFromString(body, 'text/html').body)
        }
        this.setState({
          id: data.data.id,
          body: m,
          forward_mail: type === 'forward',
          reply_to: type === 'reply',
        },()=>scrollToRef(this.ref))
      }).catch(function (error) {
      message.error(error.message)
    })
  }
  replyToMail = (mail) => {
    const id = this.props.history.location.hash.split("/")[1];
    this.setState({
      subject: 'Re:' + mail.subject,
      body: `<p></p>`,
    }, () => this.onCompose('reply', id))
  }
  forwardMail = (mail) => {
    const id = this.props.history.location.hash.split("/")[1];
    this.setState({
      subject: 'Fwd:' + mail.subject,
      body: mail.body,
    }, () => this.onCompose('forward', id))
  }
  getEmailList = (query) => {
    this.setState({
      emailList: []
    })
    axiosJSON.get('/api/email_message/suggestions/?query=' + query)
      .then(({data, status}) => {
        this.setState({
          emailList: data.data
        })
      }).catch(function (error) {
      message.error(error.message)
    })
  }
  setEmails = (type, value) => {
    this.setState({
      [type]: value
    })
  }
  setMailBody = (value) => {
    this.setState({
      body: value
    })
  }

  onOptionMenuItemSelect = (e, data) => {
    switch (e.key) {
      case 'Reply':
        this.replyToMail(data);
        break;
      case 'Forward':
        this.forwardMail(data);
        break;
      case 'Edit':
        let m = [
          {
            type: 'paragraph',
            children: [
              {text: ''},
            ],
          },
        ]
        if (data.body !== "<p></p>") {
          m = deserialize(new DOMParser().parseFromString(data.body, 'text/html').body)
        }
        if (data.subject.includes("Fwd")) {
          this.setState({
            id: data.id,
            to: data._to,
            cc: data.cc,
            bcc: data.bcc,
            subject: data.subject,
            body: m,
            fileList: data.attachments,
            forward_mail: true,
          }, () => {
            scrollToRef(this.ref)
          this.props.onMailShift(data)})
        } else if (data.subject.includes("Re")) {
          this.setState({
            id: data.id,
            to: data._to,
            cc: data.cc,
            bcc: data.bcc,
            subject: data.subject,
            body: m,
            fileList: data.attachments,
            reply_to: true,
          }, () => {
            scrollToRef(this.ref)
            this.props.onMailShift(data)})
        }
        break;
      case 'Resend':
        this.setState({
          to: data.to,
          cc: data.cc,
          bcc: data.bcc,
          subject: data.subject,
          body: data.body,
        }, () => this.onSendMail())
        ;
        break;
      default:
        break;
    }
  };

  optionMenu = (data) => {
    return (
      <Menu id="long-menu" onClick={(e) => this.onOptionMenuItemSelect(e, data)}>
        {this.props.selectedFolder === 0 && data.type === "draft" ?
          draft_options.map(option =>
            <Menu.Item key={option}>
              {option}
            </Menu.Item>,
          ) :
          this.props.selectedFolder < 2 ?
            options.map(option =>
              <Menu.Item key={option}>
                {option}
              </Menu.Item>,
            )
            :
            failed_options.map(option =>
              <Menu.Item key={option}>
                {option}
              </Menu.Item>,
            )
        }
      </Menu>)
  };
  displayMail = (to, subject, body, cc, bcc, showCc, showBcc, emailList, prgObj, fileList, id) => {
    return <div className="gx-mail-detail-inner" ref={(ref) => this.ref=ref}>
      <DisplayMail
        to={to} cc={cc} bcc={bcc} showCc={showCc} showBcc={showBcc}
        emailList={emailList} fileList={fileList} prgObj={prgObj}
        id={id} subject={subject} message={body}
        setEmails={this.setEmails} setFlag={this.setFlag} tagRender={this.tagRender}
        getEmailList={this.getEmailList} setMailBody={this.setMailBody} onSubChange={this.onSubChange}
        handleChange={this.handleChange} onDownloadFile={this.onDownloadFile} onDeleteFile={this.onDeleteFile}
      />
      <div className="gx-form-group send-btn">
        {prgObj === null &&
        <Button type="primary" onClick={() => this.onDiscardMail()}>Discard</Button>
        }

        {prgObj === null &&
        <Button type="primary" onClick={() => this.onSendMail()}>Send</Button>
        }
      </div>
      <br clear="all" />
    </div>
  }
  onDiscardMail = () => {
    const {to, cc, bcc, subject, body} = this.state;
    let self = this;
    let m = serialize(body)
    const data = {
      'to': to,
      'cc': cc,
      'bcc': bcc,
      'subject': subject,
      'body': m,
    }
    confirm({
      title: 'Discard mail',
      content: 'Do you Want to discard mail?',
      okText: 'Discard',
      cancelText: 'Save Draft',
      onOk() {
        if (data.hasOwnProperty('message')) {
          self.onDraftDiscard([this.state.id])
        }
        self.props.history.push('/mail#' + self.props.selectedName)
      },
      onCancel() {
        self.onDraftMailSend(data, this.state.id);
      },
    });
  }
  onSendMail = () => {
    const {to, cc, bcc, subject, body} = this.state;
    let m = serialize(body)
    const data = {
      'to': to,
      'cc': cc,
      'bcc': bcc,
      'subject': subject,
      'body': m,
    }
    if (this.state.id !== null) {
      axiosJSON.post('/api/email_message/' + this.state.id + '/send_mail/', data)
        .then(({data, status}) => {
          message.success(data.message)
          this.props.history.push('/mail#inbox')
        }).catch(function (error) {
        message.error(error.message)
      })
    }
  }
  handleChange = (e) => {
    const file = e.target.files[0]
    let fileList = this.state.fileList;
    let fileObj = {
      'file_name': e.target.files[0].name
    }
    fileList.push(fileObj)
    this.setState({
      fileList: fileList
    }, () => this.onFileUpload(file, this.state.id))
  }
  onFileUpload = (file, id) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('message', id);
    axiosJSON.post('/api/email_doc/', formData,
      {
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null) {
            let prgObj = {
              'file_name': file.name,
              'prg': Math.round((progressEvent.loaded * 100) / totalLength)
            }
            this.setState({
              prgObj: prgObj
            })
            if (Math.round((progressEvent.loaded * 100) / totalLength) === 100) {
              this.setState({
                prgObj: null
              })
            }
          }
        }
      }).then(({data, status}) => {
      this.setState({
        fileList: data.data
      })
      message.success(data.message)
    }).catch(function (error) {
      message.error(error.message)
    })
  }
  onDownloadFile = (file_id, message_id) => {
    axiosJSON.get('/api/email_doc/' + file_id + '/')
      .then(({data, status}) => {
        window.open(data.data.url)
      }).catch(function (error) {
      message.error(error.message)
    })
  }
  onDeleteFile = (file_id, message_id) => {
    axiosJSON.delete('/api/email_doc/' + file_id + '/')
      .then(({data, status}) => {
        let fileList = this.state.fileList.filter(val => val.id !== file_id);
        this.setState({
          fileList: fileList
        })

      }).catch(function (error) {
      message.error(error.message)
    })
  }

  onSubChange = (e) => {
    this.setState({subject: e.target.value})
  }

  setFlag = (type) => {
    this.setState({
      [type]: true
    })
  }

  tagRender(props) {
    const {label, value, closable, onClose} = props;

    var re = /\S+@\S+\.\S+/;
    return (
      <Tag color={re.test(String(value).toLowerCase()) ? 'gray' : 'darkred'} closable={closable} onClose={onClose}>
        {label}
      </Tag>
    );
  }

  render() {
    const {mails} = this.props;
    const {id, to, subject, reply_to, forward_mail, body, cc, bcc, showCc, showBcc, emailList, fileList, prgObj} = this.state;
    return (
      <div className="gx-module-detail gx-mail-detail">
        <CustomScrollbars className="gx-module-content-scroll">
          {mails.map((mail, index) =>
            <div>
              <div className="gx-mail-detail-inner">
                <div className="gx-mail-header">

                  <div className="gx-mail-header-content gx-col gx-pl-0">
                    <div className="gx-subject">
                      Subject: {mail.subject}
                    </div>
                  </div>
                  {mail.type === "draft" &&
                  <div>
                    <Tag color="orange">Draft</Tag>
                  </div>
                  }
                </div>
                <hr/>
                <div className="gx-mail-user-info gx-ml-0 gx-mb-3">
                  {mail.from_name !== "" ?
                    <Avatar
                      className="gx-avatar gx-bg-blue gx-size-40 gx-mr-3"> {mail.from_name.charAt(0).toUpperCase()}</Avatar> :
                    null
                  }
                  <div className="gx-sender-name">{mail.from_name}
                    <div className="gx-send-to gx-text-grey">to me</div>
                  </div>

                  <Dropdown trigger={['click']} overlay={this.optionMenu(mail)}>
                    <span className="gx-ml-auto"><i className="icon icon-ellipse-v gx-icon-btn"/></span>
                  </Dropdown>

                </div>

                <div className="main_inbox">

                  <div className="gx-show-link" onClick={() => {
                    let showDetail =this.state.showDetail;
                    showDetail[index]=!showDetail[index]
                    this.setState({showDetail: showDetail});
                  }}>{this.state.showDetail[index] ? 'Hide Detail' : 'Show Detail'}</div>
                  {this.state.showDetail[index] && (<div className="gx-show-detail">

                    <div className="gx-mb-2">
                      <strong>From: </strong>{mail._from}
                    </div>

                    <div className="gx-mb-2">
                      <strong> To: </strong>
                      {
                        mail._to.map((to, index) => <span key={index}>{index > 0 && ', '} {to}</span>)
                      }
                    </div>
                    {
                      mail.cc.length > 0 &&
                      <div className="gx-mb-2">
                        <strong> Cc: </strong>
                        {
                          mail.cc.map((cc, index) => <span key={index}>{index > 0 && ', '} {cc}</span>)
                        }
                      </div>

                    }
                    {
                      mail.bcc.length > 0 &&
                      <div className="gx-mb-2">
                        <strong> Bcc: </strong>
                        {
                          mail.bcc.map((bcc, index) => <span key={index}>{index > 0 && ', '} {bcc}</span>)
                        }
                      </div>
                    }
                    <div className="gx-mb-2"><strong>Date: </strong>{mail.time} </div>
                  </div>)}

                </div>

                <br clear="all"/>

                <p style={{marginLeft: '57px', marginTop: '20px'}} dangerouslySetInnerHTML={{__html: mail.body}}/>
                {mail.attachments.length > 0 && mail.attachments.map((file, i) =>

                  <p className="download_file" key={i}>{file.file_name}<DownloadOutlined className="download_icon"
                                                                                         onClick={() => this.onDownloadFile(file.id, mail.id)}/>
                  </p>
                )}
              </div>
              <hr/>
            </div>
          )}
          {reply_to &&
          this.displayMail(to, subject, body, cc, bcc, showCc, showBcc, emailList, prgObj, fileList, id)
          }
          {
            forward_mail &&
            this.displayMail(to, subject, body, cc, bcc, showCc, showBcc, emailList, prgObj, fileList, id)
          }
        </CustomScrollbars>
      </div>
    );
  }
}

export default withRouter(MailDetail);
