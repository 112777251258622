import React from "react";
import { Button, Upload, Tooltip, Table, message, Input, Spin } from "antd";
import { UpCircleOutlined, DownCircleOutlined } from "@ant-design/icons";
import { FiUpload, FiDownload } from "react-icons/fi";
import axiosJSON from "../../../util/SetHeaderAPI";
import { IoCloseCircleOutline } from "react-icons/io5";

const Data = [
  {
    name: "Serial Number",
    key: 1,
    type: "Number",
    description: "Serial Number",
  },
  {
    name: "Employee Id",
    key: 2,
    type: "Number",
    description: "Enter Employee Id",
  },
  {
    name: "Password",
    key: 3,
    type: "Text",
    description: "Enter Password",
  },
  {
    name: "Employee Name",
    key: 4,
    type: "Text",
    description: "Enter The Employee Full Name",
  },
  {
    name: "Employee Email",
    key: 5,
    type: "Email",
    description: "Enter Employee's Email Address",
  },
  {
    name: "Employee Phone",
    key: 6,
    type: "Number",
    description: "Provide Employee's Phone Number",
  },
  {
    name: "log1",
    key: 7,
    type: "Boolean",
    description: "True",
  },
  {
    name: "Beats_us",
    key: 8,
    type: "Boolean",
    description: "True",
  },
  {
    name: "Beats_india",
    key: 9,
    type: "Boolean",
    description: "False",
  },
  {
    name: "log1_role",
    key: 10,
    type: "Multiple",
    description: "Admin",
  },
  {
    name: "Beats_us_role",
    key: 11,
    type: "Single",
    description: "Screener",
  },
  {
    name: "Beats_india_role",
    key: 12,
    type: "Single",
    description: "Recruiter",
  },
];

export default class BulkUserCreation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: Data,
      showTable: false,
      bulkFile: null,
      fileName: "",
      fileUploaded: false,
      loader: false,
    };
    this.submitUsers.bind(this);
  }

  showTableData = () => {
    this.setState({
      showTable: true,
    });
  };

  notTableData = () => {
    this.setState({
      showTable: false,
    });
  };

  handleClose = () => {
    this.props.closeModal();
    this.setState({
      showTable: false,
      fileName: "",
      fileUploaded: false,
    });
  };

  onDownloadFile = () => {
    axiosJSON
      .get("/api/user/file/")
      .then(({ data, status }) => {
        window.open(data.file);
      })
      .catch(function (error) {
        message.error(error?.message);
      });
  };
  handleFileChange = (info) => {
    this.setState({
      bulkFile: info.file,
      fileName: info.file.name,
      fileUploaded: true,
      loader: false,
    });
  };

  submitUsers = () => {
    let self = this;
    this.setState({
      loader: true,
    });
    let formData = new FormData();
    formData.append("file", this.state.bulkFile.originFileObj);
    if (this.state.fileUploaded === false) {
      message.error("Please Upload a File");
    } else {
      axiosJSON
        .post("api/user/bulk_register_new/", formData)
        .then(({ data, status }) => {
          if (status === 202 || status === 201 || status === 200) {
            message.success(data.message);
            this.setState({
              loader: false,
            });
            if (data.invalid_user) {
              window.open(data.invalid_user);
            }
            this.props.closeModal();
          }
        })
        .catch(function (error) {
          self.setState({
            loader: false,
          });
          if (error.invalid_user) {
            window.open(error.invalid_user);
          }
          message.error(error?.message);
        });
    }
  };

  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "1",
        width: "33%",
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "2",
        width: "33%",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "3",
        width: "33%",
      },
    ];

    return (
      <Spin spinning={this.state.loader}>
        <div className="bulk-container">
          <div className="bulk-form col-12 py-3 mb-4 border_model">
            <div className="row m-0">
              <div className="bulkDescription col-12 p-0 mb-3">
                Add Employee Importer is used to add new employees to the
                system. Employee Number will be checked for duplication and
                those employees with duplicate employee, number will not be
                added. Please read the log message once the import is over to
                check the status.
              </div>
              <div className="bulkDownload col-6 p-0">
                <div>
                  Click this link to download a sample Excel File.{"  "}
                </div>
                <div
                  className="link_txt"
                  style={{ cursor: "pointer" }}
                  onClick={this.onDownloadFile}
                >
                  Download a sample file{" "}
                  <FiDownload className="model_download ml-1" />
                </div>
              </div>
              <div
                className="col-6 p-0 link_txt show_field"
                style={{ cursor: "pointer" }}
              >
                {this.state.showTable ? (
                  <span onClick={this.notTableData}>
                    {" "}
                    Show FIeld Info {"  "}
                    <UpCircleOutlined className="model_arrow ml-1" />
                  </span>
                ) : (
                  <span onClick={this.showTableData}>
                    {" "}
                    Show Field Info {"  "}
                    <DownCircleOutlined className="model_arrow ml-1" />
                  </span>
                )}
              </div>
            </div>
            <div className="">
              {this.state.showTable ? (
                <div className="bulk-table_contain top_seperator mt-4 pt-4">
                  {" "}
                  <div className="mb-3">
                    {" "}
                    Your Excel file can contain the following columns. The first
                    row of the excel file, indicates the field names.
                  </div>
                  <Table
                    className="bulk-table"
                    // rowKey={(record) => record.id.toString()}
                    columns={columns}
                    dataSource={this.state.data}
                    pagination={false}
                  ></Table>
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-12 p-0">
            <div className="row">
              <div className="col-6">
                <Upload
                  accept=".csv"
                  onChange={(f) => this.handleFileChange(f)}
                  multiple={false}
                  className="bulk-form"
                  showUploadList={false}
                  disabled={this.state.fileUploaded}
                >
                  <Tooltip title="Upload File">
                    <Input.Group compact>
                      <Input
                        className="input_g1"
                        value={
                          this.state.fileName !== ""
                            ? this.state.fileName
                            : "Upload File"
                        }
                      />
                      {this.state.fileUploaded && (
                        <IoCloseCircleOutline
                          className="mr-2 circle_class_rand"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({
                              bulkFile: null,
                              fileName: "",
                              fileUploaded: false,
                            });
                          }}
                        />
                      )}
                      <Button
                        type="primary upload_button"
                        disabled={this.state.fileUploaded}
                      >
                        <FiUpload className="mr-2" /> Upload
                      </Button>
                    </Input.Group>
                  </Tooltip>
                </Upload>{" "}
                <div className="mt-2">Note: Only CSV files are accepted.</div>
              </div>
              <div className="col-12 mt-4">
                <Button
                  type="primary"
                  className="submit_btn mb-0"
                  htmlType="submit"
                  disabled={this.state.fileUploaded === false}
                  onClick={this.submitUsers}
                >
                  Submit
                </Button>
                <Button
                  type="btn-outline-secondary"
                  className="cancel_btn mr-2 mb-0"
                  onClick={this.handleClose}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}
