import moment from "moment";
import React from "react";

const FeedbackDetails = ({ feedbacks }) => {
  return (
    <div>
      {feedbacks && feedbacks.length > 0 ? (
        feedbacks.map((feedback, index) => (
          <div
            style={{
              ...styles.outerWrapper,
              borderBottom:
                index === feedbacks.length - 1 ? null : "1px solid #EAEDF2",
            }}
          >
            <div style={styles.nameTagStyle}>
              {feedback.name.split(" ")[0][0].toUpperCase() +
                feedback.name.split(" ")[1][0].toUpperCase()}
            </div>
            <div style={styles.contentWrapper}>
              <div style={{ color: "#353D4A" }}>{feedback.name}</div>
              <div style={{ color: "#9AA1B0" }}>
                {moment(feedback.created_date).format("llll")}
              </div>
              <div style={styles.detailsStyle}>
                <p dangerouslySetInnerHTML={{ __html: feedback.description }} />
              </div>
            </div>
          </div>
        ))
      ) : (
        <div style={styles.defaultDivStyle}>No Feedback Recoded</div>
      )}
    </div>
  );
};

const styles = {
  outerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "16px",
    gap: "6px",
  },
  nameTagStyle: {
    padding: "10px",
    borderRadius: "40px",
    background: "#D7DDFA",
    color: "#26305C",
    fontSize: "16px",
    height: "40px",
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    fontSize: "16px",
    paddingLeft: "10px",
    flex: 1,
  },
  detailsStyle: {
    color: "#717885",
    marginTop: "10px",
  },
  defaultDivStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default FeedbackDetails;
