export const style = {
  bodyWrapper: {
    marginLeft: "-2.3vw",
    padding: "16px",
    paddingLeft: "20px",
    paddingRight: "30px",
    boxSizing: "border-box",
    position: "relative",
    zIndex: "1",
    background: "white",
    width: "106%",
  },
  outerWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardWrapper: {
    width: "49.5%",
    padding: "10px 0px 16px 10px",
    gap: 12,
    marginBottom: "10px",
  },
  topPartWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: "10px",
  },
  topRightWrapper: {
    display: "flex",
    gap: "12px",
    alignItems: "flex-start",
  },
  startDateStyle: {
    color: "#14882F",
    fontSize: "16px",
    fontWeight: 500,
  },
  endDateStyle: {
    color: "#DC2626",
    fontSize: "16px",
    fontWeight: 500,
  },
  totalHourStyle: {
    color: "#9AA1B0",
    fontSize: "16px",
    fontWeight: 500,
    display: "flex",
    gap: "5px",
  },
  rightPartStyle: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: "10px",
  },
  messageIconStyle: {
    fontSize: "16px",
    cursor: "pointer",
  },
  topDownWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 8,
    paddingLeft: "10px",
    fontSize: "16px",
  },
  projectStatusStyle: {
    background: "#DEF1FE",
    borderRadius: "6px",
    padding: "4px 10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 4,
    color: "#195986",
  },
  projectTypeStyle: {
    background: "#F1F3FA",
    borderRadius: "6px",
    padding: "4px 10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 4,
    color: "#555F8B",
  },
  projectRemoteTypeStyle: {
    background: "#FFF8EA",
    borderRadius: "6px",
    padding: "4px 10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 4,
    color: "#E8930B",
  },
  lineStyle: {
    width: "100%",
    background: "#D9DEE6",
    height: 1,
  },
  clientRoleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    gap: 12,
    paddingLeft: "10px",
  },
  clientWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    gap: 12,
    flex: 1,
    padding: "10px",
    color: "#9AA1B0",
    fontSize: "15px",
  },
  fontStyle: {
    fontSize: "16px",
  },
  detailsWrapper: {
    display: "flex",
    flexDirection: "column",
  },
};
