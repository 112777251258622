import React from "react";
import NotificationItem from "./NotificationItem";
import CustomScrollbars from "util/CustomScrollbars";
import Auxiliary from "util/Auxiliary";
import axiosJSON from "../../util/SetHeaderAPI";
import { Link } from "react-router-dom";
import { message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { unreadNotify } from "../../appRedux/actions";

const comparator = (prevProps, nextProps) => {
  if (prevProps.visible !== nextProps.visible) {
    return true;
  }
  return false;
};
const AppNotification = React.memo((props) => {
  const dispatch = useDispatch();

  const markAllRead = () => {
    axiosJSON
      .put("api/notification/mark_all_read/")
      .then((res) => {
        dispatch(unreadNotify(res.data.count));
        props.fetchData(
          localStorage.getItem("current_token")
            ? localStorage.getItem("current_token")
            : localStorage.getItem("token")
        );
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };
  return props.notificationList.length > 0 ? (
    <Auxiliary>
      <div className="gx-popover-header">
        <h3 className="gx-mb-0">Notifications</h3>
        <CloseOutlined onClick={props.hide} />
      </div>
      <CustomScrollbars className="gx-popover-scroll">
        <ul className="gx-sub-popover">
          {props.notificationList.map((notification, index) => (
            <NotificationItem
              key={index}
              id={notification.id}
              notification={notification.message}
              is_read={notification.is_read}
              hide={props.hide}
              get={props.fetchData}
            />
          ))}
        </ul>
      </CustomScrollbars>
      <Link to="/notification">
        <span onClick={props.hide}>Show All</span>
      </Link>
      <Link to="/">
        <span style={{ float: "right" }} onClick={markAllRead}>
          Mark All Read
        </span>
      </Link>
    </Auxiliary>
  ) : (
    <Auxiliary>No Notifications</Auxiliary>
  );
}, comparator);

export default AppNotification;
