
import {FILTER_MY,FILTER_FOR,QUERY_REPORTS} from 'constants/ActionTypes'

const INIT_STATE = {
  filter_my:false,
  query:localStorage.getItem("query") || '',
  filter_for:"",
};

export default (state = INIT_STATE, action) => {

  switch (action.type) {
    case FILTER_MY: {
      return {...state, my:action.payload};
    }
    case QUERY_REPORTS: {
      return {...state, query:action.payload};
    }
    case FILTER_FOR: {
      return {...state, filter_for:action.payload};
    }
    default:
      return state;
  }
}
