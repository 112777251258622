
import axiosJSON from "./Api"

let token = localStorage.getItem("token")
let current_token = localStorage.getItem("current_token")
let t =  current_token?current_token:token

axiosJSON.defaults.headers['authorization'] = "Token "+t;


export default axiosJSON;
