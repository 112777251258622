import React, { Component} from "react";
import axiosJSON from "../../../../util/SetHeaderAPI";
import { Button, DatePicker, message, Select } from "antd";
import moment from "moment";
import { positionList$, recruiterList$ ,sourceList$,stageList$,visaList$ } from '../Service/Api';
class Filters extends Component {
  constructor(props) {
    super(props);
    this.recruiterList=null;
    this.visaList=null;
    this.sourceList=null;
    this.stageList=null;
    this.positionList=null;
    this.state = {
      type: window.location.href.split("/reports/")[1] || "basic",
      screeningList: [
        { value: "cfr", name: "CFR" },
        { value: "coding", name: "Coding" },
        { value: "video_interview", name: "Video Interview" },
        { value: "initial_screening", name: "Initial Screening" },
        { value: "post_training", name: "Post Training Screening" },
      ],
      sourceList: [],
      positionList: [],
      stageList: [],
      statusList: [],
      app: "",
      recruiterList: [],
      exportLoading: false,
      visaList: [],
    };
  }

  componentDidMount() {
    this.recruiterList=recruiterList$.subscribe((res)=>{
      this.setState({
        recruiterList: res
      });
    })

    this.visaList=visaList$.subscribe((res)=>{
      this.setState({
        visaList: res
      });
    })
    
    this.sourceList=sourceList$.subscribe((res)=>{
      this.setState({
        sourceList: res
      });
    })

    this.stageList=stageList$.subscribe((res)=>{
      this.setState({
        stageList: res
      });
    })
   
   this.positionList=positionList$.subscribe((res) => {
      this.setState({
        positionList: res
      });
   })
    // if (this.state.type === "basic" || this.state.type === "bench") {
    //   this.getSourceList();
    //   this.getStageList();
    //   this.getVisaList();
    //   this.getRecruiterList();
    // }
    // if (
    //   this.state.type === "archive" ||
    //   this.state.type === "bench" ||
    //   this.state.type === "screening"
    // ) {
    //   this.getStageList();
    // }
    // this.getPositionList();
  }
  
  componentWillUnmount() {
    if (this.recruiterList) {
      this.recruiterList.unsubscribe();
    }
    if (this.visaList) {
      this.visaList.unsubscribe();
    }
    if (this.sourceList) {
      this.sourceList.unsubscribe();
    }
    if (this.stageList) {
      this.stageList.unsubscribe();
    }
    if (this.positionList) {
      this.positionList.unsubscribe();
    }
  }
  
  getStatusList = () => {
    axiosJSON
      .get("/api/choice/?type=status")
      .then(({ data }) => {
        this.setState({
          statusList: data.data,
        });
      })
      .catch((err) => message.error(err));
  };

  toggleFilterOption = (i) => {
    let openFilterOption = this.state.openFilterOption;
    openFilterOption[i] = !this.state.openFilterOption[i];
    this.setState({
      openFilterOption,
    });
  };

  exportScreeningData = () => {
    this.setState({
      exportLoading: true,
    });
    let self = this;
    let obj = JSON.parse(localStorage.getItem("filter"));
    let query = "";
    if (obj !== null) {
      query = obj.query;
    }
    axiosJSON
      .get(
        "/api/report/export?filter_json=" +
          JSON.stringify(this.props.filterObj) +
          "&query=" +
          query,
        {
          responseType: "blob",
        }
      )
      .then(({ data, status }) => {
        var blobURL =
          window.URL && window.URL.createObjectURL
            ? window.URL.createObjectURL(data)
            : window.webkitURL.createObjectURL(data);
        var tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = blobURL;
        tempLink.setAttribute(
          "download",
          "Screening-Report" +
            moment(new Date()).format("DD_MMM_YYYY_HH_MM_ss") +
            ".csv"
        );

        if (typeof tempLink.download === "undefined") {
          tempLink.setAttribute("target", "_blank");
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        message.success("Reports downloaded successfully !!!");
        setTimeout(function () {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
          self.setState({
            exportLoading: false,
          });
        }, 200);
      })
      .catch(function (error) {
        self.setState({
          exportLoading: false,
        });
        message.error(error.message);
      });
  };

  render() {
    return (
      <div className="basic-tab">
        {Object.keys(this.props.filterObj).length > 0 && (
          <span
            onClick={() => this.props.selectFilter(false, "", "")}
            className="gx-text-blue gx-pointer"
          >
            <em>Clear all filters</em>
          </span>
        )}
        {this.state.type === "screening" && (
          <span style={{ float: "right", marginRight: "40px" }}>
            <Button
              onClick={this.exportScreeningData}
              type={"primary"}
              loading={this.state.exportLoading}
            >
              Export Data
            </Button>
          </span>
        )}
        <br clear="all" />
        <ul>
          <li>
            <div>
              <label> Position </label>
              <br />
              <Select
                showSearch
                mode="multiple"
                value={this.props.filterObj["position"]}
                style={{ width: "100%" }}
                className="input-scroll"
                onChange={(value) =>
                  this.props.selectFilter(true, value, "position")
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.positionList.map((p, i) => (
                  <Select.Option key={i} value={p.id}>
                    {p.title + " - " + p.location}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </li>
          {/* {this.state.type === "basic" ||
          this.state.type === "bench" ||
          this.state.type === "archive" ||
          this.state.type === "screening" ? ( */}
          <li>
            <div>
              <label> Stage </label> <br />
              <Select
                showSearch
                mode="multiple"
                value={this.props.filterObj["stage"]}
                style={{ width: "100%" }}
                className="input-scroll"
                onChange={(value) =>
                  this.props.selectFilter(true, value, "stage")
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.stageList.map((stage, i) => (
                  <Select.Option key={i} value={stage.id}>
                    {stage.display_name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </li>
          {/* ) : null} */}

          {(this.state.type === "basic" || this.state.type === "screening") &&
          this.props.app === "india" ? (
            <li>
              <div>
                <label> Feedback By </label> <br />
                <Select
                  showSearch
                  allowClear={() =>
                    this.props.selectFilter(false, null, "feedback_by")
                  }
                  value={this.props.filterObj["feedback_by"]}
                  style={{ width: "100%" }}
                  className="input-scroll"
                  onChange={(value) =>
                    this.props.selectFilter(true, value, "feedback_by")
                  }
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.recruiterList.map((recruiter, i) => (
                    <Select.Option key={i} value={recruiter.id}>
                      {recruiter.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </li>
          ) : null}

          {this.state.type === "basic" ||
          this.state.type === "bench" ||
          (this.state.type === "screening" && this.props.app === "india") ? (
            <li>
              <div>
                <label> Source </label>
                <br />
                <Select
                  showSearch
                  mode="multiple"
                  value={this.props.filterObj["source"]}
                  style={{ width: "100%" }}
                  className="input-scroll"
                  onChange={(value) =>
                    this.props.selectFilter(true, value, "source")
                  }
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.sourceList.map((source, i) => (
                    <Select.Option key={i} value={source.id}>
                      {source.display_name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </li>
          ) : null}
          {/*{this.state.type === "archive"  ?*/}
          {/*  <li>*/}
          {/*    <div>*/}
          {/*      <label> Status </label><br/>*/}
          {/*      <Select*/}
          {/*        mode="multiple"*/}
          {/*        value={this.props.filterObj["status"]}*/}
          {/*        style={{width: '100%'}}*/}
          {/*        onChange={(value) => this.props.selectFilter(true, value, 'status')}*/}
          {/*      >*/}
          {/*        {this.state.statusList.map((s, i) =>*/}
          {/*          <Select.Option value={s.id}>{s.display_name}</Select.Option>*/}
          {/*        )}*/}
          {/*      </Select>*/}
          {/*    </div>*/}
          {/*  </li>*/}
          {/*  : null*/}
          {/*}*/}

          {this.state.type === "basic" && this.props.app !== "india" ? (
            <li>
              <div>
                <label> Visa Type </label>
                <br />
                <Select
                  showSearch
                  mode="multiple"
                  value={this.props.filterObj["visa_type"]}
                  style={{ width: "100%" }}
                  className="input-scroll"
                  onChange={(value) =>
                    this.props.selectFilter(true, value, "visa_type")
                  }
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.visaList.map((visa, i) => (
                    <Select.Option key={i} value={visa.id}>
                      {visa.display_name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </li>
          ) : null}

          {this.state.type === "screening" && this.props.app === "us" ? (
            <li>
              <div>
                <label> Screening Type </label>
                <br />
                <Select
                  showSearch
                  allowClear
                  value={this.props.filterObj["screening_type"]}
                  style={{ width: "100%" }}
                  onChange={(value) =>
                    this.props.selectFilter(true, value, "screening_type")
                  }
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.screeningList.map((s, i) => (
                    <Select.Option key={i} value={s.value}>
                      {s.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </li>
          ) : null}

          <li>
            <div>
              <label> Start </label> <br />
              <DatePicker
                value={
                  this.props.filterObj["start"]
                    ? moment(this.props.filterObj["start"])
                    : ""
                }
                disabledDate={(date) =>
                  this.props.filterObj["end"]
                    ? date &&
                      date.valueOf() > moment(this.props.filterObj["end"])
                    : false
                }
                format={"YYYY-MM-DD"}
                style={{ width: "100%" }}
                onChange={(value, dateString) => {
                  if (dateString !== "")
                    this.props.selectFilter(
                      true,
                      moment(dateString).format("YYYY-MM-DD"),
                      "start"
                    );
                  else this.props.selectFilter(true, "", "start");
                }}
              />
            </div>
          </li>

          <li>
            <div>
              <label> End </label>
              <br />
              <DatePicker
                disabledDate={(date) =>
                  this.props.filterObj["start"]
                    ? date &&
                      date.valueOf() < moment(this.props.filterObj["start"])
                    : false
                }
                value={
                  this.props.filterObj["end"]
                    ? moment(this.props.filterObj["end"])
                    : ""
                }
                format={"YYYY-MM-DD"}
                style={{ width: "100%" }}
                onChange={(value, dateString) => {
                  if (dateString !== "")
                    this.props.selectFilter(
                      true,
                      moment(dateString).format("YYYY-MM-DD"),
                      "end"
                    );
                  else this.props.selectFilter(true, "", "end");
                }}
              />
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default Filters;
