import React, { Component } from "react";
import { Button, Collapse } from "antd";

const { Panel } = Collapse;
class CustomSidebar extends Component {
  render() {
    return (
      <Collapse
        expandIconPosition="end"
        ghost={true}
        defaultActiveKey={["1", "2", "3"]}
      >
        <Panel header="Short Answer" key="1">
          <div className="row mb-3">
            <div className="col-md-12 pt-1">
              {this.props.inputFields.map((field, i) =>
                field.field_type === "text" ? (
                  <div key={i}>
                    <Button
                      className="custom-button"
                      key={i}
                      onClick={() =>
                        this.props.changeFields("append", field.name)
                      }
                    >
                      {field.display_name}
                    </Button>
                  </div>
                ) : null
              )}
            </div>
          </div>
        </Panel>
        <Panel header="Single Choice Answer" key="2">
          <div className="row mb-3">
            <div className="col-md-12 pt-1">
              {this.props.inputFields.map((field, i) =>
                field.field_type === "select" ? (
                  <div key={i}>
                    <Button
                      className="custom-button"
                      key={i}
                      onClick={() =>
                        this.props.changeFields("append", field.name)
                      }
                    >
                      {field.display_name}
                    </Button>
                  </div>
                ) : null
              )}
            </div>
          </div>
        </Panel>
        <Panel header="Number" key="3">
          <div className="row mb-3">
            <div className="col-md-12 pt-1">
              {this.props.inputFields.map((field, i) =>
                field.field_type === "number" ? (
                  <div key={i}>
                    <Button
                      className="custom-button"
                      key={i}
                      onClick={() =>
                        this.props.changeFields("append", field.name)
                      }
                    >
                      {field.display_name}
                    </Button>
                  </div>
                ) : null
              )}
            </div>
          </div>
        </Panel>
        <Panel header="Long Answer" key="4">
          <div className="row mb-3">
            <div className="col-md-12 pt-1">
              {this.props.inputFields.map((field, i) =>
                field.field_type === "longtext" ? (
                  <div key={i}>
                    <Button
                      className="custom-button"
                      key={i}
                      onClick={() =>
                        this.props.changeFields("append", field.name)
                      }
                    >
                      {field.display_name}
                    </Button>
                  </div>
                ) : null
              )}
            </div>
          </div>
        </Panel>
        <Panel header="Date Picker" key="5">
          <div className="row mb-3">
            <div className="col-md-12 pt-1">
              {this.props.inputFields.map((field, i) =>
                field.field_type === "datepicker" ? (
                  <div key={i}>
                    <Button
                      className="custom-button"
                      key={i}
                      onClick={() =>
                        this.props.changeFields("append", field.name)
                      }
                    >
                      {field.display_name}
                    </Button>
                  </div>
                ) : null
              )}
            </div>
          </div>
        </Panel>
        <Panel header="File Upload" key="6">
          <div className="row mb-3">
            <div className="col-md-12 pt-1">
              {this.props.inputFields.map((field, i) =>
                field.field_type === "file" ? (
                  <div key={i}>
                    <Button
                      className="custom-button"
                      key={i}
                      onClick={() =>
                        this.props.changeFields("append", field.name)
                      }
                    >
                      {field.display_name}
                    </Button>
                  </div>
                ) : null
              )}
            </div>
          </div>
        </Panel>
      </Collapse>
    );
  }
}

export default CustomSidebar;
