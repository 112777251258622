import React, { useState } from "react";
import { Form, Select, Button, message, DatePicker, TimePicker } from "antd";
import moment from "moment";
import axiosJSON from "../../util/Api";

const FormItem = Form.Item;
const TIME_LIST = [
  {
    name: "Later Today",
    value: moment().add(3, "hours").format("YYYY-MM-DD"),
  },
  {
    name: "Tomorrow",
    value: moment(moment(), "DD-MM-YYYY").add(1, "days").format("YYYY-MM-DD"),
  },
  {
    name: "One Week",
    value: moment(moment(), "DD-MM-YYYY").add(7, "days").format("YYYY-MM-DD"),
  },
  {
    name: "One Month",
    value: moment(moment()).add(1, "M").format("YYYY-MM-DD"),
  },
  {
    name: "Custom",
    value: "custom",
  },
];
const SetReminder = (props) => {
  const [pickTime, setPickTime] = useState("");
  const [dateTime, setdateTime] = useState("");
  const [isEdit] = useState(props.isEdit);
  // const [reminded, setreminded] = useState(false);

  const onFinish = (values) => {
    const body = {
      date:
        values.eventDate !== "custom"
          ? values.eventDate
          : moment(pickTime).format("YYYY-MM-DD"),
      start_time:
        values.eventDate !== "custom"
          ? values.eventDate + "T" + pickTime
          : pickTime,
      description: values.description,
      is_edit: isEdit === true ? true : false,
    };
    axiosJSON
      .put("/api/candidate/" + props.candidateId + "/snooze/", body)
      .then(({ data, status }) => {
        if (status === 201) {
          message.success("Reminder sent successfully!");
          props.closeModal();
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  const setPickedFrom = (value) => {
    setPickTime(value);
  };

  const showTime = (value) => {
    setdateTime(value);
  };

  return (
    <div>
      <Form
        onFinish={onFinish}
        //   onValuesChange={onchange}
      >
        <FormItem
          name="eventDate"
          label="Schedule Reminder: "
          rules={[{ required: true, message: "Please Schedule Reminder" }]}
        >
          <Select onChange={showTime} placeholder="Choose Day">
            {TIME_LIST.map((item, index) => (
              <Select.Option key={index} value={item.value}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        {dateTime === "custom" && (
          <FormItem
            name="start_time"
            label="Select Date and Time  "
            rules={[
              { required: true, message: "Please provide Date and Time!" },
            ]}
          >
            <DatePicker
              allowClear={true}
              placeholder="Set Custom Reminder"
              format="DD-MM-YYYY HH:mm:ss"
              onOk={(date) =>
                setPickedFrom(moment(date).format("YYYY-MM-DDTHH:mm:ss"))
              }
              value={pickTime ? pickTime : null}
              showTime
              style={{ width: "100%" }}
            />
          </FormItem>
        )}

        {dateTime !== "custom" && (
          <FormItem
            name="start_time"
            label="Select Time  "
            rules={[{ required: true, message: "Please provide Time!" }]}
          >
            <TimePicker
              use12Hours
              allowClear={true}
              placeholder="Set reminder time"
              format="HH:mm:ss"
              onOk={(date) => setPickedFrom(moment(date).format("HH:mm:ss"))}
              value={pickTime ? pickTime : null}
              showTime
              style={{ width: "100%" }}
            />
          </FormItem>
        )}

        <FormItem name="description" label=" Add Description">
          <textarea style={{ width: "100%" }} />
        </FormItem>

        <div>
          <FormItem>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </FormItem>
        </div>
      </Form>
    </div>
  );
};

export default SetReminder;
