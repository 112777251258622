export const styleSheet = {
  boxStyle: {
    width: "100%",
    borderRight: "1.4px solid #A8ACBE",
    background: "#ECEEF1",
    padding: "10px",
    paddingRight: "20px",
    fontFamily: "inter",
    dislay: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  // assetdetails edit button style
  bodyWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  assetsDetailsText: {
    color: "#000000",
    fontWeight: "600",
    fontSize: "20px",
    letterSpacing: "0.15px",
  },
  editButtonStyle: {
    display: "flex",
    color: "#26305C",
    border: "1px solid #51597D",
    justifyContent: "center",
    alignItems: "center",
    padding: "6px 12px",
    fontSize: "14px",
    borderRadius: "6px",
    cursor:"pointer"
  },
  // assetinfo asset status
  statusWrapper: {
    display: "flex",
    gap: "20px",
    marginTop: "30px",
    alignItems: "center",
  },
  statusInfoText: {
    color: "#000000",
    fontWeight: "500",
    fontSize: "16px",
    letterSpacing: "0.15px",
  },
  statusInfoButoon: {
    background: "#C2D3FF",
    border: "1px solid #5274CC",
    borderRadius: "6px",
    display: "flex",
    padding: "4px 8px",
    justifyContent: "center",
    alignItems: "center",
    color: "#3D5799",
    fontSize: "12px",
    fontWeight: "400",
  },
  //   assetinfo details list
  assetInfoDetailsWrapper: {
    display: "flex",
    padding: "12px 16px",
    gap: "10px",
    flexDirection: "column",
    background: "#FFFFFF",
    borderRadius: "6px",
    marginTop: "15px",
  },
  assetInfoDetailsWrapperInner: {
    display: "flex",
    padding: "8px",
    justifyContent: "space-between",
    alignItems:"center",
    gap:"20px"
  },
  titleTextStyle: {
    fontSize: "15px",
    fontWeight: "400",
    color: "#535558",
    letterSpacing: "0.25px",
  },
  titleValueTextStyle: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#2A2B2C",
    letterSpacing: "1.25px",
    textAlign: "right",
  },
  statusButtonStyle: {
    dislay: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 8px",
    color: "#096DD9",
    background: "#C1E6FD",
    border: "1px solid #64C1FA",
    borderRadius: "6px",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "300",
  },
  conditionButtonStyle: {
    dislay: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 8px",
    color: "#0B6E40",
    background: "#D0F1E1",
    border: "1px solid #0E9255",
    borderRadius: "6px",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "300",
  },
  attachmentsTitle: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginTop: "23px",
  },
  attachmentsTitleText: {
    letterSpacing: "0.15px",
    fontWeight: "600",
    fontSize: "16px",
    color: "#000000",
  },
  attachmentsTitleTextDes: {
    letterSpacing: "0.4px",
    fontWeight: "500",
    fontSize: "12px",
    color: "#2A2B2C",
  },
  // attachments images
  attachmentsImageWrapper: {
    display: "flex",
    gap: "12px",
    flexDirection: "column",
    marginTop: "10px",
    background: "#FFFFFF",
    borderRadius: "6px",
    padding: "12px 16px",
  },
  deviceImageTextStyle: {
    letterSpacing: "0.25px",
    fontWeight: "500",
    fontSize: "15px",
    color: "#535558",
    marginTop: "23px",
    lineHeight: "17px",
  },
  deviceImageWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
  },
  imagesWrap:{
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
  imageFrameWrap:{
    border: "1px solid #EAEDF2",
    borderRadius: "6px",
    height: "100px",
    width: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imgTag:{
    objectFit: "contain",
    maxHeight: "100%",
    maxWidth: "100%",
    borderRadius:"2px"
  },
  serialNumberText:{
    letterSpacing: "0.25px",
    fontWeight: "500",
    fontSize: "15px",
    color: "#535558",
    marginTop: "23px",
    lineHeight: "17px",
  },
  workingButtonStyle:{
    color: "#0B6E40",
    background: "#D0F1E1",
    borderColor: "#0E9255",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "5px",
    width:"85",
    height:"28px",
    cursor:"pointer"
  },
  notWorkingButtonStyle:{
    color: "#EE3636",
    background: "#FFDCDC",
    borderColor: "#FF6660",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "5px",
    width: "109px",
    height: "28px",
    cursor:"pointer"
  },

  editButtonGroupWrapperStyle:{
    display: "flex",
    alignItems: "center",
    gap: "10px",
    width: "200px",
  },
  useButtonStyle:{
    color: "#096DD9",
    background: "#E6F7FF",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px",
    width: "72px",
    height: "28px",
    padding: "4px 8px",
    cursor:"pointer"
  },
  notInUseButtonStyle:{
    color: "#C27A06",
    background: "#FFEAAF",
    borderColor: "#FCD44D",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "5px",
    width: "96px",
    height: "28px",
    cursor:"pointer"
  },
  commonStyle:{
    width: "200px",
    height: "32px",
    borderRadius: "4px",
  },
  imageUploadedStyle:{
    display: "flex",
    background: "#ECEEF1",
    borderRadius: "6px",
    width: "23vw",
    height: "80px",
    justifyContent: "center",
    alignItems: "center",
  },
  innerImageStyle:{
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
    color: "#26305C",
    fontWeight: "500",
    letterSpacing: "1.25px",
    alignItems: "center",
    fontSize: "14px",
  },
  fileStyle:{
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
    marginLeft: "10px",
    marginTop: "10px",
  }
};
