import React from "react";
import {
  Button,
  Upload,
  Tooltip,
  Table,
  message,
  Input,
  Spin,
  Select,
  Modal,
  Tag,
} from "antd";
import { UpCircleOutlined, DownCircleOutlined } from "@ant-design/icons";
import { FiUpload, FiDownload } from "react-icons/fi";
import axiosJSON from "../../util/SetHeaderAPI";
import { IoCloseCircleOutline } from "react-icons/io5";
import AddNewSource from "./addNewSource";

const Data = [
  {
    name: "Serial Number",
    key: 1,
    type: "Number",
    description: "Serial Number",
  },
  {
    name: "Candidate Name",
    key: 2,
    type: "Text",
    description: "Enter The Candidate Full Name",
  },
  {
    name: "Candidate Email",
    key: 3,
    type: "Email",
    description: "Enter Candidate's Email Address",
  },
  {
    name: "Candidate Phone",
    key: 4,
    type: "Number",
    description: "Provide Candidate's Phone Number",
  },
  {
    name: "Gender",
    key: 5,
    type: "Text",
    description: "Enter Gender",
  },
  {
    name: "Source",
    key: 6,
    type: "Text",
    description: "KIIT",
  },
  {
    name: "Recruiter",
    key: 7,
    type: "Email",
    description: "kamran.a@consultadd.com",
  },
  {
    name: "Stage",
    key: 8,
    type: "Text",
    description: "Group Discussion",
  },
];

export default class AddBulkUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: Data,
      showTable: false,
      bulkFile: null,
      fileName: "",
      fileUploaded: false,
      loader: false,
      stageList: [],
      openSourceModal: false,
      source: null,
      sourceList: [],
      newSource: false,
      sourceDetail: null,
    };
    this.submitUsers.bind(this);
    this.getSourceList();
  }

  showTableData = () => {
    this.setState({
      showTable: true,
    });
  };

  notTableData = () => {
    this.setState({
      showTable: false,
    });
  };

  handleClose = () => {
    this.props.closeModal();
    this.setState({
      showTable: false,
      fileName: "",
      fileUploaded: false,
    });
  };
  handleSourceClose = () => {
    this.getSourceList();
    this.setState({
      openSourceModal: false,
      newSource: false,
    });
  };
  handleAddSource = () => {
    this.setState({
      newSource: true,
    });
  };
  getSourceList = () => {
    axiosJSON
      .get("api/source/")
      .then(({ data, status }) => {
        if (status === 200) {
          this.setState({
            sourceList: data.data,
          });
        }
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  onDownloadFile = () => {
    axiosJSON
      .get("api/candidate/file/")
      .then(({ data, status }) => {
        window.open(data.file);
      })
      .catch(function (error) {
        message.error(error?.message);
      });
  };

  handleFileChange = (info) => {
    this.setState({
      bulkFile: info.file,
      fileName: info.file.name,
      fileUploaded: true,
      loader: false,
    });
  };

  getSource = (val) => {
    axiosJSON
      .get("api/source/" + val + "/")
      .then(({ data, status }) => {
        this.setState({
          sourceDetail: data.data,
        });
      })
      .catch(function (error) {
        message.error(error?.message);
      });
  };

  submitUsers = () => {
    let self = this;
    this.setState({
      loader: true,
    });
    let formData = new FormData();
    formData.append("file", this.state.bulkFile.originFileObj);
    if (this.state.fileUploaded === false) {
      message.error("Please Upload a File");
    } else {
      axiosJSON
        .post("api/candidate/add_bulk/", formData)
        .then(({ data, status }) => {
          if (status === 202 || status === 201 || status === 200) {
            message.success(data.message);
            this.setState({
              loader: false,
            });
            this.props.closeModal();
          }
        })
        .catch(function (error) {
          self.setState({
            loader: false,
          });
          message.error(error?.message);
          if (error.invalid_user) {
            window.open(error.invalid_user);
          }
        });
    }
  };
  getStageList = () => {
    axiosJSON
      .get("/api/choice/?type=stage")
      .then(({ data }) => {
        this.setState({
          stageList: data.data,
        });
      })
      .catch((err) => message.error(err));
  };

  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "1",
        width: "33%",
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "2",
        width: "33%",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "3",
        width: "33%",
      },
    ];

    return (
      <Spin spinning={this.state.loader}>
        <div className="bulk-container">
          <div className="bulk-form col-12 py-3 mb-4 border_model">
            <div className="row m-0">
              <div className="bulkDescription col-12 p-0 mb-3">
                Add Candidate Importer is used to add new Candidates to the
                system. Candidate Number will be checked for duplication and
                those Candidates with duplicate Candidate number or email, will
                not be added. Please read the log message once the import is
                over to check the status.
              </div>
              <div className="bulkDownload col-6 p-0">
                <div>
                  Click this link to download a sample Excel File.{"  "}
                </div>
                <div
                  className="link_txt"
                  style={{ cursor: "pointer" }}
                  onClick={this.onDownloadFile}
                >
                  Download a sample file{" "}
                  <FiDownload className="model_download ml-1" />
                </div>
              </div>
              <div
                className="col-6 p-0 link_txt show_field"
                style={{ cursor: "pointer" }}
              >
                {this.state.showTable ? (
                  <span onClick={this.notTableData}>
                    {" "}
                    Show FIeld Info {"  "}
                    <UpCircleOutlined className="model_arrow ml-1" />
                  </span>
                ) : (
                  <span onClick={this.showTableData}>
                    {" "}
                    Show Field Info {"  "}
                    <DownCircleOutlined className="model_arrow ml-1" />
                  </span>
                )}
              </div>
            </div>
            <div className="">
              {this.state.showTable ? (
                <div className="bulk-table_contain top_seperator mt-4 pt-4">
                  {" "}
                  <div className="mb-3">
                    {" "}
                    Your Excel file must contain the following columns. The
                    first row of the excel file, indicates the field names.
                  </div>
                  <Table
                    className="bulk-table"
                    // rowKey={(record) => record.id.toString()}
                    columns={columns}
                    dataSource={this.state.data}
                    pagination={false}
                  ></Table>
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-12 p-0">
            <div className="row">
              <div className="col-6">
                <Upload
                  accept=".csv"
                  onChange={(f) => this.handleFileChange(f)}
                  multiple={false}
                  className="bulk-form"
                  showUploadList={false}
                  disabled={this.state.fileUploaded}
                >
                  <Tooltip title="Upload File">
                    <Input.Group compact>
                      <Input
                        className="input_g1"
                        value={
                          this.state.fileName !== ""
                            ? this.state.fileName
                            : "Upload File"
                        }
                      />
                      {this.state.fileUploaded && (
                        <IoCloseCircleOutline
                          className="mr-2 circle_class_rand"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({
                              bulkFile: null,
                              fileName: "",
                              fileUploaded: false,
                            });
                          }}
                        />
                      )}
                      <Button
                        type="primary upload_button"
                        disabled={this.state.fileUploaded}
                      >
                        <FiUpload className="mr-2" /> Upload
                      </Button>
                    </Input.Group>
                  </Tooltip>
                </Upload>{" "}
                <div className="mt-2">Note: Only CSV files are accepted.</div>
              </div>
              <div className="col-12 mt-4">
                Check Source :{" "}
                <Select
                  showSearch
                  style={{ width: 259 }}
                  placeholder="Select a source"
                  optionFilterProp="children"
                  onChange={(val) => {
                    this.setState({ source: val });
                    this.getSource(val);
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.sourceList.length !== 0 &&
                    this.state.sourceList.map((source, i) => (
                      <Select.Option key={i} value={source.id}>
                        {source.display_name}
                      </Select.Option>
                    ))}
                </Select>
                <Button
                  className="add-btn"
                  type="dashed"
                  onClick={this.handleAddSource}
                >
                  + Add Source
                </Button>
                {this.state.sourceDetail && (
                  <div>
                    Source Key:{" "}
                    <Tag color={"blue"}>{this.state.sourceDetail.name}</Tag>
                    {"  "}
                    <div>
                      Display Name:{" "}
                      <Tag color={"blue"}>
                        {this.state.sourceDetail.display_name}
                      </Tag>
                    </div>
                  </div>
                )}
                <Button
                  type="primary"
                  className="submit_btn mb-0"
                  htmlType="submit"
                  disabled={this.state.fileUploaded === false}
                  onClick={this.submitUsers}
                >
                  Submit
                </Button>
                <Button
                  type="btn-outline-secondary"
                  className="cancel_btn mr-2 mb-0"
                  onClick={this.handleClose}
                >
                  Cancel
                </Button>
                {this.state.newSource && (
                  <Modal
                    title="Add Source"
                    visible={this.state.newSource}
                    style={{ width: "40%", height: "20%" }}
                    footer={null}
                    onCancel={this.handleSourceClose}
                  >
                    <AddNewSource closeModal={this.handleSourceClose} />
                  </Modal>
                )}
              </div>
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}
