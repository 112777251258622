import React from 'react';
import { Tag } from "antd";
import moment from 'moment';

export default class PositionDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        }
    }

    componentWillUnmount() {
        this.Mounted = false
    }

    componentDidMount() {
        this.Mounted = true
    }

    render() {
        const { data } = this.state;
        let status_style = {}
        if (this.state.data.status === "closed")
            status_style = { "background": '#ff3333', 'color': 'black' }
        else if (this.state.data.status === "published")
            status_style = { "background": '#b3ff66' }
        else
            status_style = { "background": '#FDE9A1' }
        return (
            <div className="gx-login-container">
                <div className="gx-login-content education-form education-form-new">
                    <div className="col-12 row p-0 m-0">
                        <div className="form-dropdown col-9 p-0">
                                <h3>{data.title}</h3>
                        </div>
                        <div className="col-3 right p-0">
                            <Tag className="btn-color-left gx-text-white gx-mr-0 gx-rounded-xxl gx-order-sm-2" style={status_style}>{data.status}</Tag>
                        </div>
                    </div>

                    <div className="col-12 p-0">
                        <div> <span>Location :</span> {data.location} </div>
                        <div> <span>Created By : </span> {data.created_by.name} </div>
                        <div> <span>Created At : </span> {moment(data.created).format("YYYY-MM-DD hh:mm A")} </div>
                        <div> <span>Modified At : </span> {data.modified ? moment(data.modified).format("YYYY-MM-DD hh:mm A") : moment(data.created).format("YYYY-MM-DD hh:mm A")}</div>
                        <div> <span>Description: </span>  {data.description}</div>
                    </div>
                </div>
            </div>
        );
    }
}