import React, {useEffect, useState} from "react";
import {Button, Form, Select, message,} from "antd";
import axiosJSON from "../../util/SetHeaderAPI";

const FormItem = Form.Item;

const AssignRecruiter = (props) => {
  const [recruiter, setRecruiter] = useState("");
  const [recruiterList, setRecruiterList] = useState([])

  useEffect(() => {
    getRecruiterList("")
  }, [])

  const getRecruiterList = (query) => {
    axiosJSON.get('api/user/?role=recruiter&query=' + query)
      .then(({data, status}) => {
        if (status === 200) {
          setRecruiterList(data.data)
        }
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = values => {

    const body = {
      'user': recruiter,
    }
    axiosJSON.put('api/candidate/' + props.id + '/assignment/', body)
      .then(({data, status}) => {
        if (status === 202) {
          message.success(data.message);
          props.closeModal()
          props.getPermissions()
        }
      }).catch(function (error) {
      message.error(error.message)
    })

  };
  return (
    <div className="gx-login-content">

      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="gx-signin-form gx-form-row0">

        <FormItem name="recruiter">
          <Select
            showSearch
            placeholder="Select a recruiter"
            optionFilterProp="children"
            onChange={(val) => setRecruiter(val)}
            onSearch={getRecruiterList}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {recruiterList.length !== 0 &&
            recruiterList.map((recruiter, i) =>
              <Select.Option key={recruiter.id}
                             value={recruiter.id}>{recruiter.name.charAt(0).toUpperCase() + recruiter.name.slice(1)}</Select.Option>
            )}
          </Select>
        </FormItem>


        <FormItem style={{ marginBottom:'0px' }}>
          <Button style={{ marginBottom:'0px' }} type="primary" htmlType="submit">
            Submit
          </Button>
        </FormItem>

      </Form>
    </div>
  );
};

export default AssignRecruiter;
