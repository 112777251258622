import React from "react";
import {Input, Select, Tooltip, Tag, Button, message} from "antd";
import {deserialize, serialize} from "../../components/Editor/parseJson";
import SlateEmailEditor from "../../components/EmailTemplateEditor";
import axiosJSON from "../../util/SetHeaderAPI";

const beforeMatch = /{(\w+)$/
const beforeStr = '{{';
const afterStr = '}}';

class EmailPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      template: '',
      cc: [],
      bcc: [],
      from: '',
      subject: '',
      body: [
        {
          type: 'paragraph',
          children: [
            { text: '' },
          ],
        },
      ],
      showCc: false,
      showBcc: false,
      templateList: [],
      emailList: [],
      flag:false,
      template_id:0
    }
  }

  componentDidMount() {
    this.setState({
      template_id:this.props.templateList[0].id,
      template: this.props.templateList[0].name,
      templateList: this.props.templateList,
    },()=>this.getEmailTemplates(this.state.template_id))
  }

  onChangeTemplate = (val) => {
    this.setState({
      template_id: val,
      template: '',
      cc:'',
      showCc:false,
      bcc:'',
      showBcc:false,
      subject:'',
      body:''
    },()=>this.getEmailTemplates(this.state.template_id))
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  tagRender(props) {
    const {label, value, closable, onClose} = props;

    var re = /\S+@\S+\.\S+/;
    return (
      <Tag color={re.test(String(value).toLowerCase()) ? 'gray' : 'darkred'} closable={closable} onClose={onClose}>
        {label}
      </Tag>
    );
  }
  getEmailTemplates=(id)=>{
    this.setState({
      flag:false
    })
    axiosJSON.get('api/candidate/preview_template/?id='+id+'&candidate='+this.props.id)
      .then(({data, status}) => {
        this.setState({
          template: data.data.name,
          cc:data.data.cc,
          showCc:data.data.cc.length>0,
          bcc:data.data.bcc,
          showBcc:data.data.bcc.length>0,
          subject:data.data.subject,
          body:deserialize(new DOMParser().parseFromString(data.data.body, 'text/html').body),
          flag: true
        })
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  setEmails = (type, value) => {
    this.setState({
      [type]: value
    })
  }
  handleBodyChange = e => {
    this.setState({
      body: e,
    });
  };
  onSubmit=()=>{
    const body= {
      'email': true,
      'stage': parseInt(this.props.stage),
      'email_data': {
        'cc': this.state.cc,
        'bcc': this.state.bcc,
        'subject': this.state.subject,
        'body': serialize(this.state.body)
      }
    }
    axiosJSON.put('api/candidate/' + this.props.id + '/', body)
      .then(({data, status}) => {
        if (status === 202) {
          message.success(data.message)
          this.props.closeModal()
        }
      }).catch(function (error) {
      message.error(error.message)
    })
  }

  render() {
    const {template, templateList, cc, emailList, showCc, bcc, showBcc, subject, body,flag} = this.state;
    return (
      <div>

        <div>
          <label> Select template:</label>
          <Select style={{width:'100%',marginTop:'10px',marginBottom:'10px'}} value={template} onSelect={this.onChangeTemplate}>
            {templateList.map((t) =>
              <Select.Option value={t.id} key={t.id}>{t.name + ":" + t.email_type}</Select.Option>
            )}
          </Select>
        </div>

        <div>
         <div className="email-cc">
          {showCc ? null : <Tooltip title="Add recipients to Cc"><span className="gx-fs-sm gx-text-grey"
            onClick={() => this.setState({showCc: !showCc})}>+Cc</span></Tooltip>}
          </div>
          <div className="email-bcc" style={{width: '50%',float:'left'}}>
          {showBcc ? null : <Tooltip title="Add recipients to Bcc"><span className="gx-fs-sm gx-text-grey"
            onClick={() => this.setState({showBcc: !showBcc})}>+Bcc</span></Tooltip>}
          </div>
        </div>

        {showCc && <div>
          <label> Cc </label><br/>
          <Select placeholder="Cc" tagRender={this.tagRender} value={cc} mode="tags" style={{width: '100%'}}
                  onChange={(value) => this.setEmails('cc', value)} tokenSeparators={[',']}>
            {emailList.map((email, i) =>
              <Select.Option key={email}>{email}</Select.Option>)}
          </Select>
        </div>
        }
        {showBcc &&
        <div>
          <label> Bcc </label><br/>
          <Select placeholder="Bcc" tagRender={this.tagRender} value={bcc} mode="tags" style={{width: '100%'}}
                  onChange={(value) => this.setEmails('bcc', value)} tokenSeparators={[',']}>
            {emailList.map((email, i) =>
              <Select.Option key={email}>{email}</Select.Option>)}
          </Select>
        </div>
        }
        <div className="email-sub">
          <label>Subject</label>
          <Input placeholder="Subject" value={subject} name="subject" onChange={this.handleChange}/>
        </div>

        <div>
          <label>Description</label>
          {flag ?
            <div className="email-text-editor-new">
            <SlateEmailEditor beforeMatch={beforeMatch} afterStr={afterStr}
                              beforeStr={beforeStr}
                              value={body}
                              setData={this.handleBodyChange} placeholdersList={[]}/>
            </div>
                              :null
          }
        </div>

        <div>
          <Button type="primary" onClick={this.onSubmit}>Send Email</Button>
        </div>
        <br clear="all"/>
      </div>
    );
  }
}

export default EmailPreview;
