import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  FETCHING,
  MY,
  QUERY,
  UNREAD_COUNTER,
  TIMEZONE,
  FILTER_OBJ,
  SUB_FILTER_OBJ,
  PAGE,
  ALLCANDIDATE,
  SORTBY,
  SUB_QUERY,
  TRAINING_OBJ,
  PERMISSION_DATA,
  CANDIDATE_DATA,
} from "../../constants/ActionTypes";

import axiosJSON from "../../util/SetHeaderAPI";

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS,
  };
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error,
  };
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const fetching = () => {
  return {
    type: FETCHING,
  };
};

export const toggleMy = (myToggle) => {
  return (dispatch) => {
    dispatch({ type: MY, payload: myToggle });
  };
};
export const onSearch = (query) => {
  return (dispatch) => {
    dispatch({ type: QUERY, payload: query });
  };
};
export const unreadNotify = (counter) => {
  return (dispatch) => {
    dispatch({ type: UNREAD_COUNTER, payload: counter });
  };
};

export const getTimeZone = (token) => {
  axiosJSON.defaults.headers["authorization"] = "Token " + token;
  return (dispatch) => {
    axiosJSON
      .get("/api/util/timezone/")
      .then(({ data, status }) => {
        dispatch({ type: TIMEZONE, payload: data.data["timezone"] });
      })
      .catch(function (error) {});
  };
};

export const setFilterObj = (payload) => {
  return (dispatch) => {
    dispatch({ type: FILTER_OBJ, payload });
  };
};

export const setPage = (payload) => {
  return (dispatch) => {
    dispatch({ type: PAGE, payload });
  };
};

export const setAll = (payload) => {
  return (dispatch) => {
    dispatch({ type: ALLCANDIDATE, payload });
  };
};

export const setSortBy = (payload) => {
  return (dispatch) => {
    dispatch({ type: SORTBY, payload });
  };
};

export const setSubFilterObj = (payload) => {
  return (dispatch) => {
    dispatch({ type: SUB_FILTER_OBJ, payload });
  };
};

export const onSubQuerySearch = (query) => {
  return (dispatch) => {
    dispatch({ type: SUB_QUERY, payload: query });
  };
};

export const setTrainingObj = (payload) => {
  return (dispatch) => {
    dispatch({ type: TRAINING_OBJ, payload: payload });
  };
};

export const setHeaderData = (payload) => {
  return (dispatch) => {
    dispatch({ type: CANDIDATE_DATA, payload });
  };
};

export const setPermissionData = (payload) => {
  return (dispatch) => {
    dispatch({ type: PERMISSION_DATA, payload });
  };
};
